import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { Subscription } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { BotTwilioVoiceService } from '../../../services/bot/bot-twilio-voice/bot-twilio-voice.service';
import { ProtocolDroidService } from '../../../protocol-droid/services/protocol-droid.service';

export interface ModalTwilioVoiceProfileOptions {
  authToken: string;
  accountSID: string;
  friendlyName: string;
  transferPhoneNumber: string;
  timeoutListening: string;
}
export type deploymentTwilioInfoType =
  | 'accountSID'
  | 'authToken'
  | 'friendlyName'
  | 'transferPhoneNumber'
  | 'timeoutListening';

export interface IsUpdatingStatus {
  id?: boolean;
  authToken?: boolean;
  accountSID?: boolean;
  connect?: boolean;
  idError?: string;
  accountSIDError?: string;
  authTokenError?: string;
  connectError?: string;
}

@Component({
  selector: 'app-modal-twilio-voice-profile',
  templateUrl: './modal-twilio-voice-profile.component.html',
  styleUrls: ['./modal-twilio-voice-profile.component.scss']
})
export class ModalTwilioVoiceProfileComponent implements OnInit, OnDestroy {
  botId: string;
  accountSID: string;
  authToken: string;
  friendlyName: string;
  flowId: string;
  transferPhoneNumber: string;
  timeoutListening: string;
  isUpdating: IsUpdatingStatus;
  private subscriptions: Subscription[];

  constructor(
    private botTwilioVoiceService: BotTwilioVoiceService,
    private protocolDroidService: ProtocolDroidService,
    private modalRef: BsModalRef
  ) {
    this.subscriptions = [];
    this.isUpdating = {};
  }

  @HostListener('window:keyup', ['$event'])
  onEnter(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.done();
    }
  }

  ngOnInit() {
    this.subscriptions.push(
      this.botTwilioVoiceService.getPageInfos().subscribe((pageInfos: any) => {
        this.friendlyName = pageInfos.friendlyName ? pageInfos.friendlyName : 'Ideta';
        this.accountSID = pageInfos.accountSID;
        this.authToken = pageInfos.authToken;
        this.transferPhoneNumber = pageInfos.transferPhoneNumber;
        this.timeoutListening = pageInfos.timeoutListening;
        this.flowId = pageInfos.flowId ? pageInfos.flowId : '-- Publish to connect a flow --';
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  done(): void {
    this.modalRef.hide();
  }

  updateInfo(infoType: deploymentTwilioInfoType, infoValue: any, acceptEmpty?: boolean) {
    this.isUpdating[infoType] = true;
    if (infoValue || acceptEmpty) {
      this.botTwilioVoiceService.updateInfo(infoType, infoValue || null).then((isSuccess: boolean) => {
        if (!isSuccess) {
          this.isUpdating[infoType + 'Error'] = this.protocolDroidService.translate(
            'misc.errors.unknown',
            'An unknown error occurred. Please contact support.'
          );
        }
        setTimeout(() => {
          this.isUpdating[infoType] = false;
        }, 500);
      });
    } else {
      this.isUpdating[infoType] = false;
    }
  }
}
