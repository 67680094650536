import { BillingService } from './../services/billing/billing.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { PaymentService } from '../services/payment/payment.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-stripe',
  templateUrl: './stripe.component.html',
  styleUrls: ['./stripe.component.scss']
})
export class StripeComponent implements OnInit, OnDestroy {
  connexionStatus = false;
  private subscriptions: Subscription[];

  constructor(private paymentService: PaymentService, private billingService: BillingService) {
    this.subscriptions = [];
  }

  ngOnInit() {
    this.checkStripeStatus();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  checkStripeStatus() {
    this.subscriptions.push(
      this.paymentService
        .readStripeStatus()
        .subscribe((payload: boolean) => (this.connexionStatus = payload ? true : false))
    );
  }

  switchStripeConnect(event: any) {
    if (!event) {
      if (this.connexionStatus) {
        this.billingService
          .predictPlan([{ name: 'stripe_connection', value: false }], {
            onConfirm: () =>
              this.paymentService.deleteStripeInfos().then((success: any) => {
                if (success) {
                  this.connexionStatus = false;
                }
              })
          })
          .catch((error: any) => {
            console.error(error);
            return false;
          });
      } else {
        alert('Stripe not currently connected');
      }
    } else {
      alert('Click on "Connect with Stripe" to connect');
    }
  }
}
