import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { InlineSVGModule } from 'ng-inline-svg';

// Modules
import { ProtocolDroidModule } from '../protocol-droid/protocol-droid.module';
import { UiElementsModule } from '../ui-elements/ui-elements.module';

// Components
import { NodeMappingFormComponent } from './node-mapping-form.component';
import { NodeMappingIconComponent } from './node-mapping-icon/node-mapping-icon.component';
import { BaseMappingFormComponent } from './shared/base-mapping-form.component';
import { MappingDataInputFormComponent } from './mapping-data-input-form/mapping-data-input-form.component';
import { SaveDataFormComponent } from './mapping-data-input-form/save-data-form/save-data-form.component';
import { TriggersFormComponent } from './mapping-data-input-form/triggers-form/triggers-form.component';
import { NlpFormComponent } from './mapping-data-input-form/nlp-form/nlp-form.component';
import { SaveNlpFormComponent } from './mapping-data-input-form/save-nlp-form/save-nlp-form.component';
import { FallbackFormComponent } from './mapping-data-input-form/fallback-form/fallback-form.component';
import { MappingGoToNodeFormComponent } from './mapping-go-to-node-form/mapping-go-to-node-form.component';
import { MappingSwitchFormComponent } from './mapping-switch-form/mapping-switch-form.component';
import { AssertEqualFormComponent } from './mapping-switch-form/assert-equal-form/assert-equal-form.component';
import { SendToExternalApiFormComponent } from './mapping-switch-form/send-to-external-api-form/send-to-external-api-form.component';
import { PerformOperationsFormComponent } from './mapping-switch-form/perform-operations-form/perform-operations-form.component';
import { MappingAsyncActionFormComponent } from './mapping-async-action-form/mapping-async-action-form.component';
import { MappingOptionsFormComponent } from './mapping-options-form/mapping-options-form.component';
import { OptionBehaviorFormComponent } from './mapping-options-form/option-behavior-form/option-behavior-form.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    BsDropdownModule,
    InlineSVGModule,
    UiElementsModule,
    ProtocolDroidModule
  ],
  declarations: [
    NodeMappingFormComponent,
    NodeMappingIconComponent,
    BaseMappingFormComponent,
    MappingDataInputFormComponent,
    SaveDataFormComponent,
    TriggersFormComponent,
    NlpFormComponent,
    SaveNlpFormComponent,
    FallbackFormComponent,
    MappingGoToNodeFormComponent,
    MappingSwitchFormComponent,
    AssertEqualFormComponent,
    SendToExternalApiFormComponent,
    PerformOperationsFormComponent,
    MappingAsyncActionFormComponent,
    MappingOptionsFormComponent,
    OptionBehaviorFormComponent
  ],
  /*
   * MappingOptionsFormComponent is used in 'app-modal-bot-settings'
   * NlpFormComponent and SaveNlpFormComponent are used in 'app-bot-nlp'
   */
  exports: [
    NodeMappingFormComponent,
    MappingOptionsFormComponent,
    NlpFormComponent,
    SaveNlpFormComponent
  ]
})
export class NodeMappingFormModule {}
