import { ButtonPostbackForm } from './button-postback-form/button-postback-form.model';
import { ButtonUrlForm } from './button-url-form/button-url-form.model';
import { ButtonLoginForm } from './button-login-form/button-login-form.model';
import { ButtonCallForm } from './button-call-form/button-call-form.model';

export type ButtonElementForm =
  | ButtonPostbackForm
  | ButtonUrlForm
  | ButtonLoginForm
  | ButtonCallForm;

export interface ButtonFactoryOptions {
  removeTitle?: boolean;
  removeMessengerUrlOptions?: boolean;
}

export class ButtonElementFormFactory {
  static factory(button: any = {}, options?: ButtonFactoryOptions): ButtonElementForm {
    switch (button.type) {
      case 'postback':
        return new ButtonPostbackForm(button, options);
      case 'url':
        return new ButtonUrlForm(button, options);
      case 'login':
        return new ButtonLoginForm(button, options);
      case 'call':
        return new ButtonCallForm(button);
      default:
        return ButtonElementFormFactory.inferButtonType(button, options);
    }
  }

  private static inferButtonType(button: any, options?: ButtonFactoryOptions): ButtonElementForm {
    if (button.targetNode) return new ButtonPostbackForm({ type: 'postback', ...button }, options);
    else if (button.url) return new ButtonUrlForm({ type: 'url', ...button }, options);
    else if (button.service) return new ButtonLoginForm({ type: 'login', ...button });
    else if (button.phoneNumber) return new ButtonCallForm({ type: 'call', ...button });
    else return new ButtonPostbackForm({ type: 'postback', ...button }, options);
  }
}
