import { FormGroup, FormControl, Validators } from '@angular/forms';

import { ButtonElementCall } from 'ideta-library/lib/common/node';

import { RichInputFormControl } from '../../../models/rich-input-form-control.model';

const defaultElement: ButtonElementCall = {
  title: '',
  type: 'call',
  phoneNumber: 0
};

export class ButtonCallForm extends FormGroup {
  constructor(element: ButtonElementCall = defaultElement) {
    const formObject = {
      type: new FormControl(element.type, [Validators.required]),
      title: new RichInputFormControl(element.title, { validators: [Validators.required] }),
      phoneNumber: new RichInputFormControl(element.phoneNumber, {
        validators: [Validators.required]
      })
    };

    super(formObject);
  }
}
