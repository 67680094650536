import { FormGroup, FormControl, Validators } from '@angular/forms';
import { get } from 'lodash';

import { ButtonElementPostback } from 'ideta-library/lib/common/node';

import { ButtonFactoryOptions } from '../button-element-form.factory';
import { RichInputFormControl } from '../../../models/rich-input-form-control.model';
import { DataOperationForm } from '../../../models/data-operation-form.model';
import { NodeKeyFormControl } from '../../../models/node-key-form-control.model';

const defaultElement: ButtonElementPostback = {
  title: 'My Button',
  type: 'postback',
  targetNode: 'noeud_0',
  operations: []
};

export class ButtonPostbackForm extends FormGroup {
  constructor(element: ButtonElementPostback = defaultElement, options?: ButtonFactoryOptions) {
    const formObject = {
      type: new FormControl(element.type, [Validators.required]),
      targetNode: new NodeKeyFormControl(element.targetNode, [Validators.required]),
      operations: DataOperationForm.getDataOpertionFormArray(get(element, 'operations'))
    };

    if (!(options && options.removeTitle)) {
      Object.assign(formObject, {
        title: new RichInputFormControl(element.title, { validators: [Validators.required] })
      });
    }

    super(formObject);
  }
}
