import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { uniqueId } from 'lodash';

import { FallbackType } from 'ideta-library/lib/common/node';

import { FallbackForm } from './fallback-form.model';
import { RichInputFormControl } from '../../../models/rich-input-form-control.model';
import { ProtocolDroidService } from '../../../protocol-droid/services/protocol-droid.service';
import { NodeKeyFormControl } from '../../../models/node-key-form-control.model';
import { BaseMappingFormComponent } from '../../shared/base-mapping-form.component';

@Component({
  selector: 'app-fallback-form',
  templateUrl: './fallback-form.component.html',
  styleUrls: ['./fallback-form.component.scss']
})
export class FallbackFormComponent extends BaseMappingFormComponent implements OnInit {
  @Input() parentForm: FallbackForm;
  fallbackTypes: { [key in FallbackType]?: string };
  componentId: string;
  private subscriptions: Subscription[];

  get fallbackActive(): FormControl {
    return this.parentForm.get('active') as FormControl;
  }

  get fallbackOptions(): FormGroup {
    return this.parentForm.get('options') as FormGroup;
  }

  get fallbackType(): FormControl {
    return this.fallbackOptions.get('type') as FormControl;
  }

  get fallbackMessage(): RichInputFormControl {
    return this.fallbackOptions.get('message') as RichInputFormControl;
  }

  get fallbackNextNode(): NodeKeyFormControl {
    return this.fallbackOptions.get('targetNode') as NodeKeyFormControl;
  }

  get fallbackUrl(): FormControl {
    return this.fallbackOptions.get('url') as FormControl;
  }

  constructor(private protocolDroidService: ProtocolDroidService) {
    super();
    this.componentId = uniqueId();
    this.subscriptions = [];
  }

  ngOnInit() {
    this.subscriptions.push(
      this.protocolDroidService.onTranslationChangeEvent.subscribe(
        () =>
          (this.fallbackTypes = {
            'go-to-node': this.protocolDroidService.translate(
              'shared.node-mapping.data-input.fallback-type-go-to-node',
              'Avancer vers une bulle'
            ),
            message: this.protocolDroidService.translate(
              'shared.node-mapping.data-input.fallback-type-message',
              'Envoyer un message'
            )
            // Not used, remove the rest of the system ?
            // ai: this.protocolDroidService.translate(
            //   'shared.node-mapping.data-input.fallback-type-ai',
            //   'Envoyer vers un service de NLU'
            // )
          })
      )
    );
  }

  activate() {
    this.parentForm.updateActive(this.fallbackActive.value);
  }
}
