import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { trigger, transition, style, animate, state } from '@angular/animations';
import { Subscription } from 'rxjs';
import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';

import { User } from 'ideta-library/lib/common/user';

import { ModalBotDataComponent } from '../../../components/modals/modal-bot-data/modal-bot-data.component';
import { AuthService } from '../../../services/auth/auth.service';
import { CoreSessionService } from '../../../services/session/core-session.service';
import { UserSessionService } from '../../../services/session/user-session.service';
import { ModalUserSettingsComponent } from '../../../components/modals/modal-user-settings/modal-user-settings.component';
import { BotSessionService } from '../../../services/session/bot-session.service';

@Component({
  selector: 'app-sidenav-menu',
  templateUrl: './sidenav-menu.component.html',
  styleUrls: ['./sidenav-menu.component.scss'],
  animations: [
    trigger('compactAnimation', [
      state('true', style({ opacity: 0, display: 'none' })),
      state('false', style({ opacity: 1, display: 'block' })),
      transition('false <=> true', animate('500ms 500ms'))
    ])
  ]
})
export class SidenavMenuComponent implements OnInit, OnDestroy {
  @Input() compact?: boolean;
  @Output() select: EventEmitter<any>;
  @Output() account: EventEmitter<void>;
  @Output() billing: EventEmitter<void>;
  @Output() action: EventEmitter<string>;

  user: User;
  unreachable: any;
  private subscriptions: Subscription[];

  constructor(
    private authService: AuthService,
    private modalService: BsModalService,
    public _session: CoreSessionService,
    public _user: UserSessionService,
    public _bot: BotSessionService
  ) {
    this.subscriptions = [];
    this.account = new EventEmitter();
    this.billing = new EventEmitter();
    this.action = new EventEmitter();
    this.select = new EventEmitter<any>();
    this.compact = true;
    this.unreachable = {
      'data-manager': {
        deployment: true,
        messaging: true
      }
    };
  }

  ngOnInit() {
    this.subscriptions.push(
      this._user.subject$.subscribe(user => {
        this.user = user;
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  selectItem(item: any): void {
    this.select.emit(item);
  }

  logout(): void {
    this._session.endSession();
    this._user.endSession();
    // waiting for all session services to stop;
    setTimeout(() => this.authService.logout(), 50);
  }

  openAccountSettingsModal(): void {
    const modalOptions = {
      class: `modal-dialog-centered modal-lg`
    };

    this.modalService.show(ModalUserSettingsComponent, modalOptions);
  }

  openAccountBillingModal(): void {
    const modalOptions = {
      class: `modal-dialog-centered modal-lg`,
      initialState: { startingTab: 'payment' }
    };

    this.modalService.show(ModalUserSettingsComponent, modalOptions);
  }

  openBotDataModal() {
    if (this._session.location === 'deployment') return;
    if (!this._user.pagesAccess['data-manager']) {
      alert('You do not have the right to access this feature');
      return;
    }
    const modalOptions: ModalOptions = {
      class: `modal-dialog-centered modal-lg`,
      initialState: { botId: this._bot.id },
      backdrop: 'static'
    };

    this.modalService.show(ModalBotDataComponent, modalOptions);
  }
}
