import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormArray, FormControl } from '@angular/forms';

import { MediaType } from 'ideta-library/lib/common/node';

import { TemplateMediaForm } from './template-media-form.model';
import { BaseTemplateFormComponent } from '../shared/base-template-form.component';
import { ButtonsListForm } from '../shared/buttons-list-form/buttons-list-form.model';
import { RichInputFormControl } from '../../models/rich-input-form-control.model';
import { ProtocolDroidService } from '../../protocol-droid/services/protocol-droid.service';

@Component({
  selector: 'app-template-media-form',
  templateUrl: './template-media-form.component.html',
  styleUrls: ['./template-media-form.component.scss']
})
export class TemplateMediaFormComponent extends BaseTemplateFormComponent implements OnInit {
  @Input() parentForm: TemplateMediaForm;
  mediaTypes: { [key in MediaType]: string };
  maxElements: number;
  maxButtons: number;

  get intro(): RichInputFormControl {
    return this.parentForm.get('intro') as RichInputFormControl;
  }

  get elements(): FormArray {
    return this.parentForm.get('elements') as FormArray;
  }

  constructor(private protocolDroidService: ProtocolDroidService) {
    super();
    this.maxElements = 1;
    this.maxButtons = 1;
  }

  ngOnInit() {
    this.protocolDroidService.onTranslationChangeEvent.subscribe(
      () =>
        (this.mediaTypes = {
          image: this.protocolDroidService.translate('shared.node-template-form.media.type-image', 'Image'),
          video: this.protocolDroidService.translate('shared.node-template-form.media.type-video', 'Vidéo'),
          audio: this.protocolDroidService.translate('shared.node-template-form.media.type-audio', 'Audio'),
          map: this.protocolDroidService.translate('shared.node-template-form.media.type-map', 'Google Maps'),
          calendar: this.protocolDroidService.translate(
            'shared.node-template-form.media.type-calendar',
            'Google Calendar'
          )
        })
    );
  }

  getElement(index: number): FormGroup {
    return this.elements.at(index) as FormGroup;
  }

  addElement() {
    this.parentForm.addElement();
  }

  removeElement(index: number) {
    this.elements.removeAt(index);
  }

  moveElementUp(index: number) {
    this.parentForm.moveElement(index, -1);
  }

  moveElementDown(index: number) {
    this.parentForm.moveElement(index, 1);
  }

  getElementType(index: number): FormControl {
    return this.getElement(index).get('mediaType') as FormControl;
  }

  getElementUrl(index: number): FormControl {
    return this.getElement(index).get('url') as FormControl;
  }

  getElementButtons(index: number): ButtonsListForm {
    return this.getElement(index).get('buttons') as ButtonsListForm;
  }

  trackByFn(index: number): number {
    return index;
  }
}
