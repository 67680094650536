import { Component, OnInit, Input } from '@angular/core';

import { GetStartedForm } from './get-started-form.model';
import { NodeKeyFormControl } from '../../models/node-key-form-control.model';

@Component({
  selector: 'app-get-started-form',
  templateUrl: './get-started-form.component.html',
  styleUrls: ['./get-started-form.component.scss']
})
export class GetStartedFormComponent implements OnInit {
  @Input() parentForm: GetStartedForm;

  get targetNode(): NodeKeyFormControl {
    return this.parentForm.get('targetNode') as NodeKeyFormControl;
  }

  constructor() {}

  ngOnInit() {}
}
