import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

import { ButtonsListForm } from './buttons-list-form.model';
import { BaseTemplateFormComponent } from '../base-template-form.component';
import { ButtonPostbackForm } from '../button-postback-form/button-postback-form.model';
import { ProtocolDroidService } from '../../../protocol-droid/services/protocol-droid.service';

@Component({
  selector: 'app-buttons-list-form',
  templateUrl: './buttons-list-form.component.html',
  styleUrls: ['./buttons-list-form.component.scss']
})
export class ButtonsListFormComponent extends BaseTemplateFormComponent implements OnInit {
  @Input() parentForm: ButtonsListForm;
  @Input() selectedParentKey: FormControl;
  @Input() options: {
    isWebMenuForm?: boolean;
    customHeader?: string;
    compatibilityInfos?: boolean;
  };
  @Input() maxButtons: number;
  buttonTypes: { [key: string]: string };
  expandedButtons: { [index: number]: boolean };
  labelNames: string;

  constructor(private protocolDroidService: ProtocolDroidService) {
    super();
    this.options = {
      compatibilityInfos: true
    };
    this.maxButtons = 3;
    this.expandedButtons = {};
  }

  ngOnInit() {
    this.labelNames = this.options.isWebMenuForm ? 'options' : 'buttons';
    this.subscriptions.push(
      this.protocolDroidService.onTranslationChangeEvent.subscribe(() => {
        this.buttonTypes = {
          postback: this.protocolDroidService.translate(
            'shared.node-template-form.button.type-postback',
            'Bulle déclenchée'
          ),
          url: this.protocolDroidService.translate('shared.node-template-form.button.type-url', 'Web URL'),
          login: this.protocolDroidService.translate('shared.node-template-form.button.type-login', 'Authentification')
        };

        if (!this.options.isWebMenuForm) {
          this.buttonTypes.call = this.protocolDroidService.translate(
            'shared.node-template-form.button.type-call',
            'Appel'
          );
          this.buttonTypes.share = this.protocolDroidService.translate(
            'shared.node-template-form.button.type-share',
            'Partage'
          );
        }
      })
    );
  }

  getButton(index: number): FormGroup {
    return this.parentForm.at(index) as FormGroup;
  }

  getButtonTitle(index: number): FormControl {
    return this.getButton(index).get('title') as FormControl;
  }

  getButtonType(index: number): FormControl {
    return this.getButton(index).get('type') as FormControl;
  }

  addButton() {
    this.parentForm.push(new ButtonPostbackForm());
    this.expandedButtons[this.parentForm.length - 1] = true;
  }

  removeButton(index: number) {
    this.expandedButtons[index] = false;
    this.parentForm.removeAt(index);
  }

  moveButton(index: number, shift: -1 | 1) {
    const button = this.parentForm.at(index);
    this.parentForm.removeAt(index);
    this.parentForm.insert(index + shift, button);
  }

  trackByFn(index: number): number {
    return index;
  }
}
