import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { BotNode } from 'ideta-library/lib/common/node';

import { NodeSessionService } from '../../../shared/services/session/node-session.service';
import { NodeService } from '../../../shared/services/node/node.service';
import { NodeTemplateForm } from '../../../shared/node-template-form/node-template-form.model';
import { KeyCreationEvent } from '../../../shared/models/data-selector.component';
import { CachedFormControl } from '../../../shared/models/cached-form-control.model';
import { ErrorsService } from '../../../shared/services/errors/errors.service';
import { CoreSessionService } from '../../../shared/services/session/core-session.service';

@Component({
  selector: 'app-node-template',
  templateUrl: './node-template.component.html',
  styleUrls: ['./node-template.component.scss']
})
export class NodeTemplateComponent implements OnInit, OnDestroy {
  @Output() createNode: EventEmitter<{
    name: string;
    callback: (key: string) => void;
  }>;
  @Output() createDataKey: EventEmitter<KeyCreationEvent>;
  node: Partial<BotNode>;
  templateForm: NodeTemplateForm;
  nodeName: CachedFormControl;
  private templateSub: Subscription;
  private subscriptions: Subscription[];

  get isAbstract(): boolean {
    return this.node && this.node.mapping && this.node.mapping.type === 'switch';
  }

  constructor(
    private nodeService: NodeService,
    private errorsService: ErrorsService,
    private _session: CoreSessionService,
    private _nodes: NodeSessionService
  ) {
    this.createNode = new EventEmitter();
    this.createDataKey = new EventEmitter();
    this.subscriptions = [];
    this.node = {};
  }

  ngOnInit() {
    this.subscriptions.push(
      this._nodes.activeNode$.pipe(filter(node => node && node.key !== this.node.key)).subscribe(node => {
        this.node = node;
        this.clearTemplate();
        if (this._session.location === 'edition') {
          this.nodeName = new CachedFormControl(node.name, Validators.required);
        }
        this.templateForm = new NodeTemplateForm(node.template);
        // delaying sub to avoid valueChange issue on new NodeTemplateForm
        setTimeout(
          () =>
            (this.templateSub =
              this.templateForm && this.templateForm.valueChanges.subscribe(() => this.updateTemplate())),
          400
        );
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
    this.clearTemplate();
  }

  updateName() {
    if (this.nodeName.valid && this.nodeName.value !== this.nodeName.initialValue) {
      this.nodeService.updateNodeName(this._nodes.activeNode.key, this.nodeName.value);
      this.nodeName.resetInitialValue();
    }
  }

  private updateTemplate() {
    if (this.templateForm.valid) {
      this._nodes.activeNode.template = this.templateForm.getRawValue();

      if (this._session.location === 'testing') return;
      // send the value again to refresh the preview
      this._nodes.activeNode$.next(this._nodes.activeNode);
      if (this._session.location === 'broadcast') return;
      // updating in database
      this.nodeService
        .updateNodeTemplate(this.node.key, JSON.parse(JSON.stringify(this.templateForm.getRawValue())))
        .catch(this.errorsService.handlePromise());
    }
  }

  private clearTemplate() {
    if (this.templateSub) this.templateSub.unsubscribe();
    this.templateForm = null;
  }
}
