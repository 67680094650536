import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormArray, FormControl } from '@angular/forms';
import { uniqueId } from 'lodash';

@Component({
  selector: 'app-multiple-selection-dropdown',
  templateUrl: './multiple-selection-dropdown.component.html',
  styleUrls: ['./multiple-selection-dropdown.component.scss']
})
export class MultipleSelectionDropdownComponent implements OnInit {
  @Input() input: FormArray;
  @Input() dicoValues: { [key: string]: string };
  @Input() isDisabled?: boolean;
  @Output() preSelect: EventEmitter<string>;
  @Output() postSelect: EventEmitter<string>;
  bufferInput: FormControl;
  id: string;

  get values() {
    return Object.keys(this.dicoValues || {});
  }

  constructor() {
    this.id = uniqueId();
    this.preSelect = new EventEmitter();
    this.postSelect = new EventEmitter();
    this.bufferInput = new FormControl();
    this.isDisabled = false;
  }

  ngOnInit() {}

  addValue(value: string) {
    this.preSelect.emit(value);
    this.input.push(this.bufferInput);
    this.bufferInput = new FormControl();
    this.postSelect.emit(value);
  }
}
