import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable, Subscription, of } from 'rxjs';
import { tap, map, switchMap } from 'rxjs/operators';
import { auth } from 'firebase/app';
// import { get } from 'lodash';
import { AuthService } from '../../services/auth/auth.service';
// import { FacebookService } from '../../services/facebook/facebook.service';
import { UserService } from '../../services/user/user.service';
import { UserSessionService } from '../../services/session/user-session.service';

interface ConnectionStatus {
  connected: boolean;
  error?: string;
}

@Component({
  selector: 'app-fb-profile-connector',
  templateUrl: './fb-profile-connector.component.html',
  styleUrls: ['./fb-profile-connector.component.scss']
})
export class FbProfileConnectorComponent implements OnInit, OnDestroy {
  status: ConnectionStatus;
  spinning: boolean;
  private subscriptions: Subscription[];

  constructor(
    private authService: AuthService,
    private userService: UserService,
    // private facebookService: FacebookService,
    public _user: UserSessionService
  ) {
    this.subscriptions = [];
    this.status = { connected: false };
    this.spinning = false;
  }

  ngOnInit() {
    this.subscriptions.push(
      this.checkIfUserFbData()
        .pipe(
          switchMap((isFbData: boolean) => (isFbData ? this.checkFbAccountLinked() : of(false))),
          // switchMap((fbAccountLinked: boolean) => (fbAccountLinked ? this.checkConnectedToFb() : of(false)))
        )
        .subscribe((isUserConnected: boolean) => {
          this.status = { connected: isUserConnected };
          this.spinning = false;
        })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  switchFacebookConnect(shouldConnect: boolean): void {
    let promise: Promise<void>;
    this.spinning = true;
    if (shouldConnect) {
      promise = this.connectToFb();
    } else {
      promise = this.disconnectFromFb();
    }

    promise.catch((error: any) => {
      this.status = { connected: false, error: error && error.message };
    });
  }

  private connectToFb(): Promise<any> {
    return this.authService.linkFbWithAuthAccount().then((credentials: auth.UserCredential) => {
//      console.log('credentials : ', JSON.stringify(credentials, undefined, 2));
      const { providerId, profile } = credentials.additionalUserInfo;
      const { oauthAccessToken } = JSON.parse(JSON.stringify(credentials.credential));
      // console.log('user : ', JSON.parse(JSON.stringify(credentials.credential)), oauthAccessToken);
      return this.userService.updateUserFbInfos({
        providerId,
        ...profile,
        accessToken: oauthAccessToken
      });
    });
  }

  private disconnectFromFb(): Promise<any> {
    return this.authService.unlinkWithAuthAccount('facebook.com').then(() => this.userService.updateUserFbInfos(null));
  }

  private checkIfUserFbData(): Observable<boolean> {
    return this._user.subject$.pipe(map((user: any) => !!user.facebook));
  }

  private checkFbAccountLinked(): Observable<boolean> {
    return this.authService.getProviderData('facebook.com')
      .pipe(
        tap((data:any) => console.log(data)),
        map((data: any) => !!data)
      );
  }

  // private checkConnectedToFb(): Promise<boolean> {
  //   return this.facebookService
  //     .getLoginStatus(false)
  //     .then((response: any) => {
  //       console.log('checkConnectedToFb : ', response);
  //       if (response && (response.status === 'not_authorized' || response.status === 'unknown')) alert('Your Facebook account is not authorized. Please contact support.');
  //       return response && response.status === 'connected'
  //     });
  // }
}
