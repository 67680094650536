import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { Observable } from 'rxjs';
import { map, take, first } from 'rxjs/operators';
import { auth as firebaseAuth } from 'firebase/app';

import { Auth, Credential, UserInfos } from './auth.types';
import { RollbarService } from '../rollbar/rollbar.service';

import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private gapiAuth2: any;

  constructor(private afAuth: AngularFireAuth, private router: Router, private injector: Injector) {
    this.getAuth()
      .pipe(first((auth: Auth) => !!(auth && auth.uid)))
      .subscribe((auth: Auth) => {
        const rollbar = this.injector.get(RollbarService);
        rollbar.configure({
          payload: {
            person: {
              id: auth.uid
            }
          }
        });
      });
  }

  getAuth(): Observable<Auth> {
    return this.afAuth.authState;
  }

  getCredential(email: string, password: string): firebaseAuth.AuthCredential {
    return firebaseAuth.EmailAuthProvider.credential(email, password);
  }

  reauthenticateWithCredential(credential: firebaseAuth.AuthCredential): Promise<Credential> {
    return this.getAuth()
      .pipe(take(1))
      .toPromise()
      .then((auth: Auth): Promise<Credential> => auth.reauthenticateWithCredential(credential));
  }

  signinWithGoogle(): void {
    // Important ! This will guarantee the presence of the email address after login
    const scope = 'https://www.googleapis.com/auth/userinfo.email';
    const provider = new firebaseAuth.GoogleAuthProvider();
    provider.setCustomParameters({ prompt: 'select_account' });
    provider.addScope(scope);
    this.afAuth.auth.signInWithPopup(provider).catch((error: any) => console.error(error));
  }

  signinWithFacebook(): void {
    // Important ! This will guarantee the presence of the email address after login
    const scope = 'email';
    const provider = new firebaseAuth.FacebookAuthProvider();
    provider.addScope(scope);
    this.afAuth.auth.signInWithPopup(provider).catch((error: any) => console.error(error));
  }

  createUserWithEmailAndPassword({ email, password }: { email: string; password: string }): Promise<Credential> {
    return this.afAuth.auth.createUserWithEmailAndPassword(email, password);
  }

  loginWithCredentials({ email, password }: { email: string; password: string }): Promise<Credential> {
    return this.afAuth.auth.signInWithEmailAndPassword(email, password);
  }

  loginAnonymously(): Promise<Credential> {
    return this.afAuth.auth.signInAnonymously().catch((error: any) => {
      console.error(error);
      return null;
    });
  }

  resetPasswordInit(email: string): Promise<void> {
    return this.afAuth.auth.sendPasswordResetEmail(email, { url: environment.domain });
  }

  logout(): Promise<void> {
    // removing support bot if signing out
    if (environment.useSupportBot) window['ideta_bot_action']('remove');
    return this.afAuth.auth
      .signOut()
      .then(() => {
        this.router.navigate(['/login']);
      })
      .catch((error: any) => {
        console.error(error);
      });
  }

  linkFbWithAuthAccount(): Promise<Credential> {
    const provider = new firebaseAuth.FacebookAuthProvider();
    provider.addScope(
      ['public_profile', 'email', 'pages_messaging', 'pages_messaging_subscriptions', 'pages_show_list', 'pages_manage_metadata', 'pages_read_user_content', 'pages_manage_engagement'].join(',')
    );

    return this.getAuth()
      .pipe(take(1))
      .toPromise()
      .then((auth: Auth): Promise<Credential> => auth.linkWithPopup(provider))
      .catch((error: any) => {
        if (error.code === 'auth/provider-already-linked') {
          return this.unlinkWithAuthAccount('facebook.com').then(() => this.linkFbWithAuthAccount());
        } else {
          throw error;
        }
      });
  }

  unlinkWithAuthAccount(providerId: string): Promise<Auth> {
    return this.getAuth()
      .pipe(take(1))
      .toPromise()
      .then((auth: Auth): Promise<any> => auth.unlink(providerId));
  }

  getProviderData(providerId: 'password' | 'facebook.com'): Observable<UserInfos> {
    return this.getAuth().pipe(
      map((auth: Auth) => auth && auth.providerData.find((providerData: any) => providerData.providerId === providerId))
    );
  }

  initGoogleAuthSDK(callback: Function = () => {}) {
    if (!this.gapiAuth2) {
      window['googleSDKLoaded'] = () => {
        window['gapi'].load('auth2', () => {
          this.gapiAuth2 = window['gapi'].auth2.init({
            client_id: environment.dialogflow.client_id,
            scope:
              'profile ' +
              'email ' +
              'https://www.googleapis.com/auth/dialogflow ' +
              'https://www.googleapis.com/auth/cloud-platform'
          });
          callback();
        });
      };
      (function(d, s, id) {
        let js;
        const fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) {
          return;
        }
        js = d.createElement(s);
        js.id = id;
        js.src = 'https://apis.google.com/js/client:platform.js?onload=googleSDKLoaded';
        fjs.parentNode.insertBefore(js, fjs);
      })(document, 'script', 'google-jssdk');
    } else {
      callback();
    }
  }

  grantOfflineAccess(): Promise<any> {
    if (this.gapiAuth2) {
      return this.gapiAuth2.grantOfflineAccess();
    }
    return Promise.reject('GAPI is not ready');
  }
}
