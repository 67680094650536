import { FormGroup, FormControl, Validators, ValidationErrors, FormArray } from '@angular/forms';
import { get } from 'lodash';

import {
  DataBoundedOperationOptions,
  DataComposedOperationOptions,
  DataOperation,
  DataSearchOperationOptions,
  OperationType
} from 'ideta-library/lib/common/node';
import { DataRichSegment } from 'ideta-library/lib/common/data';

import { RichInputSegmentForm } from './rich-input-segment-form.model';
import { RichInputFormControl } from './rich-input-form-control.model';
import { DataKeyFormControl } from './data-key-form-control.model';
import { formats } from './regex-formats.model';
import { simplePathValidator } from './custom-validators';

const defaultOperation: DataOperation = {
  type: 'set',
  key: ''
};

export class WarningFormControl extends FormControl {
  warning: string;
  constructor(value: any) {
    super(value);
  }
}

export class DataOperationForm extends FormGroup {
  public indexForm: WarningFormControl;
  private _currentIndex: number;
  private searchOverlap: OperationType[];
  private minMaxOverlap: OperationType[];
  private stringsOverlap: OperationType[];

  public get currentIndex(): number {
    return this._currentIndex;
  }

  constructor(operation: DataOperation = defaultOperation, currentIndex: number, hasConditional?: any) {
    const operationType: OperationType =
      (operation.type as any) === 'convert' ? 'toNumber' : operation.type || 'set'; /* ###L */
    const formObject: any = {
      type: new FormControl(operationType, [Validators.required])
    };

    if (operationType !== 'erase' && operationType !== 'conditional') {
      formObject.key = new DataKeyFormControl(operation.key, [Validators.required]);
    }

    switch (operationType) {
      case 'random':
      case 'slice':
        formObject.options = DataOperationForm.getBoundedOptionsForm(
          operationType === 'random',
          operation.options as DataBoundedOperationOptions
        );
        break;
      case 'find':
      case 'filter':
      case 'conditional':
        formObject.options = DataOperationForm.getSearchOptionsForm(
          operationType === 'conditional',
          operation.options as DataSearchOperationOptions
        );
        break;
      case 'maximum':
      case 'minimum':
      case 'concatenate':
      case 'formula':
      case 'split':
      case 'replace':
      case 'match':
      case 'join':
      case 'get':
        formObject.options = DataOperationForm.getDataComposedOptionsForm(
          operationType,
          operation.options as DataComposedOperationOptions,
          operation.operand
        );
        break;
    }

    if (!DataOperationForm.isNoOperandOperation(operationType)) {
      formObject.operand = new RichInputSegmentForm(operation.operand);
    }

    super(formObject, [
      DataOperationForm.formulaValidator,
      DataOperationForm.requiredSettingValidator,
      DataOperationForm.dataKeyValidator,
      DataOperationForm.operationContentValidator
    ]);

    this.searchOverlap = ['find', 'filter'];
    this.minMaxOverlap = ['maximum', 'minimum'];
    this.stringsOverlap = ['split', 'match'];
    this._currentIndex = currentIndex;
    this.indexForm = new WarningFormControl(currentIndex + 1);
    if (operationType === 'conditional' && !!hasConditional) {
      hasConditional.operation = this;
    }
  }

  public static getDataOpertionFormArray(operations: DataOperation[], validators?: any): FormArray {
    const hasConditional: any = {};
    const formArray = new FormArray(
      (operations || []).map(
        (operation: DataOperation, index: number) => new DataOperationForm(operation, index, hasConditional)
      ),
      validators
    );
    if (!!hasConditional.operation) {
      const trueDBValue = get(hasConditional.operation.get('options.ifTrue'), 'value');
      const falseDBValue = get(hasConditional.operation.get('options.ifFalse'), 'value');
      DataOperationForm.updateConditionalIndex(trueDBValue, hasConditional.operation, 'ifTrue');
      DataOperationForm.updateConditionalIndex(falseDBValue, hasConditional.operation, 'ifFalse');
    }

    return formArray;
  }

  public static updateConditionalIndex(value: any, operation: DataOperationForm, condition: 'ifTrue' | 'ifFalse') {
    const operations = get(operation, 'parent') as FormArray;
    if (operations) {
      const targetOperation = operations.at(+value - 1) as DataOperationForm;
      if (!!targetOperation) {
        (operation.get('options') as FormGroup).setControl(condition, targetOperation.indexForm);
      } else {
        (operation.get('options') as FormGroup).setControl(condition, new WarningFormControl(value));
      }
    }
  }

  // Validate formula
  private static formulaValidator = (group: DataOperationForm): ValidationErrors | null => {
    const type = group.get('type').value as OperationType;
    const source = group.get('options.source') as RichInputFormControl;
    const error = { invalidFormula: true };

    if (type !== 'formula' || !source || !source.renderedValue) return null;

    try {
      let sanitizedValue = source.renderedValue.replace(formats.regex('taggedData'), '42');
      sanitizedValue = sanitizedValue.replace(formats.regex('formulaSanitizer'), '');
      /* tslint:disable-next-line */
      return sanitizedValue !== '' && typeof eval(sanitizedValue) === 'number' ? null : error;
    } catch {
      return error;
    }
  };

  // Validate required settings
  private static requiredSettingValidator = (group: DataOperationForm): ValidationErrors | null => {
    const type = group.get('type').value as OperationType;
    const operand = group.get('operand') as FormGroup;
    const options = group.get('options') as FormGroup;
    const error = { requiredSetting: true };

    if (type === 'random' || type === 'slice') {
      const upperBound = options && options.get('upperBound');
      const lowerBound = options && options.get('lowerBound');

      return upperBound &&
        lowerBound &&
        upperBound.get('value') &&
        lowerBound.get('value') &&
        upperBound.get('value').value &&
        lowerBound.get('value').value
        ? null
        : error;
    }

    if (type === 'find' || type === 'filter') {
      const searchable = options && (options.get('searchable') as DataKeyFormControl);
      const key = options && options.get('key');

      return searchable && searchable.isList('object') && key && !key.get('value').value ? error : null;
    }

    if (type === 'conditional') {
      const key = options && (options.get('key') as RichInputSegmentForm);
      const ifTrue = options && (options.get('ifTrue') as WarningFormControl);
      const ifFalse = options && (options.get('ifFalse') as WarningFormControl);

      DataOperationForm.isOffBoundsInput(ifTrue, group);
      DataOperationForm.isOffBoundsInput(ifFalse, group);

      return key && ifTrue && ifFalse && (!key.get('value').value || !ifTrue.value || !ifFalse.value) ? error : null;
    }

    if (DataOperationForm.isComposedOperation(type)) {
      const object = options && (options.get('object') as RichInputSegmentForm);
      const source = options && (options.get('source') as RichInputFormControl);
      return (object && !object.get('value').value) || (type !== 'get' && source && !source.renderedValue)
        ? error
        : null;
    }

    return !DataOperationForm.isNonRequiredOperandValueOperation(type) && operand && !operand.get('value').value
      ? error
      : null;
  };

  // Validate operation's target datakey
  private static dataKeyValidator = (group: DataOperationForm): ValidationErrors | null => {
    const type = group.get('type').value as OperationType;
    const dataKey = group.get('key') as DataKeyFormControl;

    if (type === 'parse' && !dataKey.isObject && !dataKey.isList()) {
      return { invalidKeyType: 'object' };
    } else if (DataOperationForm.isNoDataKeyOperation(type) || !dataKey || !dataKey.value) {
      return null;
    } else if (DataOperationForm.isStringKeyOperation(type) && !dataKey.isString) {
      return { invalidKeyType: 'string' };
    } else if (DataOperationForm.isNumericalKeyOperation(type) && !dataKey.isNumber) {
      return { invalidKeyType: 'number' };
    } else if (DataOperationForm.isObjectKeyOperation(type) && !dataKey.isObject) {
      return { invalidKeyType: 'object' };
    } else if (DataOperationForm.isListKeyOperation(type)) {
      const error = { invalidKeyType: 'list' };
      if (type === 'split') {
        return !dataKey.isList('string') ? { invalidListType: 'string' } : null;
      }
      if (type === 'find' || type === 'filter') {
        if (type === 'filter' && !dataKey.isList()) return error;
        const searchable = group.get('options.searchable') as DataKeyFormControl;
        return searchable && searchable.value && !searchable.isList() ? error : null;
      }
      return !dataKey.isList() ? error : null;
    }

    return null;
  };

  // Validate operation's content
  private static operationContentValidator = (group: DataOperationForm): ValidationErrors | null => {
    const type = group.get('type').value as OperationType;
    const dataKey = group.get('key') as DataKeyFormControl;
    const operand = group.get('operand') as RichInputSegmentForm;
    const options = group.get('options') as FormGroup;

    const hasDataKeyValue = dataKey && !!dataKey.value;
    const hasOperandValue = operand && !!operand.get('value').value;
    const typeError = { invalidOperationType: true };
    const numericalError = { invalidNumericalValues: true };

    switch (type) {
      case 'push':
        return hasDataKeyValue && hasOperandValue && !dataKey.isList(operand.dataType) ? typeError : null;
      case 'filter':
        const searchable = group.get('options.searchable') as DataKeyFormControl;
        return dataKey &&
          dataKey.isList() &&
          searchable &&
          searchable.isList() &&
          dataKey.listValue !== searchable.listValue
          ? typeError
          : null;
      case 'toNumber':
      case 'toUpperCase':
      case 'toLowerCase':
        return hasOperandValue && !operand.isAlphanumeric ? { invalidAlphaNumericalValues: true } : null;
      case 'merge':
        return hasOperandValue && !operand.isObject ? typeError : null;
      case 'parse':
        return hasOperandValue && !operand.isString ? { invalidAlphaNumericalValues: true } : null;
      case 'random':
      case 'slice':
        const upperBound = (options && options.get('upperBound')) as RichInputSegmentForm;
        const lowerBound = (options && options.get('lowerBound')) as RichInputSegmentForm;

        return upperBound &&
          upperBound.get('value').value &&
          lowerBound &&
          lowerBound.get('value').value &&
          (!upperBound.isNumber || !lowerBound.isNumber)
          ? numericalError
          : null;
      case 'conditional':
        const ifTrue = options && (options.get('ifTrue') as WarningFormControl);
        const ifFalse = options && (options.get('ifFalse') as WarningFormControl);

        return ifTrue && ifFalse && (isNaN(+ifTrue.value) || isNaN(+ifFalse.value)) ? numericalError : null;
      case 'set':
        return hasDataKeyValue &&
          hasOperandValue &&
          !(
            (!operand.isKey &&
              ((!dataKey.isNumber && !dataKey.isBoolean) ||
                (dataKey.isNumber && operand.isNumber) ||
                (dataKey.isBoolean && operand.isBoolean))) ||
            (operand.isKey && dataKey.dataType === operand.dataType)
          )
          ? typeError
          : null;
      default:
        return DataOperationForm.isNumericalKeyOperation(type) && hasOperandValue && !operand.isNumber
          ? numericalError
          : null;
    }
  };

  // Validate conditional indices
  private static isOffBoundsInput = (input: WarningFormControl, group: DataOperationForm): void => {
    if (input && input.value && !isNaN(+input.value)) {
      input.warning =
        !RegExp(/^\d+$/).test(input.value) ||
        +input.value - 1 === get(group, 'currentIndex') ||
        +input.value < 1 ||
        +input.value > get(group, 'parent.length')
          ? 'isOffBounds'
          : null;
    }
  };

  private static isNumericalKeyOperation(type: OperationType): boolean {
    return (
      type === 'dateNow' ||
      type === 'add' ||
      type === 'remove' ||
      type === 'divide' ||
      type === 'multiply' ||
      type === 'random' ||
      type === 'round' ||
      type === 'toNumber' ||
      type === 'formula' ||
      type === 'find'
    );
  }

  private static isStringKeyOperation(type: OperationType): boolean {
    return (
      type === 'concatenate' ||
      type === 'replace' ||
      type === 'join' ||
      type === 'toString' ||
      type === 'toUpperCase' ||
      type === 'toLowerCase' ||
      type === 'stringify'
    );
  }

  private static isObjectKeyOperation(type: OperationType): boolean {
    return type === 'minimum' || type === 'maximum' || type === 'merge';
  }

  private static isListKeyOperation(type: OperationType) {
    return (
      type === 'split' ||
      type === 'match' ||
      type === 'push' ||
      type === 'slice' ||
      type === 'filter' ||
      type === 'sort' ||
      type === 'invertedSort'
    );
  }

  private static isNonRequiredOperandValueOperation(type: OperationType): boolean {
    return type === 'sort' || type === 'invertedSort';
  }

  private static isNoOperandOperation(type: OperationType): boolean {
    return (
      type === 'delete' ||
      type === 'erase' ||
      type === 'dateNow' ||
      type === 'random' ||
      type === 'slice' ||
      type === 'find' ||
      type === 'filter' ||
      type === 'conditional' ||
      this.isComposedOperation(type)
    );
  }

  private static isComposedOperation(type: OperationType): boolean {
    return (
      type === 'maximum' ||
      type === 'minimum' ||
      type === 'concatenate' ||
      type === 'formula' ||
      type === 'split' ||
      type === 'replace' ||
      type === 'match' ||
      type === 'join' ||
      type === 'get'
    );
  }

  private static isNoDataKeyOperation(type: OperationType): boolean {
    return type === 'erase' || type === 'conditional';
  }

  private static getBoundedOptionsForm(isRandom: boolean, options?: DataBoundedOperationOptions): FormGroup {
    const formGroup: any = {
      upperBound: new RichInputSegmentForm(get(options, 'upperBound')),
      lowerBound: new RichInputSegmentForm(get(options, 'lowerBound'))
    };
    if (isRandom) {
      formGroup.isInteger = new FormControl((options && options.isInteger) || false);
    }
    return new FormGroup(formGroup);
  }

  private static getSearchOptionsForm(isConditional: boolean, options?: DataSearchOperationOptions): FormGroup {
    const formGroup: any = {
      key: new RichInputSegmentForm(get(options, 'key')),
      type: new FormControl(get(options, 'comparisonType.value') || get(options, 'type') || '==', [
        Validators.required
      ]),
      comparator: new RichInputSegmentForm(get(options, 'value') || get(options, 'comparator'))
    };
    if (!isConditional) formGroup.searchable = new DataKeyFormControl(get(options, 'searchable'));
    else {
      formGroup.ifTrue = new WarningFormControl(get(options, 'ifTrue.value') || get(options, 'ifTrue'));
      formGroup.ifFalse = new WarningFormControl(get(options, 'ifFalse.value') || get(options, 'ifFalse'));
    }
    return new FormGroup(formGroup);
  }

  private static getDataComposedOptionsForm(
    type: OperationType,
    options?: DataComposedOperationOptions,
    operand?: DataRichSegment
  ): FormGroup {
    const formGroup: any = {};
    if (type === 'split' || type === 'replace' || type === 'match' || type === 'join') {
      const separator = get(options, 'separator');
      if (separator && separator.value) {
        separator.value =
          type === 'split' && get(options, 'includeSeparator', false) ? `(${separator.value})` : separator.value;
      }
      formGroup.separator = new RichInputSegmentForm(separator);
    }
    if (type === 'replace') {
      formGroup.replacement = new RichInputSegmentForm(get(options, 'replacement'));
    }
    if (type === 'join' || type === 'get') {
      const key = type === 'get' ? get(options, 'key') : null;
      const segment: DataRichSegment = key ? { type: 'key', value: key } : get(options, 'object');
      formGroup.object = new RichInputSegmentForm(segment);
    }
    if (
      type === 'maximum' ||
      type === 'minimum' ||
      type === 'concatenate' ||
      type === 'formula' ||
      type === 'split' ||
      type === 'match' ||
      type === 'replace' ||
      type === 'get'
    ) {
      const source =
        type === 'maximum' || type === 'minimum'
          ? get(options, 'keyList')
          : type === 'concatenate' || type === 'formula'
          ? get(operand, 'value')
          : type === 'get'
          ? get(options, 'path')
          : get(options, 'value');
      formGroup.source = new RichInputFormControl(source || get(options, 'source') || '', {
        validators: type === 'get' ? [simplePathValidator] : [],
        isString: false
      });
    }
    return new FormGroup(formGroup);
  }

  enable(options?: { onlySelf?: boolean; emitEvent?: boolean }): void {
    super.enable(options);
  }

  updateOperationType(type: OperationType) {
    this.setNoKeyForm(type);
    this.setOperandFormType(type);
    this.setOptionsForm(type);
    this.get('type').patchValue(type);
  }

  updateCurrentIndex(index: number, disableValidation?: boolean) {
    this._currentIndex = index;
    this.indexForm.patchValue(index !== null ? index + 1 : index);
    if (disableValidation) return;
    this.updateValueAndValidity();
  }

  private setNoKeyForm(type: OperationType) {
    if (DataOperationForm.isNoDataKeyOperation(type)) {
      this.removeControl('key');
    } else if (!this.contains('key')) {
      this.setControl('key', new DataKeyFormControl(null, [Validators.required]));
    }
  }

  private setOperandFormType(type: OperationType) {
    if (DataOperationForm.isNoOperandOperation(type)) {
      this.removeControl('operand');
    } else if (!(this.get('operand') instanceof RichInputSegmentForm)) {
      this.setControl('operand', new RichInputSegmentForm());
    }
  }

  private setOptionsForm(type: OperationType) {
    const previousType: OperationType = this.get('type').value;
    const options = this.get('options') as FormGroup;
    let newOptionsForm: FormGroup;

    switch (type) {
      case 'random':
      case 'slice':
        newOptionsForm = DataOperationForm.getBoundedOptionsForm(type === 'random');
        break;
      case 'find':
      case 'filter':
      case 'conditional':
        if (this.searchOverlap.indexOf(previousType) > -1 && this.searchOverlap.indexOf(type) > -1 && options) {
          return;
        }
        newOptionsForm = DataOperationForm.getSearchOptionsForm(type === 'conditional');
        break;
      case 'maximum':
      case 'minimum':
      case 'concatenate':
      case 'formula':
      case 'split':
      case 'replace':
      case 'match':
      case 'join':
      case 'get':
        if (
          ((this.minMaxOverlap.indexOf(previousType) > -1 && this.minMaxOverlap.indexOf(type) > -1) ||
            (this.stringsOverlap.indexOf(previousType) > -1 && this.stringsOverlap.indexOf(type) > -1)) &&
          options
        ) {
          return;
        }
        newOptionsForm = DataOperationForm.getDataComposedOptionsForm(type);
        break;
    }

    if (!newOptionsForm) {
      this.removeControl('options');
    } else {
      this.setControl('options', newOptionsForm);
    }
  }
}

// Two validators tha are not used for now

// private static excludeSingleQuote = (group: DataOperationForm): ValidationErrors | null => {
//   const type = group.get('type').value;
//   const operand = group.get('operand.value');
//   const error = { invalidSingleQuote: true };

//   return !DataOperationForm.isRichInputOperation(type) && operand && operand.value.match(/'/g) ? error : null;
// };

// private static invalidAlphaNumericalValues = (group: DataOperationForm): ValidationErrors | null => {
//   const type = group.get('type').value;
//   const options = group.get('options') as FormControl;
//   const error = { invalidAlphaNumericalValues: true };

//   if (type === 'find' || type === 'filter') {
//     const key = options && (options.get('key') as RichInputSegmentForm);
//     const value = options && (options.get('value') as RichInputSegmentForm);

//     return key &&
//       value &&
//       key.get('value').value &&
//       value.get('value').value &&
//       (!key.isAlphanumeric || !value.isAlphanumeric)
//       ? error
//       : null;
//   }

//   return null;
// };
