import { FormGroup, FormControl, Validators } from '@angular/forms';

import { TemplateQuickReplies } from 'ideta-library/lib/common/node';

import { QuickRepliesAutoForm } from './quick-replies-auto-form/quick-replies-auto-form.model';
import { QuickRepliesManualForm } from './quick-replies-manual-form/quick-replies-manual-form.model';
import { RichInputFormControl } from '../../models/rich-input-form-control.model';

import { environment } from '../../../../environments/environment';

const defaultTemplate: TemplateQuickReplies = {
  text: 'What is your favorite color?',
  feedType: 'manual',
  quickReplies: [
    {
      contentType: 'text',
      title: 'Red',
      targetNode: 'noeud_0',
      imageUrl: environment.domain + '/assets/img/icons/apple.png'
    },
    {
      contentType: 'text',
      title: 'Green',
      targetNode: 'noeud_0',
      imageUrl: environment.domain + '/assets/img/icons/clover.png'
    },
    {
      contentType: 'text',
      title: 'Blue! No Yellow!',
      targetNode: 'noeud_0',
      imageUrl: environment.domain + '/assets/img/icons/taxi.png'
    }
  ]
};

export class TemplateQuickRepliesForm extends FormGroup {
  constructor(template: TemplateQuickReplies = defaultTemplate) {
    const formObject = {
      text: new RichInputFormControl(template.text, { validators: [Validators.required] }),
      feedType: new FormControl(template.feedType || 'manual', [Validators.required]),
      quickReplies: new QuickRepliesManualForm(template.feedType === 'manual' && template.quickReplies),
      autoOptions: new QuickRepliesAutoForm(template.feedType === 'auto' && template.autoOptions)
    };

    super(formObject);

    this.switchControlsDisabling();
  }

  enable(options?: { onlySelf?: boolean; emitEvent?: boolean }): void {
    super.enable(options);
    this.switchControlsDisabling();
  }

  switchControlsDisabling() {
    const feedType = this.get('feedType').value;

    if (feedType === 'manual') {
      this.get('quickReplies').enable({ emitEvent: false });
      this.get('autoOptions').disable({ emitEvent: false });
    } else if (feedType === 'auto') {
      this.get('quickReplies').disable({ emitEvent: false });
      this.get('autoOptions').enable({ emitEvent: false });
    }

    this.updateValueAndValidity();
  }
}
