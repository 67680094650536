import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit {
  @ViewChild('fileInput', { static: true }) fileInput: any;
  @Input() type: string;
  @Input() status: string; // Can be 'loading', 'success' or any error message
  @Output() select: EventEmitter<FileList>;
  uniqueId: string;

  constructor() {
    this.select = new EventEmitter();
  }

  ngOnInit() {
    this.uniqueId =
      'file-' +
      Math.random()
        .toString(36)
        .substring(2, 8);
  }

  handleFileSelection(files: FileList): void {
    if (files.length > 0) {
      this.select.emit(files);
      this.fileInput.nativeElement.value = '';
    }
  }

  getLabelClass(): string {
    switch (this.status) {
      case 'success':
        return 'fa-check';
      case 'loading':
        return 'fa-circle-notch fa-spin';
      case null:
      case undefined:
        return 'fa-upload';
      default:
        return 'fa-times';
    }
  }
}
