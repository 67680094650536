import { Component, OnInit } from '@angular/core';

import { MessageTemplateList } from 'ideta-library/lib/common/message';

import { BasePreviewComponent } from '../shared/base-preview.component';
import { ColorService } from '../../services/color/color.service';

@Component({
  selector: 'app-template-list-preview',
  templateUrl: './template-list-preview.component.html',
  styleUrls: ['./template-list-preview.component.scss']
})
export class TemplateListPreviewComponent extends BasePreviewComponent implements OnInit {
  custom: any;

  get template() {
    return this.message.template as MessageTemplateList;
  }

  get subTitleColor(): string {
    return this.colorService.hexToHSL(this.displayOptions.botColors.text, -30);
  }

  get isAutoGen(): boolean {
    return this.displayOptions.context === 'preview' && this.template.feedType !== 'manual';
  }

  constructor(private colorService: ColorService) {
    super();
    this.custom = {
      'min-width': 'max-content',
      'max-width': '100%',
      'border-radius': '0.4rem',
      border: 'unset'
    };
  }

  ngOnInit() {}

  trackByFn(index: number): number {
    return index;
  }
}
