import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

import { TemplateButtonType } from 'ideta-library/lib/common/node';

import { BaseTemplateFormComponent } from '../base-template-form.component';
import { ButtonElementFormFactory, ButtonElementForm } from '../button-element-form.factory';
import { ProtocolDroidService } from '../../../protocol-droid/services/protocol-droid.service';

@Component({
  selector: 'app-default-action-form',
  templateUrl: './default-action-form.component.html',
  styleUrls: ['./default-action-form.component.scss']
})
export class DefaultActionFormComponent extends BaseTemplateFormComponent implements OnInit {
  @Input() parentForm: ButtonElementForm;
  @Input() selectedParentKey: FormControl;
  @Input() options: {
    hideHeader?: boolean;
    customHeader?: string;
  };
  actionTypes: { [key in TemplateButtonType]?: string };

  get defaultAction(): FormGroup {
    return this.parentForm.get('defaultAction') as FormGroup;
  }

  get defaultActionType(): FormControl {
    return this.defaultAction.get('type') as FormControl;
  }

  constructor(private protocolDroidService: ProtocolDroidService) {
    super();
    this.options = {};
  }

  ngOnInit() {
    this.subscriptions.push(
      this.protocolDroidService.onTranslationChangeEvent.subscribe(
        () =>
          (this.actionTypes = {
            postback: this.protocolDroidService.translate(
              'shared.node-template-form.button.type-postback',
              'Bulle déclenchée'
            ),
            url: this.protocolDroidService.translate('shared.node-template-form.button.type-url', 'Web URL')
          })
      )
    );
  }

  addAction() {
    this.parentForm.addControl(
      'defaultAction',
      ButtonElementFormFactory.factory(
        {
          type: 'url',
          url: ''
        },
        { removeTitle: true }
      )
    );
  }

  removeAction() {
    this.parentForm.removeControl('defaultAction');
  }

  updateType(type: TemplateButtonType) {
    if (this.defaultActionType.value !== type) {
      this.parentForm.setControl(
        'defaultAction',
        ButtonElementFormFactory.factory({ ...this.defaultAction.value, type }, { removeTitle: true })
      );
    }
  }
}
