import { Injectable, OnDestroy } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { Observable, Subscription, BehaviorSubject, combineLatest } from 'rxjs';
import { filter } from 'rxjs/operators';
import { get } from 'lodash';

import {
  AddonsPriceConvention,
  BillingPlan,
  BotBilling,
  BotBillingAddons,
  BotBillingOptions,
  Plans
} from 'ideta-library/lib/common/billing';
import { Bot } from 'ideta-library/lib/common/bot';

import { UserSessionService } from './../session/user-session.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ModalUserSettingsComponent } from './../../components/modals/modal-user-settings/modal-user-settings.component';
import { ProtocolDroidService } from '../../protocol-droid/services/protocol-droid.service';
import { FeedbackService } from '../../services/feedback/feedback.service';
import { SessionModel } from '../session/session.model';
import { CoreSessionService } from '../session/core-session.service';

@Injectable({
  providedIn: 'root'
})
export class BillingService implements SessionModel, OnDestroy {
  private _billing$: BehaviorSubject<BotBilling>;
  private botId: string;
  private billingSub: Subscription;
  private routerSub: Subscription;

  private get billing$() {
    return this._billing$.pipe(filter(value => !!value));
  }

  private get billing(): Partial<BotBilling> {
    return this._billing$.value || {};
  }

  constructor(
    private database: AngularFireDatabase,
    private feedbackService: FeedbackService,
    private protocolDroidService: ProtocolDroidService,
    private modalService: BsModalService,
    private _session: CoreSessionService,
    private _user: UserSessionService
  ) {
    this._billing$ = new BehaviorSubject(null);
    this.routerSub = this._session.routerEvent$.subscribe(event => {
      if (event.botId) {
        this.startSession(event.botId);
      } else {
        this.endSession();
      }
    });
  }

  public startSession(botId: string): Observable<any> {
    if (botId === this.botId) return;
    // ###M
    // console.log('init billing');
    this.botId = botId;
    this.billingSub = this.getBotBilling().subscribe(billing => {
      this._billing$.next(new BotBilling(billing || {}));
    });
  }

  public endSession() {
    if (!this.botId) return;
    // ###M
    // console.log('reset billing');
    if (this.billingSub) this.billingSub.unsubscribe();
    this._billing$.next(null);
    this.botId = null;
  }

  ngOnDestroy(): void {
    this.endSession();
    this.routerSub.unsubscribe();
  }

  /*
   * Check if a bot has the right to use a feature
   */
  public checkRights(bot: Bot, onConfirm: Function) {
    return new Promise((_res, reject) => {
      if (!bot || !this._user.exists) {
        reject(this.protocolDroidService.translate('misc.billing.error', ''));
      } else if (this._user.isAdmin && get(bot, 'billing.activePlan', 'free') === 'free') {
        return this.showMessage('misc.billing.cannot-activate', { type: 'message' });
      } else {
        return onConfirm();
      }
    });
  }

  public predictAddons(
    addon: keyof BotBillingAddons,
    botId: string,
    options?: { onConfirm?: Function; onCancel?: Function }
  ): Promise<any> {
    options = options || {};
    options.onCancel = options.onCancel || (() => {});
    options.onConfirm = options.onConfirm || (() => {});

    if (!botId || !this._user.exists) {
      return Promise.reject(this.protocolDroidService.translate('misc.billing.error', ''));
    }

    return Promise.resolve()
      .then(() => {
        const userRole = this._user.getRoleIn(botId);

        if (!this._user.isAdmin) {
          // skip former users of the platform and super admins
          return true;
        } else if (userRole !== 'owner' && userRole !== 'super_admin') {
          // If user has not the right to decide
          return this.showMessage('misc.billing.not-owner', { type: 'message' }).then(() => false);
        } else if (!!this._user.customerId) {
          // If user already has a CB
          return this.showMessage('misc.billing.addon-' + addon, {
            payload: { rate: AddonsPriceConvention[addon] }
          });
        } else {
          return this.redirectToAccountModal();
        }
      })
      .then(isConfirmed => {
        isConfirmed ? options.onConfirm() : options.onCancel();
      });
  }

  /*
   * Predict the future plan of a bot with incoming new options
   */
  public predictPlan(
    optionsList: { name: keyof BotBillingOptions; value: number | boolean }[],
    options?: {
      onConfirm?: () => Promise<any>;
      onCancel?: Function;
      dbUpdate?: boolean;
      async?: boolean;
    }
  ): Promise<any> {
    options = options || {};
    options.onCancel = options.onCancel || (() => {});
    options.onConfirm = options.onConfirm || (() => Promise.resolve());

    let predicate: BotBillingOptions;

    if (!this.botId) {
      return Promise.reject(this.protocolDroidService.translate('misc.billing.error', ''));
    }

    const promise = options.async
      ? combineLatest([this.billing$, this._user.subject$]).toPromise()
      : Promise.resolve({});

    return promise
      .then(() => {
        predicate = new BotBillingOptions(this.billing.options);
        optionsList.forEach(updateObj => predicate.updateOption(updateObj.name, updateObj.value));
        return this.compareOptions(predicate);
      })
      .then(newPlan => {
        if (newPlan.isReplacing(this.billing.activePlan, this.billing.enable_downgrade !== 'none')) {
          const userRole = this._user.getRoleIn(this.botId);

          if (!this._user.isAdmin) {
            // skip former users of the platform and super admins
            return true;
          } else if (userRole !== 'owner' && userRole !== 'super_admin') {
            // If user has not the right to decide
            return this.showMessage('misc.billing.not-owner', { type: 'message' }).then(() => false);
          } else if (!!this._user.customerId) {
            // If user already has a CB
            return this.showMessage('misc.billing.plan-switch', {
              payload: { plan: newPlan.name, rate: newPlan.rate }
            });
          } else {
            return this.redirectToAccountModal();
          }
        } else {
          return true;
        }
      })
      .then(isConfirmed =>
        isConfirmed
          ? options.onConfirm().then(() => (options.dbUpdate ? this.updateOptions(predicate) : null))
          : options.onCancel()
      );
  }

  private updateOptions(options: BotBillingOptions): Promise<any> {
    return this.database.object(`/bots/${this.botId}/billing/options`).update(options);
  }

  /**
   * [compareOptions description]
   * @return           [description]
   */
  private compareOptions(options: BotBillingOptions): Promise<BillingPlan> {
    return new Promise((resolve, reject) => {
      Plans.forEach(plan => {
        if (plan.compare(options, !(this.billing.activePlan === 'free' || this.billing.enable_downgrade === 'all'))) {
          resolve(plan);
        }
      });
      reject('Users options do not match with any plans');
    });
  }

  private getBotBilling(): Observable<BotBilling> {
    return this.database.object<BotBilling>(`/bots/${this.botId}/billing`).valueChanges();
  }

  private redirectToAccountModal(): Promise<boolean> {
    return this.showMessage('misc.billing.no-payment').then(isConfirmed => {
      if (isConfirmed) {
        const modalOptions = {
          class: `modal-dialog-centered modal-lg`,
          initialState: { startingTab: 'payment' }
        };
        this.modalService.show(ModalUserSettingsComponent, modalOptions);
      }
      return false;
    });
  }

  private showMessage(
    path: string,
    options: {
      payload?: any;
      type?: 'message' | 'confirm';
      def?: string;
    } = {}
  ): Promise<boolean> {
    return this.feedbackService.showMessage(
      this.protocolDroidService.translate(path, options.def || '', options.payload),
      {
        type: options.type || 'confirm',
        decoration: 'info',
        title: this.protocolDroidService.translate('misc.billing.title')
      }
    );
  }
}
