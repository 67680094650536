import { Injectable } from '@angular/core';
import { AngularFireDatabase, AngularFireAction, DatabaseSnapshot } from '@angular/fire/database';
import { Observable, of, pipe } from 'rxjs';
import { map } from 'rxjs/operators';
import { database } from 'firebase/app';

import { Template } from 'ideta-library/lib/common/template';

import { BotSessionService } from '../session/bot-session.service';
import { UserSessionService } from '../session/user-session.service';

@Injectable({
  providedIn: 'root'
})
export class TemplateService {
  constructor(private db: AngularFireDatabase, private _bot: BotSessionService, private _user: UserSessionService) {}

  getTemplatesList(): Observable<Template[]> {
    return this.db
      .list(`/templates/${this._user.id}`)
      .snapshotChanges()
      .pipe(this.templatesHandler());
  }

  createTemplate(nodesIds: { [id: string]: boolean }, name: string): any {
    return this.db
      .list(`/templates/${this._user.id}`)
      .push({
        timestamp: new Date().toString(),
        status: 'creating',
        name,
        botId: this._bot.id,
        nodesIds
      })
      .then((creationRef: database.ThenableReference) => this.getDeploymentStatus(creationRef));
  }

  deleteTemplate(templateId: string): Promise<boolean> {
    return this.db
      .object(`/templates/${this._user.id}/${templateId}`)
      .remove()
      .then(() => true)
      .catch((error: any) => {
        console.error(error);
        return false;
      });
  }

  startDeployment(templateId: string, prefix: string) {
    return this.db
      .list(`/templates/${this._user.id}/${templateId}/deployments`)
      .push({
        timestamp: new Date().toString(),
        status: 'pending',
        botId: this._bot.id,
        prefix
      })
      .then((deploymentRef: database.ThenableReference) => this.getDeploymentStatus(deploymentRef));
  }

  getDeploymentStatus(deploymentRef: database.ThenableReference): Observable<any> {
    if (deploymentRef && deploymentRef.key) {
      return this.db.object(deploymentRef.child('status')).valueChanges();
    } else {
      return of('error');
    }
  }

  private templatesHandler() {
    return pipe(
      map((changes: AngularFireAction<DatabaseSnapshot<any>>[]) => {
        return changes.map(c => {
          return { key: c.payload.key, ...c.payload.val() };
        });
      }),
      map(this.orderListByStringKey('name'))
    );
  }

  private orderListByStringKey(key: string) {
    return (list: any = []) => [...list].sort((a: any, b: any) => a[key].localeCompare(b[key]));
  }
}
