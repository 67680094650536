import { FormControl, FormGroup } from '@angular/forms';

import { MappingAsyncAction } from 'ideta-library/lib/common/node';

import { AsyncActionForm, defaultAction } from '../../ui-elements/components/async-action-form/async-action-form.model';

const defaultMappingAction: MappingAsyncAction = {
  trigger: 'enter',
  action: defaultAction()
};

export class MappingAsyncActionForm extends FormGroup {
  constructor(mappingAction: MappingAsyncAction = defaultMappingAction) {
    const formGroup: any = {
      trigger: new FormControl(mappingAction.trigger || 'enter'),
      action: new AsyncActionForm(mappingAction.action)
    };

    super(formGroup);
  }
}
