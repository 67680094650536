import { Pipe, PipeTransform } from '@angular/core';
import * as linkifyHtml from 'linkifyjs/html';
import { formats } from '../models/regex-formats.model';
import { DataStoreSessionService } from '../services/session/data-store-session.service';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'fullBotText'
})
export class FullBotTextPipe implements PipeTransform {
  constructor(private _dataStore: DataStoreSessionService, private sanitizer: DomSanitizer) {}

  transform(value: string): any {
    const parsedValue = linkifyHtml(this.handleDataReplacement((value || '').replace(/\n|\\n/g, '<br>')), {
      defaultProtocol: 'https'
    });
    return this.sanitizer.bypassSecurityTrustHtml(parsedValue);
  }

  handleDataReplacement(input: string) {
    const data = input.match(formats.regex('chainTaggedData'));
    if (!data) return input;

    data.forEach((key: string) => {
      const { subject, property } = formats.parseAddress(key.slice(3, -3));
      const dataElem = this._dataStore.getDataStoreElem(subject);
      input = input.replace(
        key,
        `<span class="preview-key">${dataElem ? dataElem.key : 'key'}${property ? '...' : ''}</span>`
      );
    });
    return input;
  }
}
