import { FormGroup, FormControl } from '@angular/forms';

import { AsyncAction } from 'ideta-library/lib/common/node';

import { EmailActionForm, defaultEmailAction } from './email-action-form/email-action-form.model';
import { ApiActionForm, defaultApiAction } from './api-action-form/api-action-form.model';
import { WorkflowActionForm, defaultWorkflowAction } from './workflow-action-form/workflow-action-form.model';
import { OperationsActionForm, defaultOperationsAction } from './operations-action-form/operations-action-form.model';

const defaultName = () =>
  `My action - ${Math.random()
    .toString(36)
    .substring(2, 8)}`;

export const defaultAction = (): AsyncAction => ({
  name: defaultName(),
  type: 'api',
  optionsEmail: defaultEmailAction,
  optionsApi: defaultApiAction,
  optionsWorkflow: defaultWorkflowAction,
  optionsOperations: defaultOperationsAction
});

export class AsyncActionForm extends FormGroup {
  constructor(action: AsyncAction = defaultAction()) {
    const formGroup: any = {
      name: new FormControl(action.name || defaultName()),
      type: new FormControl(action.type || 'api'),
      optionsEmail: new EmailActionForm(action.optionsEmail),
      optionsApi: new ApiActionForm(action.optionsApi),
      optionsWorkflow: new WorkflowActionForm(action.optionsWorkflow),
      optionsOperations: new OperationsActionForm(action.optionsOperations)
    };

    super(formGroup);

    this.switchControlsDisabling();
  }

  switchControlsDisabling() {
    const type = this.get('type').value;
    this.get('optionsApi').disable();
    this.get('optionsEmail').disable();
    this.get('optionsWorkflow').disable();
    this.get('optionsOperations').disable();
    switch (type) {
      case 'api':
        this.get('optionsApi').enable();
        break;
      case 'email':
        this.get('optionsEmail').enable();
        break;
      case 'workflow':
        this.get('optionsWorkflow').enable();
        break;
      case 'operations':
        this.get('optionsOperations').enable();
        break;
    }
  }
}
