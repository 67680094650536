import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-bot-channel-icone',
  templateUrl: './bot-channel-icone.component.html',
  styleUrls: ['./bot-channel-icone.component.scss']
})
export class BotChannelIconeComponent implements OnInit {
  @Input() active: boolean;
  @Input() size: number;
  @Input() channel: string;

  constructor() {}

  ngOnInit() {}

  isEmpty() {
    if (this.isEmpty) return true;
    return false;
  }
}
