import { FormGroup, FormControl } from '@angular/forms';

import { NodeKeyFormControl } from '../../../models/node-key-form-control.model';
import { urlValidator } from '../../../models/custom-validators';

export type PersistentMenuButtonType = 'postback' | 'web_url';

export type PersistentMenuWebviewType = 'compact' | 'tall' | 'full';

export interface PersistentMenuButton {
  type: PersistentMenuButtonType;
  title: string;
  /* Data that will be sent back to your webhook as a messaging_postbacks event.
   * Required if type is postback. 1000 character limit
   */
  payload?: string;
  /* URL to open when the button is tapped. Required if type is web_url */
  url?: string;
  /* Height of the webview */
  webview_height_ratio?: 'compact' | 'tall' | 'full';
  /* Must be true if the item type is web_url and the Messenger Extensions SDK
   * will be used in the webview
   */
  messenger_extensions?: boolean;
}

export class PersistentMenuButtonForm extends FormGroup {
  constructor(button: any = {}) {
    let targetNode: string;
    try {
      targetNode = JSON.parse(button.payload).targetNode;
    } catch {}

    const formGroup: any = {
      type: new FormControl(button.type || 'postback'),
      title: new FormControl(button.title),
      targetNode: new NodeKeyFormControl(targetNode, [], false),
      url: new FormControl(button.url, [urlValidator]),
      webviewHeightRatio: new FormControl(button.webview_height_ratio)
    };

    super(formGroup);

    this.switchControlsDisabling();
  }

  getValue(): PersistentMenuButton {
    const button: PersistentMenuButton = {
      type: this.get('type').value,
      title: this.get('title').value
    };

    if (button.type === 'postback') {
      const targetNode = this.get('targetNode').value;
      button.payload = JSON.stringify({ targetNode });
    } else if (button.type === 'web_url') {
      button.url = this.get('url').value;
      button.webview_height_ratio = this.get('webviewHeightRatio').value || 'full';
      button.messenger_extensions = true;
    }

    return button;
  }

  switchControlsDisabling() {
    const type = this.get('type').value;

    if (type === 'postback') {
      this.get('targetNode').enable({ emitEvent: false });
      this.get('url').disable({ emitEvent: false });
      this.get('webviewHeightRatio').disable({ emitEvent: false });
    } else if (type === 'web_url') {
      this.get('targetNode').disable({ emitEvent: false });
      this.get('url').enable({ emitEvent: false });
      this.get('webviewHeightRatio').enable({ emitEvent: false });
    }

    this.updateValueAndValidity();
  }
}
