import { Injectable } from '@angular/core';
import { AngularFireDatabase, AngularFireAction, DatabaseSnapshot } from '@angular/fire/database';
import { Observable, of, pipe } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { Entity, Example, Intent, LexiconCredentials } from 'ideta-library/lib/common/lexicon';

import { BotSessionService } from '../session/bot-session.service';

@Injectable({
  providedIn: 'root'
})
export class LexiconService {
  constructor(private db: AngularFireDatabase, private _bot: BotSessionService) {}

  // CREDENTIALS

  public getCredentials(service: string): Observable<LexiconCredentials> {
    return this._bot.subject$.pipe(
      switchMap(() =>
        this.db.object<LexiconCredentials>(`lexicons-credentials/${this._bot.id}/${service}`).valueChanges()
      )
    );
  }

  public isNlpServiceAvailable(service: string): Observable<boolean> {
    if (!service) return of(false);
    return this.getCredentials(service).pipe(
      map(credentials => {
        switch (service) {
          case 'dialogflow':
            return this.isValidDialogflowCredentials(credentials);
          case 'luis':
            return this.isValidLuisCredentials(credentials);
          default:
            return false;
        }
      })
    );
  }

  // INTENTS

  public getIntents(): Observable<any> {
    return this.db
      .list(`/lexicons/${this._bot.id}/intents`)
      .snapshotChanges()
      .pipe(this.addObjectId());
  }

  public getIntent(intentId: string): Observable<any> {
    return this.db
      .object(`/lexicons/${this._bot.id}/intents/${intentId}`)
      .valueChanges()
      .pipe(map((intent: any) => ({ id: intentId, ...intent })));
  }

  public createIntent(name: string): Promise<void> {
    return new Promise((resolve: any, reject: any) =>
      this.db
        .list(`/lexicons/${this._bot.id}/intents`)
        .push({ name })
        .then(resolve)
        .catch(reject)
    );
  }

  public updateIntent(intent: Intent): Promise<void> {
    const { id } = intent;
    if (!id) return Promise.reject('Missing intent id');
    return this.db.object(`/lexicons/${this._bot.id}/intents/${id}`).update(intent);
  }

  public deleteIntent(intentId: string): Promise<void> {
    if (!intentId) return Promise.reject('Missing intent id');
    return this.db.object(`/lexicons/${this._bot.id}/intents/${intentId}`).remove();
  }

  // ENTITIES

  public getEntities(): Observable<any> {
    return this.db
      .list(`/lexicons/${this._bot.id}/entities`)
      .snapshotChanges()
      .pipe(
        this.addObjectId(),
        this.remapEntityValues()
      );
  }

  public createEntity(entity: Entity): Promise<void> {
    return new Promise((resolve: any, reject: any) =>
      this.db
        .list(`/lexicons/${this._bot.id}/entities`)
        .push(entity)
        .then(resolve)
        .catch(reject)
    );
  }

  public updateEntity(entity: Entity): Promise<void> {
    const { id } = entity;
    if (!id) return Promise.reject('Missing entity id');
    return this.db.object(`/lexicons/${this._bot.id}/entities/${id}`).update(entity);
  }

  public deleteEntity(entityId: string): Promise<void> {
    if (!entityId) return Promise.reject('Missing entity id');
    return this.db.object(`/lexicons/${this._bot.id}/entities/${entityId}`).remove();
  }

  // EXAMPLES

  public getExamples(): Observable<any> {
    return this.db
      .list(`/lexicons/${this._bot.id}/examples`)
      .snapshotChanges()
      .pipe(this.addObjectId());
  }

  public createExample(example: Example): Promise<void> {
    return new Promise((resolve: any, reject: any) =>
      this.db
        .list(`/lexicons/${this._bot.id}/examples`)
        .push(example)
        .then(resolve)
        .catch(reject)
    );
  }

  public updateExample(example: Example): Promise<void> {
    const { id } = example;

    if (!id) return Promise.reject('Missing example id');
    return this.db.object(`/lexicons/${this._bot.id}/examples/${id}`).update(example);
  }

  public deleteExample(exampleId: string): Promise<void> {
    if (!exampleId) return Promise.reject('Missing example id');

    return this.db.object(`/lexicons/${this._bot.id}/examples/${exampleId}`).remove();
  }

  // UTILS

  private addObjectId() {
    return pipe(
      map((changes: AngularFireAction<DatabaseSnapshot<any>>[]) => {
        return changes.map(c => ({ id: c.payload.key, ...c.payload.val() }));
      })
    );
  }

  private remapEntityValues() {
    return pipe(
      map((entities: any) => {
        return entities.map((entity: any) => {
          if (entity.values) {
            entity.values = Object.keys(entity.values).map((entityValueKey: string) => entity.values[entityValueKey]);
          } else {
            entity.values = [];
          }
          return entity;
        });
      })
    );
  }

  private isValidDialogflowCredentials(credentials: any): boolean {
    if (!credentials || !credentials.project_id) return false;
    let isOAuthCredentials = true;
    let isRegularCredentials = true;
    ['access_token', 'refresh_token'].forEach((key: string) => {
      if (!credentials[key]) isOAuthCredentials = false;
    });
    if (!isOAuthCredentials) {
      ['private_key', 'client_email'].forEach((key: string) => {
        if (!credentials[key]) isRegularCredentials = false;
      });
    }
    return isOAuthCredentials || isRegularCredentials;
  }

  private isValidLuisCredentials(credentials: any): boolean {
    if (!credentials) return false;
    let isRegularCredentials = true;
    ['appId', 'authKey', 'endpointKey'].forEach((key: string) => {
      if (!credentials[key]) isRegularCredentials = false;
    });

    return isRegularCredentials;
  }
}
