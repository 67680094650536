import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ColorPickerModule } from 'ngx-color-picker';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { RouterModule } from '@angular/router';


// Modules
import { ProtocolDroidModule } from '../protocol-droid/protocol-droid.module';
import { PipesModule } from '../pipes/pipes.module';

// Components
import { AvatarComponent } from './components/avatar/avatar.component';
import { TinyBotSpinnerComponent } from './components/tiny-bot-spinner/tiny-bot-spinner.component';
import { BotChannelIconeComponent } from './components/bot-channel-icone/bot-channel-icone.component';
import { ToggleComponent } from './components/toggle/toggle.component';
import { ButtonFlashingComponent } from './components/button-flashing/button-flashing.component';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { LayeredTextComponent } from './components/layered-text/layered-text.component';
import { LayeredInputComponent } from './components/layered-input/layered-input.component';
import { DataKeysRichInputComponent } from './components/data-keys-rich-input/data-keys-rich-input.component';
import { DataKeysBadgeComponent } from './components/data-keys-badge/data-keys-badge.component';
import { DataKeysDropdownComponent } from './components/data-keys-dropdown/data-keys-dropdown.component';
import { DataKeysDropdownMenuComponent } from './components/data-keys-dropdown-menu/data-keys-dropdown-menu.component';
import { DataKeysDropdownMenuListComponent } from './components/data-keys-dropdown-menu-list/data-keys-dropdown-menu-list.component';
import { DataKeysDropdownFormComponent } from './components/data-keys-dropdown-form/data-keys-dropdown-form.component';
import { DataKeysSafeInputComponent } from './components/data-keys-safe-input/data-keys-safe-input.component';
import { DataSingleValueSelectorComponent } from './components/data-single-value-selector/data-single-value-selector.component';
import { DataKeysFloatingSelectorComponent } from './components/data-keys-floating-selector/data-keys-floating-selector.component';
import { DataOperationListComponent } from './components/data-operation-list/data-operation-list.component';
import { DataOperationComponent } from './components/data-operation/data-operation.component';
import { DataComparisonListComponent } from './components/data-comparison-list/data-comparison-list.component';
import { DataComparisonComponent } from './components/data-comparison/data-comparison.component';
import { DataComparisonActionSelectorComponent } from './components/data-comparison/data-comparison-action-selector/data-comparison-action-selector.component';
import { DataComparisonTypeSelectorComponent } from './components/data-comparison/data-comparison-type-selector/data-comparison-type-selector.component';
import { DeploymentSelectionComponent } from './components/deployment-selection/deployment-selection.component';
import { NextNodeDropdownComponent } from './components/next-node-dropdown/next-node-dropdown.component';
import { YoutubeDisplaySupportComponent } from './components/youtube-display-support/youtube-display-support.component';
import { NavigationMenuComponent } from './components/navigation-menu/navigation-menu.component';
import { NavTabsComponent } from './components/nav-tabs/nav-tabs.component';
import { NavPanelsComponent } from './components/nav-panels/nav-panels.component';
import { UserAvatarComponent } from './components/user-avatar/user-avatar.component';
import { BadgeComponent } from './components/badge/badge.component';
import { BadgeErrorsComponent } from './components/badge-errors/badge-errors.component';
import { BadgeInfosComponent } from './components/badge-infos/badge-infos.component';
import { SearchBarComponent } from './components/search-bar/search-bar.component';
import { AddItemBarComponent } from './components/add-item-bar/add-item-bar.component';
import { SimpleDropdownComponent } from './components/simple-dropdown/simple-dropdown.component';
import { MultipleSelectionDropdownComponent } from './components/multiple-selection-dropdown/multiple-selection-dropdown.component';
import { SortableSectionComponent } from './components/sortable-section/sortable-section.component';
import { AsyncActionFormComponent } from './components/async-action-form/async-action-form.component';
import { EmailActionFormComponent } from './components/async-action-form/email-action-form/email-action-form.component';
import { ApiActionFormComponent } from './components/async-action-form/api-action-form/api-action-form.component';
import { WorkflowActionFormComponent } from './components/async-action-form/workflow-action-form/workflow-action-form.component';
import { EphemeralMessageComponent } from './components/ephemeral-message/ephemeral-message.component';
import { TypeAdaptingFormControlComponent } from './components/type-adapting-form-control/type-adapting-form-control.component';
import { InlineMediaUploadComponent } from './components/inline-media-upload/inline-media-upload.component';
import { DataDisplayComponent } from './components/data-display/data-display.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { DataDisplayListComponent } from './components/data-display/data-display-list/data-display-list.component';
import { SpeechTextRecorderComponent } from './components/speech-text-recorder/speech-text-recorder.component';
import { SidenavMenuComponent } from './components/sidenav-menu/sidenav-menu.component';
import { TextSpeechReaderComponent } from './components/text-speech-reader/text-speech-reader.component';
import { FilterBadgeComponent } from './components/filter-badge/filter-badge.component';

// Directives
import { InputAutofocusDirective } from './directives/input-autofocus.directive';
import { InputDisabledFixDirective } from './directives/input-disabled-fix.directive';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    BsDropdownModule,
    ProtocolDroidModule,
    ColorPickerModule,
    PerfectScrollbarModule,
    PipesModule
  ],
  declarations: [
    AvatarComponent,
    TinyBotSpinnerComponent,
    BotChannelIconeComponent,
    ToggleComponent,
    ButtonFlashingComponent,
    FileUploadComponent,
    LayeredTextComponent,
    LayeredInputComponent,
    DataKeysRichInputComponent,
    DataKeysBadgeComponent,
    DataKeysDropdownComponent,
    DataKeysDropdownMenuComponent,
    DataKeysDropdownMenuListComponent,
    DataKeysDropdownFormComponent,
    DataKeysFloatingSelectorComponent,
    DataKeysSafeInputComponent,
    DataOperationListComponent,
    DataOperationComponent,
    DataSingleValueSelectorComponent,
    DataComparisonListComponent,
    DataComparisonComponent,
    DataComparisonActionSelectorComponent,
    DeploymentSelectionComponent,
    NextNodeDropdownComponent,
    InputAutofocusDirective,
    InputDisabledFixDirective,
    YoutubeDisplaySupportComponent,
    NavigationMenuComponent,
    NavTabsComponent,
    NavPanelsComponent,
    UserAvatarComponent,
    BadgeComponent,
    BadgeErrorsComponent,
    BadgeInfosComponent,
    SearchBarComponent,
    AddItemBarComponent,
    SimpleDropdownComponent,
    MultipleSelectionDropdownComponent,
    SortableSectionComponent,
    AsyncActionFormComponent,
    EmailActionFormComponent,
    ApiActionFormComponent,
    WorkflowActionFormComponent,
    EphemeralMessageComponent,
    TypeAdaptingFormControlComponent,
    InlineMediaUploadComponent,
    DataDisplayComponent,
    DataDisplayListComponent,
    SidebarComponent,
    SpeechTextRecorderComponent,
    TextSpeechReaderComponent,
    SidenavMenuComponent,
    DataComparisonTypeSelectorComponent,
    FilterBadgeComponent
  ],
  exports: [
    AvatarComponent,
    TinyBotSpinnerComponent,
    BotChannelIconeComponent,
    ToggleComponent,
    ButtonFlashingComponent,
    FileUploadComponent,
    RouterModule,
    LayeredTextComponent,
    LayeredInputComponent,
    DataKeysRichInputComponent,
    DataKeysBadgeComponent,
    DataKeysDropdownComponent,
    DataKeysDropdownMenuComponent,
    DataKeysFloatingSelectorComponent,
    DataKeysSafeInputComponent,
    DataOperationListComponent,
    DataOperationComponent,
    DataSingleValueSelectorComponent,
    DataComparisonListComponent,
    DataComparisonComponent,
    DeploymentSelectionComponent,
    NextNodeDropdownComponent,
    InputAutofocusDirective,
    InputDisabledFixDirective,
    YoutubeDisplaySupportComponent,
    NavigationMenuComponent,
    NavTabsComponent,
    NavPanelsComponent,
    UserAvatarComponent,
    BadgeComponent,
    BadgeErrorsComponent,
    BadgeInfosComponent,
    SimpleDropdownComponent,
    SearchBarComponent,
    AddItemBarComponent,
    MultipleSelectionDropdownComponent,
    SortableSectionComponent,
    AsyncActionFormComponent,
    EphemeralMessageComponent,
    TypeAdaptingFormControlComponent,
    InlineMediaUploadComponent,
    DataDisplayComponent,
    SidebarComponent,
    SpeechTextRecorderComponent,
    TextSpeechReaderComponent,
    SidenavMenuComponent,
    DataComparisonTypeSelectorComponent,
    FilterBadgeComponent
  ]
})
export class UiElementsModule {}
