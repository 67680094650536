import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { ProtocolDroidService } from '../../../protocol-droid/services/protocol-droid.service';

@Component({
  selector: 'app-badge-infos',
  templateUrl: './badge-infos.component.html',
  styleUrls: ['./badge-infos.component.scss']
})
export class BadgeInfosComponent implements OnInit, OnDestroy {
  @Input() infoAddress: string;
  info: string;
  private subscription: Subscription;

  constructor(private protocolDroidService: ProtocolDroidService) {}

  ngOnInit() {
    this.subscription = this.protocolDroidService.onTranslationChangeEvent.subscribe(() => {
      this.info = this.protocolDroidService.translate(this.infoAddress);
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
