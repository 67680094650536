import { filter } from 'rxjs/operators';
import { Component, OnInit, Input } from '@angular/core';
import {
  transition,
  style as animStyle,
  animate,
  trigger,
  state,
  animateChild,
  query,
  group
} from '@angular/animations';
import { Subscription } from 'rxjs';
import { get } from 'lodash';

import { BaseDisplayComponent } from '../components/bot-display/base-display/base-display.component';
import { MessageDisplay } from '../components/bot-display/bot-display.types';
import { Router } from '@angular/router';
import { BotSessionService } from '../services/session/bot-session.service';
import { NodeSessionService } from '../services/session/node-session.service';
import { ConversationSessionService } from '../services/session/conversation-session.service';

@Component({
  selector: 'app-node-template-preview',
  templateUrl: './node-template-preview.component.html',
  styleUrls: ['./node-template-preview.component.scss'],
  animations: [
    trigger('containerAnim', [
      state('left', animStyle({})),
      state('right', animStyle({})),
      transition('void => left', [
        animStyle({ transform: 'translateX(-30%)', filter: 'opacity(0%) blur(6px)' }),
        group([
          animate(
            '1s cubic-bezier(0.23, 1, 0.32, 1)',
            animStyle({ transform: 'translateX(0)', filter: 'opacity(100%) blur(0px)' })
          ),
          query('@bubbleAnim', animateChild())
        ])
      ]),
      transition('void => right', [
        animStyle({ transform: 'translateX(30%)', filter: 'opacity(0%) blur(6px)' }),
        group([
          animate(
            '1s cubic-bezier(0.23, 1, 0.32, 1)',
            animStyle({ transform: 'translateX(0)', filter: 'opacity(100%) blur(0px)' })
          ),
          query('@bubbleAnim', animateChild())
        ])
      ])
    ]),
    trigger('bubbleAnim', [
      state('enter', animStyle({})),
      transition('void => enter', [animStyle({ opacity: 0 }), animate(1000, animStyle({ opacity: 1 }))])
    ]),
    trigger('iconAnim', [
      state('enter', animStyle({})),
      transition('void => enter', [
        animStyle({ transform: 'translateY(-100%)' }),
        animate('0.5s cubic-bezier(.4,1.25,.22,1.04)', animStyle({ transform: 'translateY(0%)' }))
      ])
    ]),
    trigger('qrAnim', [
      transition(':enter', [
        animStyle({ transform: 'translateY(100%)', opacity: 0 }),
        animate('1s cubic-bezier(0.23, 1, 0.32, 1)')
      ]),
      transition(':leave', [
        query('@*', animateChild()),
        animate('0.25s', animStyle({ transform: 'translateY(30%)', opacity: 0 }))
      ])
    ]),
    trigger('responseAnim', [
      transition(':enter', [
        animStyle({ transform: 'translateY(100%)', opacity: 0 }),
        animate('1s cubic-bezier(0.23, 1, 0.32, 1)')
      ]),
      transition(':leave', [
        query('@*', animateChild()),
        animate('0.25s', animStyle({ transform: 'translateY(100%)', opacity: 0 }))
      ])
    ]),
    trigger('navAnim', [
      transition(':enter', [animStyle({ opacity: 0 }), animate('0.5s')]),
      transition(':leave', [animate('0.05s', animStyle({ opacity: 0 }))])
    ])
  ]
})
export class NodeTemplatePreviewComponent extends BaseDisplayComponent implements OnInit {
  @Input() message: MessageDisplay;
  showNode: boolean;
  showResponse: boolean;
  showQR: boolean;
  showTools: boolean;
  isRead: boolean;
  private isInput: boolean;
  private hideSub: Subscription;
  private metadataSub: Subscription;

  constructor(
    private router: Router,
    private _nodes: NodeSessionService,
    private _bot: BotSessionService,
    public _conversation: ConversationSessionService
  ) {
    super();
    this.showNode = true;
    this.showResponse = false;
    this.showQR = false;
    this.isInput = false;
  }

  ngOnInit() {
    if (this.message.sender === this.actors.emitter && this.message.lastOfGroup) {
      this.metadataSub = this.metadata$.pipe(filter(metadata => !!metadata)).subscribe(metadata => {
        this.isRead =
          new Date(get(metadata, `${this.actors.receiver}.last_read`, null)).getTime() >=
          new Date(this.message.sent_at || null).getTime();
      });
    } else {
      if (this.metadataSub) this.metadataSub.unsubscribe();
    }
    // to remove once a new is typing system is found
    if (get(this.message, 'node.id') === 'typing') {
      this._conversation.botDisplayInit
        ? (this.showNode = false)
        : setTimeout(() => (this.showNode = false), this.displayOptions.delay);
    }
    if (this.message.lastOfConv) {
      this.hideSub = this._conversation.hideResponseElementsEvent.subscribe(() => this.hide());
    } else {
      if (this.hideSub) this.hideSub.unsubscribe();
    }
    if (get(this.message, 'lastOfConv') && get(this.message, 'node.dataInput')) {
      this.isInput = !!Object.keys(get(this.message, 'node.dataInput') || {})
        .filter((key: string) => key !== 'fallback')
        .find((key: string) => get(this.message, `node.dataInput.${key}.active`, false));
      if (get(this.message, 'node.dataInput.saveData.options')) {
        this._conversation.inputType$.next(get(this.message, 'node.dataInput.saveData.options.formatCheck'));
      } else {
        this._conversation.inputType$.next('text');
      }
    } else {
      this._conversation.inputType$.next('text');
    }
    this.displayTools(true);
  }

  get lineStyle(): any {
    return {
      'box-shadow': '0 0 0 5rem ' + this.displayOptions.getColors(this.message.sender).bubble
    };
  }

  get subLineStyle(): any {
    return {
      'border-bottom-color': this.displayOptions.getColors(this.message.sender).bubble
    };
  }

  get nodePreviewStyle(): any {
    if (this.message.lastOfGroup && !this.message.lastOfConv) {
      return { 'padding-bottom': '2rem' };
    }
  }

  get bubbleContainerStyle(): any {
    if (this.displayOptions.isIcon) {
      return { 'max-width': 'calc(100% - 3.5rem)' };
    }
  }

  get hasNodeShape(): boolean {
    return (
      'generic' !== this.message.template.type && get(this.message, "template['elements'][0].mediaType") !== 'audio'
    );
  }

  get containerStyle(): any {
    const style = {};

    if (this.hasNodeShape) {
      style['background-color'] = this.displayOptions.getColors(this.message.sender).bubble;
      style['border-color'] = this.displayOptions.getColors(this.message.sender).bubble;
    } else {
      style['border-radius'] = '0';
    }
    if ('quick-replies' === this.message.template.type) {
      style['overflow'] = 'visible';
    }
    return style;
  }

  get containerAnim(): string {
    if (this._conversation.botDisplayInit) return '';
    if (this.displayOptions.context !== 'preview' && this.message.firstOfGroup) {
      if (!this.isRightSided) {
        return 'left';
      }
      return 'right';
    }
    return '';
  }

  get isRightSided(): boolean {
    return (
      (this.displayOptions.inverted && this.message.sender === 'bot') ||
      (!this.displayOptions.inverted && this.message.sender === 'user')
    );
  }

  get title(): string {
    if (this.displayOptions.context === 'web' || this.displayOptions.context === 'preview') {
      return '';
    }
    return (this.message.node && this.message.node.name) || 'Noeud inconnu';
  }

  get iconStyle(): any {
    if (!this.message.lastOfConv) {
      return { opacity: 0.4 };
    }
  }

  get fullSize(): boolean {
    const url = get(this.message, "template['elements'][0].url", '');
    const mediaType = get(this.message, "template['elements'][0].mediaType");
    return url.startsWith('http') && (mediaType === 'audio' || mediaType === 'map' || mediaType === 'calendar');
  }

  get isResponse(): boolean {
    return (
      ('preview' && 'cockpit') !== this.displayOptions.context &&
      this.message.sender !== 'user' &&
      this.message.lastOfConv &&
      !this.displayOptions.freetext &&
      this.isInput
    );
  }

  show(option: string): boolean {
    switch (option) {
      case 'qr':
        return (
          this.message.template.type === 'quick-replies' &&
          (this.message.lastOfConv || this.displayOptions.usedQR) &&
          (this.displayOptions.context !== 'preview' || this.message.template['feedType'] === 'manual')
        );
      case 'response':
        return this.isResponse;
    }
  }

  hide() {
    if (!this.displayOptions.usedQR) {
      this.showQR = false;
    }
    this.showResponse = false;
  }

  forwardEvent(event: any): void {
    if (this.displayOptions.context !== 'cockpit' && (this.message.lastOfConv || this.displayOptions.usedButtons)) {
      this.sendEvent(event);
    }
  }

  sendEvent(event: any): void {
    this._conversation.hideResponseElements();
    this.send.emit(event);
  }

  notifyEnd(): void {
    this.showQR = true;
    this.showResponse = true;
    this.scroll();
    if (this.isResponse) {
      this._conversation.focusResponseBar();
    }
  }

  scroll() {
    if (this.message.lastOfConv) {
      this._conversation.scroll();
    }
  }

  displayTools(status: boolean) {
    if (status && this.displayOptions.context === 'sandbox' && this.message.sender === 'bot') {
      this.showTools = true;
    } else {
      this.showTools = false;
    }
  }

  navigateToNode() {
    if (this.displayOptions.context === 'sandbox') {
      const url = this.router.createUrlTree(['edition', this._bot.id], {
        queryParams: { node: this.message.node.id }
      });
      window.open(url.toString(), '_blank');
    }
  }

  edit() {
    if (this.displayOptions.context === 'sandbox') {
      this._nodes.navigateToNode(this.message.node.id, true, true);
    }
  }
}
