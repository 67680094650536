import { FormControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export class UniqueFormControl extends FormControl {
  private blackList: string[];

  constructor(value: string, validators: ValidatorFn[] = []) {
    super(value, [...validators, UniqueFormControl.uniquenessValidator]);
    this.blackList = [];
  }

  static uniquenessValidator = (control: UniqueFormControl): ValidationErrors | null => {
    const value = control.value;
    const blackList = control.blackList;
    return value && blackList && blackList.indexOf(value) > -1 ? { duplicated: true } : null;
  };

  setBlackList(list: string[]) {
    this.blackList = list;
  }
}
