import { FormControl, FormGroup, FormArray, ValidationErrors, Validators } from '@angular/forms';
import { get } from 'lodash';

import { AllowedScope, DataStoreElemKeys } from 'ideta-library/lib/common/data';

import { DataAsyncActionForm } from './data-async-action-form/data-async-action-form.model';
import { UniqueFormControl } from '../../../../models/unique-form-control.model';
import { formats } from '../../../../models/regex-formats.model';

export class BotDataForm extends FormGroup {
  private scope: AllowedScope;

  constructor(data: any = {}, isSuperAdmin?: boolean) {
    const formGroup: any = {
      key: new UniqueFormControl(data.key, [Validators.required, BotDataForm.keyLengthValidator('key')]),
      desc: new FormControl(data.desc),
      type: new FormControl(data.type, { updateOn: 'change' }),
      value: new FormControl(data.value),
      init: new FormControl(data.init),
      elements: new FormGroup({
        length: BotDataForm.defaultKeysForm('length', 'number'),
        type: new FormControl(get(data, 'elements.type') || 'string', { updateOn: 'change' }),
        keys: BotDataForm.buildKeysForm(get(data, 'elements.keys', {}))
      }),
      keys: BotDataForm.buildKeysForm(get(data, 'keys', {})),
      actions: new FormArray(get(data, 'actions', [] as any).map((action: any) => new DataAsyncActionForm(action))),
      editable: new FormControl(data.editable),
      keepValue: new FormControl(data.keepValue),
      minimum: new FormControl(data.minimum)
    };

    if (isSuperAdmin && data.scope === 'bot') {
      formGroup.isTemplateKey = new FormControl(data.isTemplateKey);
    }

    super(formGroup, { updateOn: 'blur' });
    this.scope = data.scope;
    if (this.scope === 'system') this.disable();
    else this.switchControlsDisabling();
  }

  static keyLengthValidator = (type: 'key' | 'property') => (control: FormControl): ValidationErrors | null => {
    const key = control.value;
    const regex = formats.regex(type === 'key' ? 'strictSubjectName' : 'strictPropertyName', 'm');

    if (key && !key.match(regex)) {
      return type === 'key' ? { invalidKey: true } : { invalidProperty: true };
    } else return null;
  };

  private static buildKeysForm(keys: DataStoreElemKeys): FormGroup {
    const form = {};

    Object.keys(keys).forEach((id: string) => {
      form[id] = BotDataForm.defaultKeysForm(keys[id].key, keys[id].type);
    });

    return new FormGroup(form);
  }

  private static defaultKeysForm(key: string, type: string): FormGroup {
    return new FormGroup({
      key: new UniqueFormControl(key, [Validators.required, BotDataForm.keyLengthValidator('property')]),
      type: new FormControl(type)
    });
  }

  setType(type: string) {
    this.get('type').patchValue(type);
    this.switchControlsDisabling();
  }

  setElementsType(type: string) {
    this.get('elements.type').patchValue(type);
    this.switchControlsDisabling();
  }

  addProperty(address) {
    const keys = this.get(address) as FormGroup;
    const newInfos = formats.generatePropertyInfos();
    keys.addControl(newInfos.id, BotDataForm.defaultKeysForm(newInfos.name, 'string'));
  }

  private switchControlsDisabling(): void {
    const { type } = this.value;

    if ((type === 'string' || type === 'number' || type === 'boolean') && this.scope === 'bot') {
      if (type === 'boolean') {
        const value = this.get('value').value;
        this.get('value').patchValue(value === 'true' || value === true);
      }
      this.get('value').enable();
      this.get('keepValue').enable();
    } else {
      this.get('value').disable();
      this.get('keepValue').disable();
    }

    if ((type === 'string' || type === 'number' || type === 'boolean') && this.scope !== 'bot') {
      if (type === 'boolean') {
        const init = this.get('init').value;
        this.get('init').patchValue(init === 'true' || init === true);
      }
      this.get('init').enable();
    } else this.get('init').disable();

    if (type === 'number') {
      this.get('minimum').enable();
    } else {
      this.get('minimum').disable();
    }

    if (type === 'array') {
      this.get('elements').enable();
      if (this.get('elements.type').value === 'object') this.get('elements.keys').enable();
      else this.get('elements.keys').disable();
    } else this.get('elements').disable();

    if (type === 'object') this.get('keys').enable();
    else this.get('keys').disable();

    this.updateValueAndValidity();
  }
}
