import {
  Component,
  OnInit,
  Input,
  ViewChildren,
  QueryList,
  ElementRef,
  OnDestroy,
  Output,
  EventEmitter,
  HostListener
} from '@angular/core';
import { FormArray } from '@angular/forms';
import { Subject } from 'rxjs';
import { get } from 'lodash';

import { RichInputFormControl } from '../../../models/rich-input-form-control.model';
import {
  DataSelectorComponent,
  DataStoreElemPreview,
  SelectedDataStoreElem
} from '../../../models/data-selector.component';
import { DataStoreSessionService } from '../../../services/session/data-store-session.service';

export interface ViewEvent {
  float?: boolean;
  badge?: boolean;
}

@Component({
  selector: 'app-data-keys-rich-input',
  templateUrl: './data-keys-rich-input.component.html',
  styleUrls: ['./data-keys-rich-input.component.scss']
})
export class DataKeysRichInputComponent extends DataSelectorComponent implements OnInit, OnDestroy {
  @ViewChildren('segmentItem') segmentItems: QueryList<ElementRef>;
  @Input() input: RichInputFormControl;
  @Input() location: string;
  @Input() enableDataStore: boolean;
  @Output() dispose?: EventEmitter<void>;
  @Output() submit?: EventEmitter<void>;
  viewEvent$: Subject<ViewEvent>;
  dataStoreExists: boolean;
  private editingContext: {
    index?: number;
    caretPosition: number;
  };

  get segments(): FormArray {
    return this.input.segments as FormArray;
  }

  constructor(private _dataStore: DataStoreSessionService) {
    super();
    this.dispose = new EventEmitter();
    this.submit = new EventEmitter();
    this.viewEvent$ = new Subject();
    this.editingContext = { caretPosition: 0 };
    this.allowedTypes = ['string', 'number', 'boolean', 'element'];
    this.enableDataStore = true;
  }

  @HostListener('keyup', ['$event'])
  onKeyUp(event) {
    if (event.code === 'ArrowLeft' || event.code === 'ArrowRight') {
      this.goToNextElement(event.code);
    } else if (event.code === 'Backspace' && event.ctrlKey) {
      this.removeKeyBadge();
    }
  }

  ngOnInit() {
    if (this.enableDataStore) {
      this.subscriptions.push(this._dataStore.exists$.subscribe(doesExist => (this.dataStoreExists = doesExist)));
    }
    this.subscriptions.push(this.segments.statusChanges.subscribe(() => this.input.updateValueAndValidity()));
    if (this.input.focus$) {
      this.subscriptions.push(this.input.focus$.subscribe(() => this.focusInput()));
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  updateInput(event: any, index: number) {
    event.preventDefault();
    this.saveEditingContext(index);
    this.input.updateSegment(index, event.target.innerText);
  }

  focusInput() {
    const inputs = this.segmentItems.filter((item: any) => item instanceof ElementRef);
    if (inputs.length > 0) {
      const lastInput = inputs[inputs.length - 1];
      lastInput.nativeElement.focus();
    }
  }

  createDataSegment(dataKey: DataStoreElemPreview) {
    this.createDataKey.emit({
      dataKey,
      callback: createdDataKey => this.insertDataSegment(createdDataKey)
    });
  }

  insertDataSegment(dataKey: SelectedDataStoreElem) {
    if (!dataKey) return;
    const { index = 0, caretPosition: position } = this.editingContext;
    this.input.insertDataSegment(index, position, dataKey);
    this.selectDataKey.emit(dataKey);
  }

  updateDataSegment(dataKey: SelectedDataStoreElem, index: number) {
    this.input.updateSegment(index, dataKey);
    this.selectDataKey.emit(dataKey);
  }

  removeDataSegment(index: number) {
    this.input.removeSegment(index);
  }

  onSubmit(event: any) {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      this.submit.emit();
      if (window.getSelection) {
        window.getSelection().removeAllRanges();
      }
    }
  }

  saveEditingContext(index: number) {
    const element = this.getElement(index);
    this.editingContext = {
      index,
      caretPosition: element ? this.getCaretPosition(element.nativeElement) : 0
    };
  }

  private goToNextElement(code: string) {
    let index = this.editingContext.index;
    const element = this.getElement(index);
    const length = this.getNodeLength(element);
    if (code === 'ArrowLeft' && this.editingContext.caretPosition === 0 && index >= 2) {
      const newIndex = index - 2;
      if (this.moveCaretTo(newIndex)) index = newIndex;
    } else if (
      code === 'ArrowRight' &&
      (!length || this.editingContext.caretPosition === length) &&
      index < this.segments.length - 2
    ) {
      const newIndex = index + 2;
      if (this.moveCaretTo(newIndex, 0)) index = newIndex;
    }
    this.saveEditingContext(index);
  }

  private removeKeyBadge() {
    if (this.editingContext.caretPosition === 0 && this.editingContext.index >= 1) {
      const element = this.getElement(this.editingContext.index - 1);
      if (element && (!element.nativeElement || !element.nativeElement.childNodes)) {
        this.removeDataSegment(this.editingContext.index - 1);
      }
    }
  }

  private getCaretPosition(element: HTMLElement) {
    let caretPos = 0;
    if (window.getSelection) {
      const sel = window.getSelection();
      if (sel.rangeCount) {
        const range = sel.getRangeAt(0);
        if (range.commonAncestorContainer.parentElement === element) {
          caretPos = range.endOffset;
        }
      }
    }
    return caretPos;
  }

  private getElement(index: number): ElementRef {
    const elements = this.segmentItems.toArray();
    return elements[index];
  }

  private getNode(element: ElementRef): any {
    return get(element, 'nativeElement.childNodes[0]');
  }

  private getNodeLength(element: ElementRef): number {
    const node = this.getNode(element);
    return node && node.length;
  }

  // Returns a boolean to know if caret was moved or not
  private moveCaretTo(index: number, position?: number): boolean {
    const node = this.getNode(this.getElement(index));
    if (!node) return false;
    else {
      const range = document.createRange();
      const sel = window.getSelection();
      range.setStart(node, position !== undefined ? position : node.length);
      sel.removeAllRanges();
      sel.addRange(range);
      return true;
    }
  }
}
