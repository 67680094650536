import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { SmoochPageInfos } from 'ideta-library/lib/common/bot';

import { BotSessionService } from '../../session/bot-session.service';

@Injectable({
  providedIn: 'root'
})
export class BotSmoochService {
  constructor(private db: AngularFireDatabase, private _bot: BotSessionService) {}

  public getPageInfos(): Observable<SmoochPageInfos> {
    return this.db
      .object('/bots/' + this._bot.id + '/channels/smooch/pageInfos')
      .valueChanges()
      .pipe(
        catchError((error: any) => {
          if (error && error.code === 'PERMISSION_DENIED') {
            console.log('Permission denied for bot', this._bot.id);
          } else {
            console.error(error);
          }
          return of(null);
        })
      );
  }

  public updatePageToken(token: string): any {
    return this.db
      .object('/bots/' + this._bot.id + '/channels/smooch/pageInfos')
      .update({ access_token: token })
      .then(() => true)
      .catch(error => {
        console.error(error);
        return false;
      });
  }
  public updatePageId(id: string): any {
    return this.db
      .object('/bots/' + this._bot.id + '/channels/smooch/pageInfos')
      .update({ id: id })
      .then(() => true)
      .catch(error => {
        console.error(error);
        return false;
      });
  }
}
