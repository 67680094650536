import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

import { UiElementsModule } from '../ui-elements/ui-elements.module';
import { ProtocolDroidModule } from '../protocol-droid/protocol-droid.module';

// Components
import { NodeTemplateFormComponent } from './node-template-form.component';
import { BaseTemplateFormComponent } from './shared/base-template-form.component';
import { ButtonsListFormComponent } from './shared/buttons-list-form/buttons-list-form.component';
import { ButtonPostbackFormComponent } from './shared/button-postback-form/button-postback-form.component';
import { ButtonUrlFormComponent } from './shared/button-url-form/button-url-form.component';
import { ButtonCallFormComponent } from './shared/button-call-form/button-call-form.component';
import { ButtonLoginFormComponent } from './shared/button-login-form/button-login-form.component';
import { DefaultActionFormComponent } from './shared/default-action-form/default-action-form.component';
import { TemplateTextFormComponent } from './template-text-form/template-text-form.component';
import { TemplateButtonsFormComponent } from './template-buttons-form/template-buttons-form.component';
import { TemplateQuickRepliesFormComponent } from './template-quick-replies-form/template-quick-replies-form.component';
import { QuickRepliesManualFormComponent } from './template-quick-replies-form/quick-replies-manual-form/quick-replies-manual-form.component';
import { QuickRepliesAutoFormComponent } from './template-quick-replies-form/quick-replies-auto-form/quick-replies-auto-form.component';
import { TemplateMediaFormComponent } from './template-media-form/template-media-form.component';
import { TemplateListFormComponent } from './template-list-form/template-list-form.component';
import { ListAutoFormComponent } from './template-list-form/list-auto-form/list-auto-form.component';
import { ListManualFormComponent } from './template-list-form/list-manual-form/list-manual-form.component';
import { TemplateCarrouselFormComponent } from './template-carrousel-form/template-carrousel-form.component';
import { CarrouselManualFormComponent } from './template-carrousel-form/carrousel-manual-form/carrousel-manual-form.component';
import { CarrouselAutoFormComponent } from './template-carrousel-form/carrousel-auto-form/carrousel-auto-form.component';
import { TemplateReceiptFormComponent } from './template-receipt-form/template-receipt-form.component';
import { TemplateOpenGraphFormComponent } from './template-open-graph-form/template-open-graph-form.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    BsDropdownModule,
    UiElementsModule,
    ProtocolDroidModule
  ],
  declarations: [
    NodeTemplateFormComponent,
    BaseTemplateFormComponent,
    ButtonsListFormComponent,
    ButtonPostbackFormComponent,
    ButtonUrlFormComponent,
    ButtonCallFormComponent,
    ButtonLoginFormComponent,
    DefaultActionFormComponent,
    TemplateTextFormComponent,
    TemplateButtonsFormComponent,
    TemplateQuickRepliesFormComponent,
    QuickRepliesManualFormComponent,
    QuickRepliesAutoFormComponent,
    TemplateMediaFormComponent,
    TemplateListFormComponent,
    ListAutoFormComponent,
    ListManualFormComponent,
    TemplateCarrouselFormComponent,
    CarrouselManualFormComponent,
    CarrouselAutoFormComponent,
    TemplateReceiptFormComponent,
    TemplateOpenGraphFormComponent
  ],
  exports: [NodeTemplateFormComponent, ButtonsListFormComponent]
})
export class NodeTemplateFormModule {}
