import { FormGroup, FormControl, FormArray } from '@angular/forms';

interface GreetingLocale {
  locale: string;
  text: string;
}

export type Greeting = GreetingLocale[];

export class GreetingForm extends FormGroup {
  get greetings(): FormArray {
    return this.get('greetings') as FormArray;
  }

  constructor(greetings: any = []) {
    const formGroup = {
      greetings: new FormArray(
        greetings.map(
          (greeting: GreetingLocale) =>
            new FormGroup({
              locale: new FormControl(greeting.locale),
              text: new FormControl(greeting.text)
            })
        )
      )
    };

    super(formGroup);

    if (this.greetings.length === 0) this.addGreeting({ locale: 'default' });
  }

  getProfileValue(): Greeting {
    const greetings = this.greetings.value.filter(
      (greeting: GreetingLocale) => !!greeting.locale && !!greeting.text
    );

    if (greetings.length < 1) greetings.push({ locale: 'default', text: '' });

    return greetings;
  }

  setProfileValue(value: any = []) {
    this.greetings.clear();
    value.forEach((greeting: GreetingLocale) =>
      this.greetings.push(
        new FormGroup({
          locale: new FormControl(greeting.locale),
          text: new FormControl(greeting.text)
        })
      )
    );
  }

  addGreeting(greeting: any = {}) {
    this.greetings.push(
      new FormGroup({
        locale: new FormControl(greeting.locale),
        text: new FormControl(greeting.text)
      })
    );
  }
}
