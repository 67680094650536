import { trigger, state, style, animate, transition } from '@angular/animations';

export const collapse = trigger('collapse', [
  state(
    'collapsed',
    style({
      height: '0',
      overflow: 'hidden'
    })
  ),
  state(
    'expanded',
    style({
      height: '*'
    })
  ),
  transition('collapsed <=> expanded', [animate('0.4s cubic-bezier(.87,-.41,.19,1.44)')])
]);
