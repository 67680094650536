import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { take, filter } from 'rxjs/operators';

import { FeedbackService } from '../../../services/feedback/feedback.service';
import { ErrorsService } from '../../../services/errors/errors.service';
import { BotService } from '../../../services/bot/bot.service';

export interface ModalBotNameOptions {
  botName: string;
  botId: string;
}

@Component({
  selector: 'app-modal-bot-name',
  templateUrl: './modal-bot-name.component.html',
  styleUrls: ['./modal-bot-name.component.scss']
})
export class ModalBotNameComponent implements OnInit {
  botId: string;
  botName: string;
  botNameForm: FormControl;
  private shouldSave: boolean;

  constructor(
    private modalRef: BsModalRef,
    private modalService: BsModalService,
    private feedbackService: FeedbackService,
    private errorsService: ErrorsService,
    private botService: BotService
  ) {
    this.botNameForm = new FormControl('', Validators.required);
  }

  ngOnInit() {
    this.botNameForm.patchValue(this.botName);
    this.modalService.onHidden
      .pipe(
        filter(() => this.modalService.getModalsCount() === 0),
        take(1)
      )
      .subscribe(() => {
        this.onHidden();
      });
  }

  save(): void {
    if (this.botNameForm.valid) {
      this.shouldSave = true;
      this.modalRef.hide();
    }
  }

  cancel(): void {
    this.modalRef.hide();
  }

  private onHidden(): void {
    if (this.shouldSave) {
      this.feedbackService
        .showSpinner()
        .then(() => this.botService.updateBot(this.botId, { name: this.botNameForm.value }))
        .then(() => this.feedbackService.hideSpinner())
        .catch(this.errorsService.handlePromise());
    }
  }
}
