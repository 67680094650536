import { FormGroup, FormArray, FormControl, Validators } from '@angular/forms';

import { AssertEqualOptions, DataComparison } from 'ideta-library/lib/common/node';

import { DataComparisonForm } from '../../../models/data-comparison-form.model';

const defaultOptions = { active: false };

export class AssertEqualForm extends FormGroup {
  constructor(assertEqual: AssertEqualOptions = defaultOptions) {
    const formGroup = {
      active: new FormControl(assertEqual.active, { updateOn: 'change' }),
      comparisons: new FormArray(
        (assertEqual.comparisons &&
          assertEqual.comparisons.map((comparison: DataComparison) => new DataComparisonForm(comparison))) ||
          [],
        [Validators.required]
      )
    };

    super(formGroup);
    this.switchControlsDisabling();
  }

  enable(options?: { onlySelf?: boolean; emitEvent?: boolean }): void {
    super.enable(options);
    this.switchControlsDisabling();
  }

  updateActive(active: boolean) {
    this.get('active').patchValue(active, { emitViewToModelChange: false, emitEvent: false });
    this.switchControlsDisabling();
  }

  switchControlsDisabling() {
    if (this.get('active').value) {
      this.get('comparisons').enable({ emitEvent: false });
    } else {
      this.get('comparisons').disable({ emitEvent: false });
    }
    this.updateValueAndValidity();
  }
}
