import { AngularFireDatabase } from '@angular/fire/database';
import { Injectable, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Observable, Subscription, BehaviorSubject } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { Channel } from 'ideta-library/lib/common/bot';

import { formats } from '../../models/regex-formats.model';

export type AppPage =
  | BotAppPage
  | 'login'
  | 'signup'
  | 'forgot-password'
  | 'embedded'
  | 'web-display'
  | 'oauth-callback';

export type BotAppPage =
  | 'bots-list'
  | 'data-manager'
  | 'edition'
  | 'deployment'
  | 'analytics'
  | 'lexicon'
  | 'testing'
  | 'settings'
  | 'broadcast'
  | 'messaging'
  | 'accounts';

export interface RouterEvent {
  location: AppPage;
  botId?: string;
  channel?: Channel;
}

@Injectable({
  providedIn: 'root'
})
export class CoreSessionService implements OnDestroy {
  private _routerEvent$: BehaviorSubject<RouterEvent>;
  private routerSub: Subscription;

  get routerEvent$() {
    return this._routerEvent$.pipe(filter(event => !!event));
  }

  get location(): AppPage {
    return this._routerEvent$.value.location;
  }

  constructor(private db: AngularFireDatabase, private router: Router) {
    this._routerEvent$ = new BehaviorSubject(null);
    this.routerSub = this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map((event: NavigationEnd) => event.url),
        filter(url => url !== '/')
      )
      .subscribe(activeUrl => {
        const route = activeUrl.split(formats.regex('appRouteSplit'));
//         console.log('route :', route);
        const routerEvent: RouterEvent = {
          location: route[1] as AppPage,
          // botId: (route[2] || '').split(formats.regex('appBotIdSplit'))[1],
          channel: (route[2] || '').split(formats.regex('appChannelSplit'))[1] as Channel
        };
        this._routerEvent$.next(routerEvent);
      });
  }

  endSession() {
    this._routerEvent$.next({ location: 'login' });
  }

  ngOnDestroy(): void {
    if (this.routerSub) this.routerSub.unsubscribe();
  }

  public emit(state: RouterEvent) {
    this._routerEvent$.next(state);
  }

  public getVersion(): Observable<string> {
    return this.db.object<string>('version').valueChanges();
  }
}
