import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ChartsModule } from '@carbon/charts-angular';
import { NgxStripeModule } from 'ngx-stripe';
import { LightboxModule } from 'ngx-lightbox';
import { DragScrollModule } from 'cdk-drag-scroll';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { ColorPickerModule } from 'ngx-color-picker';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { AngularPageVisibilityModule } from 'angular-page-visibility';

import { UiElementsModule } from './ui-elements/ui-elements.module';
import { MessengerProfileFormModule } from './messenger-profile-form/messenger-profile-form.module';
import { NodeMappingFormModule } from './node-mapping-form/node-mapping-form.module';
import { NodeTemplateFormModule } from './node-template-form/node-template-form.module';
import { NodeTemplatePreviewModule } from './node-template-preview/node-template-preview.module';
import { ProtocolDroidModule } from './protocol-droid/protocol-droid.module';

import { environment } from '../../environments/environment';

// Modules
import { PipesModule } from './pipes/pipes.module';

// Components
import { FeedbackSpinnerComponent } from './components/feedback/feedback-spinner/feedback-spinner.component';
import { FeedbackMessageComponent } from './components/feedback/feedback-message/feedback-message.component';
import { NodeListComponent } from './components/node-list/node-list.component';
import { ModalBotDataComponent } from './components/modals/modal-bot-data/modal-bot-data.component';
import { BotDataFormComponent } from './components/modals/modal-bot-data/bot-data-form/bot-data-form.component';
import { DataAsyncActionFormComponent } from './components/modals/modal-bot-data/bot-data-form/data-async-action-form/data-async-action-form.component';
import { DataObjectFormComponent } from './components/modals/modal-bot-data/bot-data-form/data-object-form/data-object-form.component';
import { ModalConversationComponent } from './components/modals/modal-conversation/modal-conversation.component';
import { ModalUserSettingsComponent } from './components/modals/modal-user-settings/modal-user-settings.component';
import { ModalMessengerProfileComponent } from './components/modals/modal-messenger-profile/modal-messenger-profile.component';
import { ModalSmoochProfileComponent } from './components/modals/modal-smooch-profile/modal-smooch-profile.component';
import { ModalTwilioVoiceProfileComponent } from './components/modals/modal-twilio-voice-profile/modal-twilio-voice-profile.component';
import { ModalShareComponent } from './components/modals/modal-share/modal-share.component';
import { ModalLexiconComponent } from './components/modals/modal-lexicon/modal-lexicon.component';
import { ModalTemplatesComponent } from './components/modals/modal-templates/modal-templates.component';
import { ModalBotCreationComponent } from './components/modals/modal-bot-creation/modal-bot-creation.component';
import { ModalBotNameComponent } from './components/modals/modal-bot-name/modal-bot-name.component';
import { BotDisplayComponent } from './components/bot-display/bot-display.component';
import { BaseDisplayComponent } from './components/bot-display/base-display/base-display.component';
import { DfProfileConnectorComponent } from './dialogflow/df-profile-connector/df-profile-connector.component';
import { LuisProfileConnectorComponent } from './luis/luis-profile-connector/luis-profile-connector.component';
import { FbProfileConnectorComponent } from './facebook/fb-profile-connector/fb-profile-connector.component';
import { StripeComponent } from './stripe/stripe.component';
import { UserGeneralSettingsComponent } from './components/modals/modal-user-settings/user-general-settings/user-general-settings.component';
import { UserPaymentSettingsComponent } from './components/modals/modal-user-settings/user-payment-settings/user-payment-settings.component';
import { NodeTemplateComponent } from '../idechat/edition/node-template/node-template.component';
import { AutoReplyParametersComponent } from './components/auto-reply-parameters/auto-reply-parameters.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    BsDropdownModule,
    PopoverModule,
    ColorPickerModule,
    PerfectScrollbarModule,
    MessengerProfileFormModule,
    NodeMappingFormModule,
    NodeTemplateFormModule,
    NodeTemplatePreviewModule,
    UiElementsModule,
    ProtocolDroidModule,
    DragDropModule,
    ChartsModule,
    DragScrollModule,
    LightboxModule,
    AngularPageVisibilityModule,
    PipesModule,
    NgxStripeModule.forRoot(environment.stripe.self_secretKey)
  ],
  declarations: [
    FeedbackSpinnerComponent,
    FeedbackMessageComponent,
    ModalUserSettingsComponent,
    ModalBotDataComponent,
    BotDataFormComponent,
    DataAsyncActionFormComponent,
    DataObjectFormComponent,
    ModalMessengerProfileComponent,
    ModalShareComponent,
    ModalLexiconComponent,
    ModalTemplatesComponent,
    NodeListComponent,
    BotDisplayComponent,
    DfProfileConnectorComponent,
    LuisProfileConnectorComponent,
    FbProfileConnectorComponent,
    ModalSmoochProfileComponent,
    ModalTwilioVoiceProfileComponent,
    ModalConversationComponent,
    StripeComponent,
    BaseDisplayComponent,
    UserGeneralSettingsComponent,
    UserPaymentSettingsComponent,
    NodeTemplateComponent,
    ModalBotNameComponent,
    ModalBotCreationComponent,
    AutoReplyParametersComponent
  ],
  entryComponents: [
    FeedbackSpinnerComponent,
    FeedbackMessageComponent,
    ModalBotNameComponent,
    ModalUserSettingsComponent,
    ModalBotDataComponent,
    ModalMessengerProfileComponent,
    ModalShareComponent,
    ModalLexiconComponent,
    ModalTemplatesComponent,
    ModalSmoochProfileComponent,
    ModalTwilioVoiceProfileComponent,
    ModalConversationComponent,
    ModalBotCreationComponent
  ],
  exports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    BsDropdownModule,
    PopoverModule,
    DragDropModule,
    ChartsModule,
    ColorPickerModule,
    PerfectScrollbarModule,
    MessengerProfileFormModule,
    NodeMappingFormModule,
    NodeTemplateFormModule,
    NodeTemplatePreviewModule,
    UiElementsModule,
    ProtocolDroidModule,
    NodeListComponent,
    BotDisplayComponent,
    DfProfileConnectorComponent,
    LuisProfileConnectorComponent,
    FbProfileConnectorComponent,
    NodeTemplateComponent,
    StripeComponent,
    UserGeneralSettingsComponent,
    AutoReplyParametersComponent
  ]
})
export class SharedModule {}
