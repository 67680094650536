import { Pipe, PipeTransform } from '@angular/core';
import { formats } from '../models/regex-formats.model';

@Pipe({
  name: 'trustUrl'
})
export class TrustUrlPipe implements PipeTransform {
  transform(value: string): any {
    if (formats.test('specifiedProtocolUrl', value || '')) return value;
    return '//' + value;
  }
}
