export const nationalities = [
  'Française',
  'Suisse',
  'Belge',
  'Allemande',
  'Italienne',
  'Afghane',
  'Albanaise',
  'Algerienne',
  'Americaine',
  'Andorrane',
  'Angolaise',
  'Antiguaise et barbudienne',
  'Argentine',
  'Armenienne',
  'Australienne',
  'Autrichienne',
  'Azerbaïdjanaise',
  'Bahamienne',
  'Bahreinienne',
  'Bangladaise',
  'Barbadienne',
  'Belizienne',
  'Beninoise',
  'Bhoutanaise',
  'Bielorusse',
  'Birmane',
  'Bissau-Guinéenne',
  'Bolivienne',
  'Bosnienne',
  'Botswanaise',
  'Bresilienne',
  'Britannique',
  'Bruneienne',
  'Bulgare',
  'Burkinabe',
  'Burundaise',
  'Cambodgienne',
  'Camerounaise',
  'Canadienne',
  'Cap-verdienne',
  'Centrafricaine',
  'Chilienne',
  'Chinoise',
  'Chypriote',
  'Colombienne',
  'Comorienne',
  'Congolaise',
  'Costaricaine',
  'Croate',
  'Cubaine',
  'Curaçaoane',
  'Danoise',
  'Djiboutienne',
  'Dominicaine',
  'Dominiquaise',
  'Egyptienne',
  'Emirienne',
  'Equato-guineenne',
  'Equatorienne',
  'Erythreenne',
  'Espagnole',
  'Est-timoraise',
  'Estonienne',
  'Ethiopienne',
  'Fidjienne',
  'Finlandaise',
  'Gabonaise',
  'Gambienne',
  'Georgienne',
  'Ghaneenne',
  'Grenadienne',
  'Guatemalteque',
  'Guineenne',
  'Guyanienne',
  'Haïtienne',
  'Hellenique',
  'Hondurienne',
  'Hongroise',
  'Indienne',
  'Indonesienne',
  'Irakienne',
  'Irlandaise',
  'Islandaise',
  'Israélienne',
  'Ivoirienne',
  'Jamaïcaine',
  'Japonaise',
  'Jordanienne',
  'Kazakhstanaise',
  'Kenyane',
  'Kirghize',
  'Kiribatienne',
  'Kittitienne-et-nevicienne',
  'Kossovienne',
  'Koweitienne',
  'Laotienne',
  'Lesothane',
  'Lettone',
  'Libanaise',
  'Liberienne',
  'Libyenne',
  'Liechtensteinoise',
  'Lituanienne',
  'Luxembourgeoise',
  'Macedonienne',
  'Malaisienne',
  'Malawienne',
  'Maldivienne',
  'Malgache',
  'Malienne',
  'Maltaise',
  'Marocaine',
  'Marshallaise',
  'Mauricienne',
  'Mauritanienne',
  'Mexicaine',
  'Micronesienne',
  'Moldave',
  'Monegasque',
  'Mongole',
  'Montenegrine',
  'Mozambicaine',
  'Namibienne',
  'Nauruane',
  'Néerlandaise',
  'Neo-zelandaise',
  'Nepalaise',
  'Nicaraguayenne',
  'Nigeriane',
  'Nigerienne',
  'Nord-coréenne',
  'Norvegienne',
  'Omanaise',
  'Ougandaise',
  'Ouzbeke',
  'Pakistanaise',
  'Palau',
  'Palestinienne',
  'Panameenne',
  'Papouane-neoguineenne',
  'Paraguayenne',
  'Peruvienne',
  'Philippine',
  'Polonaise',
  'Portoricaine',
  'Portugaise',
  'Qatarienne',
  'Roumaine',
  'Russe',
  'Rwandaise',
  'Saint-Lucienne',
  'Saint-Marinaise',
  'Saint-Martinoise',
  'Saint-Vincentaise-et-Grenadine',
  'Salomonaise',
  'Salvadorienne',
  'Samoane',
  'Santomeenne',
  'Saoudienne',
  'Senegalaise',
  'Serbe',
  'Seychelloise',
  'Sierra-leonaise',
  'Singapourienne',
  'Slovaque',
  'Slovene',
  'Somalienne',
  'Soudanaise',
  'Sri-lankaise',
  'Sud-africaine',
  'Sud-coréenne',
  'Suedoise',
  'Surinamaise',
  'Swazie',
  'Syrienne',
  'Tadjike',
  'Taiwanaise',
  'Tanzanienne',
  'Tchadienne',
  'Tcheque',
  'Thaïlandaise',
  'Togolaise',
  'Tonguienne',
  'Trinidadienne',
  'Tunisienne',
  'Turkmene',
  'Turque',
  'Tuvaluane',
  'Ukrainienne',
  'Uruguayenne',
  'Vanuatuane',
  'Vaticane',
  'Venezuelienne',
  'Vietnamienne',
  'Yemenite',
  'Zambienne',
  'Zimbabweenne'
];
