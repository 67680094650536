import { FormGroup, FormControl, Validators } from '@angular/forms';

import { TemplateList } from 'ideta-library/lib/common/node';

import { GenericManualForm } from '../shared/generic-manual-form.model';
import { GenericAutoForm } from '../shared/generic-auto-form.model';
import { ButtonsListForm } from '../shared/buttons-list-form/buttons-list-form.model';
import { RichInputFormControl } from '../../models/rich-input-form-control.model';

const defaultTemplate: TemplateList = {
  intro: 'Select an item in the list below',
  feedType: 'manual',
  elements: [
    {
      title: 'Classic White T-Shirt',
      imageUrl: 'https://cdn.laredoute.com/products/641by641/d' + '/1/3/d1332bba3c190b9a90ee9f6f3a4f9f49.jpg',
      subtitle: '100% Cotton, 200% Comfortable',
      defaultAction: {
        type: 'url',
        url: 'https://peterssendreceiveapp.ngrok.io/view?item=100',
        webviewHeightRatio: 'tall'
      },
      buttons: [
        {
          title: 'Buy',
          type: 'url',
          url: 'https://peterssendreceiveapp.ngrok.io/shop?item=100',
          webviewHeightRatio: 'tall'
        }
      ]
    },
    {
      title: 'Classic Gray T-Shirt',
      imageUrl: 'https://cdn.laredoute.com/products/641by641/6/6/' + '1/6613d3156dd1b87bfd6c583e2d05f1ba.jpg',
      subtitle: '100% Cotton, 200% Comfortable',
      defaultAction: {
        type: 'url',
        url: 'https://peterssendreceiveapp.ngrok.io/view?item=103',
        webviewHeightRatio: 'tall'
      },
      buttons: [
        {
          title: 'Buy',
          type: 'url',
          url: 'https://peterssendreceiveapp.ngrok.io/shop?item=103',
          webviewHeightRatio: 'tall'
        }
      ]
    },
    {
      title: 'An Other Gray T-Shirt',
      imageUrl: 'https://cdn.laredoute.com/products/641by641/6/6/' + '1/6613d3156dd1b87bfd6c583e2d05f1ba.jpg',
      subtitle: '100% Cotton, 200% Comfortable',
      defaultAction: {
        type: 'url',
        url: 'https://peterssendreceiveapp.ngrok.io/view?item=103',
        webviewHeightRatio: 'tall'
      },
      buttons: [
        {
          title: 'Buy',
          type: 'url',
          url: 'https://peterssendreceiveapp.ngrok.io/shop?item=103',
          webviewHeightRatio: 'tall'
        }
      ]
    }
  ],
  buttons: [
    {
      title: 'View More',
      type: 'postback',
      targetNode: 'noeud_0'
    }
  ]
};

export class TemplateListForm extends FormGroup {
  constructor(template: TemplateList = defaultTemplate) {
    const formGroup = {
      intro: new RichInputFormControl(template.intro),
      feedType: new FormControl(template.feedType || 'manual', [Validators.required]),
      buttons: new ButtonsListForm(template.buttons),
      elements: new GenericManualForm(template.feedType === 'manual' && template.elements),
      autoOptions: new GenericAutoForm(template.feedType === 'auto' && template.autoOptions)
    };

    super(formGroup);

    this.switchControlsDisabling();
  }

  enable(options?: { onlySelf?: boolean; emitEvent?: boolean }): void {
    super.enable(options);
    this.switchControlsDisabling();
  }

  private switchControlsDisabling() {
    const feedType = this.get('feedType').value;

    if (feedType === 'manual') {
      this.get('elements').enable({ emitEvent: false });
      this.get('autoOptions').disable({ emitEvent: false });
    } else if (feedType === 'auto') {
      this.get('elements').disable({ emitEvent: false });
      this.get('autoOptions').enable({ emitEvent: false });
    }
    this.updateValueAndValidity();
  }
}
