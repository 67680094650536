import { Component, OnInit, HostListener } from '@angular/core';
import { Subscription } from 'rxjs';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-feedback-message',
  templateUrl: './feedback-message.component.html',
  styleUrls: ['./feedback-message.component.scss']
})
export class FeedbackMessageComponent implements OnInit {
  title: string;
  message: string;
  type: 'message' | 'confirm';
  decoration: 'warning' | 'success' | 'error';
  subscription: Subscription;
  private isConfirmed: boolean;

  @HostListener('window:keyup', ['$event'])
  onEnter(event: KeyboardEvent) {
    if (event.key === 'Enter' && this.type === 'message') {
      this.dismiss();
    } else if (event.key === 'Enter' && this.type === 'confirm') {
      this.confirm();
    }
  }

  constructor(private modalService: BsModalService, private modalRef: BsModalRef) {}

  ngOnInit() {
    this.isConfirmed = false;
    this.subscription = this.modalService.onHidden.subscribe(() => {
      this.callback(this.isConfirmed);
      this.subscription.unsubscribe();
    });
  }

  confirm(): void {
    this.isConfirmed = true;
    this.modalRef.hide();
  }

  dismiss(): void {
    this.modalRef.hide();
  }

  callback(_: boolean): void {} // Will be overwritten at opening time
}
