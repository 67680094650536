import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { startWith } from 'rxjs/operators';

import { DataStoreElemKeys, DataStoreElemType } from 'ideta-library/lib/common/data';

import { UniqueFormControl } from '../../../../../models/unique-form-control.model';

@Component({
  selector: 'app-data-object-form',
  templateUrl: './data-object-form.component.html',
  styleUrls: ['./data-object-form.component.scss']
})
export class DataObjectFormComponent implements OnInit, OnDestroy {
  @Input() parentForm: FormGroup;
  @Input() types: { [key in DataStoreElemType]: string };
  @Input() isDisabled: boolean;
  @Output() add: EventEmitter<void>;
  private subscription: Subscription;

  constructor() {
    this.add = new EventEmitter();
  }

  ngOnInit() {
    this.subscription = this.parentForm.valueChanges
      .pipe(startWith(this.parentForm.value))
      .subscribe((dataKeys: DataStoreElemKeys) => {
        Object.keys(this.parentForm.controls).forEach((key: string, index: number) => {
          const nameControl = this.parentForm.controls[key].get('key') as UniqueFormControl;
          const blackList = Object.keys(dataKeys)
            .filter((_id: string, i: number) => i !== index)
            .map((id: string) => dataKeys[id].key);
          nameControl.setBlackList(blackList);
        });
      });
  }

  ngOnDestroy() {
    if (!this.subscription.closed) this.subscription.unsubscribe();
  }
}
