import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';

import { ProtocolDroidService } from '../../protocol-droid/services/protocol-droid.service';
import { BaseMappingFormComponent } from '../shared/base-mapping-form.component';

@Component({
  selector: 'app-mapping-async-action-form',
  templateUrl: './mapping-async-action-form.component.html',
  styleUrls: ['./mapping-async-action-form.component.scss']
})
export class MappingAsyncActionFormComponent extends BaseMappingFormComponent implements OnInit, OnDestroy {
  triggerTypes: { [key: string]: string };
  private subscriptions: Subscription[];

  get trigger(): FormControl {
    return this.parentForm.get('trigger') as FormControl;
  }

  get action(): FormGroup {
    return this.parentForm.get('action') as FormGroup;
  }

  constructor(private protocolDroidService: ProtocolDroidService) {
    super();
    this.subscriptions = [];
  }

  ngOnInit() {
    this.subscriptions.push(
      this.protocolDroidService.onTranslationChangeEvent.subscribe(() => {
        this.triggerTypes = {
          enter: this.protocolDroidService.translate('shared.node-mapping.actions.label-enter', 'Arriver sur la bulle'),
          leave: this.protocolDroidService.translate('shared.node-mapping.actions.label-leave', 'Quitter la bulle')
        };
      })
    );
  }

  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
    }
  }
}
