import { FormGroup, FormControl, FormArray, ValidationErrors } from '@angular/forms';
import { get } from 'lodash';

import {
  PersistentMenuButton,
  PersistentMenuButtonForm
} from './persistent-menu-button-form/persistent-menu-button-form.model';

export interface PersistentMenuItem {
  locale: 'default';
  composer_input_disabled: boolean; // Do not show text input
  call_to_actions: PersistentMenuButton[];
}

export type PersistentMenu = PersistentMenuItem[];

export class PersistentMenuForm extends FormGroup {
  static validatorMinButtons = (group: FormGroup): ValidationErrors | null => {
    const disableInput = group.get('disableInput').value;
    const buttons = group.get('buttons').value;

    return disableInput && buttons.length < 1 ? { minButtons: true } : null;
  };

  get disableInput(): FormControl {
    return this.get('disableInput') as FormControl;
  }

  get buttons(): FormArray {
    return this.get('buttons') as FormArray;
  }

  constructor(profile: any = []) {
    const formGroup = {
      disableInput: new FormControl(get(profile, '[0].composer_input_disabled', false)),
      buttons: new FormArray(
        get(profile, '[0].call_to_actions', []).map(
          (button: any) => new PersistentMenuButtonForm(button)
        )
      )
    };

    super(formGroup, [PersistentMenuForm.validatorMinButtons]);
  }

  getProfileValue(): PersistentMenu {
    const persistentMenu: any = {
      locale: 'default',
      composer_input_disabled: this.disableInput.value
    };
    const buttons = this.buttons.controls;

    if (buttons.length > 0) {
      persistentMenu.call_to_actions = buttons.map((button: PersistentMenuButtonForm) =>
        button.getValue()
      );
    }

    return [persistentMenu];
  }

  setProfileValue(value: any) {
    this.disableInput.patchValue(get(value, '[0].composer_input_disabled', false));
    this.buttons.clear();
    get(value, '[0].call_to_actions', []).forEach((button: any) =>
      this.buttons.push(new PersistentMenuButtonForm(button))
    );
  }
}
