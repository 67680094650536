import { Component, OnInit, Input, TemplateRef, ViewChild, ElementRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface NavPanel {
  id: string;
  ref: TemplateRef<any>;
}

interface ContainerStyle {
  transform: string;
  width: string;
}

@Component({
  selector: 'app-nav-panels',
  templateUrl: './nav-panels.component.html',
  styleUrls: ['./nav-panels.component.scss']
})
export class NavPanelsComponent implements OnInit {
  @Input() panels: NavPanel[];
  @Input() activePanel$: BehaviorSubject<string>;
  @ViewChild('panelsContainer', { static: true }) panelsContainer: ElementRef<HTMLElement>;
  containerStyle: ContainerStyle;

  constructor() {}

  ngOnInit() {
    this.containerStyle = { transform: '', width: this.panels.length * 100 + '%' };
    this.activePanel$.subscribe(this.movePanels.bind(this));
  }

  movePanels(activePanel: string) {
    const activePanelIndex = this.panels.findIndex((panel: NavPanel) => panel.id === activePanel);
    this.containerStyle.transform =
      'translateX(' + (-activePanelIndex * 100) / this.panels.length + '%)';
  }

  trackById(_index: number, panel: NavPanel): string {
    return panel.id;
  }
}
