import { Injectable, Injector, InjectionToken, ErrorHandler } from '@angular/core';
import * as Rollbar from 'rollbar';

import { environment } from '../../../../environments/environment';

const rollbarConfig = {
  enabled: environment.rollbar.active,
  accessToken: environment.rollbar.accessToken,
  captureUncaught: true,
  captureUnhandledRejections: true,
  captureIP: 'anonymize',
  verbose: true,
  payload: {
    environment: environment.name,
    client: {
      javascript: {
        code_version: environment.rollbar.buildVersion
      }
    }
  }
};

@Injectable({
  providedIn: 'root'
})
export class RollbarErrorHandler implements ErrorHandler {
  constructor(private injector: Injector) {}

  handleError(err: any): void {
    const rollbar = this.injector.get(RollbarService);
    rollbar.error(err.originalError || err);
    if (!environment.rollbar.active) {
      console.error(err.originalError || err);
    }
  }
}

export function rollbarFactory() {
  return new Rollbar(rollbarConfig);
}

export const RollbarService = new InjectionToken<Rollbar>('rollbar');
