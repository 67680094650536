import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl, FormArray } from '@angular/forms';

import { MappingGoToNodeForm } from './mapping-go-to-node-form.model';
import { BaseMappingFormComponent } from '../shared/base-mapping-form.component';
import { NodeKeyFormControl } from '../../models/node-key-form-control.model';

@Component({
  selector: 'app-mapping-go-to-node-form',
  templateUrl: './mapping-go-to-node-form.component.html',
  styleUrls: ['./mapping-go-to-node-form.component.scss']
})
export class MappingGoToNodeFormComponent extends BaseMappingFormComponent implements OnInit {
  @Input() parentForm: MappingGoToNodeForm;

  get active(): FormControl {
    return this.parentForm.get('active') as FormControl;
  }

  get options(): FormGroup {
    return this.parentForm.get('options') as FormGroup;
  }

  get targetNode(): NodeKeyFormControl {
    return this.options.get('targetNode') as NodeKeyFormControl;
  }

  get operations(): FormArray {
    return this.options.get('operations') as FormArray;
  }

  constructor() {
    super();
  }

  ngOnInit() {}

  activate() {
    this.parentForm.updateActive(this.active.value);
  }
}
