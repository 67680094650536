import { Component, OnInit, Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss']
})
export class SearchBarComponent implements OnInit {
  @Input() search$: BehaviorSubject<string>;
  @Input() placeholder?: string;
  @Input() debounceTime?: number;
  searchInput$: BehaviorSubject<string>;

  constructor() {
    this.searchInput$ = new BehaviorSubject(null);
    this.placeholder = 'Search...';
    this.debounceTime = 300;
  }

  ngOnInit() {
    this.searchInput$
      .pipe(
        debounceTime(this.debounceTime),
        distinctUntilChanged()
      )
      .subscribe((search: string) => this.search$.next(search));
  }
}
