import { Injectable, EventEmitter } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { get } from 'lodash';

import { MessageDisplay } from './../../components/bot-display/bot-display.types';
import { DisplayOptions } from '../../models/display-options.model';
import { CoreSessionService } from './core-session.service';
import { UserService } from '../user/user.service';
import { UserSessionService } from './user-session.service';

import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConversationSessionService {
  public focusResponseBarEvent: EventEmitter<void>;
  public hideResponseElementsEvent: EventEmitter<void>;
  public stopAudioReadingEvent: EventEmitter<void>;
  public stopAudiolisteningEvent: EventEmitter<void>;
  public scrollEvent: EventEmitter<void>;
  public inputType$: BehaviorSubject<string>;
  public botDisplayInit: boolean;
  public isAutoTextReadingActive: boolean;
  public isSoundOn: boolean;
  public isEmbedded: boolean;
  public lastBotMessage: MessageDisplay;

  constructor(
    private userService: UserService,
    private _session: CoreSessionService,
    private _user: UserSessionService
  ) {
    this.hideResponseElementsEvent = new EventEmitter();
    this.focusResponseBarEvent = new EventEmitter();
    this.stopAudioReadingEvent = new EventEmitter();
    this.stopAudiolisteningEvent = new EventEmitter();
    this.scrollEvent = new EventEmitter();
    this.inputType$ = new BehaviorSubject('text');
    this.isSoundOn = true;
  }

  hideResponseElements() {
    this.hideResponseElementsEvent.emit();
  }

  focusResponseBar() {
    this.focusResponseBarEvent.emit();
  }

  stopAudioEvents() {
    this.stopAudioReading();
    this.stopAudiolistening();
  }

  stopAudioReading() {
    this.stopAudioReadingEvent.emit();
  }

  stopAudiolistening() {
    this.stopAudiolisteningEvent.emit();
  }

  scroll() {
    this.scrollEvent.emit();
  }

  getSpeechLang(displayOptions: DisplayOptions): string {
    if (displayOptions.context === 'cockpit') {
      return get(this._user, 'settings.messaging.speechLang', environment.defaults.misc.textSpeechLang);
    } else if (displayOptions.context === 'sandbox') {
      return get(this._user, 'settings.testing.speechLang', environment.defaults.misc.textSpeechLang);
    }

    return displayOptions.speechLang;
  }

  setSpeechLang(lang: string) {
    if (this._session.location === 'messaging') {
      this.userService.updateUser({ 'settings/messaging/speechLang': lang } as any);
    } else if (this._session.location === 'testing') {
      this.userService.updateUser({ 'settings/testing/speechLang': lang } as any);
    }
  }
}
