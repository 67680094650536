import { Component, OnInit } from '@angular/core';

import { MessageTemplateQuickReplies } from 'ideta-library/lib/common/message';

import { BasePreviewComponent } from '../shared/base-preview.component';

@Component({
  selector: 'app-template-quick-replies-preview',
  templateUrl: './template-quick-replies-preview.component.html',
  styleUrls: ['./template-quick-replies-preview.component.scss']
})
export class TemplateQuickRepliesPreviewComponent extends BasePreviewComponent implements OnInit {
  get template() {
    return this.message.template as MessageTemplateQuickReplies;
  }

  ngOnInit() {}

  getClass(): string {
    return (
      ((this.displayOptions.context === 'cockpit' || this.displayOptions.context === 'web') && 'r-quick-reply-item') ||
      'f-quick-reply-item'
    );
  }
}
