import { Injectable, OnDestroy } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { Subscription, BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { filter, map, tap, switchMap, take } from 'rxjs/operators';
import { get, mapKeys } from 'lodash';

import {
  AllowedScope,
  AllowedType,
  DataStore,
  DataStoreElem,
  DataStoreElemKey,
  DataStoreElemKeys
} from 'ideta-library/lib/common/data';
import { Bot } from 'ideta-library/lib/common/bot';

import { CoreSessionService } from '../../services/session/core-session.service';
import { SessionModel } from './session.model';
import { ChannelSessionService } from './channel-session.service';
import { ParsedAddress, formats } from '../../models/regex-formats.model';
import { DataKeyFormControl, AdditionalDataInfos } from '../../models/data-key-form-control.model';
import { RichInputSegmentForm } from '../../models/rich-input-segment-form.model';
import { BotSessionService } from './bot-session.service';
import { UserSessionService } from './user-session.service';
import { BotDataService } from '../bot/bot-data/bot-data.service';
import { ErrorsService } from '../errors/errors.service';

/* ###DK */
type CheckedDataStoreElem = DataStoreElem & { oldFormat?: boolean };
type CheckedDataStoreElemKey = DataStoreElemKey & { oldFormat?: boolean };

@Injectable({
  providedIn: 'root'
})
export class DataStoreSessionService implements SessionModel, OnDestroy {
  public dataStoreList: DataStoreElem[];
  private keyBasedictionary: DataStore; // ###DK
  private _subject$: BehaviorSubject<DataStore>;
  private botId: string;
  private channel: string;
  private dataStoreSub: Subscription;
  private routerSub: Subscription;
  private logInfos: Pick<Bot, 'endPointBack' | 'token'>;
  private userLogChannel: string;
  private currentLocation: string;

  get subject$() {
    return this._subject$.pipe(filter(value => !!value));
  }

  get value(): DataStore {
    return this._subject$.value || {};
  }

  get exists$() {
    return this._subject$.pipe(map(value => !!value));
  }

  get exists() {
    return !!this._subject$.value;
  }

  constructor(
    private db: AngularFireDatabase,
    private errorService: ErrorsService,
    private botDataService: BotDataService,
    private _session: CoreSessionService,
    private _bot: BotSessionService,
    private _channel: ChannelSessionService,
    private _user: UserSessionService
  ) {
    this.keyBasedictionary = {}; // ###DK
    this.dataStoreList = [];
    this._subject$ = new BehaviorSubject(null);
    this.routerSub = combineLatest([this._session.routerEvent$, this._channel.subject$]).subscribe(
      ([event, channel]) => {
        if (event.botId) {
          switch (event.location) {
            case 'embedded':
            case 'web-display':
              this.endSession();
              break;
            default:
              this.startSession(event.botId, channel.type, event.location);
              break;
          }
        } else {
          this.endSession();
        }
      }
    );
  }

  public startSession(botId: string, channel: string, location: string) {
    if (!botId) return;
    // ###M
    // console.log('init _dataStore', botId, channel);

    // session may vary inside the same page
    // that's why we need to properly end the previous one before
    this.endSession(location === this.currentLocation);
    this.botId = botId;
    this.channel = channel;
    this.currentLocation = location;

    // Waiting for a bot value to avoid permission denied
    this.dataStoreSub = this._bot.subject$
      .pipe(
        take(1),
        tap(bot => {
          this.logInfos = {
            token: bot.token,
            endPointBack: bot.endPointBack
          };
        }),
        switchMap(() => this.getDataStore())
      )
      .subscribe(dataStore => {
        this._subject$.next(dataStore);
        this.loginSupportUser();
      });
  }

  public endSession(sameLocation?: boolean) {
    if (!this.botId || !this.channel) return;
    // ###M
    // console.log('reset _dataStore');
    if (this.dataStoreSub) this.dataStoreSub.unsubscribe();
    this.logoutSupportUser(sameLocation); // must be done before botId, logInfos and userLogChannel are null
    this.botId = this.channel = null;
    this.keyBasedictionary = {}; /* ###DK */
    this.dataStoreList = [];
  }

  ngOnDestroy(): void {
    this.endSession();
    this.routerSub.unsubscribe();
  }

  public isObjectWithProperty(dataElem: DataStoreElem): boolean {
    return this.isObject(dataElem) && !!dataElem.keys;
  }

  public isList(dataElem: DataStoreElem): boolean {
    return dataElem && dataElem.type === 'array' && dataElem.elements && !!dataElem.elements.type;
  }

  public isPrimitiveList(adress: string): boolean {
    const dataElem = this.getDataStoreElem(adress);
    return (
      this.isList(dataElem) &&
      (dataElem.elements.type === 'string' ||
        dataElem.elements.type === 'number' ||
        dataElem.elements.type === 'boolean')
    );
  }

  public isObjectWithPropertyList(address: string | DataStoreElem): boolean {
    let dataElem: any = address;
    if (typeof address === 'string') {
      dataElem = this.getDataStoreElem(address);
    }
    return this.isObjectList(dataElem) && !!dataElem.elements.keys;
  }

  public getScopedDataList(scope: AllowedScope): DataStoreElem[] {
    return this.dataStoreList.filter((elem: DataStoreElem) => elem.scope === scope);
  }

  /* ###DK */
  public getDataStoreElem(address: string): CheckedDataStoreElem {
    // for now this function has to return a verified
    // version of DataStoreElem to let its ancestors know if
    // this address is still referenced in the old format
    if (this.value[address]) {
      return this.value[address];
    } else if (this.keyBasedictionary[address]) {
      return { ...this.keyBasedictionary[address], oldFormat: true };
    }
    return null;
  }

  public isKeyNameUnique(name: string): boolean {
    return !Object.keys(this.value).find((id: string) => this.value[id].key === name);
  }

  /* ###DK */
  public getDataStoreElemKey(dataElem: DataStoreElem, property: string, isIndexed: boolean): CheckedDataStoreElemKey {
    // for now this function has to return a verified
    // version of DataStoreElemKey to let its ancestors know if
    // this address is still referenced in the old format
    if (!property || !dataElem) return null;
    if (this.isObjectWithProperty(dataElem)) {
      return this.getInKeys(dataElem.keys, property);
    }
    if (this.isList(dataElem) && (property === 'length' || property === '_length' || property === '___length___')) {
      return { ...dataElem.elements.length, oldFormat: property === '_length' };
    }
    if (this.isList(dataElem) && (property === '___element___' || property === '___index___')) {
      return property === '___element___'
        ? { key: '___element___', type: dataElem.elements.type as any }
        : { key: '___index___', type: 'number' };
    }
    if (this.isObjectWithPropertyList(dataElem) && isIndexed) {
      return this.getInKeys(dataElem.elements.keys, property);
    }

    return null;
  }

  /* ###DK */
  public updateSelectedDataInfos(
    parseAddress: ParsedAddress,
    input: DataKeyFormControl | RichInputSegmentForm,
    allowedTypes: AllowedType[],
    allowedScopes: AllowedScope[],
    isElement?: boolean
  ): { name: string; oldFormat: boolean } {
    const { subject, property, index } = parseAddress;

    // get data elem from store
    const dataKey = this.getDataStoreElem(subject);
    const propertyKey = this.getDataStoreElemKey(dataKey, property, isElement || !!index);

    if ((isElement && !propertyKey) || (!isElement && !dataKey) || (property && !propertyKey)) {
      const name = isElement
        ? 'unknown'
        : formats.buildDisplayedName(
            dataKey && dataKey.key,
            !property ? null : propertyKey ? propertyKey.key : 'unknown',
            index
          );
      const oldFormat = false;
      input.setAsMissingKey();
      return { name, oldFormat };
    }

    const valueBuilder: AdditionalDataInfos = {
      key: isElement ? propertyKey.key : formats.buildDisplayedName(dataKey.key, propertyKey && propertyKey.key, index),
      oldFormat: (propertyKey && propertyKey.oldFormat) || (!isElement && dataKey.oldFormat),
      scope: dataKey.scope,
      allowedTypes,
      allowedScopes
    };

    if (propertyKey) {
      valueBuilder.dataType = propertyKey.type;
    } else if (!property) {
      valueBuilder.elements = dataKey.elements;
      valueBuilder.dataType = dataKey.type === 'array' && !!index ? dataKey.elements.type : dataKey.type;
    }

    input.patchValue(valueBuilder);
    return { name: valueBuilder.key, oldFormat: valueBuilder.oldFormat };
  }

  private isObject(dataElem: DataStoreElem): boolean {
    return dataElem && dataElem.type === 'object';
  }

  private isObjectList(dataElem: DataStoreElem): boolean {
    return this.isList(dataElem) && dataElem.elements.type === 'object';
  }

  /* ###DK */
  private getInKeys(keys: DataStoreElemKeys, property: string): CheckedDataStoreElemKey {
    keys = keys || {};
    if (keys[property]) {
      return keys[property];
    }
    const elemKey = Object.keys(keys).find(id => keys[id].key === property);
    if (elemKey) {
      return { ...keys[elemKey], oldFormat: true };
    }
    return null;
  }

  private loginSupportUser(): void {
    if (this.userLogChannel === this.channel) return;
    const userRole = this._user.getRoleIn(this.botId);
    if (
      this._session.location === 'messaging' &&
      this._bot.useAutoConnect &&
      (userRole === 'owner' || userRole === 'support') &&
      !!this.value[this._user.id] &&
      this._bot.isDeployedOn(this.channel)
    ) {
      this.userLogChannel = this.channel;
      this.botDataService
        .updateKeyValue(this.botId, this.userLogChannel, this.logInfos.endPointBack, this.logInfos.token, {
          [this._user.id]: true
        })
        .catch(this.errorService.handlePromise());
    }
  }

  private logoutSupportUser(sameLocation: boolean): void {
    if (
      !get(this.value, `${this._user.id}.value`, false) &&
      (!this.userLogChannel || (sameLocation && !this._bot.useChannelDataStore))
    ) {
      return;
    }
    this.botDataService
      .updateKeyValue(this.botId, this.userLogChannel, this.logInfos.endPointBack, this.logInfos.token, {
        [this._user.id]: false
      })
      .catch(this.errorService.handlePromise());
    this.logInfos = this.userLogChannel = null;
  }

  private getDataStore(): Observable<DataStore> {
    const dbChannel = this._bot.isDeployedOn(this.channel) && !this._bot.useChannelDataStore ? 'sandbox' : this.channel;
    return this.db
      .object<DataStore>(`/data-stores/${this._bot.id}/${dbChannel}`)
      .valueChanges()
      .pipe(
        map((dataStore: DataStore) => dataStore || {}),
        tap((dataStore: DataStore) => {
          this.keyBasedictionary = mapKeys(dataStore, value => value.key); // ###DK
          this.dataStoreList = Object.keys(dataStore)
            .map((id: string) => ({ ...dataStore[id], id }))
            .sort(this.orderListByStringKey('key'));
        })
      );
  }

  private orderListByStringKey(key: string) {
    return (a: DataStoreElem, b: DataStoreElem) => a[key].localeCompare(b[key]);
  }
}
