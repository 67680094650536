import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { Observable, Subscription, BehaviorSubject } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { BotSmoochService } from '../../../services/bot/bot-smooch/bot-smooch.service';
import { MessengerProfilesService } from '../../../services/messenger-profiles/messenger-profiles.service';
import { FacebookService } from '../../../services/facebook/facebook.service';
import { ProtocolDroidService } from '../../../protocol-droid/services/protocol-droid.service';

export interface ModalSmoochProfileOptions {
  pageId: string;
}

@Component({
  selector: 'app-modal-smooch-profile',
  templateUrl: './modal-smooch-profile.component.html',
  styleUrls: ['./modal-smooch-profile.component.scss']
})
export class ModalSmoochProfileComponent implements OnInit, OnDestroy {
  pageId: string;
  actionsList: any = [{ key: 'get' }, { key: 'set' }, { key: 'delete' }];
  selectedAction: any;
  propertyList: any;
  selectedProperty: any;
  selectedTemplate$: BehaviorSubject<any>;
  templateContent$: Observable<string>;
  actionError: string;
  jsonError: string;
  tokenError: string;
  pageIdError: string;
  isUpdatingToken: boolean;
  isUpdatingPageId: boolean;
  isUpdatingKey: boolean;
  accessToken: string;
  private subscriptions: Subscription[];

  constructor(
    private botSmoochService: BotSmoochService,
    private modalRef: BsModalRef,
    private facebookService: FacebookService,
    private messengerProfilesService: MessengerProfilesService,
    private protocolDroidService: ProtocolDroidService
  ) {
    this.subscriptions = [];
    this.selectedTemplate$ = new BehaviorSubject(null);
    this.templateContent$ = this.selectedTemplate$.pipe(
      map((template: any) => (template ? JSON.stringify(template, null, 4) : ''))
    );
  }

  @HostListener('window:keyup', ['$event'])
  onEnter(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.done();
    }
  }

  ngOnInit() {
    this.subscriptions.push(
      this.botSmoochService
        .getPageInfos()
        .pipe(filter(value => !!value))
        .subscribe((pageInfos: any) => {
          const { id, access_token } = pageInfos;
          this.pageId = id;
          this.accessToken = access_token;
        }),
      this.messengerProfilesService
        .getPropertyList()
        .subscribe((list: any) => (this.propertyList = list))
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  done(): void {
    this.modalRef.hide();
  }

  updateToken() {
    this.tokenError = '';
    this.isUpdatingToken = true;
    if (this.accessToken) {
      this.botSmoochService.updatePageToken(this.accessToken).then((isSuccess: boolean) => {
        if (!isSuccess) {
          this.tokenError = this.protocolDroidService.translate(
            'misc.errors.unknown',
            'An unknown error occurred. Please contact support.'
          );
        }
        setTimeout(() => {
          this.isUpdatingToken = false;
        }, 500);
      });
    } else {
      this.isUpdatingToken = false;
    }
  }

  updatePageId() {
    this.tokenError = '';
    this.isUpdatingPageId = true;
    if (this.pageId) {
      this.botSmoochService.updatePageId(this.pageId).then((isSuccess: boolean) => {
        if (!isSuccess) {
          this.pageIdError = this.protocolDroidService.translate(
            'misc.errors.unknown',
            'An unknown error occurred. Please contact support.'
          );
        }
        setTimeout(() => {
          this.isUpdatingPageId = false;
        }, 500);
      });
    } else {
      this.isUpdatingPageId = false;
    }
  }

  loadAction() {
    switch (this.selectedAction.key) {
      case 'get':
        this.selectedTemplate$.next(null);
        break;
      case 'delete':
        this.selectedTemplate$.next({
          fields: ['greeting']
        });
        break;
    }
  }

  loadProperty() {
    if (this.selectedProperty && this.selectedAction.key === 'set') {
      this.selectedTemplate$.next(this.selectedProperty.template);
    } else {
      this.selectedTemplate$.next(null);
    }
  }

  getProfileKey() {
    this.isUpdatingKey = true;
    this.facebookService.getMessengerProfile(this.accessToken).then((property: any) => {
      const response = property || {
        error: this.protocolDroidService.translate(
          'misc.errors.empty-response-error',
          'Empty Response'
        )
      };
      this.selectedTemplate$.next(response);
      this.isUpdatingKey = false;
    });
  }

  setProfileKey() {
    this.isUpdatingKey = true;
    this.actionError = '';
    this.facebookService
      .updateMessengerProfile(this.selectedTemplate$.value, this.accessToken)
      .then((isSuccess: boolean) => {
        if (!isSuccess) {
          this.actionError = this.protocolDroidService.translate(
            'misc.errors.unknown',
            'An unknown error occurred. Please contact support.'
          );
        }
        this.isUpdatingKey = false;
      });
  }
  //
  // deleteProfileKey() {
  //   this.isUpdatingKey = true;
  //   this.actionError = '';
  //   this.facebookService
  //     .deleteMessengerProfile(this.selectedProperty.key, this.accessToken)
  //     .then((isSuccess: boolean) => {
  //       if (!isSuccess) {
  //         this.actionError = this.protocolDroidService.translate(
  //           'misc.errors.unknown',
  //           'An unknown error occurred. Please contact support.'
  //         );
  //       }
  //       this.isUpdatingKey = false;
  //     });
  // }

  updateTemplateContent(content: string): void {
    try {
      this.selectedTemplate$.next(JSON.parse(content));
      this.jsonError = '';
    } catch (error) {
      if (error instanceof SyntaxError) {
        this.jsonError = error.message;
      }
    }
  }
}
