import { FormGroup, Validators } from '@angular/forms';

import { WorkflowAsyncAction } from 'ideta-library/lib/common/node';

import { NodeKeyFormControl } from '../../../../models/node-key-form-control.model';

export const defaultWorkflowAction: WorkflowAsyncAction = {
  node: ''
};

export class WorkflowActionForm extends FormGroup {
  constructor(workflowAction: WorkflowAsyncAction = defaultWorkflowAction) {
    const formGroup = {
      node: new NodeKeyFormControl(workflowAction.node, [Validators.required])
    };

    super(formGroup);
  }
}
