import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { BotSessionService } from '../../session/bot-session.service';

@Injectable({
  providedIn: 'root'
})
export class BotTwilioVoiceService {
  constructor(private db: AngularFireDatabase, private _bot: BotSessionService) {}

  public getPageInfos(): Observable<any> {
    return this.db
      .object('/bots/' + this._bot.id + '/channels/twiliovoice/pageInfos')
      .valueChanges()
      .pipe(
        catchError((error: any) => {
          if (error && error.code === 'PERMISSION_DENIED') {
            console.log('Permission denied for bot', this._bot.id);
          } else {
            console.error(error);
          }
          return of(null);
        })
      );
  }

  public updateInfo(infoType: string, data: string): any {
    return this.db
      .object('/bots/' + this._bot.id + '/channels/twiliovoice/pageInfos')
      .update({
        id: this._bot.id,
        [infoType]: data
      })
      .then(() => true)
      .catch(error => {
        console.error(error);
        return false;
      });
  }

}
