import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';

import { OptionBehaviorType } from 'ideta-library/lib/common/node';

import { OptionBehaviorForm } from './option-behavior-form.model';
import { BaseMappingFormComponent } from '../../shared/base-mapping-form.component';
import { ProtocolDroidService } from '../../../protocol-droid/services/protocol-droid.service';
import { NodeKeyFormControl } from '../../../models/node-key-form-control.model';

@Component({
  selector: 'app-option-behavior-form',
  templateUrl: './option-behavior-form.component.html',
  styleUrls: ['./option-behavior-form.component.scss']
})
export class OptionBehaviorFormComponent extends BaseMappingFormComponent implements OnInit, OnDestroy {
  @Input() parentForm: OptionBehaviorForm;
  @Input() action: 'Attachments' | 'Localisation';
  @Input() allowCurrentNodeSelection: boolean;
  behaviorTypes: { [key in OptionBehaviorType]: string };
  htmlId: string;
  private subscriptions: Subscription[];

  get active(): FormControl {
    return this.parentForm.get('active') as FormControl;
  }

  get options(): FormGroup {
    return this.parentForm.get('options') as FormGroup;
  }

  get type(): FormControl {
    return this.options.get('type') as FormControl;
  }

  get targetNode(): NodeKeyFormControl {
    return this.options.get('targetNode') as NodeKeyFormControl;
  }

  get message(): FormControl {
    return this.options.get('message') as FormControl;
  }

  get url(): FormControl {
    return this.options.get('url') as FormControl;
  }

  constructor(private protocolDroidService: ProtocolDroidService) {
    super();
    this.htmlId = Math.random()
      .toString(36)
      .substring(2, 8);
    this.subscriptions = [];
  }

  ngOnInit() {
    this.subscriptions.push(
      this.protocolDroidService.onTranslationChangeEvent.subscribe(
        () =>
          (this.behaviorTypes = {
            message: this.protocolDroidService.translate(
              'shared.node-mapping.option-behavior.action-message',
              'Envoyer un message'
            ),
            api: this.protocolDroidService.translate(
              'shared.node-mapping.option-behavior.action-api',
              'Envoyer vers une API'
            )
          })
      )
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }
}
