import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { trigger, state, style, transition, animate } from '@angular/animations';

import { NodeSessionService } from '../../../services/session/node-session.service';

import { environment } from '../../../../../environments/environment';
import { CoreSessionService } from '../../../services/session/core-session.service';

@Component({
  selector: 'app-sortable-section',
  templateUrl: './sortable-section.component.html',
  styleUrls: ['./sortable-section.component.scss'],
  animations: [
    trigger('expandAnimation', [
      state(
        'collapsed',
        style({
          height: '0',
          overflow: 'hidden'
        })
      ),
      state(
        'expanded',
        style({
          height: '*'
        })
      ),
      transition('collapsed <=> expanded', [animate('0.4s cubic-bezier(.87,-.41,.19,1.44)')])
    ])
  ]
})
export class SortableSectionComponent implements OnInit {
  @Input() form?: FormGroup;
  @Input() titleAddress?: string;
  @Input() title?: string;
  @Input() subtitle?: string;
  @Input() color?: string;
  @Input() infos?: string;
  @Input() index?: number;
  @Input() length?: number;
  @Input() isExpanded?: boolean;
  @Input() isRemovable?: boolean;
  @Input() nextBubble?: string;
  @Output() move?: EventEmitter<1 | -1>;
  @Output() remove?: EventEmitter<void>;
  @Output() expand?: EventEmitter<void>;

  constructor(public _nodes:  NodeSessionService, public _session: CoreSessionService) {
    this.move = new EventEmitter();
    this.remove = new EventEmitter();
    this.expand = new EventEmitter();
    this.isExpanded = true;
    this.isRemovable = true;
    this.color = environment.colors.primary;
  }

  ngOnInit() {}

  getBackgroundColor(): string {
    if (this.form && this.form.invalid) return environment.colors.invalid;
    else if (this.isExpanded) return 'transparent';
    else return this.color;
  }
  navigate() {
    this._nodes.navigateToNode(this.nextBubble);
  }
}
