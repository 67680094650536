import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { UiElementsModule } from '../ui-elements/ui-elements.module';
import { ProtocolDroidModule } from '../protocol-droid/protocol-droid.module';

import { MessengerProfileFormComponent } from './messenger-profile-form.component';
import { GetStartedFormComponent } from './get-started-form/get-started-form.component';
import { AccountUrlFormComponent } from './account-url-form/account-url-form.component';
import { PersistentMenuFormComponent } from './persistent-menu-form/persistent-menu-form.component';
import { PersistentMenuButtonFormComponent } from './persistent-menu-form/persistent-menu-button-form/persistent-menu-button-form.component';
import { GreetingFormComponent } from './greeting-form/greeting-form.component';
import { HomeUrlFormComponent } from './home-url-form/home-url-form.component';
import { WhitelistedDomainsFormComponent } from './whitelisted-domains-form/whitelisted-domains-form.component';
import { TargetAudienceFormComponent } from './target-audience-form/target-audience-form.component';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, UiElementsModule, ProtocolDroidModule],
  declarations: [
    MessengerProfileFormComponent,
    AccountUrlFormComponent,
    GetStartedFormComponent,
    GreetingFormComponent,
    HomeUrlFormComponent,
    PersistentMenuFormComponent,
    PersistentMenuButtonFormComponent,
    TargetAudienceFormComponent,
    WhitelistedDomainsFormComponent
  ],
  exports: [MessengerProfileFormComponent]
})
export class MessengerProfileFormModule {}
