import { FormGroup, FormControl, Validators, ValidationErrors } from '@angular/forms';
import { get, every } from 'lodash';

import { SendToExternalApiOptions } from 'ideta-library/lib/common/node';
import { DataStoreElem, DataStoreElemKeys } from 'ideta-library/lib/common/data';

import { DataKeyFormControl } from '../../../models/data-key-form-control.model';
import { RichInputFormControl } from '../../../models/rich-input-form-control.model';
import { NodeKeyFormControl } from '../../../models/node-key-form-control.model';
import { SelectedDataStoreElem } from '../../../models/data-selector.component';
import { urlOrKeyValidator, multiplePathValidator } from '../../../models/custom-validators';
import { formats } from '../../../models/regex-formats.model';

const defaultOptions = { active: false };

export class SendToExternalApiForm extends FormGroup {
  constructor(sendToExternalApi: SendToExternalApiOptions = defaultOptions) {
    const isJSON = get(sendToExternalApi, 'options.isJSON');
    const bodyType =
      isJSON === true || isJSON === false
        ? isJSON
          ? 'JSON'
          : 'TEXT'
        : get(sendToExternalApi, 'options.bodyType', 'JSON');
    const formGroup = {
      active: new FormControl(sendToExternalApi.active, { updateOn: 'change' }),
      options: new FormGroup({
        headers: new RichInputFormControl(get(sendToExternalApi, 'options.headers'), {
          validators: [SendToExternalApiForm.httpHeaders]
        }),
        url: new RichInputFormControl(get(sendToExternalApi, 'options.url'), {
          validators: [Validators.required, urlOrKeyValidator]
        }),
        method: new FormControl(get(sendToExternalApi, 'options.method'), [Validators.required]),
        body: new RichInputFormControl(get(sendToExternalApi, 'options.body', '{}'), {
          validators: [Validators.required],
          isJSON: bodyType === 'JSON'
        }),
        bodyType: new FormControl(bodyType, {
          updateOn: 'change'
        }),
        targetNode: new NodeKeyFormControl(get(sendToExternalApi, 'options.targetNode'), [Validators.required]),
        fallbackNode: new NodeKeyFormControl(get(sendToExternalApi, 'options.fallbackNode'), [Validators.required]),
        timeout: new FormControl(get(sendToExternalApi, 'options.timeout', 10000), [
          Validators.required,
          Validators.min(0),
          Validators.max(10000)
        ]),
        data: new FormGroup({
          active: new FormControl(get(sendToExternalApi, 'options.data.active', false), {
            updateOn: 'change'
          }),
          key: new DataKeyFormControl(get(sendToExternalApi, 'options.data.key'), [Validators.required]),
          type: new FormControl(get(sendToExternalApi, 'options.data.type')),
          arrayPath: new RichInputFormControl(get(sendToExternalApi, 'options.data.arrayPath'), {
            validators: [multiplePathValidator]
          }),
          mapping: new FormGroup(
            Object.keys(get(sendToExternalApi, 'options.data.mapping', {})).reduce(
              (mappingFormObj: any, prop: string) => {
                mappingFormObj[prop] = new RichInputFormControl(
                  get(sendToExternalApi, 'options.data.mapping.' + prop),
                  { validators: [multiplePathValidator] }
                );
                return mappingFormObj;
              },
              {}
            ),
            { validators: [SendToExternalApiForm.minOneElement] }
          )
        })
      })
    };

    super(formGroup);
    this.switchControlsDisabling();
  }

  static httpHeaders = (control: FormControl): ValidationErrors | null => {
    const value = control.value;

    return value && !formats.test('httpHeaders', value) ? { invalidHeaders: true } : null;
  };

  static minOneElement = (group: FormGroup): ValidationErrors | null => {
    const controls = Object.keys(group.controls) || [];

    return controls.length > 0 && every(controls, (control: string) => !group.get(control).value)
      ? { noElement: true }
      : null;
  };

  enable(options?: { onlySelf?: boolean; emitEvent?: boolean }): void {
    super.enable(options);
    this.switchControlsDisabling();
  }

  updateActive(active: boolean) {
    this.get('active').patchValue(active, { emitViewToModelChange: false, emitEvent: false });
    this.switchControlsDisabling();
  }

  updateDataActive(active: boolean) {
    this.get('options.data.active').patchValue(active, {
      emitViewToModelChange: false,
      emitEvent: false
    });
    this.switchControlsDisabling();
  }

  updateDataKey(dataKey: SelectedDataStoreElem) {
    this.get('options.data.key').patchValue(dataKey.id, {
      emitViewToModelChange: false,
      emitEvent: false
    });
    this.updateDataForm(dataKey);
    this.switchControlsDisabling();
  }

  updateDataForm(dataKey: DataStoreElem) {
    if (dataKey) {
      const props = this.getPropertyList(dataKey);
      const mappingForm = this.get('options.data.mapping') as FormGroup;
      this.get('options.data.type').patchValue(dataKey.type, {
        emitViewToModelChange: false,
        emitEvent: false
      });
      if (this.get('options.data.type').value === 'array') {
        this.get('options.data.arrayPath').enable({ emitEvent: false });
      } else {
        this.get('options.data.arrayPath').disable({ emitEvent: false });
      }
      Object.keys(props).forEach((id: string) => {
        const prop = props[id];
        if (!mappingForm.contains(prop.key)) {
          mappingForm.addControl(
            prop.key,
            new RichInputFormControl('', {
              validators: [multiplePathValidator]
            })
          );
        }
      });
      Object.keys(mappingForm.controls).forEach((control: string) => {
        if (!Object.keys(props).find((id: string) => props[id].key === control)) {
          mappingForm.removeControl(control);
        }
      });
    }
  }

  switchControlsDisabling() {
    if (this.get('active').value) {
      this.get('options').enable({ emitEvent: false });
      if (['PUT', 'POST', 'PATCH'].indexOf(this.get('options.method').value) > -1) {
        this.get('options.body').enable({ emitEvent: false });
      } else {
        this.get('options.body').disable({ emitEvent: false });
      }
      if (this.get('options.data.active').value) {
        this.get('options.data.type').enable({ emitEvent: false });
        this.get('options.data.key').enable({ emitEvent: false });
        if (this.get('options.data.key').value) {
          this.get('options.data.mapping').enable({ emitEvent: false });
        } else {
          this.get('options.data.mapping').disable({ emitEvent: false });
        }
        if (this.get('options.data.type').value === 'array') {
          this.get('options.data.arrayPath').enable({ emitEvent: false });
        } else {
          this.get('options.data.arrayPath').disable({ emitEvent: false });
        }
        if (this.get('options.data.type').value === 'string') {
          this.get('options.data.mapping').disable({ emitEvent: false });
        } else {
          this.get('options.data.mapping').enable({ emitEvent: false });
        }
      } else {
        this.get('options.data.type').disable({ emitEvent: false });
        this.get('options.data.key').disable({ emitEvent: false });
        this.get('options.data.arrayPath').disable({ emitEvent: false });
        this.get('options.data.mapping').disable({ emitEvent: false });
      }
    } else {
      this.get('options').disable({ emitEvent: false });
    }
    this.updateValueAndValidity();
  }

  private getPropertyList(dataKey: DataStoreElem): DataStoreElemKeys {
    if (!dataKey) return {};
    if (dataKey.type === 'array') return (dataKey.elements && dataKey.elements.keys) || {};
    else if (dataKey.type === 'object') return dataKey.keys || {};
    else return {};
  }
}
