import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import * as RecordRTC from 'recordrtc';

import { DisplayOptions } from './../../../models/display-options.model';
import { TextSpeechService } from '../../../../shared/services/text-speech/text-speech.service';
import { ConversationSessionService } from '../../../services/session/conversation-session.service';

import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-speech-text-recorder',
  templateUrl: './speech-text-recorder.component.html',
  styleUrls: ['./speech-text-recorder.component.scss']
})
export class SpeechTextRecorderComponent implements OnInit, OnDestroy {
  @Input() displayOptions: DisplayOptions;
  @Input() realTime: boolean;
  @Input() showLangSelector: boolean;
  @Input() inputLang: string;
  @Output() start: EventEmitter<void>;
  @Output() update: EventEmitter<string>;
  @Output() end: EventEmitter<void>; // auto end of listenning
  @Output() langChange: EventEmitter<string>;
  isRecording: boolean;
  isDisabled: boolean;
  dicoLangs: { [key: string]: string };
  private speechListener: EventEmitter<void>;
  private cancelTimeout?: NodeJS.Timeout;
  private mediaRecorder?: RecordRTC;
  private socket?: SocketIOClient.Socket;
  private stream?: MediaStream;
  private stopRecordingSub: Subscription;

  get langs(): string[] {
    return Object.keys(this.dicoLangs);
  }

  constructor(private textSpeechService: TextSpeechService, private _conversation: ConversationSessionService) {
    this.dicoLangs = { fr_FR: 'FR', en_US: 'EN' };
    this.speechListener = new EventEmitter();
    this.start = new EventEmitter();
    this.update = new EventEmitter();
    this.end = new EventEmitter();
    this.langChange = new EventEmitter();
  }

  ngOnInit() {
    this.stopRecordingSub = this._conversation.stopAudiolisteningEvent.subscribe(() => {
      if (this.isRecording) this.triggerRecording();
    });

    this.speechListener.pipe(debounceTime(this.displayOptions.autoSendDelay)).subscribe(() => {
      this.stopRecording();
    });
  }

  ngOnDestroy(): void {
    if (this.stopRecordingSub) this.stopRecordingSub.unsubscribe();
  }

  triggerRecording() {
    if (this.isRecording) {
      this.stopRecording();
    } else {
      this.start.emit();
      this.isRecording = true;
      this.launchRecording();
    }
  }

  private async launchRecording() {
    try {
      this.stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      this.mediaRecorder = this.createRecorder();
      this.mediaRecorder.startRecording();
      this.setupSocket();
      this.cancelTimeout = setTimeout(() => {
        this.triggerRecording();
      }, 15000);
    } catch {
      this.isDisabled = true;
      this.clearState();
    }
  }

  private createRecorder() {
    const realtimeHandler = this.sendRealTimeData.bind(this);
    const recorder = new RecordRTC(this.stream, {
      // @ts-ignore
      recorderType: RecordRTC.StereoAudioRecorder,
      disableLogs: environment.production,
      numberOfAudioChannels: 1,
      audioBitsPerSecond: 16000,
      mimeType: 'audio/wav',
      sampleRate: 48000,
      timeSlice: this.realTime ? 1000 : undefined,
      ondataavailable: this.realTime ? realtimeHandler : undefined
    });
    return recorder;
  }

  private sendRealTimeData(data: Blob) {
    if (this.socket) {
      this.socket.emit('voice', data);
    }
  }

  private setupSocket() {
    if (this.realTime) {
      this.socket = this.textSpeechService.createSocket({
        lang: this.inputLang || environment.defaults.misc.textSpeechLang
      });
      this.socket.on('voice-response', (transcript: string) => {
        this.speechListener.emit();
        this.update.emit(transcript);
      });
      this.socket.on('voice-error', () => {
        this.clearState();
      });
    }
  }

  private async stopRecording() {
    if (this.mediaRecorder) {
      const handler = this.getFinalTranscription.bind(this);
      this.mediaRecorder.stopRecording(handler);
    } else {
      this.clearState();
    }
  }

  private async getFinalTranscription() {
    if (this.realTime) {
      this.clearState();
      this.end.emit();
    } else {
      try {
        const blob: any = this.mediaRecorder.getBlob();
        this.clearState();
        const buffer = await blob.arrayBuffer();
        const result = await this.textSpeechService.turnToText(buffer, {
          lang: this.inputLang || environment.defaults.misc.textSpeechLang
        });
        this.update.emit(result);
        this.end.emit();
      } catch (error) {
        this.clearState();
      }
    }
  }

  private clearState() {
    if (this.socket) {
      this.socket.disconnect();
      this.socket = null;
    }
    if (this.mediaRecorder) this.mediaRecorder = null;
    if (this.stream) {
      this.stream.getTracks().forEach(track => track.stop());
      this.stream = null;
    }
    clearTimeout(this.cancelTimeout);
    this.cancelTimeout = null;
    this.isRecording = false;
  }
}
