import { Component, OnInit, Input } from '@angular/core';
import { FormArray } from '@angular/forms';

import { WhitelistedDomainsForm } from './whitelisted-domains-form.model';

@Component({
  selector: 'app-whitelisted-domains-form',
  templateUrl: './whitelisted-domains-form.component.html',
  styleUrls: ['./whitelisted-domains-form.component.scss']
})
export class WhitelistedDomainsFormComponent implements OnInit {
  @Input() parentForm: WhitelistedDomainsForm;

  get domains(): FormArray {
    return this.parentForm.get('domains') as FormArray;
  }
  constructor() {}

  ngOnInit() {}

  trackByFn(index: number): number {
    return index;
  }
}
