import { DataStoreSessionService } from './../../../services/session/data-store-session.service';
import { Component, OnInit, SimpleChanges, OnChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { trigger, state, style, transition, animate } from '@angular/animations';

import { BaseTemplateFormComponent } from '../../shared/base-template-form.component';
import { ButtonsListForm } from '../../shared/buttons-list-form/buttons-list-form.model';
import { DataKeyFormControl } from '../../../models/data-key-form-control.model';
import { RichInputFormControl } from '../../../models/rich-input-form-control.model';
import { NodeKeyFormControl } from '../../../models/node-key-form-control.model';

@Component({
  selector: 'app-carrousel-auto-form',
  templateUrl: './carrousel-auto-form.component.html',
  styleUrls: ['./carrousel-auto-form.component.scss'],
  animations: [
    trigger('expandAnimation', [
      state(
        'collapsed',
        style({
          height: '0',
          overflow: 'hidden'
        })
      ),
      state(
        'expanded',
        style({
          height: '*'
        })
      ),
      transition('collapsed <=> expanded', [animate('0.4s cubic-bezier(.87,-.41,.19,1.44)')])
    ])
  ]
})
export class CarrouselAutoFormComponent extends BaseTemplateFormComponent implements OnInit, OnChanges {
  isValidObjectList: boolean;

  get listKey(): DataKeyFormControl {
    return this.parentForm.get('listKey') as DataKeyFormControl;
  }

  get title(): RichInputFormControl {
    return this.parentForm.get('title') as RichInputFormControl;
  }

  get subtitle(): RichInputFormControl {
    return this.parentForm.get('subtitle') as RichInputFormControl;
  }

  get picture(): RichInputFormControl {
    return this.parentForm.get('picture') as RichInputFormControl;
  }

  get fallbackNode(): NodeKeyFormControl {
    return this.parentForm.get('fallbackNode') as NodeKeyFormControl;
  }

  get buttons(): ButtonsListForm {
    return this.parentForm.get('buttons') as ButtonsListForm;
  }

  constructor(private _dataStore: DataStoreSessionService) {
    super();
  }

  ngOnInit() {
    this.subscriptions.push(this._dataStore.subject$.subscribe(() => this.updateState()));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.parentForm) {
      this.updateState();
    }
  }

  createNodeDropDownValue(control: FormControl, name: string) {
    this.createNode.emit({
      name,
      callback: (key: string) => this.updateControlValue(control, key)
    });
  }

  private updateState() {
    this.isValidObjectList = this._dataStore.isObjectWithPropertyList(this.listKey.value);
  }

  private updateControlValue(control: FormControl, value: any) {
    control.patchValue(value);
    this.updateState();
  }
}
