// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --configuration=production` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  name: 'development',

  // App-wide colors
  colors: {
    primary: '#01a0c7',
    secondary: '#183255',
    tertiary: '#6c757d',
    valid: '#1abc9c',
    invalid: '#e46664'
  },

  // Super Admin
  superAdmin: {
    id: 'IV0HFEZhoUdVHm1bfbp6B2OpdGa2'
  },

  //
  // Domain url
  //
  domain: 'https://trhs-sandbox1.firebaseapp.com',
  creationBotId: '-MOJLMLNTVxyKYoAZM1t',
  useSupportBot: false,

  //
  // Firebase projects
  //
  firebase: {
    apiKey: 'AIzaSyA_YCBPzpLxQGZqkcLXv-71jAL23QHk6jY',
    authDomain: 'trhs-sandbox1.firebaseapp.com',
    databaseURL: 'https://trhs-sandbox1.firebaseio.com',
    projectId: 'trhs-sandbox1',
    storageBucket: 'trhs-sandbox1.appspot.com',
    messagingSenderId: '1059659963313'
  },

  firestore: {
    apiKey: "AIzaSyBvBEiU_pqQAdc7LG6SINfJ8CFmSxWVJ3M",
    authDomain: "private-replies.firebaseapp.com",
    projectId: "private-replies",
    storageBucket: "private-replies.appspot.com",
    messagingSenderId: "893067945162",
    appId: "1:893067945162:web:c38d9151b6b676c66ac033",
    measurementId: "G-HBM7FDTJHF"
  },

  stripe: {
    clientId: 'ca_EsDZeAWDsKRkjqwfzPgzwVnOko3oRxRZ',
    secretKey: 'pk_test_6pRNASCoBOKtIshFeQd4XMUh',
    self_secretKey: 'pk_test_lZzqRcUC3rEqYYfdWJ0w62S2'
  },

  //
  // Bot Signup
  //
  botSignup: {
    src:
      // tslint:disable-next-line: max-line-length
      'https://firebasestorage.googleapis.com/v0/b/ideta-prod.appspot.com/o/bots%2F-MNrUBBZHeJNjGoX1NzD%2Fscripts%2Fintegration-web.js?alt=media&token=3b1bb9df-1b0b-4d98-8721-f9e0fe8a7edd',
    type: 'text/javascript',
    use: false
  },

  //
  // Facebook applications
  //
  facebook: {
    appId: '328730850940468', // Ideta-trhs (dev)
    xfbml: true,
    version: 'v8.0',
    cookie: true,
    logging: true
  },

  //
  // Slack Application
  //
  slack: {
    oauth_url: 'https://slack.com/oauth/v2/authorize',
    oauth_scopes: 'app_mentions:read,chat:write,team:read',
    clientId: '190639077222.786884948657',
    redirectUri: 'https://us-central1-trhs-sandbox1.cloudfunctions.net/slackOAuth'
  },

  //
  // Analytics
  //
  analytics: {
    web_display_watermark:
      'https://ideta.io/?utm_content=conversation-powered-by-ideta' +
      '&utm_source=web&utm_medium=messenger&utm_campaign=powered-by-ideta',
    email_watermark:
      'https://ideta.io/?utm_content=email-powered-by-ideta' +
      '&utm_source=email&utm_medium=customer-email&utm_campaign=powered-by-ideta'
  },

  //
  // Rollbar
  //
  rollbar: {
    active: false,
    accessToken: '5cdd738c57014cd79e6019f0b748a730',
    buildVersion: ''
  },

  //
  // Dialogflow
  //
  dialogflow: {
    client_id: '640370672682-sbpclbvoe5gb6ourqao29cr4fbpqj2k3.apps.googleusercontent.com'
  },

  //
  // NLP Services
  //
  nlpServices: [
    {
      code: 'dialogflow',
      name: 'Dialogflow',
      logo: 'dialogflow.png',
      available: true,
      hasApi: true,
      options: [
        {
          code: 'import',
          available: true,
          requireCredentials: true
        },
        {
          code: 'export',
          available: true,
          requireCredentials: true
        }
      ]
    },
    {
      code: 'watson',
      name: 'Watson',
      logo: 'watson.png',
      available: false,
      hasApi: true
    },
    {
      code: 'alexa',
      name: 'Alexa',
      logo: 'alexa.png',
      available: false,
      hasApi: true,
      options: [
        {
          code: 'json',
          available: true
        },
        {
          code: 'api',
          available: false
        }
      ]
    },
    {
      code: 'luis',
      name: 'Luis',
      logo: 'luis.png',
      available: true,
      hasApi: true,
      options: [
        {
          code: 'import',
          available: true,
          requireCredentials: true
        },
        {
          code: 'export',
          available: true,
          requireCredentials: true
        }
      ]
    },
    {
      code: 'wit',
      name: 'Wit',
      logo: 'wit.png',
      available: false,
      hasApi: true
    },
    {
      code: 'rasa',
      name: 'Rasa',
      logo: 'rasa.png',
      available: true,
      hasApi: false,
      requireCredentials: false,
      options: [
        {
          code: 'json',
          available: true
        }
      ]
    },
    {
      code: 'recast',
      name: 'Recast',
      logo: 'recast.png',
      available: false,
      hasApi: true
    }
  ],

  //
  // Deployment Services
  //
  deploymentServices: [
    {
      name: 'Sandbox',
      type: 'sandbox'
    },
    {
      name: 'Web / Livechat',
      type: 'web'
    },
    {
      name: 'Facebook',
      type: 'facebook'
    },
    {
      name: 'Google Home',
      type: 'google'
    },
    {
      name: 'Slack',
      type: 'slack'
    },
    {
      name: 'Workplace',
      type: 'workplace'
    },
    {
      name: 'Smooch',
      type: 'smooch'
    },
    {
      name: 'Twilio Voice',
      type: 'twiliovoice'
    },
    {
      name: 'Campaigns',
      type: 'campaigns'
    },
    {
      name: 'List',
      type: 'list'
    }
  ],

  //
  // Other options
  //
  opengraphio: {
    endPoint: 'https://opengraph.io/api/1.1/site/',
    apiKey: '5adf19c5ca761f6607b7fc16' // Free tier API key
  },

  appSettings: {
    supportPagesAccess: ['bots-list', 'analytics', 'messaging'],
    maxBroadcastListLength: 100,
    maxPaginatedUserListLength: 50,
    maxLastUserListLength: 100,
    typingVisualDelay: 1500,
    maxQuickReplyElements: 20,
    emptyTemplateImg: 'https://img.icons8.com/pastel-glyph/2x/empty-box.png',
    maxKeyNameLength: 100,
    maxPropertyNameLength: 100,
    delayBeforeCokcpitNodeResend: 5000
  },

  defaults: {
    user: {
      avatarUrl: 'assets/img/avatar.svg'
    },
    bot: {
      name: 'Mon nouveau Bot',
      endPointBack: 'https://ideta-backend-test.herokuapp.com',
      // default dataStore is now handled by the Cloud Functions
      defaultMappingOptions: {
        behaviorMedia: {
          active: true,
          options: {
            type: 'message',
            message: 'Je ne gère pas les pièces jointes...',
            targetNode: 'noeud_0'
          }
        },
        behaviorGeoloc: {
          active: true,
          options: {
            type: 'message',
            message: 'Je ne gère pas la localisation...',
            targetNode: 'noeud_0'
          }
        }
      }
    },
    displayOptions: {
      logo: {
        key: 'logo',
        // Keep default value as false to avoid having the default logo showing up during bot loading
        active: false,
        values: {
          url: {
            value:
              /* tslint:disable-next-line */
              'https://res.cloudinary.com/meet-ideta/image/upload/v1531495960/ideta_logo_un1opi.png',
            type: 'media'
          }
        }
      },
      background: {
        key: 'background',
        active: false,
        values: {
          type: { value: 'url', type: 'dropdown', choices: ['url', 'color'] },
          url: {
            value:
              /* tslint:disable-next-line */
              'https://res.cloudinary.com/meet-ideta/image/upload/v1546525337/wd-background_uozrmw.jpg',
            type: 'media'
          },
          color: { value: '#ffffff', type: 'color' },
          opacity: { value: 100, type: 'number', min: 0, max: 100, inc: 10 },
          position: { value: 'unset', type: 'dropdown', choices: ['unset', 'top', 'center', 'bottom'] }
        }
      },
      icon: {
        key: 'icon',
        active: true,
        values: {
          url: {
            value:
              /* tslint:disable-next-line */
              'https://res.cloudinary.com/meet-ideta/image/upload/v1546525366/bot-icon_ericrs.png',
            type: 'media'
          }
        }
      },
      colors: {
        key: 'colors',
        active: false,
        values: {
          webHeader: { value: '#ffffff', type: 'color' },
          webFooter: { value: '#ffffff', type: 'color' },
          botMessageTxt: { value: '#343a40', type: 'color' },
          botMessageBg: { value: '#dee2e6', type: 'color' },
          userMessageTxt: { value: '#f6f6f6', type: 'color' },
          userMessageBg: { value: '#01a0c7', type: 'color' },
          controlButtons: { value: '#adb5bd', type: 'color' }
        }
      },
      chat: {
        key: 'chat',
        active: false,
        values: {
          freetext: { value: false, type: 'boolean' },
          attachments: { value: false, type: 'boolean' },
          restart: { value: false, type: 'boolean' },
          restartTarget: { value: 'noeud_0', type: 'node' },
          usedQR: { value: true, type: 'boolean' },
          usedButtons: { value: true, type: 'boolean' },
          delay: { value: 1000, type: 'number', min: 0, max: 10000, inc: 1000 },
          deleteOnReload: { value: true, type: 'boolean' },
          deleteOnRestart: { value: false, type: 'boolean' },
          showTypingVisual: { value: true, type: 'boolean' },
          showMessageDate: { value: false, type: 'boolean' },
          showMessageRead: { value: false, type: 'boolean' },
          setInactiveAfter: { value: 300, type: 'number', min: 0, max: 3600, inc: 30 },
          no_branding: { value: false, type: 'boolean' },
          layout: { value: 'small', type: 'dropdown', choices: ['small', 'medium', 'tall'] },
          nodesLayout: { value: 'medium', type: 'dropdown', choices: ['small', 'medium', 'tall'] },
          useNotificationSound: { value: true, type: 'boolean' }
        }
      },
      vocal: {
        key: 'vocal',
        active: false,
        values: {
          speechToText: { value: false, type: 'boolean' },
          autoSend: { value: false, type: 'boolean' },
          autoSendDelay: { value: 2000, type: 'number', min: 500, inc: 1000 },
          textToSpeech: { value: false, type: 'boolean' },
          autoTextToSpeech: { value: false, type: 'boolean' },
          toggleAutoTextToSpeech: { value: false, type: 'boolean' },
          speechLang: { value: 'en_US', type: 'text' }
        }
      }
    },
    layoutOptions: {
      framed: true,
      context: 'preview',
      inverted: false
    },
    integrationOptions: {
      useAltId: { value: false, type: 'boolean' },
      keepOpen: { value: false, type: 'boolean' },
      autoLoad: { value: true, type: 'boolean' },
      lgBreakPoint: { value: 1200, type: 'number', min: 0, inc: 50 },
      lgFullScreen: { value: false, type: 'boolean' },
      marginHoriz: { value: 25, type: 'number', min: 0, inc: 5 },
      marginVert: { value: 25, type: 'number', min: 0, inc: 5 },
      marginVertMiddle: { value: 20, type: 'number', min: 0, inc: 5 },
      marginVertTop: { value: 25, type: 'number', min: 0, inc: 5 },
      iconSize: { value: 65, type: 'number', min: 0, inc: 5, max: 130 },
      iconElementSize: { value: 35, type: 'number', min: 0, inc: 2, max: 130 },
      iframeHeight: { value: 700, type: 'number', min: 200, inc: 50, max: 1000 },
      iframeWidth: { value: 400, type: 'number', min: 200, inc: 50, max: 1000 },
      mdFullScreen: { value: false, type: 'boolean' },
      mdMarginHoriz: { value: 20, type: 'number', min: 0, inc: 5 },
      mdMarginVert: { value: 20, type: 'number', min: 0, inc: 5 },
      mdMarginVertMiddle: { value: 20, type: 'number', min: 0, inc: 5 },
      mdMarginVertTop: { value: 20, type: 'number', min: 0, inc: 5 },
      mdIconSize: { value: 65, type: 'number', min: 0, inc: 5, max: 130 },
      mdIconElementSize: { value: 35, type: 'number', min: 0, inc: 2, max: 130 },
      mdIframeHeight: { value: 600, type: 'number', min: 200, inc: 50, max: 1000 },
      mdIframeWidth: { value: 400, type: 'number', min: 200, inc: 50, max: 1000 },
      smBreakPoint: { value: 700, type: 'number', min: 0, inc: 50 },
      smMarginHoriz: { value: 15, type: 'number', min: 0, inc: 5 },
      smMarginVert: { value: 15, type: 'number', min: 0, inc: 5 },
      smMarginVertMiddle: { value: 15, type: 'number', min: 0, inc: 5 },
      smIconSize: { value: 60, type: 'number', min: 0, inc: 5, max: 130 },
      position: { value: 'right', type: 'dropdown', choices: ['left', 'right'] },
      borderSize: { value: 1, type: 'number', min: 0, inc: 1, max: 10 },
      borderColor: { value: '#01A0C7', type: 'color' },
      shadowSize: { value: 25, type: 'number', min: 0, inc: 1, max: 100 },
      shadowColor: { value: '#DADADA', type: 'color' },
      fontFamily: { value: 'sans-serif', type: 'text' },
      iconImgUrl: {
        value: 'https://res.cloudinary.com/meet-ideta/image/upload/v1546525366/bot-icon_ericrs.png',
        type: 'media'
      },
      iconBorderRadius: { value: 100, type: 'number', min: 0, inc: 5, max: 100 },
      iconBgColor: { value: '#FFFFFF', type: 'color' },
      iconTextColor: { value: '#333333', type: 'color' },
      iframeBorderRadius: { value: 1, type: 'number', min: 0, inc: 0.5, max: 10 },
      iframeBgImage: {
        value: 'https://res.cloudinary.com/meet-ideta/image/upload/v1546525337/wd-background_uozrmw.jpg',
        type: 'media',
        optional: true
      },
      iframeBgPosition: { value: 'unset', type: 'dropdown', choices: ['unset', 'top', 'center', 'bottom'] },
      closeButtonColor: { value: '#adb5bd', type: 'color' },
      closeButtonSize: { value: 2, type: 'number', min: 1, max: 4, inc: 0.5 },
      ctaActive: { value: true, type: 'boolean' },
      ctaBorderRadius: { value: 0.5, type: 'number', min: 0, inc: 0.1, max: 3 },
      ctaTextColor: { value: '#01A0C7', type: 'color' },
      ctaBgColor: { value: '#FFFFFF', type: 'color' },
      ctaText: { value: 'Need help ?', type: 'text' },
      fadeInDelay: { value: 2000, type: 'number', min: 0, inc: 50, max: 10000 },
      loadOpen: { value: false, type: 'boolean' },
      menuActive: { value: true, type: 'boolean' },
      menuMargin: { value: 1, type: 'number', min: 0, inc: 0.1, max: 3 },
      menuBorderRadius: { value: 1, type: 'number', min: 0, inc: 0.5, max: 6 },
      menuBgColor: { value: '#01A0C7', type: 'color' },
      menuLogoUrl: {
        value: 'https://res.cloudinary.com/meet-ideta/image/upload/v1546525366/bot-icon_ericrs.png',
        type: 'media'
      },
      menuLogoSize: { value: 0, type: 'number', min: 0, inc: 1, max: 100 },
      menuTextColor: { value: '#FFFFFF', type: 'color' },
      menuTitleSize: { value: 22, type: 'number', min: 16, inc: 2, max: 30 },
      menuLogoTitleMargin: { value: 0, type: 'number', min: 0, inc: 1, max: 10 },
      menuTitleDescMargin: { value: 2, type: 'number', min: 0, inc: 1, max: 10 },
      menuDescButtonMargin: { value: 3, type: 'number', min: 0, inc: 1, max: 10 },
      menuBtnBgColor: { value: '#FFFFFF', type: 'color' },
      menuBtnWidth: { value: 55, type: 'number', min: 0, inc: 5, max: 100 },
      menuBtnHeight: { value: 7, type: 'number', min: 0, inc: 1, max: 20 },
      menuBtnRadius: { value: 1, type: 'number', min: 0, inc: 0.5, max: 10 },
      menuBtnTextColor: { value: '#183255', type: 'color' },
      menuBtnBorderSize: { value: 2, type: 'number', min: 0, inc: 1, max: 10 },
      menuBtnBorderColor: { value: '#183255', type: 'color' },
      menuBtnShadowColor: { value: '#dadada', type: 'color' },
      menuBtnShadowSize: { value: 10, type: 'number', min: 0, inc: 1, max: 35 },
      menuTitle: { value: 'My title', type: 'text' },
      menuDescription: { value: 'My description', type: 'text' },
      menuStart: { value: 'Start !', type: 'text' },
      menuResume: { value: 'Resume !', type: 'text' }
    },
    shareOptions: {
      ogDescription: { value: 'Create beautiful chatbots on your own', type: 'text' },
      ogImage: {
        value: 'https://res.cloudinary.com/meet-ideta/image/upload/v1531495960/ideta_logo_un1opi.png',
        type: 'media'
      },
      ogSiteName: { value: 'Ideta', type: 'text' },
      ogTitle: { value: 'Ideta Nuki', type: 'text' },
      pageTitle: { value: 'Ideta Nuki', type: 'text' },
      twCard: { value: 'summary', type: 'dropdown', choices: ['summary', 'summary_large_image'] },
      twDescription: { value: 'Create beautiful chatbots on your own', type: 'text' },
      twImage: {
        value: 'https://res.cloudinary.com/meet-ideta/image/upload/v1531495960/ideta_logo_un1opi.png',
        type: 'media'
      },
      twSite: { value: '@ideta', type: 'text' },
      twTitle: { value: 'Ideta Nuki', type: 'text' }
    },
    mappings: {
      pictureApiUrl: 'https://ideta-backend-test.herokuapp.com/error_message_media/',
      pictureErrorMessage: "Désolé! C'est gentil de m'envoyer ça mais je ne peux pas t'aider...",
      geolocApiUrl: 'https://ideta-backend-test.herokuapp.com/error_message_geoloc/',
      geolocErrorMessage: "Désolé! C'est gentil de m'envoyer ça mais je ne peux pas t'aider...",
      apiUrl: 'https://ideta-backend-test.herokuapp.com/error_message/',
      aiApiUrl: 'https://ideta-backend-test.herokuapp.com/error_ai/',
      aiErrorMessage: "Désolé! Je n'ai pas compris..."
    },
    naming: {
      noNameNode: 'bulle_sans_nom'
    },
    api: {
      maxTimeout: 10000
    },
    misc: {
      textSpeechLang: 'en_US',
      voiceNumberThreshold: 100000
    }
  }
};
