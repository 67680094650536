import { FormGroup, FormArray, FormControl, Validators, ValidationErrors } from '@angular/forms';
import { get } from 'lodash';

import { ApiAsyncAction } from 'ideta-library/lib/common/node';

import { RichInputFormControl } from '../../../../models/rich-input-form-control.model';
import { urlOrKeyValidator } from '../../../../models/custom-validators';
import { formats } from '../../../../models/regex-formats.model';

export const defaultApiAction: ApiAsyncAction = { method: 'GET', url: '' };

export class ApiActionForm extends FormGroup {
  get headers(): FormArray {
    return this.get('headers') as FormArray;
  }

  constructor(action: ApiAsyncAction = defaultApiAction) {
    const formGroup = {
      url: new RichInputFormControl(action.url, {
        validators: [Validators.required, urlOrKeyValidator]
      }),
      method: new FormControl(action.method || 'GET', [Validators.required]),
      headers: new FormArray(
        (action.headers &&
          action.headers.map(
            (header: string) => new RichInputFormControl(header, { validators: [Validators.required] })
          )) ||
          []
      ),
      body: new FormGroup(
        {
          type: new FormControl(get(action, 'body.type', 'TEXT'), [Validators.required]),
          value: new RichInputFormControl(get(action, 'body.value'), {
            validators: [Validators.required]
          })
        },
        [ApiActionForm.jsonValidator]
      )
    };

    super(formGroup);

    this.switchControlsDisabling();
  }

  static jsonValidator = (group: FormGroup): ValidationErrors | null => {
    const body = group.value;
    if (body.type === 'JSON') {
      try {
        // replace key names by a value to pass JSON.parse() test
        const parsedValue = body.value.replace(formats.regex('chainTaggedData'), 42);
        JSON.parse(parsedValue);
        return null;
      } catch {
        return { invalidJSON: true };
      }
    } else return null;
  };

  addHeader(): RichInputFormControl {
    this.headers.push(new RichInputFormControl('', { validators: [Validators.required] }));
    return this.headers.at(this.headers.length - 1) as RichInputFormControl;
  }

  enable() {
    super.enable();
    this.switchControlsDisabling();
  }

  switchControlsDisabling() {
    const method = this.get('method').value;
    if (['POST', 'PUT', 'PATCH'].indexOf(method) > -1) this.get('body').enable();
    else this.get('body').disable();
  }
}
