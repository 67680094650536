import { Injectable } from '@angular/core';
import { FacebookService as Facebook, LoginStatus } from 'ngx-facebook';
import { Observable, of, from as fromPromise, combineLatest } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';
import { get } from 'lodash';
import { Page, PageSummary } from 'ideta-library/lib/common/channels/facebook';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { UserSessionService } from '../session/user-session.service';

@Injectable({
  providedIn: 'root'
})
export class FacebookService {
  constructor(private fb: Facebook, private _user: UserSessionService, private httpClient: HttpClient) {
//    console.log('environment.facebook : ', environment.facebook);
    fb.init(environment.facebook);
  }

  public getLoginStatus(forceRefresh?: boolean): Promise<LoginStatus> {
    return this.fb.getLoginStatus(forceRefresh);
  }

  public getPages(): Observable<Page[]>{
    return this._user.distinctSubject$
      .pipe(
        switchMap((user) => this.httpClient.get('https://graph.facebook.com/' +
          environment.facebook.version +
          '/' +
          user.facebook.id +
          '/accounts?access_token=' +
          user.facebook.accessToken)),
        map((res: any) => res.data),
        // tap((pages: any) => console.log(pages)),
        switchMap((pages: PageSummary[]) => {
          if (pages && pages.length > 0) {
            return combineLatest(
              pages.map((pageSummary: PageSummary) =>
                this.getPage(pageSummary.id, pageSummary.access_token).pipe(
                  map((page: any) => ({
                    ...page,
                    id: pageSummary.id,
                    access_token: pageSummary.access_token
                  }))
                )
              )
            );
          } else {
            return of([]);
          }
      }));
  }

  public getPage(pageId: string, accessToken: string): Observable<Page> {
    return fromPromise(this.fb.api(pageId + '?fields=name,about,picture{url}&access_token=' + accessToken)).pipe(
      catchError((error: any) => {
        console.error(error);
        return of(null);
      })
    );
  }

  public getMessengerProfile(token: string): Promise<any> {
    return this.fb
      .api(
        '/me/messenger_profile?fields=account_linking_url,get_started,greeting,' +
          'persistent_menu,target_audience,whitelisted_domains&access_token=' +
          token
      )
      .then((res: any) => {
        return res.data && res.data[0];
      })
      .catch(this.interceptRateLimitationError);
  }

  public updateMessengerProfile(value: any, token: string): Promise<any> {
    return this.fb
      .api('/me/messenger_profile?access_token=' + token, 'post', value)
      .catch(this.interceptRateLimitationError);
  }

  public clearMessengerProfileKey(key: string, token: string): Promise<any> {
    return this.fb
      .api('/me/messenger_profile?access_token=' + token, 'delete', { fields: [key] })
      .catch(this.interceptRateLimitationError);
  }

  private interceptRateLimitationError(error: any) {
    if (get(error, 'message', '').includes('613')) {
      throw new Error(
        'Calls to this api have exceeded the rate limit.' +
          ' You should try again in a few minutes. ' +
          '(<a class="melted" target="_blank" ' +
          'href="https://developers.facebook.com/docs/' +
          'messenger-platform/reference/messenger-profile-api/">learn more</a>)'
      );
    } else throw error;
  }
}
