import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormArray, FormControl } from '@angular/forms';

import { PerformOperationsForm } from './perform-operations-form.model';
import { ActivateSectionEvent } from '../mapping-switch-form.component';
import { BaseMappingFormComponent } from '../../shared/base-mapping-form.component';
import { NodeKeyFormControl } from '../../../models/node-key-form-control.model';

@Component({
  selector: 'app-perform-operations-form',
  templateUrl: './perform-operations-form.component.html',
  styleUrls: ['./perform-operations-form.component.scss']
})
export class PerformOperationsFormComponent extends BaseMappingFormComponent implements OnInit {
  @Input() parentForm: PerformOperationsForm;
  @Output() activateSection: EventEmitter<ActivateSectionEvent>;

  get performOperationsActive(): FormControl {
    return this.parentForm.get('active') as FormControl;
  }

  get performOperationsOptions(): FormGroup {
    return this.parentForm.get('options') as FormGroup;
  }

  get operations(): FormArray {
    return this.performOperationsOptions.get('operations') as FormArray;
  }

  get operationsTargetNode(): NodeKeyFormControl {
    return this.performOperationsOptions.get('targetNode') as NodeKeyFormControl;
  }

  constructor() {
    super();
    this.activateSection = new EventEmitter();
  }

  ngOnInit() {}

  activate() {
    this.parentForm.updateActive(this.performOperationsActive.value);
    this.activateSection.emit({
      section: 'performOperations',
      value: this.performOperationsActive.value
    });
  }
}
