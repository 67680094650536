import { FormGroup, FormControl } from '@angular/forms';

import { NodeTemplate } from 'ideta-library/lib/common/node';

import { TemplateTextForm } from './template-text-form/template-text-form.model';
import { TemplateButtonsForm } from './template-buttons-form/template-buttons-form.model';
import { TemplateQuickRepliesForm } from './template-quick-replies-form/template-quick-replies-form.model';
import { TemplateMediaForm } from './template-media-form/template-media-form.model';
import { TemplateListForm } from './template-list-form/template-list-form.model';
import { TemplateCarrouselForm } from './template-carrousel-form/template-carrousel-form.model';
import { TemplateReceiptForm } from './template-receipt-form/template-receipt-form.model';
import { TemplateOpenGraphForm } from './template-open-graph-form/template-open-graph-form.model';

export class NodeTemplateForm extends FormGroup {
  constructor(nodeTemplate: Partial<NodeTemplate>) {
    nodeTemplate = nodeTemplate || {};
    const formObject = {
      type: new FormControl(nodeTemplate.type || 'text'),
      templateText: new TemplateTextForm(nodeTemplate.templateText),
      templateQuickReplies: new TemplateQuickRepliesForm(nodeTemplate.templateQuickReplies),
      templateButton: new TemplateButtonsForm(nodeTemplate.templateButton),
      templateMedia: new TemplateMediaForm(nodeTemplate.templateMedia),
      templateList: new TemplateListForm(nodeTemplate.templateList),
      templateGeneric: new TemplateCarrouselForm(nodeTemplate.templateGeneric),
      templateReceipt: new TemplateReceiptForm(nodeTemplate.templateReceipt),
      templateOpenGraph: new TemplateOpenGraphForm(nodeTemplate.templateOpenGraph)
    };

    super(formObject, { updateOn: 'change' });

    this.switchControlsDisabling();
  }

  switchControlsDisabling() {
    const type = this.get('type').value;

    /* Disabeling all template forms */
    this.get('templateText').disable({ emitEvent: false });
    this.get('templateQuickReplies').disable({ emitEvent: false });
    this.get('templateButton').disable({ emitEvent: false });
    this.get('templateMedia').disable({ emitEvent: false });
    this.get('templateList').disable({ emitEvent: false });
    this.get('templateGeneric').disable({ emitEvent: false });
    this.get('templateReceipt').disable({ emitEvent: false });
    this.get('templateOpenGraph').disable({ emitEvent: false });

    /* Re-enabeling only selected form */
    if (type === 'text') {
      this.get('templateText').enable({ emitEvent: false });
    } else if (type === 'button') {
      this.get('templateButton').enable({ emitEvent: false });
    } else if (type === 'quick-replies') {
      this.get('templateQuickReplies').enable({ emitEvent: false });
    } else if (type === 'media') {
      this.get('templateMedia').enable({ emitEvent: false });
    } else if (type === 'list') {
      this.get('templateList').enable({ emitEvent: false });
    } else if (type === 'generic') {
      this.get('templateGeneric').enable({ emitEvent: false });
    } else if (type === 'receipt') {
      this.get('templateReceipt').enable({ emitEvent: false });
    } else if (type === 'open-graph') {
      this.get('templateOpenGraph').enable({ emitEvent: false });
    }
    this.updateValueAndValidity();
  }
}
