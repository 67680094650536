import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/functions';

import { Lexicon, LexiconCredentials } from 'ideta-library/lib/common/lexicon';

import { BotSessionService } from '../session/bot-session.service';

@Injectable({
  providedIn: 'root'
})
export class LexiconApiService {
  constructor(private functions: AngularFireFunctions, private _bot: BotSessionService) {}

  getLexicon(service: string): Promise<Lexicon> {
    return Promise.all([this.getIntents(service), this.getEntities(service)]).then(
      ([intents, entities]: [any, any]) => ({ intents, entities })
    );
  }

  diffFromService(service: string, lexicon: any): Promise<any> {
    const callable = this.functions.httpsCallable('getServiceDiff');
    return callable({
      botId: this._bot.id,
      service,
      lexicon: {
        intents: lexicon.intents && lexicon.intents.map((intent: any) => intent.name),
        entities: lexicon.entities && lexicon.entities.map((entity: any) => entity.name),
        examples: lexicon.examples && lexicon.examples.map((example: any) => example.text)
      }
    }).toPromise();
  }

  importFromService(service: string): Promise<any> {
    const callable = this.functions.httpsCallable('importLexicon');
    return callable({
      botId: this._bot.id,
      service
    }).toPromise();
  }

  exportToService(service: string): Promise<any> {
    const callable = this.functions.httpsCallable('exportLexicon');
    return callable({
      botId: this._bot.id,
      service
    }).toPromise();
  }

  updateCredentials(service: string, credentials: Partial<LexiconCredentials>): Promise<any> {
    const callable = this.functions.httpsCallable('updateLexiconCredentials');
    return callable({ botId: this._bot.id, service, credentials }).toPromise();
  }

  fetchAgents(service: string): Promise<any> {
    const callable = this.functions.httpsCallable('fetchAgents');
    return callable({ botId: this._bot.id, service }).toPromise();
  }

  private getIntents(service: string): Promise<any> {
    const callable = this.functions.httpsCallable('getServiceIntents');
    return callable({ botId: this._bot.id, service })
      .toPromise()
      .then(this.orderListByStringKey('name'));
  }

  private getEntities(service: string): Promise<any> {
    const callable = this.functions.httpsCallable('getServiceEntities');
    return callable({ botId: this._bot.id, service })
      .toPromise()
      .then(this.orderListByStringKey('name'));
  }

  private orderListByStringKey(key: string) {
    return (list: any = []) => {
      return [...list].sort((a: any, b: any) => a[key].localeCompare(b[key]));
    };
  }
}
