import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { trigger, transition, animate, style, query, stagger } from '@angular/animations';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { FormControl } from '@angular/forms';
import { Observable, BehaviorSubject, Subscription } from 'rxjs';
import { tap, combineLatest, debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';

import { CachedFormControl } from '../../models/cached-form-control.model';
import { NodeSessionService } from '../../services/session/node-session.service';
import { CoreSessionService } from '../../services/session/core-session.service';

export interface SelectableItem {
  key: string;
  name: string;
}

@Component({
  selector: 'app-node-list',
  templateUrl: './node-list.component.html',
  styleUrls: ['./node-list.component.scss'],
  animations: [
    trigger('filterAnimation', [
      transition(':increment', [
        query(
          ':enter',
          [
            style({ opacity: 0, width: '0px' }),
            stagger(0, [animate('300ms ease-out', style({ opacity: 1, width: '*' }))])
          ],
          { optional: true }
        )
      ]),
      transition(':decrement', [
        query(':leave', [stagger(0, [animate('300ms ease-out', style({ opacity: 0, width: '0px' }))])], {
          optional: true
        })
      ])
    ])
  ]
})
export class NodeListComponent implements OnInit, OnDestroy {
  @Input() itemsList$: Observable<SelectableItem[]>;
  @Input() checkedItems: { [id: string]: boolean };
  @Input() selectedKey?: string;
  @Input() hideTrashIcon?: boolean;
  @Input() deleteStatus?: 'bubble' | 'template';
  @Input() hideCheckBox?: 'none' | 'root' | 'all';
  @Input() hideCategories: boolean;
  @Input() selectable?: boolean;
  @Input() searchable?: boolean;
  @Input() collapsedOnInit: boolean;
  @Input() categoriesList: CachedFormControl[];
  @Input() skipNodesValues: number;
  @Input() search$: BehaviorSubject<string>;
  @Input() editable: boolean;
  @Output() deleteAction?: EventEmitter<void>;
  @Output() manageCategories: EventEmitter<boolean>;
  @Output() selectNode?: EventEmitter<string>;
  @Output() add?: EventEmitter<void>;
  @Output() check?: EventEmitter<any>;
  @Output() updateCategory?: EventEmitter<{ value: string; nodeKeys: string[] }>;
  @Output() resetCheckList: EventEmitter<void>;
  @Output() deleteNode: EventEmitter<void>;
  filteredList: any[];
  isListLoaded: boolean;
  isEmpty: boolean;
  uniqueId: string;
  nbNodes: number;
  searchAction: boolean;
  private subscriptions: Subscription[];

  constructor(private _nodes: NodeSessionService, public _session: CoreSessionService) {
    this.search$ = new BehaviorSubject('');
    this.deleteAction = new EventEmitter();
    this.selectNode = new EventEmitter();
    this.add = new EventEmitter();
    this.check = new EventEmitter();
    this.updateCategory = new EventEmitter();
    this.manageCategories = new EventEmitter();
    this.deleteNode = new EventEmitter();
    this.uniqueId = Math.random()
      .toString(36)
      .substring(2, 8);
    this.checkedItems = {};
    this.deleteStatus = 'bubble';
    this.hideTrashIcon = true;
    this.hideCheckBox = 'all';
    this.selectable = true;
    this.searchable = true;
    this.searchAction = false;
    this.collapsedOnInit = false;
    this.nbNodes = 0;
    this.subscriptions = [];
    this.categoriesList = [];
    this.filteredList = [];
    this.skipNodesValues = 0;
    this.isEmpty = true;
    this.resetCheckList = new EventEmitter();
  }

  ngOnInit() {
    this.categoriesList.push(
      new CachedFormControl({ value: '', disabled: true }, { updateOn: 'blur' }, { expanded: !this.collapsedOnInit })
    );
    this.subscriptions.push(
      (this.itemsList$ || this._nodes.subject$)
        .pipe(
          filter(() => {
            if (this.skipNodesValues > 0) this.skipNodesValues -= 1;
            return this.skipNodesValues === 0;
          }),
          tap(() => {
            this.isListLoaded = false;
            if (!this.editable && this.categoriesList.length > 1) {
              this.categoriesList.splice(1, this.categoriesList.length - 1);
            }
          }),
          combineLatest(
            this.search$.pipe(
              debounceTime(300),
              distinctUntilChanged(),
              tap(() => (this.searchAction = true))
            ),
            this.filterList
          )
        )
        .subscribe((filteredList: any) => {
          if (filteredList) {
            filteredList.forEach((node: any) => {
              if (node.category && !this.categoriesList.find(entry => entry.value === node.category)) {
                this.categoriesList.push(
                  new CachedFormControl(
                    { value: node.category, disabled: !this.editable },
                    { updateOn: 'blur' },
                    { expanded: this.isListLoaded ? true : !this.collapsedOnInit }
                  )
                );
              }
              this.checkedItems[node.key] = this.searchAction ? false : this.checkedItems[node.key] || false;
            });
            if (this.searchAction) this.resetCheckList.emit();
            this.nbNodes = filteredList.length;
            const isEmpty = this.nbNodes === 0;
            if (!this.isListLoaded || !isEmpty) {
              this.isEmpty = isEmpty;
            } else if (isEmpty) {
              setTimeout(() => (this.isEmpty = isEmpty), 300);
            }
            this.filteredList.splice(0, this.filteredList.length);
            this.filteredList.push(...filteredList);
            this.isListLoaded = true;
          }
          this.searchAction = false;
          this.categoriesList.sort((a, b) => a.value.localeCompare(b.value));
        })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  searchItem(value: string): void {
    this.search$.next(value);
  }

  selectItem(item: any): void {
    if (this.selectable && this.selectNode.observers.length && item.key !== this.selectedKey) {
      this.selectNode.emit(item);
    }
  }

  checkItem(item: any, value: boolean): void {
    this.check.emit({ scope: [item.key], value });
  }

  trackByItem(_index: number, item: any): string {
    return item.key;
  }

  trackByCategory(_index: number, category: CachedFormControl): string {
    return category.value;
  }

  isInvisibleCheckBox(itemKey: string): boolean {
    return this.hideCheckBox === 'root' && itemKey === 'noeud_0';
  }

  moveNode(event: CdkDragDrop<FormControl>) {
    if (event.container.data.value === event.item.data.category) return;
    this.updateCategory.emit({
      value: event.container.data.value,
      nodeKeys: [event.item.data.key]
    });
  }

  renameCategory(category: CachedFormControl, index: number) {
    if (category.value === category.initialValue) return;
    const nodeKeys = this.categoryNodes(category.initialValue, true);
    this.skipNodesValues = nodeKeys.length;
    const update = {
      value: category.value,
      nodeKeys
    };
    this.categoriesList.splice(index, 1);
    this.updateCategory.emit(update);
  }

  categoryNodes(categoryName: string = '', onlyKey?: boolean): any[] {
    const nodeList = this.filteredList.filter((node: any) => (node.category || '') === categoryName) || [];
    return onlyKey ? nodeList.map((node: any) => node.key) : nodeList;
  }

  deleteItem(id: string) {
    this.checkedItems[id] = true;
    this.deleteNode.emit();
    this.checkedItems[id] = false;
  }

  private filterList(list: any, search: string) {
    if (!list) return null;

    const lowerSearch = search.toLowerCase();
    return list.filter((item: any) => {
      const lowerName = item.name && item.name.toLowerCase();
      return lowerName && lowerName.indexOf(lowerSearch) > -1;
    });
  }
}
