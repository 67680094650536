import { Component, OnInit } from '@angular/core';

import { BaseTemplateFormComponent } from '../shared/base-template-form.component';
import { RichInputFormControl } from '../../models/rich-input-form-control.model';

@Component({
  selector: 'app-template-text-form',
  templateUrl: './template-text-form.component.html',
  styleUrls: ['./template-text-form.component.scss']
})
export class TemplateTextFormComponent extends BaseTemplateFormComponent implements OnInit {
  get text(): RichInputFormControl {
    return this.parentForm.get('text') as RichInputFormControl;
  }

  ngOnInit() {}
}
