import { Injectable } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { UserSessionService } from './../session/user-session.service';

@Injectable({
  providedIn: 'root'
})
export class UserStorageService {
  constructor(private storage: AngularFireStorage, private userSession: UserSessionService) {}

  getFileUrl(bucket: string): Promise<any> {
    const filePath = `users/${this.userSession.id}/${bucket}`;

    return this.storage
      .ref(filePath)
      .getDownloadURL()
      .toPromise()
      .catch(() => false);
  }

  setFile(bucket: string, file: File | Blob): Promise<string> {
    const filePath = `users/${this.userSession.id}/${bucket}`;

    return this.storage
      .upload(filePath, file)
      .snapshotChanges()
      .toPromise()
      .then(() =>
        this.storage
          .ref(filePath)
          .getDownloadURL()
          .toPromise()
      )
      .catch((error: any) => {
        console.error(error);
        return null;
      });
  }

  deleteFile(bucket: string): Promise<string> {
    const filePath = `users/${this.userSession.id}/${bucket}`;

    return this.storage
      .ref(filePath)
      .delete()
      .toPromise()
      .then(() => null)
      .catch((error: any) => {
        console.error(error);
        return null;
      });
  }
}
