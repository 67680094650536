import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';

import {
  PersistentMenuButtonType,
  PersistentMenuButtonForm,
  PersistentMenuWebviewType
} from './persistent-menu-button-form.model';
import { ProtocolDroidService } from '../../../protocol-droid/services/protocol-droid.service';
import { NodeKeyFormControl } from '../../../models/node-key-form-control.model';

@Component({
  selector: 'app-persistent-menu-button-form',
  templateUrl: './persistent-menu-button-form.component.html',
  styleUrls: ['./persistent-menu-button-form.component.scss']
})
export class PersistentMenuButtonFormComponent implements OnInit, OnDestroy {
  @Input() parentForm: PersistentMenuButtonForm;
  buttonTypes: { [key in PersistentMenuButtonType]: string };
  webviewTypes: { [key in PersistentMenuWebviewType]: string };
  private subscription: Subscription;

  get type(): FormControl {
    return this.parentForm.get('type') as FormControl;
  }

  get title(): FormControl {
    return this.parentForm.get('title') as FormControl;
  }

  get targetNode(): NodeKeyFormControl {
    return this.parentForm.get('targetNode') as NodeKeyFormControl;
  }

  get url(): FormControl {
    return this.parentForm.get('url') as FormControl;
  }

  get webviewHeightRatio(): FormControl {
    return this.parentForm.get('webviewHeightRatio') as FormControl;
  }

  constructor(private protocolDroidService: ProtocolDroidService) {}

  ngOnInit() {
    this.subscription = this.protocolDroidService.onTranslationChangeEvent.subscribe(() => {
      this.buttonTypes = {
        postback: this.protocolDroidService.translate(
          'shared.components.modals.messenger-profile.form.persistent-menu.button-postback',
          'Avancer vers une bulle'
        ),
        web_url: this.protocolDroidService.translate(
          'shared.components.modals.messenger-profile.form.persistent-menu.button-web_url',
          'Ouvrir une URL'
        )
      };
      this.webviewTypes = {
        compact: this.protocolDroidService.translate(
          'shared.components.modals.messenger-profile.form.persistent-menu.webview-compact',
          'Compacte'
        ),
        tall: this.protocolDroidService.translate(
          'shared.components.modals.messenger-profile.form.persistent-menu.webview-tall',
          'Grande'
        ),
        full: this.protocolDroidService.translate(
          'shared.components.modals.messenger-profile.form.persistent-menu.webview-full',
          'Pleine'
        )
      };
    });
  }

  ngOnDestroy() {
    if (this.subscription && !this.subscription.closed) this.subscription.unsubscribe();
  }
}
