import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnChanges, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { startWith, delay } from 'rxjs/operators';

import {
  DataSelectorComponent,
  SelectedDataStoreElem,
  DataStoreElemPreview
} from '../../../models/data-selector.component';
import { DataKeyFormControl } from '../../../models/data-key-form-control.model';
import { DataStoreSessionService } from './../../../services/session/data-store-session.service';
import { formats } from '../../../models/regex-formats.model';

@Component({
  selector: 'app-data-keys-dropdown',
  templateUrl: './data-keys-dropdown.component.html',
  styleUrls: ['./data-keys-dropdown.component.scss']
})
export class DataKeysDropdownComponent extends DataSelectorComponent implements OnInit, OnChanges, OnDestroy {
  @Input() input: DataKeyFormControl;
  @Input() hideErrorBadge?: boolean;
  @Output() clear?: EventEmitter<void>;
  @Output() dispose?: EventEmitter<void>;
  validityClass: string;
  // now that we stock dataKeys by id we want to display their name as a placeholder
  dataKeyName: string;
  // ###DK This property is used to warn the admin in the case
  // his key is still stored in the previous format
  // and require to be changed
  oldFormat: boolean;
  private inputStatusSub: Subscription;
  private inputValueSub: Subscription;

  constructor(private _dataStore: DataStoreSessionService) {
    super();
    this.clear = new EventEmitter();
    this.dispose = new EventEmitter();
  }

  ngOnInit() {
    this.subscriptions.push(this._dataStore.subject$.pipe(delay(0)).subscribe(() => this.updateInputInfos()));
    this.subscribeToInput();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.input && !changes.input.firstChange) {
      this.subscribeToInput();
      // Avoid ExpressionChangedAfterItHasBeenCheckedError
      setTimeout(() => this.updateInputInfos(), 0);
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub: Subscription) => sub.unsubscribe());
    this.clearInputSubscriptions();
  }

  selectKey(dataKey: SelectedDataStoreElem) {
    const { id, key, scope, type: dataType, elements } = dataKey;
    this.dataKeyName = key;
    this.oldFormat = false;
    this.input.patchValue({ value: id, key, scope, dataType, elements });
    this.selectDataKey.emit(dataKey);
  }

  createKey(dataKey: DataStoreElemPreview) {
    this.createDataKey.emit({
      dataKey,
      callback: createdDataKey => this.selectKey(createdDataKey)
    });
  }

  private updateInputInfos() {
    if (!this.input.value) return;
    const { name, oldFormat } = this._dataStore.updateSelectedDataInfos(
      formats.parseAddress(
        this.input.isElement ? this.selectedParentKey.value + '.' + this.input.value : this.input.value
      ),
      this.input,
      this.allowedTypes,
      this.allowedScopes,
      this.input.isElement
    );
    this.dataKeyName = name;
    this.oldFormat = oldFormat;
  }

  private subscribeToInput() {
    this.clearInputSubscriptions();
    this.inputStatusSub = this.input.statusChanges.pipe(startWith(this.input.status)).subscribe((status: string) => {
      if (status === 'INVALID') this.validityClass = 'btn-danger';
      else this.validityClass = 'btn-light';
    });
    this.inputValueSub = this.input.valueChanges.subscribe(() => [(this.dataKeyName = this.input.displayName)]);
  }

  private clearInputSubscriptions() {
    if (this.inputStatusSub) this.inputStatusSub.unsubscribe();
    if (this.inputValueSub) this.inputValueSub.unsubscribe();
  }
}
