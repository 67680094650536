import { FormGroup, FormControl, Validators } from '@angular/forms';

import { ReceiptElement } from 'ideta-library/lib/common/node';

import { urlOrKeyValidator } from '../../models/custom-validators';

const defaultElement: ReceiptElement = {
  title: '',
  price: 0
};

export class ReceiptElementForm extends FormGroup {
  constructor(element: ReceiptElement = defaultElement) {
    const formGroup = {
      title: new FormControl(element.title, [Validators.required]),
      subtitle: new FormControl(element.subtitle),
      quantity: new FormControl(element.quantity || 0),
      price: new FormControl(element.price || 0, [Validators.required]),
      currency: new FormControl(element.currency),
      imageUrl: new FormControl(element.imageUrl, [urlOrKeyValidator])
    };

    super(formGroup);
  }
}
