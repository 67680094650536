import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'trustRessource'
})
export class TrustRessourcePipe implements PipeTransform {
  constructor(private dom: DomSanitizer) {}

  transform(value: any): any {
    return this.dom.bypassSecurityTrustResourceUrl(value);
  }
}
