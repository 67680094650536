import { Component, OnInit, Input, EventEmitter, Output, OnDestroy } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';

import { DataAsyncActionForm } from './data-async-action-form.model';
import { ProtocolDroidService } from '../../../../../protocol-droid/services/protocol-droid.service';
import { KeyCreationEvent } from '../../../../../models/data-selector.component';

@Component({
  selector: 'app-data-async-action-form',
  templateUrl: './data-async-action-form.component.html',
  styleUrls: ['./data-async-action-form.component.scss']
})
export class DataAsyncActionFormComponent implements OnInit, OnDestroy {
  @Input() parentForm: DataAsyncActionForm;
  @Output() createDataKey: EventEmitter<KeyCreationEvent>;
  @Output() createNode: EventEmitter<{
    name: string;
    callback: (key: string) => void;
  }>;
  triggerTypes: { [key: string]: string };
  private subscriptions: Subscription[];

  get trigger(): FormControl {
    return this.parentForm.get('trigger') as FormControl;
  }

  get action(): FormGroup {
    return this.parentForm.get('action') as FormGroup;
  }

  constructor(private protocolDroidService: ProtocolDroidService) {
    this.createDataKey = new EventEmitter();
    this.createNode = new EventEmitter();
    this.subscriptions = [];
  }

  ngOnInit() {
    this.subscriptions.push(
      this.protocolDroidService.onTranslationChangeEvent.subscribe(() => {
        this.triggerTypes = {
          update: this.protocolDroidService.translate(
            'idechat.edition.bot-data.bot-data-form.label-data-action-update',
            'Mise à jour de la variable (nouvelle valeur seulement)'
          ),
          write: this.protocolDroidService.translate(
            'idechat.edition.bot-data.bot-data-form.label-data-action-write',
            'Mise à jour de la variable (ancienne valeur incluse)'
          )
        };
      })
    );
  }

  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
    }
  }
}
