import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { uniqueId } from 'lodash';

@Component({
  selector: 'app-simple-dropdown',
  templateUrl: './simple-dropdown.component.html',
  styleUrls: ['./simple-dropdown.component.scss']
})
export class SimpleDropdownComponent implements OnInit {
  @ViewChild('dropdownContent', { static: true }) dropdownContent: ElementRef;
  @Input() input: FormControl;
  @Input() dicoValues: { [key: string]: string };
  @Input() isDisabled?: boolean;
  @Input() isDropup?: boolean;
  @Input() showErrors: boolean;
  @Output() preSelect?: EventEmitter<string>;
  @Output() postSelect?: EventEmitter<string>;
  id: string;

  get values() {
    return Object.keys(this.dicoValues || {});
  }

  constructor() {
    this.id = uniqueId();
    this.preSelect = new EventEmitter();
    this.postSelect = new EventEmitter();
    this.isDisabled = false;
  }

  ngOnInit() {}

  select(value: string) {
    this.preSelect.emit(value);
    this.input.patchValue(value);
    this.postSelect.emit(value);
    // Used to enable 'enter' event after selection
    this.dropdownContent.nativeElement.focus();
  }
}
