import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { map, take, filter } from 'rxjs/operators';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { get } from 'lodash';

import { DataStore, DataStoreElem } from 'ideta-library/lib/common/data';

import { DataStoreSessionService } from '../../../services/session/data-store-session.service';
import { NodeService } from '../../../services/node/node.service';
import { BotDataService } from '../../../services/bot/bot-data/bot-data.service';
import { FeedbackService } from '../../../services/feedback/feedback.service';
import { ProtocolDroidService } from '../../../protocol-droid/services/protocol-droid.service';
import { ErrorsService } from '../../../services/errors/errors.service';
import { KeyCreationEvent } from '../../../models/data-selector.component';
import { collapse } from '../../../animations/collapse.animation';
import { BotSessionService } from '../../../services/session/bot-session.service';

@Component({
  selector: 'app-modal-bot-data',
  templateUrl: './modal-bot-data.component.html',
  styleUrls: ['./modal-bot-data.component.scss'],
  animations: [collapse]
})
export class ModalBotDataComponent implements OnInit, OnDestroy {
  dataKeysConversation: DataStoreElem[];
  dataKeysBot: DataStoreElem[];
  dataKeysSystem: DataStoreElem[];
  sections: any;
  expandedKeys: { [key: string]: boolean };
  visualCheck: {
    conversation: boolean;
    bot: boolean;
  };
  private subscriptions: Subscription[];

  constructor(
    private modalRef: BsModalRef,
    private modalService: BsModalService,
    private botDataService: BotDataService,
    private nodeService: NodeService,
    private errorsService: ErrorsService,
    private feedbackService: FeedbackService,
    private protocolDroidService: ProtocolDroidService,
    private _dataStore: DataStoreSessionService,
    public _bot: BotSessionService
  ) {
    this.subscriptions = [];
    this.expandedKeys = {};
    this.sections = {
      conversation: { collapsed: false },
      bot: { collapsed: false },
      system: { collapsed: true }
    };
    this.visualCheck = { conversation: false, bot: false };
  }

  ngOnInit() {
    this.subscriptions.push(
      this._dataStore.subject$.subscribe(() => {
        this.dataKeysConversation = this._dataStore.getScopedDataList('conversation');
        this.dataKeysBot = this._dataStore.getScopedDataList('bot');
        this.dataKeysSystem = this._dataStore.getScopedDataList('system');
      })
    );

    this.modalService.onHidden.pipe(
      filter(() => this.modalService.getModalsCount() === 0),
      take(1)
    );

    // this is the only way to ignore only mouseup events on the backdrop
    const modalView = get(document.getElementsByClassName('modal'), '0');
    const modalDocument = get(document.getElementsByClassName('modal-content'), '0');
    modalView.addEventListener('mousedown', () => this.dismiss());
    modalDocument.addEventListener('mousedown', event => event.stopPropagation());
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  getDataObservable(keyId: string, index: number): Observable<any> {
    return this._dataStore.subject$.pipe(map((dataStore: DataStore) => ({ index, ...dataStore[keyId], id: keyId })));
  }

  updateKey(id: string, value: any) {
    return this.botDataService.updateKey(id, value).catch(this.errorsService.handlePromise());
  }

  deleteKey(dataKey: DataStoreElem, event: MouseEvent) {
    if (dataKey.scope === 'system') return;
    this.feedbackService
      .showMessage(
        this.protocolDroidService.translate(
          'idechat.edition.bot-data.warning-delete-key',
          'All information related to this key will be lost.'
        ),
        {
          title: this.protocolDroidService.translate('misc.modals.confirm-title', 'Do you confirm?'),
          type: 'confirm',
          decoration: 'warning'
        }
      )
      .then((isConfirmed: boolean) => {
        if (isConfirmed) {
          this.botDataService.deleteKey(dataKey.id).catch(this.errorsService.handlePromise());
        }
      });
    if (event) {
      event.stopPropagation();
    }
  }

  switchSectionCollapse(sectionKey: string) {
    this.sections[sectionKey].collapsed = !this.sections[sectionKey].collapsed;
  }

  addDatakey(scope: string) {
    this.botDataService.createKey({ scope }).catch(this.errorsService.handlePromise());
    this.visualCheck[scope] = true;
    setTimeout(() => (this.visualCheck[scope] = false), 1500);
  }

  attachDataKey({ dataKey, callback }: KeyCreationEvent) {
    if (!dataKey.type) dataKey.type = 'string';
    this.botDataService
      .createKey(dataKey)
      .then(ref => {
        if (typeof callback === 'function') {
          callback({ ...dataKey, id: ref.key });
        }
      })
      .catch(this.errorsService.handlePromise());
  }

  attachNode({ name, callback }: { name: string; callback: (key: string) => void }) {
    this.nodeService
      .createNode(this._bot.defaultMappingOptions, { name })
      .then((nodeKey: string) => {
        if (typeof callback === 'function') {
          callback(nodeKey);
        }
      })
      .catch(this.errorsService.handlePromise());
  }

  // As lists are constantly sorted, we have to combine the
  // dataKey id and index to know which ones have moved
  trackByFn(index: number, dataKey: DataStoreElem): string {
    return index + dataKey.id;
  }

  dismiss(): void {
    this.modalRef.hide();
  }
}
