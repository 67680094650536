import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { get } from 'lodash';

import { ProtocolDroidService } from '../../protocol-droid/services/protocol-droid.service';
import { AuthService } from '../../services/auth/auth.service';

export interface DialogflowProfile {
  project_id?: string;
  client_email?: string;
  private_key?: string;
}

@Component({
  selector: 'app-df-profile-connector',
  templateUrl: './df-profile-connector.component.html',
  styleUrls: ['./df-profile-connector.component.scss']
})
export class DfProfileConnectorComponent implements OnInit {
  @Input() profile: any;
  @Input() agents: any;
  @Output() update: EventEmitter<DialogflowProfile>;
  @Output() dismiss: EventEmitter<void>;
  dialogflowForm: FormGroup;
  showErrors: boolean;
  status: string; // Can be 'loading', 'success' or any error message
  gapiOAuthReady = false;

  get isLocalhost(): boolean {
    return window.location.hostname === 'localhost';
  }

  get isLoggedWithOAuth(): boolean {
    return get(this.profile, 'access_token') && get(this.profile, 'refresh_token');
  }

  get isProjectSelected(): boolean {
    return get(this.profile, 'project_id');
  }

  get projectId(): FormControl {
    return this.dialogflowForm.get('project_id') as FormControl;
  }

  get clientEmail(): FormControl {
    return this.dialogflowForm.get('client_email') as FormControl;
  }

  get privateKey(): FormControl {
    return this.dialogflowForm.get('private_key') as FormControl;
  }

  get agentName(): FormControl {
    return this.dialogflowForm.get('agentName') as FormControl;
  }

  get timeZone(): FormControl {
    return this.dialogflowForm.get('timeZone') as FormControl;
  }

  constructor(private protocolDroidService: ProtocolDroidService, private authService: AuthService) {
    this.dialogflowForm = new FormGroup({}, { updateOn: 'blur' });
    this.update = new EventEmitter();
    this.dismiss = new EventEmitter();
    this.showErrors = false;
  }

  ngOnInit() {
    this.authService.initGoogleAuthSDK(() => {
      this.gapiOAuthReady = true;
    });
    this.updateForm();
  }

  save() {
    if (this.isLoggedWithOAuth && !this.dialogflowForm.dirty) {
      this.showErrors = false;
      this.dismiss.emit();
    } else if (this.dialogflowForm.valid) {
      this.showErrors = false;
      this.update.emit(this.dialogflowForm.value);
    } else {
      this.showErrors = true;
    }
  }

  handleCredentialsUpload(files: FileList): void {
    if (files.length > 0) {
      this.status = 'loading';
      this.parseJsonKey(files)
        .then((parsedJSON: any) => {
          this.dialogflowForm.patchValue(parsedJSON);
          this.dialogflowForm.markAsDirty();
        })
        .then(() => setTimeout(() => this.flashStatus('success'), 1000))
        .catch((error: any) => {
          const message =
            (error && error.message) ||
            this.protocolDroidService.translate('misc.errors.unknown-short', 'Unknown Error');
          setTimeout(() => this.flashStatus(message), 1000);
        });
    }
  }

  startOAuthProcess() {
    this.authService
      .grantOfflineAccess()
      .then(code => this.update.emit(code))
      .catch((error: any) => console.log(error));
  }

  selectAgent(index: number) {
    this.profile = {
      ...this.profile,
      project_id: this.agents[index].parent.split('/')[1],
      displayName: this.agents[index].displayName
    };
    this.update.emit(this.profile);
  }

  isSelectedAgent(agent: any) {
    return get(this.profile, 'project_id') === get(agent, 'parent', '').split('/')[1];
  }

  private updateForm() {
    const config = this.profile || {};

    this.dialogflowForm.setControl('project_id', new FormControl(config && config.project_id, [Validators.required]));
    this.dialogflowForm.setControl(
      'client_email',
      new FormControl(config && config.client_email, [Validators.required])
    );
    this.dialogflowForm.setControl('private_key', new FormControl(config && config.private_key, [Validators.required]));
  }

  private parseJsonKey(files: FileList): Promise<void> {
    const reader = new FileReader();
    return new Promise((resolve: any, reject: any) => {
      reader.onloadend = () => {
        if (reader.error) {
          reject(reader.error);
        } else if (reader.result) {
          try {
            const parsedJSON = JSON.parse(reader.result as string);
            ['project_id', 'client_email', 'private_key'].forEach((field: string) => {
              if (!parsedJSON[field]) {
                throw new Error('Missing key ' + field);
              }
            });
            resolve(parsedJSON);
          } catch (error) {
            reject(error);
          }
        }
      };
      reader.readAsText(files[0]);
    });
  }

  private flashStatus(status: string): void {
    this.status = status;
    setTimeout(() => (this.status = null), 1000);
  }
}
