import { FormControl, ValidatorFn, ValidationErrors, Validators } from '@angular/forms';

export type AllowedType = 'boolean' | 'number' | 'text' | 'color' | 'dropdown' | 'media';

interface Options {
  type: AllowedType;
  name: string;
  defaultValue: string | number | boolean;
  disabled?: boolean;
  min?: number;
  max?: number;
  inc?: number;
  choices?: string[];
  optional?: boolean;
}

export class TypeAdaptingFormControl extends FormControl {
  type: AllowedType;
  name: string;
  defaultValue: string | number | boolean;
  min?: number; // Type 'number'
  max?: number; // Type 'number'
  inc?: number; // Type 'number'
  choices?: string[]; // Type 'dropdown'

  constructor(value: any, options: Options, vals?: ValidatorFn[]) {
    if (['boolean', 'number', 'text', 'color', 'dropdown', 'media'].indexOf(options.type) === -1) {
      throw new Error('Invalid form control type');
    }
    const validators = vals || [];

    super(value);

    this.type = options.type;
    this.name = options.name;
    this.defaultValue = options.defaultValue;
    this.min = options.min;
    this.max = options.max;
    this.inc = options.inc;
    this.choices = options.choices;
    this.choices = options.choices;

    if (options.min !== undefined) validators.push(TypeAdaptingFormControl.minValue);
    if (options.max !== undefined) validators.push(TypeAdaptingFormControl.maxValue);
    if (options.choices !== undefined) validators.push(TypeAdaptingFormControl.dropdownValues);
    if (!options.optional) validators.push(Validators.required);

    this.setValidators(validators);
    if (options.disabled) this.disable();
  }

  private static minValue = (control: TypeAdaptingFormControl): ValidationErrors | null => {
    return control.value && control.value < control.min ? { invalidMinValue: true } : null;
  };

  private static maxValue = (control: TypeAdaptingFormControl): ValidationErrors | null => {
    return control.value && control.value > control.max ? { invalidMaxValue: true } : null;
  };

  private static dropdownValues = (control: TypeAdaptingFormControl): ValidationErrors | null => {
    return !control.value || control.choices.indexOf(control.value) > -1 ? null : { invalidChoice: true };
  };
}
