import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { uniqueId } from 'lodash';
import { Subscription } from 'rxjs';

import { DateDisplay, DateInput, DateOutput, FormatCheckType } from 'ideta-library/lib/common/node';

import { SaveDataForm } from './save-data-form.model';
import { BaseMappingFormComponent } from '../../shared/base-mapping-form.component';
import { DataKeyFormControl } from '../../../models/data-key-form-control.model';
import { ProtocolDroidService } from '../../../protocol-droid/services/protocol-droid.service';
import { NodeKeyFormControl } from '../../../models/node-key-form-control.model';

@Component({
  selector: 'app-save-data-form',
  templateUrl: './save-data-form.component.html',
  styleUrls: ['./save-data-form.component.scss']
})
export class SaveDataFormComponent extends BaseMappingFormComponent implements OnInit {
  @Input() parentForm: SaveDataForm;
  formatTypes: { [key in FormatCheckType]: string };
  dateDisplays: { [key in DateDisplay]: string };
  dateInputs: { [key in DateInput]: string };
  dateOutputs: { [key in DateOutput]: string };
  componentId: string;
  private subscriptions: Subscription[];

  get saveDataActive(): FormControl {
    return this.parentForm.get('active') as FormControl;
  }

  get saveDataOptions(): FormGroup {
    return this.parentForm.get('options') as FormGroup;
  }

  get saveDataKey(): DataKeyFormControl {
    return this.saveDataOptions.get('key') as DataKeyFormControl;
  }

  get saveDataNextNode(): NodeKeyFormControl {
    return this.saveDataOptions.get('targetNode') as NodeKeyFormControl;
  }

  get saveDataCheckFormat(): FormControl {
    return this.saveDataOptions.get('formatCheck') as FormControl;
  }

  get saveDataCustomFormat(): FormControl {
    return this.saveDataOptions.get('customFormat') as FormControl;
  }

  get saveDataDateDisplay(): FormControl {
    return this.saveDataOptions.get('dateDisplay') as FormControl;
  }

  get saveDataDateInput(): FormControl {
    return this.saveDataOptions.get('dateInput') as FormControl;
  }

  get saveDataDateOutput(): FormControl {
    return this.saveDataOptions.get('dateOutput') as FormControl;
  }

  get saveDataErrorNextNode(): NodeKeyFormControl {
    return this.saveDataOptions.get('targetNodeIfCheckFails') as NodeKeyFormControl;
  }

  constructor(private protocolDroidService: ProtocolDroidService) {
    super();
    this.componentId = uniqueId();
    this.subscriptions = [];
  }

  ngOnInit() {
    this.subscriptions.push(
      this.protocolDroidService.onTranslationChangeEvent.subscribe(() => {
        this.formatTypes = {
          none: this.protocolDroidService.translate('shared.node-mapping.data-input.format-type-none', 'Aucun'),
          email: this.protocolDroidService.translate('shared.node-mapping.data-input.format-type-email', 'Email'),
          phone: this.protocolDroidService.translate(
            'shared.node-mapping.data-input.format-type-phone',
            'Num. de Tel.'
          ),
          date: this.protocolDroidService.translate('shared.node-mapping.data-input.format-type-date', 'Date'),
          number: this.protocolDroidService.translate('shared.node-mapping.data-input.format-type-number', 'Numérique'),
          country: this.protocolDroidService.translate('shared.node-mapping.data-input.format-type-country', 'Pays'),
          nationality: this.protocolDroidService.translate(
            'shared.node-mapping.data-input.format-type-nationality',
            'Nationalité'
          ),
          custom: this.protocolDroidService.translate(
            'shared.node-mapping.data-input.format-type-custom',
            'Personnalisé'
          )
        };

        this.dateDisplays = {
          asInput: this.protocolDroidService.translate(
            'shared.node-mapping.data-input.date-format-asInput',
            'As input'
          ),
          'dd/mm/yyyy': this.protocolDroidService.translate(
            'shared.node-mapping.data-input.date-format-dd/mm/yyyy',
            'DD/MM/YYYY'
          ),
          'mm/dd/yyyy': this.protocolDroidService.translate(
            'shared.node-mapping.data-input.date-format-mm/dd/yyyy',
            'MM/DD/YYYY'
          ),
          'yyyy/mm/dd': this.protocolDroidService.translate(
            'shared.node-mapping.data-input.date-format-yyyy/mm/dd',
            'YYYY/MM/DD'
          )
        };

        this.dateInputs = {
          'dd-mm-yyyy': this.protocolDroidService.translate(
            'shared.node-mapping.data-input.date-format-dd-mm-yyyy',
            'DD-MM-YYYY'
          ),
          'mm-dd-yyyy': this.protocolDroidService.translate(
            'shared.node-mapping.data-input.date-format-mm-dd-yyyy',
            'MM-DD-YYYY'
          )
        };

        this.dateOutputs = {
          ...this.dateDisplays,
          ...this.dateInputs,
          timestamp: this.protocolDroidService.translate(
            'shared.node-mapping.data-input.date-format-timestamp',
            'Timestamp'
          ),
          iso8601: this.protocolDroidService.translate(
            'shared.node-mapping.data-input.date-format-iso8601',
            'Iso 8601 (only date)'
          ),
          fullIso8601: this.protocolDroidService.translate(
            'shared.node-mapping.data-input.date-format-fullIso8601',
            'Iso 8601 (date and time)'
          ),
          yyyymmdd: this.protocolDroidService.translate(
            'shared.node-mapping.data-input.date-format-yyyymmdd',
            'YYYYMMDD'
          )
        };
      })
    );
  }

  activate() {
    this.parentForm.updateActive(this.saveDataActive.value);
  }
}
