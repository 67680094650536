import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BotTextPipe } from './bot-text.pipe';
import { FullBotTextPipe } from './full-bot-text.pipe';
import { LinkifyPipe } from './linkify.pipe';
import { MapsPipe } from './maps.pipe';
import { TrustRessourcePipe } from './trust-ressource.pipe';
import { TrustUrlPipe } from './trust-url.pipe';
import { SimpleTextPipe } from './simple-text.pipe';
import { HtmlStringifyPipe } from './html-stringify.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [
    BotTextPipe,
    FullBotTextPipe,
    LinkifyPipe,
    MapsPipe,
    TrustRessourcePipe,
    TrustUrlPipe,
    SimpleTextPipe,
    HtmlStringifyPipe
  ],
  exports: [
    BotTextPipe,
    FullBotTextPipe,
    LinkifyPipe,
    MapsPipe,
    TrustRessourcePipe,
    TrustUrlPipe,
    SimpleTextPipe,
    HtmlStringifyPipe
  ]
})
export class PipesModule {}
