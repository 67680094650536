import { Directive, ElementRef, Input, AfterViewInit, OnChanges, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { ProtocolDroidService } from '../services/protocol-droid.service';

@Directive({
  selector: '[appProtocolDroid]'
})
export class ProtocolDroidDirective implements AfterViewInit, OnChanges, OnDestroy {
  @Input() appProtocolDroid: string;
  @Input() appProtocolDroidParams?: any;
  @Input() appProtocolDroidActive: boolean; // activate translation process on condition
  @Input() appProtocolDroidAttribute?: 'title' | 'placeholder'; // Could be any other HTML attribute
  private elem: HTMLElement;
  private subscription: Subscription;

  constructor(private el: ElementRef, private protocolDroidService: ProtocolDroidService) {
    this.elem = this.el.nativeElement;
    this.appProtocolDroidActive = true;
  }

  ngAfterViewInit() {
    this.subscription = this.protocolDroidService.onTranslationChangeEvent.subscribe(() => {
      this.translate();
    });
  }

  ngOnChanges() {
    this.translate();
  }

  ngOnDestroy() {
    if (this.subscription) this.subscription.unsubscribe();
  }

  private translate(): void {
    if (this.appProtocolDroidActive) {
      const value = this.elem.innerHTML;
      const address = this.appProtocolDroid;
      const params = this.appProtocolDroidParams;
      if (!this.appProtocolDroid) return;
      if (this.appProtocolDroidAttribute === 'title' || this.appProtocolDroidAttribute === 'placeholder') {
        this.elem.setAttribute(
          this.appProtocolDroidAttribute,
          this.protocolDroidService.translate(address, value, params)
        );
      } else {
        this.elem.innerHTML = this.protocolDroidService.translate(address, value, params);
      }
    }
  }
}
