import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { Property } from 'ideta-library/lib/common/channels/facebook';

@Injectable({
  providedIn: 'root'
})
export class MessengerProfilesService {
  private properties: any;

  constructor() {
    this.properties = this.getProperties();
  }

  public getProperty(key: string): Observable<Property> {
    return of(this.properties.find((property: any) => property.key === key));
  }

  public getPropertyList(): Observable<Property[]> {
    return of(this.properties);
  }

  private getProperties(): Property[] {
    return [
      {
        key: 'account_linking_url',
        name: 'Account linking URL',
        template: {
          account_linking_url: 'https://www.example.com/'
        }
      },
      {
        key: 'persistent_menu',
        name: 'Persistent Menu',
        template: {
          persistent_menu: [
            {
              locale: 'default',
              composer_input_disabled: true,
              call_to_actions: [
                {
                  title: 'My Account',
                  type: 'nested',
                  call_to_actions: [
                    {
                      title: 'Pay Bill',
                      type: 'postback',
                      payload: 'PAYBILL_PAYLOAD'
                    },
                    {
                      title: 'History',
                      type: 'postback',
                      payload: 'HISTORY_PAYLOAD'
                    },
                    {
                      title: 'Contact Info',
                      type: 'postback',
                      payload: 'CONTACT_INFO_PAYLOAD'
                    }
                  ]
                },
                {
                  type: 'url',
                  title: 'Latest News',
                  url: 'http://petershats.parseapp.com/hat-news',
                  webview_height_ratio: 'full'
                }
              ]
            },
            {
              locale: 'zh_CN',
              composer_input_disabled: false,
              call_to_actions: [
                {
                  title: 'Pay Bill',
                  type: 'postback',
                  payload: 'PAYBILL_PAYLOAD'
                }
              ]
            }
          ]
        }
      },
      {
        key: 'greeting',
        name: 'Greeting',
        template: {
          greeting: [
            {
              locale: 'default',
              text: 'Hello!'
            },
            {
              locale: 'en_US',
              text: 'Timeless apparel for the masses.'
            }
          ]
        }
      },
      {
        key: 'get_started',
        name: 'Get Started',
        template: {
          get_started: {
            payload: '<GET_STARTED_PAYLOAD>'
          }
        }
      },
      {
        key: 'whitelisted_domains',
        name: 'Whitelisted Domains',
        template: {
          whitelisted_domains: ['<WHITELISTED_DOMAIN>', '<WHITELISTED_DOMAIN>']
        }
      },
      {
        key: 'payment_settings',
        name: 'Payment Settings',
        template: {
          payment_settings: {
            privacy_url: '<PRIVACY_POLICY_URL>',
            public_key: '<YOUR_PUBLIC_KEY>',
            testers: []
          }
        }
      },
      {
        key: 'target_audience',
        name: 'Target Audience',
        template: {
          target_audience: {
            audience_type: 'custom',
            countries: {
              whitelist: ['US', 'CA']
            }
          }
        }
      },
      {
        key: 'home_url',
        name: 'Home URL',
        template: {
          home_url: {
            url: '<URL_FOR_CHAT_EXTENSION>',
            webview_height_ratio: 'tall',
            webview_share_button: 'show',
            in_test: true
          }
        }
      }
    ];
  }
}
