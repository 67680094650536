import { Component, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Subscription, BehaviorSubject } from 'rxjs';

import { Actors, ConversationMetadata } from 'ideta-library/lib/common/conversation';

import { DisplayOptions } from '../../../models/display-options.model';

@Component({
  selector: 'app-base-display',
  template: ''
})
export class BaseDisplayComponent implements OnDestroy {
  @Input() displayOptions: DisplayOptions;
  @Input() metadata$: BehaviorSubject<ConversationMetadata>;
  @Input() actors: Actors;
  @Output() send: EventEmitter<any>;
  @Output() isTyping: EventEmitter<boolean>;
  subscriptions: Subscription[];

  constructor() {
    this.subscriptions = [];
    this.metadata$ = new BehaviorSubject(undefined);
    // ----------------------------------------------
    this.send = new EventEmitter();
    this.isTyping = new EventEmitter();
    this.actors = {
      emitter: 'user',
      receiver: 'bot'
    };
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }
}
