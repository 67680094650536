import { Pipe, PipeTransform } from '@angular/core';
import * as linkifyHtml from 'linkifyjs/html';

@Pipe({ name: 'linkify' })
export class LinkifyPipe implements PipeTransform {
  transform(input_text: string): string {
    return linkifyHtml(input_text, {
      defaultProtocol: 'https'
    });
  }
}
