import { FormGroup, FormControl, Validators } from '@angular/forms';
import { get } from 'lodash';

import { MappingGoToNode } from 'ideta-library/lib/common/node';

import { DataOperationForm } from '../../models/data-operation-form.model';
import { NodeKeyFormControl } from '../../models/node-key-form-control.model';

const defaultMapping: MappingGoToNode = {
  active: false
};

export class MappingGoToNodeForm extends FormGroup {
  constructor(mapping: MappingGoToNode = defaultMapping) {
    const formGroup = {
      active: new FormControl(mapping.active, { updateOn: 'change' }),
      options: new FormGroup({
        targetNode: new NodeKeyFormControl(get(mapping, 'options.targetNode'), [Validators.required]),
        operations: DataOperationForm.getDataOpertionFormArray(get(mapping, 'options.operations'))
      })
    };

    super(formGroup);
    this.switchControlsDisabling();
  }

  enable(options?: { onlySelf?: boolean; emitEvent?: boolean }): void {
    super.enable(options);
    this.switchControlsDisabling();
  }

  updateActive(active: boolean) {
    this.get('active').patchValue(active, { emitViewToModelChange: false, emitEvent: false });
    this.switchControlsDisabling();
  }

  switchControlsDisabling() {
    if (this.get('active').value) {
      this.get('options').enable({ emitEvent: false });
    } else {
      this.get('options').disable({ emitEvent: false });
    }
    this.updateValueAndValidity();
  }
}
