import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

import { CachedFormControl } from '../../../models/cached-form-control.model';
import { keyNameValidator, propertyNameValidator } from '../../../models/custom-validators';

@Component({
  selector: 'app-data-keys-safe-input',
  templateUrl: './data-keys-safe-input.component.html',
  styleUrls: ['./data-keys-safe-input.component.scss']
})
export class DataKeysSafeInputComponent implements OnInit {
  // @Input() isUrl: boolean;
  @ViewChild('textInput', { static: true }) textInput: ElementRef;
  @Input() options: {
    autoSelect?: boolean;
    checkFormat?: 'key' | 'property' | 'url';
    hideShadow?: boolean;
    isRichInput?: boolean;
    maxLength?: number;
  };
  @Input() input: FormControl | CachedFormControl;
  @Output() update: EventEmitter<FormControl>;

  constructor() {
    this.options = {};
    this.input = new CachedFormControl();
    this.update = new EventEmitter();
  }

  ngOnInit() {
    if (this.options.autoSelect && this.textInput) this.textInput.nativeElement.select();
    switch (this.options.checkFormat) {
      case 'key':
        this.setKeyValidators();
        break;
      case 'property':
        this.setPropertyValidators();
        break;
      case 'url':
        break;
    }
  }

  private setKeyValidators(): void {
    this.input.setValidators([Validators.required, keyNameValidator]);
  }

  private setPropertyValidators(): void {
    this.input.setValidators([Validators.required, propertyNameValidator]);
  }
}
