import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormArray, FormControl } from '@angular/forms';
import { get } from 'lodash';

import { DataOperationForm } from '../../../models/data-operation-form.model';
import { KeyCreationEvent } from '../../../models/data-selector.component';

@Component({
  selector: 'app-data-operation-list',
  templateUrl: './data-operation-list.component.html',
  styleUrls: ['./data-operation-list.component.scss']
})
export class DataOperationListComponent implements OnInit {
  @Input() operations: FormArray;
  @Input() selectedParentKey: FormControl;
  @Output() createDataKey: EventEmitter<KeyCreationEvent>;

  constructor() {
    this.createDataKey = new EventEmitter();
  }

  ngOnInit() {}

  addOperation() {
    const newOperation = new DataOperationForm(undefined, this.operations.length);
    this.operations.push(newOperation);
    this.refrechOperationsIndices();
    const conditional = this.operations.controls.find(control => control.get('type').value === 'conditional');
    if (conditional) {
      const ifTrueValue = get(conditional.get('options.ifTrue'), 'value');
      const ifFalseValue = get(conditional.get('options.ifFalse'), 'value');
      if (+ifTrueValue - 1 === newOperation.currentIndex) {
        (conditional.get('options') as DataOperationForm).setControl('ifTrue', newOperation.indexForm);
      }
      if (+ifFalseValue - 1 === newOperation.currentIndex) {
        (conditional.get('options') as DataOperationForm).setControl('ifFalse', newOperation.indexForm);
      }
    }
  }

  removeOperation(operation: DataOperationForm) {
    const operationIndex = operation.currentIndex;
    operation.updateCurrentIndex(null, true);
    this.operations.removeAt(operationIndex);
    this.refrechOperationsIndices();
  }

  handleOrderChange(event: 'up' | 'down', operation: DataOperationForm) {
    const array = [...this.operations.controls];
    if (
      (event === 'up' && operation.currentIndex > 0) ||
      (event === 'down' && operation.currentIndex < this.operations.controls.length - 1)
    ) {
      const newIndex = event === 'up' ? operation.currentIndex - 1 : operation.currentIndex + 1;
      array[operation.currentIndex] = array[newIndex];
      array[newIndex] = operation;

      this.operations.controls = array;
      this.refrechOperationsIndices();
      this.operations.updateValueAndValidity();
    }
  }

  private refrechOperationsIndices() {
    this.operations.controls.forEach((control: DataOperationForm, index: number) => control.updateCurrentIndex(index));
  }
}
