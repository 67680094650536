import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';

import { MappingIconType } from '../node-mapping-form.component';
import { ProtocolDroidService } from '../../protocol-droid/services/protocol-droid.service';

@Component({
  selector: 'app-node-mapping-icon',
  templateUrl: './node-mapping-icon.component.html',
  styleUrls: ['./node-mapping-icon.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NodeMappingIconComponent implements OnInit {
  @Input() type: MappingIconType;
  @Input() active: boolean;
  @Input() invalid: boolean;
  @Input() selected: boolean;

  constructor(public protocolDroidService: ProtocolDroidService) {}

  ngOnInit() {}
}
