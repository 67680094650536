import { Component, OnInit, OnDestroy, OnChanges } from '@angular/core';
import { Subscription } from 'rxjs';

import { MessageTemplateOpenGraph } from 'ideta-library/lib/common/message';

import { BasePreviewComponent } from '../shared/base-preview.component';
import { OpengraphioService } from './opengraphio.service';
import { ColorService } from '../../services/color/color.service';
import { OpenGraphMetadata } from './opengraphio.types';

@Component({
  selector: 'app-template-open-graph-preview',
  templateUrl: './template-open-graph-preview.component.html',
  styleUrls: ['./template-open-graph-preview.component.scss']
})
export class TemplateOpenGraphPreviewComponent extends BasePreviewComponent implements OnInit, OnDestroy, OnChanges {
  metadata: any;
  private subscription: Subscription;

  get template() {
    return this.message.template as MessageTemplateOpenGraph;
  }

  constructor(private opengraphioService: OpengraphioService, private colorService: ColorService) {
    super();
  }

  ngOnInit() {
    this.initMetadata();
  }

  ngOnChanges() {
    this.initMetadata();
  }

  ngOnDestroy() {
    if (this.subscription && !this.subscription.closed) {
      this.subscription.unsubscribe();
    }
  }

  get subTitleColor(): string {
    return this.colorService.hexToHSL(this.displayOptions.botColors.text, -30);
  }

  private initMetadata(): void {
    const template = this.message.template as MessageTemplateOpenGraph;
    const element = template.elements && template.elements[0];
    if (element && element.url) {
      this.subscription = this.opengraphioService
        .getOpenGraphMetaData(element.url)
        .subscribe((metadata: OpenGraphMetadata) => {
          this.metadata = metadata;
        });
    }
  }
}
