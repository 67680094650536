import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

import {
  ButtonElement,
  ButtonElementLogin,
  ButtonElementPostback,
  ButtonElementUrl
} from 'ideta-library/lib/common/node';

import { ConversationSessionService } from '../../../services/session/conversation-session.service';
import { DisplayOptions } from '../../../models/display-options.model';
import { ColorService } from '../../../services/color/color.service';

@Component({
  selector: 'app-button-element-preview',
  templateUrl: './button-element-preview.component.html',
  styleUrls: ['./button-element-preview.component.scss']
})
export class ButtonElementPreviewComponent implements OnInit {
  @Input() buttonList: ButtonElement[] = [];
  @Input() displayOptions: DisplayOptions;
  @Input() customStyle: any = {};
  @Output() send: EventEmitter<any>;

  constructor(private colorService: ColorService, private _conversation: ConversationSessionService) {
    this.buttonList = [];
    this.displayOptions = new DisplayOptions();
    this.customStyle = {};
    this.send = new EventEmitter();
  }

  ngOnInit() {}

  get buttonStyle(): any {
    return {
      ...this.customStyle,
      color: this.displayOptions.botColors.text,
      'background-color': this.colorService.hexToRGB(this.displayOptions.botColors.text, 0.3)
    };
  }

  postback(button: ButtonElementPostback) {
    this.send.emit({
      targetNode: button.targetNode,
      operations: button.operations,
      type: 'postback'
    });
  }

  login(button: ButtonElementLogin) {
    this.send.emit(button);
  }

  getUrlTarget(button: ButtonElementUrl): string {
    return this._conversation.isEmbedded || button.openNewTab === undefined || button.openNewTab === true
      ? '_blank'
      : '';
  }
}
