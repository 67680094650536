import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { ElementOptions, ElementsOptions, StripeCardComponent } from 'ngx-stripe';

import { UserSessionService } from './../../../../services/session/user-session.service';
import { FeedbackService } from '../../../../services/feedback/feedback.service';
import { ErrorsService } from './../../../../services/errors/errors.service';
import { PaymentService } from '../../../../services/payment/payment.service';
import { ProtocolDroidService } from '../../../../protocol-droid/services/protocol-droid.service';

@Component({
  selector: 'app-user-payment-settings',
  templateUrl: './user-payment-settings.component.html',
  styleUrls: ['./user-payment-settings.component.scss']
})
export class UserPaymentSettingsComponent implements OnInit, OnDestroy {
  @ViewChild('cardComponent', { static: false }) cardComponent: StripeCardComponent;
  error: any;
  complete: boolean;
  elementsOptions: ElementsOptions;
  cardOptions: ElementOptions;
  firstNameForm: FormControl;
  nameForm: FormControl;
  isPaymentRegistered: boolean;
  private userSub: Subscription;

  constructor(
    private paymentService: PaymentService,
    private errorsService: ErrorsService,
    private feedbackService: FeedbackService,
    private protocolDroidService: ProtocolDroidService,
    private userSession: UserSessionService
  ) {
    this.cardOptions = {
      hidePostalCode: true,
      style: {
        base: {
          color: '#343a40',
          fontWeight: 300,
          fontFamily: 'Roboto, Helvetica, sans-serif',
          fontSize: '1rem',
          ':focus': {
            color: '#adb5bd'
          },
          '::placeholder': {
            color: '#adb5bd'
          }
        },
        invalid: {
          color: '#fff',
          ':focus': {
            color: '#e46664'
          },
          '::placeholder': {
            color: '#e46664'
          }
        }
      }
    };

    this.elementsOptions = {
      locale: 'en',
      fonts: [
        {
          cssSrc: 'https://fonts.googleapis.com/css?family=Quicksand'
        }
      ]
    };
    this.firstNameForm = new FormControl();
    this.nameForm = new FormControl();
  }

  ngOnInit() {
    this.userSub = this.userSession.subject$.pipe(filter(user => !!user)).subscribe(user => {
      this.elementsOptions.locale = user.lang;
      this.firstNameForm = new FormControl(user.firstname, Validators.required);
      this.nameForm = new FormControl(user.lastname, Validators.required);
      this.isPaymentRegistered = user.customer && !!user.customer.id;
    });
  }

  ngOnDestroy(): void {
    if (this.userSub) this.userSub.unsubscribe();
  }

  updateCard(result) {
    if (result.type === 'change') {
      this.complete = result.event.complete;
      this.error = result.event.error;
    }
  }

  registerCutomer() {
    if (this.firstNameForm.invalid || this.nameForm.invalid) return;
    const firstname = ((this.firstNameForm.value as string) || '').trim();
    const name = ((this.nameForm.value as string) || '').trim();
    this.feedbackService
      .showSpinner()
      .then(() =>
        this.paymentService.registerStripeCustomer(
          this.cardComponent.getCard(),
          this.userSession.id,
          firstname + name,
          this.userSession.email
        )
      )
      .then(() => this.feedbackService.hideSpinner())
      .then(() => {
        this.feedbackService.showMessage(this.protocolDroidService.translate('misc.billing.stripe-creation-success'), {
          type: 'message',
          decoration: 'info',
          title: this.protocolDroidService.translate('misc.billing.title')
        });
      })
      .catch(this.errorsService.handlePromise());
  }
}
