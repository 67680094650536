import { FormControl, FormArray, ValidatorFn, ValidationErrors } from '@angular/forms';
import { Subject } from 'rxjs';
import { every } from 'lodash';

import { DataRichSegment } from 'ideta-library/lib/common/data';

import { RichInputSegmentForm } from './rich-input-segment-form.model';
import { SelectedDataStoreElem } from './data-selector.component';
import { formats } from './regex-formats.model';

interface Options {
  validators?: ValidatorFn[];
  updateOn?: 'change' | 'blur' | 'submit';
  isJSON?: boolean;
  isString?: boolean;
  displayNamne?: string;
  /* ###DK */
  oldFormat?: boolean;
}

export class RichInputFormControl extends FormControl {
  segments: FormArray;
  focus$: Subject<void>;
  isJSON: boolean;
  renderedValue: string;
  displayNamne?: string;
  /* ###DK */
  oldFormat?: boolean;
  private isString: boolean;

  /**
   * [constructor description]
   * @param input [description]
   */
  constructor(input: string | number | DataRichSegment[] = '', options: Options = {}) {
    const { validators = [], updateOn = 'change', isJSON = false, isString = true, displayNamne, oldFormat } = options;
    super(input, {
      validators: [...validators, RichInputFormControl.invalidSegment, RichInputFormControl.invalidJSON],
      updateOn: updateOn
    });

    this.segments = new FormArray([]);
    this.isJSON = isJSON;
    this.isString = isString;
    this.focus$ = new Subject();
    this.displayNamne = displayNamne;
    this.oldFormat = oldFormat;

    this.updateSegmentsFromInput();
  }

  static invalidSegment = (control: RichInputFormControl): ValidationErrors | null => {
    return control.segments && control.segments.invalid ? { invalidSegment: true } : null;
  };

  static invalidJSON = (control: RichInputFormControl): ValidationErrors | null => {
    if (control.isJSON) {
      try {
        // replace key names by a value to pass JSON.parse() test
        const parsedValue = control.value.replace(formats.regex('chainTaggedData'), 42);
        JSON.parse(parsedValue);
        return null;
      } catch {
        return { invalidJSON: true };
      }
    } else return null;
  };

  /**
   * [parseInput description]
   * @param  input [description]
   * @return       [description]
   */
  updateSegmentsFromInput() {
    this.segments.clear();

    if (!this.value) {
      this.segments.push(new RichInputSegmentForm());
      return;
    } else if (Array.isArray(this.value)) {
      this.value.forEach(segment => {
        this.segments.push(new RichInputSegmentForm(segment));
      });
      this.renderStringValue();
      return;
    }

    this.value
      .split(formats.regex('chainTaggedData'))
      .filter((segment: string) => segment !== undefined)
      .reduce(
        (arr: string[], segmentString: string) => [...arr, ...segmentString.split(formats.regex('chainElementData'))],
        []
      )
      .forEach((segment: string) => this.segments.push(new RichInputSegmentForm(segment)));
  }

  updateInputFromSegments() {
    this.renderStringValue();
    this.patchValue(this.isString ? this.renderedValue : this.renderArrayValue());
  }

  insertDataSegment(index: number = 0, position: number = 0, dataKey: SelectedDataStoreElem) {
    const { isElement, id, type: dataType, ...additionalInfos } = dataKey;
    const { type, value } = this.segments.at(index).value;
    let newSegments: RichInputSegmentForm[];
    if (index < this.segments.length && type === 'literal') {
      newSegments = [
        new RichInputSegmentForm(value.slice(0, position)),
        new RichInputSegmentForm({ type: isElement ? 'element' : 'key', value: id }, { dataType, ...additionalInfos }),
        new RichInputSegmentForm(value.slice(position))
      ];
    } else {
      newSegments = [
        new RichInputSegmentForm({ type: isElement ? 'element' : 'key', value: id }, { dataType, ...additionalInfos }),
        new RichInputSegmentForm('')
      ];
    }
    this.segments.removeAt(index);
    newSegments.forEach((segment: RichInputSegmentForm, i: number) => this.segments.insert(index + i, segment));
    this.updateInputFromSegments();
  }

  updateSegment(index: number, value: string | SelectedDataStoreElem) {
    const segment = this.segments.at(index) as RichInputSegmentForm;
    if (!segment.isKey && typeof value === 'string') segment.patchValue({ value });
    else if (typeof value === 'object') {
      const { isElement, id, type: dataType, ...additionalInfos } = value;
      segment.patchValue({
        type: isElement ? 'element' : 'key',
        value: id,
        dataType,
        ...additionalInfos
      });
    }
    this.updateInputFromSegments();
  }

  removeSegment(index: number) {
    const p: RichInputSegmentForm = this.segments.at(index - 1) as RichInputSegmentForm;
    const n: RichInputSegmentForm = this.segments.at(index + 1) as RichInputSegmentForm;

    if ((!p && !n) || (!p && n.isKey) || (p.isKey && !n) || (p.isKey && n.isKey)) {
      this.segments.at(index).patchValue({ type: 'literal', value: '' });
    } else if (!p.isKey && !n.isKey) {
      this.segments.removeAt(index + 1);
      this.segments.removeAt(index);
      p.patchValue({ type: 'literal', value: p.get('value').value + n.get('value').value });
    }
    this.updateInputFromSegments();
  }

  // ###U
  isAlphaNumericalValue(): boolean {
    return every(
      this.segments.controls,
      (segment: RichInputSegmentForm) =>
        !segment.isKey || segment.dataType === 'string' || segment.dataType === 'number'
    );
  }

  setIsJSON(value: boolean) {
    this.isJSON = value;
    this.updateValueAndValidity();
  }

  private renderStringValue() {
    this.renderedValue = this.segments.controls.map((segment: RichInputSegmentForm) => segment.toString()).join('');
  }

  private renderArrayValue(): DataRichSegment[] {
    const eTag = formats.elementKeyTag;
    return this.segments.value.map((segment: DataRichSegment) => ({
      type: segment.type,
      value:
        segment.type === 'element' && !formats.test('elementData', segment.value)
          ? eTag + segment.value + eTag
          : segment.value
    }));
  }
}
