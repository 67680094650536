import { FormGroup, FormArray, Validators, FormControl, ValidationErrors } from '@angular/forms';
import { get } from 'lodash';

import { EmailAsyncAction } from 'ideta-library/lib/common/node';

import { RichInputFormControl } from '../../../../models/rich-input-form-control.model';
import { formats } from '../../../../models/regex-formats.model';

export const defaultEmailAction: EmailAsyncAction = {
  from: '',
  addresses: [''],
  title: '',
  content: ''
};

export class EmailActionForm extends FormGroup {
  get addresses(): FormArray {
    return this.get('addresses') as FormArray;
  }

  constructor(emailAction: EmailAsyncAction = defaultEmailAction) {
    const formGroup = {
      from: new RichInputFormControl(emailAction.from, {
        validators: [Validators.required, EmailActionForm.emailValidator]
      }),
      addresses: new FormArray(
        get(emailAction, 'addresses', ['']).map(
          (address: string) =>
            new RichInputFormControl(address, {
              validators: [Validators.required, EmailActionForm.emailValidator]
            })
        ),
        [Validators.required]
      ),
      title: new RichInputFormControl(emailAction.title, { validators: [Validators.required] }),
      content: new RichInputFormControl(emailAction.content, { validators: [Validators.required] })
    };

    super(formGroup);
  }

  static emailValidator = (control: FormControl): ValidationErrors | null => {
    const email = control.value;

    return email && !formats.test('email', email, 'i') ? { invalidEmail: true } : null;
  };

  addAddress(): RichInputFormControl {
    this.addresses.push(new RichInputFormControl('', { validators: [Validators.required] }));
    return this.addresses.at(this.addresses.length - 1) as RichInputFormControl;
  }
}
