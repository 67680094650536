import { FormGroup, Validators } from '@angular/forms';
import { get } from 'lodash';

import { QuickRepliesAutoOptions } from 'ideta-library/lib/common/node';

import { DataKeyFormControl } from '../../../models/data-key-form-control.model';
import { DataOperationForm } from '../../../models/data-operation-form.model';
import { RichInputFormControl } from '../../../models/rich-input-form-control.model';
import { NodeKeyFormControl } from '../../../models/node-key-form-control.model';

export class QuickRepliesAutoForm extends FormGroup {
  constructor(quickReplyAuto: QuickRepliesAutoOptions = {}) {
    const formObject = {
      listKey: new DataKeyFormControl(quickReplyAuto.listKey, [Validators.required]),
      title: new RichInputFormControl(quickReplyAuto.title, { validators: [Validators.required] }),
      imageUrl: new RichInputFormControl(quickReplyAuto.imageUrl),
      targetNode: new NodeKeyFormControl(quickReplyAuto.targetNode, [Validators.required]),
      fallbackNode: new NodeKeyFormControl(quickReplyAuto.fallbackNode, [Validators.required]),
      operations: DataOperationForm.getDataOpertionFormArray(get(quickReplyAuto, 'operations'))
    };
    super(formObject);
  }
}
