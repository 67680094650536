import { Component, OnInit, OnDestroy, Input, SimpleChanges, OnChanges } from '@angular/core';
import { FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import { Observable, Subscription, from, of, BehaviorSubject } from 'rxjs';
import { switchMap, distinctUntilChanged, catchError } from 'rxjs/operators';
import { get, uniqueId } from 'lodash';
import { BsModalService } from 'ngx-bootstrap/modal';

import { Lexicon } from 'ideta-library/lib/common/lexicon';

import { NlpForm } from './nlp-form.model';
import { SaveNlpForm } from '../save-nlp-form/save-nlp-form.model';
import { BaseMappingFormComponent } from '../../shared/base-mapping-form.component';
import { LexiconApiService } from '../../../services/lexicon-api/lexicon-api.service';
import { ErrorsService } from '../../../services/errors/errors.service';
import { ProtocolDroidService } from '../../../protocol-droid/services/protocol-droid.service';
import { ModalLexiconComponent } from '../../../components/modals/modal-lexicon/modal-lexicon.component';
import { BotSessionService } from '../../../services/session/bot-session.service';
import { NodeKeyFormControl } from '../../../models/node-key-form-control.model';
import { LexiconService } from '../../../services/lexicon/lexicon.service';

import { environment } from '../../../../../environments/environment';

export interface LexiconDictionnary {
  intents: { [name: string]: boolean };
  entities: { [name: string]: any };
  loaded?: boolean;
}

type LexiconStatus = 'pending' | 'not-connected' | 'success';

@Component({
  selector: 'app-nlp-form',
  templateUrl: './nlp-form.component.html',
  styleUrls: ['./nlp-form.component.scss']
})
export class NlpFormComponent extends BaseMappingFormComponent implements OnInit, OnChanges, OnDestroy {
  @Input() parentForm: NlpForm;
  @Input() dicoLexicon: LexiconDictionnary;
  nlpServices: any;
  lexiconStatus: LexiconStatus;
  componentId: string;
  private currentService$: BehaviorSubject<string>;
  private botSub: Subscription;
  private formSub: Subscription;

  get nlpActive(): FormControl {
    return this.parentForm.get('active') as FormControl;
  }

  get nlpOptions(): FormGroup {
    return this.parentForm.get('options') as FormGroup;
  }

  get nlpOptionsService(): FormControl {
    return this.nlpOptions.get('service') as FormControl;
  }

  get nlpOptionsIntents(): FormArray {
    return this.nlpOptions.get('intents') as FormArray;
  }

  get nlpSaveOptions(): SaveNlpForm {
    return this.nlpOptions.get('storage') as SaveNlpForm;
  }

  constructor(
    private lexiconApiService: LexiconApiService,
    private lexiconService: LexiconService,
    private modalService: BsModalService,
    private protocolDroidService: ProtocolDroidService,
    private errorsService: ErrorsService,
    private _bot: BotSessionService
  ) {
    super();
    this.componentId = uniqueId();
    this.nlpServices = environment.nlpServices
      .filter((service: any) => service.hasApi && service.available)
      .reduce((services: any, service: any) => (services[service.code] = service.name) && services, {});
    this.currentService$ = new BehaviorSubject(undefined);
    this.dicoLexicon = { intents: {}, entities: {} };
  }

  ngOnInit() {
    this.botSub = this._bot.distinctSubject$
      .pipe(
        switchMap(() => this.currentService$.pipe(distinctUntilChanged())),
        switchMap(currentService => this.lexiconService.isNlpServiceAvailable(currentService)),
        switchMap(servicesAvailability =>
          this.getServiceLexicon(servicesAvailability).pipe(catchError(this.errorsService.handleObservable))
        )
      )
      .subscribe((lexicon: Lexicon | LexiconStatus) => {
        if (typeof lexicon === 'string') this.lexiconStatus = lexicon;
        else {
          /*
           * The dicoLexicon is used to check if an intent/entity listed in the mapping still
           * exists in the list of intents/entities we fetch from the service
           */
          this.buildLexiconDictionnary(lexicon);
          this.lexiconStatus = 'success';
        }
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    // Using onChange because parentForm reference may change
    // during component's life, causing previous valueChange observable to be closed
    if (changes.parentForm) {
      this.currentService$.next(get(this.parentForm.value, 'options.service', 'dialogflow'));
      if (this.formSub) {
        this.formSub.unsubscribe();
      }
      this.formSub = this.parentForm.valueChanges.subscribe(newValue => {
        this.currentService$.next(get(newValue, 'options.service', 'dialogflow'));
      });
    }
  }

  ngOnDestroy() {
    this.botSub.unsubscribe();
    if (this.formSub) this.formSub.unsubscribe();
  }

  activate() {
    this.parentForm.updateActive(this.nlpActive.value);
  }

  getNlpIntent(index: number): FormControl {
    return this.nlpOptionsIntents.at(index).get('intent') as FormControl;
  }

  getNlpEntity(index: number): FormControl {
    return this.nlpOptionsIntents.at(index).get('entity') as FormControl;
  }

  getNlpNextNode(index: number): NodeKeyFormControl {
    return this.nlpOptionsIntents.at(index).get('targetNode') as NodeKeyFormControl;
  }

  openLexiconServiceModal(code: string) {
    const modalOptions = {
      class: `modal-dialog-centered modal-lg`,
      initialState: {
        serviceCode: code
      }
    };

    this.modalService.show(ModalLexiconComponent, modalOptions);
  }

  addIntent() {
    this.nlpOptionsIntents.push(
      new FormGroup({
        intent: new FormControl('', [Validators.required]),
        entity: new FormControl('[WHATEVER]', [Validators.required]),
        targetNode: new NodeKeyFormControl('', [Validators.required], false)
      })
    );
  }

  removeIntent(index: number) {
    this.nlpOptionsIntents.removeAt(index);
  }

  private getServiceLexicon(isNlpServiceAvailable: boolean): Observable<Lexicon | LexiconStatus> {
    if (isNlpServiceAvailable) {
      this.lexiconStatus = 'pending';
      return from(this.lexiconApiService.getLexicon(this.currentService$.value));
    } else return of('not-connected');
  }

  /**
   * Format may seem weird, but it matches simple-dropdown dicoValues format
   *
   * @param  lexicon [description]
   * @return         [description]
   */
  private buildLexiconDictionnary(lexicon: any = {}): void {
    this.dicoLexicon.intents = this.dicoLexicon.entities = {};
    const types = ['intents', 'entities'];
    types.forEach((type: 'intents' | 'entities') => {
      this.dicoLexicon[type] =
        get(lexicon, type, []).reduce((dico: any, item: any) => (dico[item.name] = item.name) && dico, {}) || {};
    });
    this.dicoLexicon.entities['[WHATEVER]'] = this.protocolDroidService.translate(
      'shared.node-mapping.data-input.whatever',
      'Peu importe'
    );
    if (get(lexicon, 'entities', []).length > 1) {
      this.dicoLexicon.entities['[MULTIPLE]'] = this.protocolDroidService.translate(
        'shared.node-mapping.data-input.multiple',
        'Multiple'
      );
    }
    this.dicoLexicon.loaded = true;
  }
}
