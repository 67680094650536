import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { AuthService } from '../shared/services/auth/auth.service';
import { UserSessionService } from '../shared/services/session/user-session.service';
import { UserService } from '../shared/services/user/user.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit, OnDestroy {
  state: 'default' | 'success';
  changePasswordError: string;

  passwords: FormGroup = new FormGroup({
    oldPassword: new FormControl(null, Validators.required),
    newPassword: new FormControl(null, Validators.required),
    confirmNewPassword: new FormControl(null, Validators.required)
  });

  private subscriptions: Subscription[];

  get oldPassword() {
    return this.passwords.get('oldPassword');
  }

  get newPassword() {
    return this.passwords.get('newPassword');
  }

  get confirmNewPassword() {
    return this.passwords.get('confirmNewPassword');
  }

  constructor(private _user: UserSessionService, private user: UserService, private authService: AuthService) {
    this.subscriptions = [];
  }

  ngOnInit() {
    this.state = 'default';
    this.subscriptions.push(this.passwords.valueChanges.subscribe(() => this.checkPasswords()));
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  changePassword(): void {
    const oldPassword = this.oldPassword.value;
    let credential = null;
    try {
      credential = this.authService.getCredential(this._user.email, oldPassword);
    } catch {
      this.changePasswordError = 'The password is invalid or the user does not have a password.';
    }
    const newPassword = this.newPassword.value;
    this.authService
      .reauthenticateWithCredential(credential)
      .then(() => {
        if (!this.passwords.invalid && this.checkPasswords()) {
          this.user
            .updatePassword(newPassword)
            .then(() => {
              this.state = 'success';
            })
            .catch(error => {
              this.changePasswordError = error.message;
            });
        }
      })
      .catch(error => {
        this.changePasswordError = error.message;
      });
  }

  checkPasswords(): boolean {
    if (this.newPassword.value !== this.confirmNewPassword.value) {
      this.changePasswordError = 'Passwords not matching';
      return false;
    } else {
      this.changePasswordError = null;
      return true;
    }
  }
}
