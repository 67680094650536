import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-ephemeral-message',
  templateUrl: './ephemeral-message.component.html',
  styleUrls: ['./ephemeral-message.component.scss'],
  animations: [
    trigger('disappearing', [
      transition(':leave', [animate('500ms ease-out', style({ opacity: 0 }))])
    ])
  ]
})
export class EphemeralMessageComponent implements OnInit, OnDestroy {
  @Input() message$: Observable<string>;
  @Input() delay?: number;
  @Input() decoration?: 'default' | 'error';
  message: string;
  private timeoutId: number;
  private subscription: Subscription;

  constructor() {
    this.delay = 1000;
    this.decoration = 'error';
  }

  ngOnInit() {
    this.subscription = this.message$.subscribe((mess: string) => {
      clearTimeout(this.timeoutId);
      this.message = mess;
      setTimeout(() => (this.message = null), this.delay);
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
