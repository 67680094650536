import { Validators, FormArray } from '@angular/forms';

import { GenericElement } from 'ideta-library/lib/common/node';

import { GenericElementForm } from './generic-element-form.model';

export class GenericManualForm extends FormArray {
  constructor(elements: GenericElement[] = []) {
    const formArray = (elements && elements.map((element: GenericElement) => new GenericElementForm(element))) || [];

    super(formArray, [Validators.required]);
  }

  addElement() {
    this.push(new GenericElementForm());
  }

  moveElement(index: number, shift: -1 | 1) {
    const element = this.at(index);
    this.removeAt(index);
    this.insert(index + shift, element);
  }
}
