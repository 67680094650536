import { FormControl, FormGroup } from '@angular/forms';

import { MappingOptions } from 'ideta-library/lib/common/node';

import { OptionBehaviorForm } from './option-behavior-form/option-behavior-form.model';

const defaultMapping: MappingOptions = {
  behaviorMedia: { active: false },
  behaviorGeoloc: { active: false }
};

export class MappingOptionsForm extends FormGroup {
  constructor(mapping: MappingOptions = defaultMapping) {
    const formGroup = {
      behaviorMedia: new OptionBehaviorForm(mapping.behaviorMedia),
      behaviorGeoloc: new OptionBehaviorForm(mapping.behaviorGeoloc),
      isSystemNode: new FormControl(mapping.isSystemNode)
    };

    super(formGroup);
  }
}
