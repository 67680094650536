import { FormGroup, FormControl } from '@angular/forms';

import { GetStartedForm, GetStarted } from './get-started-form/get-started-form.model';
import { AccountUrlForm, AccountUrl } from './account-url-form/account-url-form.model';
// import { PersistentMenuForm /* PersistentMenu */ } from './persistent-menu-form/persistent-menu-form.model';
import { GreetingForm, Greeting } from './greeting-form/greeting-form.model';
// import { HomeUrlForm /* HomeUrl */ } from './home-url-form/home-url-form.model';
import { WhitelistedDomainsForm, WhitelistedDomains } from './whitelisted-domains-form/whitelisted-domains-form.model';
import { TargetAudienceForm, TargetAudience } from './target-audience-form/target-audience-form.model';

export type ProfileKey =
  | 'account_linking_url'
  | 'get_started'
  | 'greeting'
  // | 'home_url'
  // | 'persistent_menu'
  | 'target_audience'
  | 'whitelisted_domains';

export type ProfileValue =
  | AccountUrl
  | GetStarted
  | Greeting
  // | PersistentMenu
  // | HomeUrl
  | WhitelistedDomains
  | TargetAudience;

export class MessengerProfileForm extends FormGroup {
  get accountLinkingUrl(): AccountUrlForm {
    return this.get('account_linking_url') as AccountUrlForm;
  }

  get getStarted(): GetStartedForm {
    return this.get('get_started') as GetStartedForm;
  }

  get greeting(): GreetingForm {
    return this.get('greeting') as GreetingForm;
  }

  // get homeUrl(): HomeUrlForm {
  //   return this.get('home_url') as HomeUrlForm;
  // }

  // get persistentMenu(): PersistentMenuForm {
  //   return this.get('persistent_menu') as PersistentMenuForm;
  // }

  get targetAudience(): TargetAudienceForm {
    return this.get('target_audience') as TargetAudienceForm;
  }

  get whitelistedDomains(): WhitelistedDomainsForm {
    return this.get('whitelisted_domains') as WhitelistedDomainsForm;
  }

  constructor(profile: any = {}) {
    const formGroup = {
      profileKey: new FormControl(profile.profileKey || 'account_linking_url'),
      account_linking_url: new AccountUrlForm(profile.account_linking_url),
      get_started: new GetStartedForm(profile.get_started),
      greeting: new GreetingForm(profile.greeting),
      // home_url: new HomeUrlForm(profile.home_url),
      // persistent_menu: new PersistentMenuForm(profile.persistent_menu),
      target_audience: new TargetAudienceForm(profile.target_audience),
      whitelisted_domains: new WhitelistedDomainsForm(profile.whitelisted_domains)
    };

    super(formGroup);
  }

  getProfileValue() {
    const profile: any = {
      account_linking_url: this.accountLinkingUrl.getProfileValue(),
      get_started: this.getStarted.getProfileValue(),
      // persistent_menu: this.persistentMenu.getProfileValue(),
      target_audience: this.targetAudience.getProfileValue()
    };

    const greetings = this.greeting.getProfileValue();
    // const homeUrl = this.homeUrl.getProfileValue();

    if (greetings.length > 0) profile.greeting = greetings;
    // if (homeUrl) {
    //   profile.home_url = homeUrl;
    //   this.whitelistedDomains.whitelistUrl(homeUrl.url);
    // }

    const whitelistedDomains = this.whitelistedDomains.getProfileValue();
    if (whitelistedDomains.length > 0) profile.whitelisted_domains = whitelistedDomains;

    return profile;
  }

  setProfileValue(value: any = {}) {
    this.accountLinkingUrl.setProfileValue(value.account_linking_url);
    this.getStarted.setProfileValue(value.get_started);
    this.greeting.setProfileValue(value.greeting);
    // this.homeUrl.setProfileValue(value.home_url);
    // this.persistentMenu.setProfileValue(value.persistent_menu);
    this.targetAudience.setProfileValue(value.target_audience);
    this.whitelistedDomains.setProfileValue(value.whitelisted_domains);
  }
}
