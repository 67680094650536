import { FormArray, ValidatorFn } from '@angular/forms';

import { ButtonElement, TemplateButtonType } from 'ideta-library/lib/common/node';

import { ButtonElementFormFactory, ButtonFactoryOptions } from '../button-element-form.factory';

export class ButtonsListForm extends FormArray {
  private initOptions: ButtonFactoryOptions;

  constructor(buttons: ButtonElement[] = [], validators?: ValidatorFn | ValidatorFn[], options?: ButtonFactoryOptions) {
    const formArray = buttons.map((button: ButtonElement) => ButtonElementFormFactory.factory(button, options));

    super(formArray, validators);
    this.initOptions = options;
  }

  updateType(index: number, type: TemplateButtonType) {
    if (this.at(index).get('type').value !== type) {
      this.setControl(index, ButtonElementFormFactory.factory({ ...this.at(index).value, type }, this.initOptions));
    }
  }

  moveButton(index: number, shift: -1 | 1) {
    const button = this.at(index);
    this.removeAt(index);
    this.insert(index + shift, button);
  }
}
