import { Component, OnInit, Input } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { FormGroup, FormArray, FormControl } from '@angular/forms';

import { TemplateOpenGraphForm } from './template-open-graph-form.model';
import { BaseTemplateFormComponent } from '../shared/base-template-form.component';
import { ButtonsListForm } from '../shared/buttons-list-form/buttons-list-form.model';

@Component({
  selector: 'app-template-open-graph-form',
  templateUrl: './template-open-graph-form.component.html',
  styleUrls: ['./template-open-graph-form.component.scss'],
  animations: [
    trigger('expandAnimation', [
      state(
        'collapsed',
        style({
          height: '0',
          overflow: 'hidden'
        })
      ),
      state(
        'expanded',
        style({
          height: '*'
        })
      ),
      transition('collapsed <=> expanded', [animate('0.4s cubic-bezier(.87,-.41,.19,1.44)')])
    ])
  ]
})
export class TemplateOpenGraphFormComponent extends BaseTemplateFormComponent implements OnInit {
  @Input() parentForm: TemplateOpenGraphForm;
  maxElements: number;
  maxButtons: number;

  get elements(): FormArray {
    return this.parentForm.get('elements') as FormArray;
  }

  constructor() {
    super();
    this.maxElements = 1;
    this.maxButtons = 1;
  }

  ngOnInit() {}

  getElement(index: number): FormGroup {
    return this.elements.at(index) as FormGroup;
  }

  getElementUrl(index: number): FormControl {
    return this.getElement(index).get('url') as FormControl;
  }

  getElementButtons(index: number): ButtonsListForm {
    return this.getElement(index).get('buttons') as ButtonsListForm;
  }

  addElement() {
    this.parentForm.addElement();
  }

  removeElement(index: number) {
    this.elements.removeAt(index);
  }

  trackByFn(index: number): number {
    return index;
  }
}
