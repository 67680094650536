import { Component, OnInit, Input } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { FormGroup, FormControl, FormArray } from '@angular/forms';

import { QuickReplyContentType } from 'ideta-library/lib/common/node';

import { QuickRepliesManualForm } from './quick-replies-manual-form.model';
import { BaseTemplateFormComponent } from '../../shared/base-template-form.component';
import { ProtocolDroidService } from '../../../protocol-droid/services/protocol-droid.service';

import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-quick-replies-manual-form',
  templateUrl: './quick-replies-manual-form.component.html',
  styleUrls: ['./quick-replies-manual-form.component.scss'],
  animations: [
    trigger('expandAnimation', [
      state(
        'collapsed',
        style({
          height: '0',
          overflow: 'hidden'
        })
      ),
      state(
        'expanded',
        style({
          height: '*'
        })
      ),
      transition('collapsed <=> expanded', [animate('0.4s cubic-bezier(.87,-.41,.19,1.44)')])
    ])
  ]
})
export class QuickRepliesManualFormComponent extends BaseTemplateFormComponent implements OnInit {
  @Input() parentForm: QuickRepliesManualForm;
  quickReplyTypes: { [key in QuickReplyContentType]: string };
  maxQuickReplies: number;
  expandedQuickReplies: { [index: number]: boolean };
  isHoverDataRow: { [index: string]: boolean };

  constructor(private protocolDroidService: ProtocolDroidService) {
    super();
    this.maxQuickReplies = environment.appSettings.maxQuickReplyElements;
    this.expandedQuickReplies = {};
    this.isHoverDataRow = {};
  }

  ngOnInit() {
    this.subscriptions.push(
      this.protocolDroidService.onTranslationChangeEvent.subscribe(
        () =>
          (this.quickReplyTypes = {
            text: this.protocolDroidService.translate(
              'shared.node-template-form.quick-replies.manual.content-type-text',
              'Texte'
            ),
            location: this.protocolDroidService.translate(
              'shared.node-template-form.quick-replies.manual.content-type-location',
              'Localisation'
            )
          })
      )
    );
  }

  getQuickReply(index: number): FormGroup {
    return this.parentForm.at(index) as FormGroup;
  }

  addQuickReply() {
    this.parentForm.addQuickReply();
    this.expandedQuickReplies[this.parentForm.length - 1] = true;
  }

  removeQuickReply(index: number) {
    this.expandedQuickReplies[index] = false;
    this.parentForm.removeQuickReply(index);
  }

  moveQuickReply(index: number, shift: -1 | 1) {
    const quickReply = this.parentForm.at(index);
    this.parentForm.removeAt(index);
    this.parentForm.insert(index + shift, quickReply);
  }

  getQuickReplyControl(index: number, controlName: string): FormControl {
    return this.getQuickReply(index).get(controlName) as FormControl;
  }

  getQuickReplyOperations(index: number): FormArray {
    return this.getQuickReply(index).get('operations') as FormArray;
  }

  createNodeDropDownValue(control: FormControl, name: string) {
    this.createNode.emit({
      name,
      callback: (key: string) => this.updateControlValue(control, key)
    });
  }

  trackByFn(index: number): number {
    return index;
  }

  private updateControlValue(control: FormControl, value: any) {
    control.patchValue(value);
  }
}
