import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import { uniqueId } from 'lodash';

import { TriggersForm } from './triggers-form.model';
import { BaseMappingFormComponent } from '../../shared/base-mapping-form.component';
import { NodeKeyFormControl } from '../../../models/node-key-form-control.model';

@Component({
  selector: 'app-triggers-form',
  templateUrl: './triggers-form.component.html',
  styleUrls: ['./triggers-form.component.scss']
})
export class TriggersFormComponent extends BaseMappingFormComponent implements OnInit {
  @Input() parentForm: TriggersForm;
  componentId: string;
  get triggersActive(): FormControl {
    return this.parentForm.get('active') as FormControl;
  }

  get triggersOptions(): FormArray {
    return this.parentForm.get('options') as FormArray;
  }

  constructor() {
    super();
    this.componentId = uniqueId();
  }

  ngOnInit() {}

  activate() {
    this.parentForm.updateActive(this.triggersActive.value);
  }

  getTriggersValue(index: number): FormControl {
    return this.triggersOptions.at(index).get('value') as FormControl;
  }

  getTriggersNextNode(index: number): NodeKeyFormControl {
    return this.triggersOptions.at(index).get('targetNode') as NodeKeyFormControl;
  }

  addTrigger() {
    this.triggersOptions.push(
      new FormGroup({
        value: new FormControl('', [Validators.required]),
        targetNode: new NodeKeyFormControl('', [Validators.required])
      })
    );
  }

  removeTrigger(index: number) {
    this.triggersOptions.removeAt(index);
  }
}
