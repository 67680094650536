import { OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';

import { AllowedScope, AllowedType, DataStoreElem } from 'ideta-library/lib/common/data';

export interface KeyCreationEvent {
  dataKey: Partial<DataStoreElem>;
  callback?: (dataKey: SelectedDataStoreElem) => void;
}

export type DataStoreElemPreview = Pick<DataStoreElem, 'id' | 'key' | 'type' | 'scope' | 'elements' | 'keys'>;

export type SelectedDataStoreElem = DataStoreElemPreview & {
  isElement?: boolean;
};

export interface SelectableItems {
  items?: MenuItem[];
  elementItems?: MenuItem[];
  index?: any;
}

export type MenuItem = DataStoreElemPreview & {
  selectableItems?: SelectableItems;
  isAllowed: boolean;
};

export class DataSelectorComponent implements OnDestroy {
  @Input() allowedTypes: AllowedType[];
  @Input() allowedScopes: AllowedScope[];
  @Input() selectedParentKey?: FormControl;
  @Output() createDataKey: EventEmitter<KeyCreationEvent>;
  @Output() selectDataKey: EventEmitter<SelectedDataStoreElem>;
  protected subscriptions: Subscription[];

  constructor() {
    this.subscriptions = [];
    this.allowedTypes = ['string', 'number', 'boolean', 'object', 'array', 'element'];
    this.allowedScopes = ['conversation', 'bot', 'system'];
    this.createDataKey = new EventEmitter();
    this.selectDataKey = new EventEmitter();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }
}
