import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BotSessionService } from '../session/bot-session.service';
import * as io from 'socket.io-client';

import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TextSpeechService {
  audioContext: AudioContext;

  constructor(private http: HttpClient, private _bot: BotSessionService) {
    const Context =
      (window as any).AudioContext || // Default
      (window as any).webkitAudioContext || // Safari and old versions of Chrome
      false;
    if (Context) this.audioContext = new Context();
  }

  // MISC ------------------------------------------------------------------------
  resume(): Promise<void> {
    if (!this.audioContext || !this.audioContext.resume) return;
    return this.audioContext.resume();
  }

  suspend(): Promise<void> {
    if (!this.audioContext || !this.audioContext.suspend) return;
    return this.audioContext.suspend();
  }

  get state() {
    if (!this.audioContext) return;
    return this.audioContext.state;
  }

  // SPEECH TO TEXT --------------------------------------------------------------
  turnToText(buffer: ArrayBuffer, options?: { lang: string }): Promise<string> {
    const speech = this.arrayBufferToBase64(buffer);
    const lang = (options && options.lang) || environment.defaults.misc.textSpeechLang;
    return new Promise((resolve, reject) => {
      this.http
        .post(`${this._bot.endPointBack}/voice/speech-to-text`, { speech, lang })
        .subscribe((data: { transcript: string }) => {
          resolve(data.transcript);
        }, reject);
    });
  }

  createSocket(options?: { lang: string }) {
    const lang = (options && options.lang) || environment.defaults.misc.textSpeechLang;
    const params = ['lang', lang].join('=');
    return io([this._bot.endPointBack, params].join('?'), { transports: ['websocket'] });
  }

  // TEXT TO SPEECH --------------------------------------------------------------
  readText(text: string, options?: { lang: string }): Promise<AudioBufferSourceNode> {
    if (!text || !this.audioContext) return;
    const lang = (options && options.lang) || environment.defaults.misc.textSpeechLang;

    // This is a simple trick to force text-to-speech to read large number characters one by one
    text = !isNaN(+text) && +text > environment.defaults.misc.voiceNumberThreshold ? text.split('').join(' ') : text;

    return this.http
      .post(`${this._bot.endPointBack}/voice/text-to-speech`, { text, lang }, { responseType: 'arraybuffer' })
      .toPromise()
      .then(arrayBuffer => this.decodeAndRead(arrayBuffer));
  }

  private decodeAndRead(arrayBuffer: ArrayBuffer): Promise<AudioBufferSourceNode> {
    return new Promise(resolve =>
      this.audioContext.decodeAudioData(arrayBuffer, audioBuffer => {
        const source = this.audioContext.createBufferSource();
        source.buffer = audioBuffer;
        source.connect(this.audioContext.destination);
        source.start();
        resolve(source);
      })
    );
  }

  private arrayBufferToBase64(buffer: ArrayBuffer) {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }
}
