import { Router } from '@angular/router';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { combineLatest, Subscription } from 'rxjs';
import { filter, take } from 'rxjs/operators';

import { BotTemplateSettings } from 'ideta-library/lib/common/bot';

import { ErrorsService } from '../../../services/errors/errors.service';
import { FeedbackService } from '../../../services/feedback/feedback.service';
import { BotService } from '../../../services/bot/bot.service';
import { BotApiService } from '../../../services/bot-api/bot-api.service';
import { ProtocolDroidService } from '../../../protocol-droid/services/protocol-droid.service';

import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-modal-bot-creation',
  templateUrl: './modal-bot-creation.component.html',
  styleUrls: ['./modal-bot-creation.component.scss']
})
export class ModalBotCreationComponent implements OnInit, OnDestroy {
  botNameForm: FormControl;
  botTemplateList: BotTemplateSettings[];
  private shouldCreateTemplate: BotTemplateSettings;
  private templatesSub: Subscription;

  constructor(
    private router: Router,
    private modalRef: BsModalRef,
    private modalService: BsModalService,
    private botApiService: BotApiService,
    private feedbackService: FeedbackService,
    private errorsService: ErrorsService,
    private botService: BotService,
    private protocolDroidService: ProtocolDroidService
  ) {
    this.botNameForm = new FormControl('', Validators.required);
    this.buildBotTemplateList([]);
  }

  ngOnInit() {
    this.botNameForm.patchValue(environment.defaults.bot.name);
    this.templatesSub = combineLatest([
      this.botService.getBotTemplates(),
      this.protocolDroidService.onTranslationChangeEvent
    ])
      .pipe(take(1))
      .subscribe(([bots]) => {
        this.buildBotTemplateList(bots || []);
      });
    this.modalService.onHidden
      .pipe(
        filter(() => this.modalService.getModalsCount() === 0),
        take(1)
      )
      .subscribe(() => {
        this.onHidden();
      });
  }

  ngOnDestroy() {
    if (this.templatesSub) this.templatesSub.unsubscribe();
  }

  create(template: BotTemplateSettings): void {
    if (this.botNameForm.valid) {
      this.shouldCreateTemplate = template;
      this.modalRef.hide();
    }
  }

  cancel(): void {
    this.modalRef.hide();
  }

  private onHidden(): void {
    if (this.shouldCreateTemplate) {
      this.feedbackService
        .showSpinner()
        .then(() =>
          this.botApiService.createBot({
            bot: {
              ...environment.defaults.bot,
              name: this.botNameForm.value
            },
            templateId: this.shouldCreateTemplate.id
          })
        )
        .then(res => this.feedbackService.hideSpinner().then(() => res))
        .then(res => {
          const redirection = this.shouldCreateTemplate.redirection || 'edition';
          this.shouldCreateTemplate.useCreationBot
            ? (document.location.href = `${environment.domain}/web-display/${this.shouldCreateTemplate.creationBotId ||
                environment.creationBotId}?bot=${res.botId}&redirect=${redirection}&token=${res.token}`)
            : this.router.navigate([redirection, res.botId]);
        })
        .catch(this.errorsService.handlePromise());
    }
  }

  private buildBotTemplateList(templateList: BotTemplateSettings[]) {
    this.botTemplateList = [
      {
        id: null,
        title: this.protocolDroidService.translate(
          'shared.components.modals.bot-creation.empty-template-title',
          'Empty'
        ),
        redirection: 'edition',
        image: environment.appSettings.emptyTemplateImg
      },
      ...templateList
    ];
  }
}
