import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { AssertEqualForm } from './assert-equal-form/assert-equal-form.model';
import { PerformOperationsForm } from './perform-operations-form/perform-operations-form.model';
import { SendToExternalApiForm } from './send-to-external-api-form/send-to-external-api-form.model';
import { BaseMappingFormComponent } from '../shared/base-mapping-form.component';

export interface ActivateSectionEvent {
  section: 'assertEqual' | 'performOperations' | 'sendToExternalApi';
  value: boolean;
}

type SwitchForm = AssertEqualForm | PerformOperationsForm | SendToExternalApiForm;

@Component({
  selector: 'app-mapping-switch-form',
  templateUrl: './mapping-switch-form.component.html',
  styleUrls: ['./mapping-switch-form.component.scss']
})
export class MappingSwitchFormComponent extends BaseMappingFormComponent implements OnInit {
  get assertEqual(): FormGroup {
    return this.parentForm.get('assertEqual') as FormGroup;
  }

  get performOperations(): FormGroup {
    return this.parentForm.get('performOperations') as FormGroup;
  }

  get sendToExternalApi(): FormGroup {
    return this.parentForm.get('sendToExternalApi') as FormGroup;
  }

  constructor() {
    super();
  }

  ngOnInit() {}

  activateSection(event: ActivateSectionEvent) {
    if (event.value) {
      /* Deactivating other sections */
      ['assertEqual', 'performOperations', 'sendToExternalApi']
        .filter((name: string) => name !== event.section)
        .forEach((name: string) => {
          const form = this.parentForm.get(name) as SwitchForm;
          form.updateActive(false);
        });
      this.parentForm.updateValueAndValidity();
    }
  }
}
