import { FormGroup, FormControl, Validators } from '@angular/forms';
import { get } from 'lodash';

import { QuickReplyContentType, QuickReplyElement } from 'ideta-library/lib/common/node';

import { DataOperationForm } from '../../../models/data-operation-form.model';
import { RichInputFormControl } from '../../../models/rich-input-form-control.model';
import { NodeKeyFormControl } from '../../../models/node-key-form-control.model';
import { urlOrKeyValidator } from '../../../models/custom-validators';

export type ContentType = 'text' | 'location';

const defaultQuickReply: QuickReplyElement = {
  contentType: 'text',
  title: 'Reply',
  targetNode: 'noeud_0'
};

export class QuickReplyItemForm extends FormGroup {
  constructor(quickReply: QuickReplyElement = defaultQuickReply) {
    const formObject = {
      contentType: new FormControl(quickReply.contentType || 'text', [Validators.required]),
      title: new RichInputFormControl(quickReply.title, { validators: [Validators.required] }),
      targetNode: new NodeKeyFormControl(quickReply.targetNode, [Validators.required]),
      imageUrl: new RichInputFormControl(quickReply.imageUrl, {
        validators: [urlOrKeyValidator]
      }),
      operations: DataOperationForm.getDataOpertionFormArray(get(quickReply, 'operations'))
    };
    super(formObject);
    this.switchControlsDisabling();
  }

  updateContentType(contentType: QuickReplyContentType) {
    this.get('contentType').patchValue(contentType, {
      emitViewToModelChange: false,
      emitEvent: false
    });
    this.switchControlsDisabling();
  }

  private switchControlsDisabling(): void {
    const contentType = this.get('contentType').value;
    if (contentType === 'location') {
      this.get('title').disable({ emitEvent: false });
      this.get('targetNode').disable({ emitEvent: false });
      this.get('imageUrl').disable({ emitEvent: false });
      this.get('operations').disable({ emitEvent: false });
    } else if (contentType === 'text') {
      this.get('title').enable({ emitEvent: false });
      this.get('targetNode').enable({ emitEvent: false });
      this.get('imageUrl').enable({ emitEvent: false });
      this.get('operations').enable({ emitEvent: false });
    }
    this.updateValueAndValidity();
  }
}
