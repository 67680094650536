import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'app-youtube-display-support',
  templateUrl: './youtube-display-support.component.html',
  styleUrls: ['./youtube-display-support.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class YoutubeDisplaySupportComponent implements OnInit {
  @Input() source: any;
  url_youtube_clean: any;

  constructor() {}

  ngOnInit() {
    this.url_youtube_clean = this.source;
  }
}
