import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';

export interface AddEvent {
  value: string;
  callbackSuccess?: Function;
  callbackError?: Function;
}

@Component({
  selector: 'app-add-item-bar',
  templateUrl: './add-item-bar.component.html',
  styleUrls: ['./add-item-bar.component.scss']
})
export class AddItemBarComponent implements OnInit {
  @Input() placeholder?: string;
  @Output() create: EventEmitter<AddEvent>;
  errorMessage$: Subject<string>;
  isVisibleSpinner: boolean;

  constructor() {
    this.create = new EventEmitter();
    this.errorMessage$ = new Subject();
  }

  ngOnInit() {}

  addItem(input: HTMLInputElement) {
    if (input.value) {
      const addEvent = {
        value: input.value,
        callbackSuccess: () => {
          input.value = '';
          this.isVisibleSpinner = false;
          setTimeout(() => input.focus(), 0);
        },
        callbackError: (error: Error) => {
          this.errorMessage$.next(error.message);
          this.isVisibleSpinner = false;
        }
      };
      this.isVisibleSpinner = true;
      this.create.emit(addEvent);
    }
  }
}
