import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { AbstractControl, FormControl, FormArray } from '@angular/forms';
import { Subscription } from 'rxjs';
import { get } from 'lodash';

@Component({
  selector: 'app-badge-errors',
  templateUrl: './badge-errors.component.html',
  styleUrls: ['./badge-errors.component.scss']
})
export class BadgeErrorsComponent implements OnInit, OnDestroy {
  @Input() input: AbstractControl;
  status: { [errorName: string]: boolean };
  isInvalid: boolean;
  private subscription: Subscription;

  constructor() {
    this.input = new FormControl();
    this.status = {};
  }

  ngOnInit() {
    this.updateStatus();
    this.subscription =
      this.input && this.input.statusChanges.subscribe(this.updateStatus.bind(this));
  }

  ngOnDestroy() {
    if (this.subscription) this.subscription.unsubscribe();
  }

  updateStatus() {
    this.status = {};
    if (get(this.input, 'errors.required')) {
      if (this.input instanceof FormControl) this.status.isRequired = true;
      else if (this.input instanceof FormArray) this.status.isEmptyArray = true;
    }
    if (get(this.input, 'errors.noElement')) this.status.isEmptyArray = true;
    if (get(this.input, 'errors.invalidUrl')) this.status.isInvalidUrl = true;
    if (get(this.input, 'errors.invalidEmail')) this.status.isInvalidEmail = true;
    if (get(this.input, 'errors.invalidJSON')) this.status.isInvalidJson = true;
    if (get(this.input, 'errors.invalidKey')) this.status.isInvalidKey = true;
    if (get(this.input, 'errors.invalidProperty')) this.status.isInvalidProperty = true;
    if (get(this.input, 'errors.invalidDataType')) this.status.isInvalidDataType = true;
    if (get(this.input, 'errors.missingDataKey')) this.status.isMissingDataKey = true;
    if (get(this.input, 'errors.invalidDataScope')) this.status.isInvalidDataScope = true;
    if (get(this.input, 'errors.invalidPath')) this.status.isInvalidPath = true;
    if (get(this.input, 'errors.invalidHeaders')) this.status.isInvalidHeaders = true;
    if (get(this.input, 'errors.invalidSegment')) this.status.isInvalidSegment = true;
    if (get(this.input, 'errors.duplicated')) this.status.isNotUnique = true;
    if (get(this.input, 'errors.pattern')) {
      console.error('Pattern form validation should be avoided, please use a more specific error');
    }
    this.isInvalid = get(this.input, 'errors') && !Object.keys(this.status).length;
  }
}
