import { Injectable } from '@angular/core';
import { AngularFireDatabase, AngularFireAction, DatabaseSnapshot } from '@angular/fire/database';
import { Observable, pipe } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { keyBy } from 'lodash';

import { DataTransferOrder, ShareInfos, WebIntegration, WebMenu, WebParam } from 'ideta-library/lib/common/bot';

import { DisplayOptions } from '../../../models/display-options.model';
import { BotSessionService } from '../../session/bot-session.service';

@Injectable({
  providedIn: 'root'
})
export class BotWebService {
  constructor(private db: AngularFireDatabase, private _bot: BotSessionService) {}

  public getDisplayOptions(): Observable<DisplayOptions> {
    return this.db.object<DisplayOptions>(`/bots/${this._bot.id}/channels/web/displayOptions`).valueChanges();
  }

  public updateDisplayOptions(options: DisplayOptions): Promise<void> {
    return this.db.object(`/bots/${this._bot.id}/channels/web/displayOptions`).update(options);
  }

  public getWebIntegration(): Observable<WebIntegration> {
    return this.db.object<WebIntegration>(`/bots/${this._bot.id}/channels/web/integration`).valueChanges();
  }

  public updateWebIntegration(integration: WebIntegration): Promise<void> {
    return this.db.object(`/bots/${this._bot.id}/channels/web/integration`).update(integration);
  }

  public getDataTransfers(): Observable<DataTransferOrder[]> {
    return this.db
      .list(`/bots/${this._bot.id}/channels/web/dataTransfers`)
      .snapshotChanges()
      .pipe(this.addObjectId());
  }

  public addDataTransfer(dataTransfer: Omit<DataTransferOrder, 'id'>): Promise<DataTransferOrder> {
    return this.db
      .list(`/bots/${this._bot.id}/channels/web/dataTransfers`)
      .push(dataTransfer)
      .then(ref =>
        this.getDataTransfer(ref.key)
          .pipe(take(1))
          .toPromise()
      );
  }

  public updateDataTransfers(dataTransfers: DataTransferOrder[]): any {
    const transfersObj = keyBy(dataTransfers, transfer => transfer.id);
    return this.db.object(`/bots/${this._bot.id}/channels/web/dataTransfers`).update(transfersObj);
  }

  public deleteDataTransfer(transferId: string): Promise<void> {
    if (transferId) {
      return this.db.object(`/bots/${this._bot.id}/channels/web/dataTransfers/${transferId}`).remove();
    } else {
      console.error('Missing id for data transfer deletion');
      return Promise.reject();
    }
  }

  public getWebParams(): Observable<WebParam[]> {
    return this.db
      .list(`/bots/${this._bot.id}/channels/web/params`)
      .snapshotChanges()
      .pipe(this.addObjectId());
  }

  public addWebParam(paramKey: string): Promise<WebParam> {
    return this.db
      .list(`/bots/${this._bot.id}/channels/web/params`)
      .push({ key: paramKey })
      .then(ref =>
        this.getWebParam(ref.key)
          .pipe(take(1))
          .toPromise()
      );
  }

  public updateWebParams(params: WebParam[]): any {
    const paramsObj = keyBy(params, param => param.id);
    return this.db.object(`/bots/${this._bot.id}/channels/web/params`).update(paramsObj);
  }

  public deleteWebParam(paramId: string): Promise<void> {
    if (paramId) {
      return this.db.object(`/bots/${this._bot.id}/channels/web/params/${paramId}`).remove();
    } else {
      console.error('Missing id for web parameter deletion');
      return Promise.reject();
    }
  }

  public getWebMenu(): Observable<WebMenu> {
    return this.db.object<WebMenu>(`/bots/${this._bot.id}/channels/web/menu`).valueChanges();
  }

  public updateWebMenu(menu: WebMenu): Promise<any> {
    return this.db.object(`/bots/${this._bot.id}/channels/web/menu`).update(menu);
  }

  public getShareInfos(): Observable<ShareInfos> {
    return this.db.object<ShareInfos>(`/bots/${this._bot.id}/channels/web/share`).valueChanges();
  }

  public updateShareInfos(shareInfos: ShareInfos): Promise<void> {
    return this.db.object(`/bots/${this._bot.id}/channels/web/share`).update(shareInfos);
  }

  private getDataTransfer(id: string): Observable<DataTransferOrder> {
    return this.db
      .object<DataTransferOrder>(`/bots/${this._bot.id}/channels/web/dataTransfers/${id}`)
      .valueChanges()
      .pipe(map((transfer: DataTransferOrder) => ({ id, ...transfer })));
  }

  private getWebParam(id: string): Observable<WebParam> {
    return this.db
      .object<WebParam>(`/bots/${this._bot.id}/channels/web/params/${id}`)
      .valueChanges()
      .pipe(map((param: WebParam) => ({ id, ...param })));
  }

  private addObjectId() {
    return pipe(
      map((changes: AngularFireAction<DatabaseSnapshot<any>>[]) => {
        return changes.map(c => ({ id: c.payload.key, ...c.payload.val() }));
      })
    );
  }
}
