import { FormGroup, FormArray, FormControl, Validators } from '@angular/forms';

import { Trigger, TriggersOptions } from 'ideta-library/lib/common/node';

import { NodeKeyFormControl } from '../../../models/node-key-form-control.model';

const defaultOptions = { active: false };

export class TriggersForm extends FormGroup {
  constructor(triggers: TriggersOptions = defaultOptions) {
    const formGroup = {
      active: new FormControl(triggers.active, { updateOn: 'change' }),
      options: new FormArray(
        (triggers.options &&
          triggers.options.map(
            (trigger: Trigger) =>
              new FormGroup({
                value: new FormControl(trigger.value, [Validators.required]),
                targetNode: new NodeKeyFormControl(trigger.targetNode, [Validators.required], true)
              })
          )) ||
          [],
        [Validators.required]
      )
    };

    super(formGroup);
    this.switchControlsDisabling();
  }

  enable(options?: { onlySelf?: boolean; emitEvent?: boolean }): void {
    super.enable(options);
    this.switchControlsDisabling();
  }

  updateActive(active: boolean) {
    this.get('active').patchValue(active, { emitViewToModelChange: false, emitEvent: false });
    this.switchControlsDisabling();
  }

  switchControlsDisabling() {
    if (this.get('active').value) {
      this.get('options').enable({ emitEvent: false });
    } else {
      this.get('options').disable({ emitEvent: false });
    }
    this.updateValueAndValidity();
  }
}
