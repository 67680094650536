import { DataStoreSessionService } from './../../../services/session/data-store-session.service';
import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { delay } from 'rxjs/operators';

import { RequestType } from 'ideta-library/lib/common/node';

import { SendToExternalApiForm } from './send-to-external-api-form.model';
import { ActivateSectionEvent } from '../mapping-switch-form.component';
import { BaseMappingFormComponent } from '../../shared/base-mapping-form.component';
import { DataKeyFormControl } from '../../../models/data-key-form-control.model';
import { RichInputFormControl } from '../../../models/rich-input-form-control.model';
import { NodeKeyFormControl } from '../../../models/node-key-form-control.model';
import { SelectedDataStoreElem } from '../../../models/data-selector.component';

import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-send-to-external-api-form',
  templateUrl: './send-to-external-api-form.component.html',
  styleUrls: ['./send-to-external-api-form.component.scss']
})
export class SendToExternalApiFormComponent extends BaseMappingFormComponent implements OnInit, OnDestroy {
  @Input() parentForm: SendToExternalApiForm;
  @Output() activateSection: EventEmitter<ActivateSectionEvent>;
  requestTypes: { [key in RequestType]: string };
  bodyTypes: { [key: string]: string };
  maxTimeout: number;
  private subscriptions: Subscription[];

  get sendToExternalApiActive(): FormControl {
    return this.parentForm.get('active') as FormControl;
  }

  get sendToExternalApiOptions(): FormGroup {
    return this.parentForm.get('options') as FormGroup;
  }

  get apiData(): FormGroup {
    return this.sendToExternalApiOptions.get('data') as FormGroup;
  }

  get apiDataActive(): FormControl {
    return this.apiData.get('active') as FormControl;
  }

  get dataKey(): DataKeyFormControl {
    return this.apiData.get('key') as DataKeyFormControl;
  }

  get arrayPath(): RichInputFormControl {
    return this.apiData.get('arrayPath') as RichInputFormControl;
  }

  get dataMapping(): FormGroup {
    return this.apiData.get('mapping') as FormGroup;
  }

  get mappingKeys(): string[] {
    return Object.keys(this.dataMapping.controls) || [];
  }

  get dataType(): FormControl {
    return this.apiData.get('type') as FormControl;
  }

  get headers(): FormControl {
    return this.sendToExternalApiOptions.get('headers') as FormControl;
  }

  get method(): FormControl {
    return this.sendToExternalApiOptions.get('method') as FormControl;
  }

  get url(): FormControl {
    return this.sendToExternalApiOptions.get('url') as FormControl;
  }

  get body(): RichInputFormControl {
    return this.sendToExternalApiOptions.get('body') as RichInputFormControl;
  }

  get bodyType(): FormControl {
    return this.sendToExternalApiOptions.get('bodyType') as FormControl;
  }

  get targetNode(): NodeKeyFormControl {
    return this.sendToExternalApiOptions.get('targetNode') as NodeKeyFormControl;
  }

  get fallbackNode(): NodeKeyFormControl {
    return this.sendToExternalApiOptions.get('fallbackNode') as NodeKeyFormControl;
  }

  get timeout(): FormControl {
    return this.sendToExternalApiOptions.get('timeout') as FormControl;
  }

  constructor(private _dataStore: DataStoreSessionService) {
    super();
    this.activateSection = new EventEmitter();
    /* Format seems weird but we need to match simple-dropdown keys format */
    this.requestTypes = {
      GET: 'GET',
      POST: 'POST',
      PUT: 'PUT',
      PATCH: 'PATCH',
      DELETE: 'DELETE'
    };
    this.bodyTypes = {
      TEXT: 'TEXT',
      JSON: 'JSON',
      XML: 'XML'
    };
    this.maxTimeout = environment.defaults.api.maxTimeout;
    this.subscriptions = [];
  }

  ngOnInit() {
    this.subscriptions.push(
      this._dataStore.subject$
        .pipe(
          delay(0) // Avoid ExpressionChangedAfterItHasBeenCheckedError
        )
        .subscribe(() => {
          const key = this.parentForm.get('options.data.key');
          const dataKey = this._dataStore.getDataStoreElem(key.value);
          this.parentForm.updateDataForm(dataKey);
        })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  activate() {
    this.parentForm.updateActive(this.sendToExternalApiActive.value);
    this.activateSection.emit({
      section: 'sendToExternalApi',
      value: this.sendToExternalApiActive.value
    });
  }

  updateBodyType() {
    this.body.setIsJSON(this.bodyType.value === 'JSON');
  }

  updateDataActive() {
    this.parentForm.updateDataActive(this.apiDataActive.value);
  }

  updateDataKeyValue(dataKey: SelectedDataStoreElem) {
    this.parentForm.updateDataKey(dataKey);
  }
}
