import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

export interface LuisProfile {
  appId?: string;
  authKey?: string;
  useAuthAsEndpointKey?: boolean;
  endpointKey?: string;
}

@Component({
  selector: 'app-luis-profile-connector',
  templateUrl: './luis-profile-connector.component.html',
  styleUrls: ['./luis-profile-connector.component.scss']
})
export class LuisProfileConnectorComponent implements OnInit {
  @Input() profile: LuisProfile;
  @Output() update: EventEmitter<LuisProfile>;
  @Output() dismiss: EventEmitter<void>;
  luisForm: FormGroup;
  showErrors: boolean;

  get appId(): FormControl {
    return this.luisForm.get('appId') as FormControl;
  }

  get authKey(): FormControl {
    return this.luisForm.get('authKey') as FormControl;
  }

  get useAuthAsEndpointKey(): FormControl {
    return this.luisForm.get('useAuthAsEndpointKey') as FormControl;
  }

  get endpointKey(): FormControl {
    return this.luisForm.get('endpointKey') as FormControl;
  }

  constructor() {
    this.luisForm = new FormGroup({}, { updateOn: 'blur' });
    this.update = new EventEmitter();
    this.dismiss = new EventEmitter();
    this.showErrors = false;
  }

  ngOnInit() {
    this.createForm();
  }

  save() {
    if (this.luisForm.valid) {
      this.update.emit(this.luisForm.value);
      this.showErrors = false;
    } else {
      this.showErrors = true;
    }
  }

  updateForm() {
    const config = this.profile || {};
    const value = this.luisForm.value;

    if (value.useAuthAsEndpointKey && this.luisForm.contains('endpointKey')) {
      this.luisForm.removeControl('endpointKey');
    } else if (!value.useAuthAsEndpointKey && !this.luisForm.contains('endpointKey')) {
      this.luisForm.setControl(
        'endpointKey',
        new FormControl(config && config.endpointKey, [Validators.required])
      );
    }
  }

  private createForm() {
    const config = this.profile || {};
    /* useAuthAsEndpointKey is true by default */
    const useAuthAsEndpointKey =
      config && config.useAuthAsEndpointKey !== undefined ? config.useAuthAsEndpointKey : true;

    this.luisForm.setControl(
      'appId',
      new FormControl(config && config.appId, [Validators.required])
    );

    this.luisForm.setControl(
      'authKey',
      new FormControl(config && config.authKey, [Validators.required])
    );

    this.luisForm.setControl(
      'useAuthAsEndpointKey',
      new FormControl(useAuthAsEndpointKey, { updateOn: 'change' })
    );

    this.updateForm();
  }
}
