import { Component, OnInit, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

import { ButtonUrlWebviewType } from 'ideta-library/lib/common/node';

import { ButtonUrlForm } from './button-url-form.model';
import { BaseTemplateFormComponent } from '../base-template-form.component';
import { ProtocolDroidService } from '../../../protocol-droid/services/protocol-droid.service';
import { CoreSessionService } from '../../../services/session/core-session.service';

@Component({
  selector: 'app-button-url-form',
  templateUrl: './button-url-form.component.html',
  styleUrls: ['./button-url-form.component.scss']
})
export class ButtonUrlFormComponent extends BaseTemplateFormComponent implements OnInit {
  @Input() parentForm: ButtonUrlForm;
  @Input() showTitle: boolean;
  @Input() selectedParentKey: FormControl;
  webviewTypes: { [key in ButtonUrlWebviewType]: string };
  uid: string; // A unique ID that avoids interferences between form elements

  get title(): FormControl {
    return this.parentForm.get('title') as FormControl;
  }

  get url(): FormControl {
    return this.parentForm.get('url') as FormControl;
  }

  get openNewTab(): FormControl {
    return this.parentForm.get('openNewTab') as FormControl;
  }

  get messengerExtensions(): FormControl {
    return this.parentForm.get('messengerExtensions') as FormControl;
  }

  get webviewHeightRatio(): FormControl {
    return this.parentForm.get('webviewHeightRatio') as FormControl;
  }

  constructor(private protocolDroidService: ProtocolDroidService, public _session: CoreSessionService) {
    super();
    this.showTitle = true;

    this.uid = Math.random()
      .toString(36)
      .substring(2, 8);
  }

  ngOnInit() {
    this.subscriptions.push(
      this.protocolDroidService.onTranslationChangeEvent.subscribe(
        () =>
          (this.webviewTypes = {
            compact: this.protocolDroidService.translate(
              'shared.node-template-form.button.url.webview-compact',
              'Compacte'
            ),
            tall: this.protocolDroidService.translate('shared.node-template-form.button.url.webview-tall', 'Grande'),
            full: this.protocolDroidService.translate('shared.node-template-form.button.url.webview-full', 'Pleine')
          })
      )
    );
  }
}
