import { FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import { get } from 'lodash';

import { NlpOptions, NlpTrigger } from 'ideta-library/lib/common/node';

import { SaveNlpForm } from '../save-nlp-form/save-nlp-form.model';
import { NodeKeyFormControl } from '../../../models/node-key-form-control.model';

const defaultOptions = { active: false };

export class NlpForm extends FormGroup {
  public alwaysVisible: boolean;

  constructor(nlp: NlpOptions = defaultOptions, handleStorage: boolean = true, alwaysVisible?: boolean) {
    const formGroup = {
      active: new FormControl(nlp.active, { updateOn: 'change' }),
      options: new FormGroup({
        service: new FormControl(get(nlp, 'options.service', 'dialogflow')),
        intents: new FormArray(
          get(nlp, 'options.intents', []).map(
            (trigger: NlpTrigger) =>
              new FormGroup({
                intent: new FormControl(trigger.intent, [Validators.required]),
                entity: new FormControl(trigger.entity || '[WHATEVER]', [Validators.required]),
                targetNode: new NodeKeyFormControl(trigger.targetNode, [Validators.required], false)
              })
          ) || [],
          [Validators.required]
        )
      })
    };

    if (handleStorage) {
      formGroup.options.registerControl('storage', new SaveNlpForm(get(nlp, 'options.storage')));
    }

    super(formGroup);
    this.alwaysVisible = alwaysVisible || false;
    this.switchControlsDisabling();
  }

  enable(options?: { onlySelf?: boolean; emitEvent?: boolean }): void {
    super.enable(options);
    this.switchControlsDisabling();
  }

  updateActive(active: boolean) {
    this.get('active').patchValue(active, { emitViewToModelChange: false, emitEvent: false });
    this.switchControlsDisabling();
  }

  switchControlsDisabling() {
    if (this.get('active').value || this.alwaysVisible) {
      this.get('options').enable({ emitEvent: false });
    } else {
      this.get('options').disable({ emitEvent: false });
    }
    this.updateValueAndValidity();
  }
}
