import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { map, take } from 'rxjs/operators';

type PossibleStatus = 'success' | 'failure';

@Component({
  selector: 'app-oauth-callback',
  templateUrl: './oauth-callback.component.html',
  styleUrls: ['./oauth-callback.component.scss']
})
export class OauthCallbackComponent implements OnInit {
  status: PossibleStatus;
  constructor(private route: ActivatedRoute) {}

  ngOnInit() {
    this.route.paramMap
      .pipe(
        map((params: ParamMap) => params.get('status')),
        take(1)
      )
      .subscribe((status: PossibleStatus) => (this.status = status));
  }
}
