import { FormGroup, FormControl } from '@angular/forms';

import { urlValidator } from '../../models/custom-validators';

export type AccountUrl = string;

export class AccountUrlForm extends FormGroup {
  constructor(url: string) {
    const formGroup = {
      url: new FormControl(url, [urlValidator])
    };

    super(formGroup);
  }

  getProfileValue(): AccountUrl {
    return this.get('url').value;
  }

  setProfileValue(value: string) {
    this.get('url').patchValue(value);
  }
}
