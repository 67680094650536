import { FormGroup, FormControl, Validators } from '@angular/forms';

import { ButtonElementLogin, ButtonLoginService } from 'ideta-library/lib/common/node';

import { ButtonFactoryOptions } from '../button-element-form.factory';
import { DataKeyFormControl } from '../../../models/data-key-form-control.model';
import { RichInputFormControl } from '../../../models/rich-input-form-control.model';
import { NodeKeyFormControl } from '../../../models/node-key-form-control.model';

const defaultElement: ButtonElementLogin = {
  title: '',
  type: 'login',
  service: 'google',
  targetNodeLoginSuccess: '',
  targetNodeLoginFailure: '',
  dataProfileKey: ''
};

export class ButtonLoginForm extends FormGroup {
  constructor(element: ButtonElementLogin = defaultElement, options?: ButtonFactoryOptions) {
    const formObject = {
      type: new FormControl(element.type, [Validators.required]),
      service: new FormControl(element.service, [Validators.required]),
      targetNodeLoginSuccess: new NodeKeyFormControl(element.targetNodeLoginSuccess, [Validators.required]),
      targetNodeLoginFailure: new NodeKeyFormControl(element.targetNodeLoginFailure, [Validators.required]),
      dataProfileKey: new DataKeyFormControl(element.dataProfileKey, [Validators.required])
    };

    if (!(options && options.removeTitle)) {
      Object.assign(formObject, {
        title: new RichInputFormControl(element.title, { validators: [Validators.required] })
      });
    }

    super(formObject);
  }

  getServiceProfileKeys(service: ButtonLoginService): string[] {
    switch (service) {
      case 'facebook':
        return ['id', 'first_name', 'last_name', 'email'];
      case 'google':
        return ['id', 'name', 'given_name', 'family_name', 'picture', 'locale', 'email', 'verified_email'];
      default:
        return [];
    }
  }
}
