import { FormGroup, FormArray, FormControl, Validators, ValidationErrors } from '@angular/forms';

import { ReceiptElement, TemplateReceipt } from 'ideta-library/lib/common/node';

import { ReceiptElementForm } from './receipt-element-form.model';
import { RichInputFormControl } from '../../models/rich-input-form-control.model';

const defaultTemplate: TemplateReceipt = {
  recipientName: 'Stephane Crozatier',
  orderNumber: '12345678902',
  currency: 'USD',
  paymentMethod: 'Visa 2345',
  orderUrl: 'http://petersapparel.parseapp.com/order?order_id=123456',
  elements: [
    {
      title: 'Classic White T-Shirt',
      subtitle: '100% Soft and Luxurious Cotton',
      quantity: 2,
      price: 50,
      currency: 'USD',
      imageUrl: 'https://cdn.laredoute.com/products/641by641' + '/d/1/3/d1332bba3c190b9a90ee9f6f3a4f9f49.jpg'
    },
    {
      title: 'Classic Gray T-Shirt',
      subtitle: '100% Soft and Luxurious Cotton',
      quantity: 1,
      price: 25,
      currency: 'USD',
      imageUrl: 'https://cdn.laredoute.com/products/641by641/' + '6/6/1/6613d3156dd1b87bfd6c583e2d05f1ba.jpg'
    }
  ],
  address: {
    street1: '1 Hacker Way',
    city: 'Menlo Park',
    postalCode: '94025',
    state: 'CA',
    country: 'US'
  },
  summary: {
    subtotal: 75.0,
    shippingCost: 4.95,
    totalTax: 6.19,
    totalCost: 56.14
  },
  adjustments: [
    {
      name: 'New Customer Discount',
      amount: 20
    },
    {
      name: '$10 Off Coupon',
      amount: 10
    }
  ]
};

export class TemplateReceiptForm extends FormGroup {
  get elements(): FormArray {
    return this.get('elements') as FormArray;
  }

  get adjustments(): FormArray {
    return this.get('adjustments') as FormArray;
  }

  constructor(template: TemplateReceipt = defaultTemplate) {
    const formGroup = {
      recipientName: new RichInputFormControl(template.recipientName, {
        validators: [Validators.required]
      }),
      orderNumber: new RichInputFormControl(template.orderNumber, {
        validators: [Validators.required]
      }),
      currency: new RichInputFormControl(template.currency, {
        validators: [Validators.required]
      }),
      paymentMethod: new RichInputFormControl(template.paymentMethod, {
        validators: [Validators.required]
      }),
      orderUrl: new RichInputFormControl(template.orderUrl, {
        validators: [Validators.required]
      }),
      timestamp: new RichInputFormControl(template.timestamp),
      elements: new FormArray(
        (template.elements && template.elements.map((element: ReceiptElement) => new ReceiptElementForm(element))) || []
      ),
      address: new FormGroup(
        {
          street1: new RichInputFormControl(template.address && template.address.street1),
          street2: new RichInputFormControl(template.address && template.address.street2),
          city: new RichInputFormControl(template.address && template.address.city),
          postalCode: new RichInputFormControl(template.address && template.address.postalCode),
          state: new RichInputFormControl(template.address && template.address.state),
          country: new RichInputFormControl(template.address && template.address.country)
        },
        { validators: [TemplateReceiptForm.addressValidator] }
      ),
      summary: new FormGroup({
        subtotal: new FormControl(template.summary && template.summary.subtotal),
        shippingCost: new FormControl(template.summary && template.summary.shippingCost),
        totalTax: new FormControl(template.summary && template.summary.totalTax),
        totalCost: new FormControl(template.summary && template.summary.totalCost, [Validators.required])
      }),
      adjustments: new FormArray(
        (template.adjustments &&
          template.adjustments.map(
            (adj: any) =>
              new FormGroup({
                name: new FormControl(adj.name, [Validators.required]),
                amount: new FormControl(adj.amount, [Validators.required])
              })
          )) ||
          []
      )
    };

    super(formGroup);
  }

  static addressValidator = (control: FormGroup): ValidationErrors | null => {
    const street1 = control.get('street1').value;
    const street2 = control.get('street2').value;
    const city = control.get('city').value;
    const postalCode = control.get('postalCode').value;
    const state = control.get('state').value;
    const country = control.get('country').value;

    return (street1 || street2 || city || postalCode || state || country) &&
      !(street1 && city && postalCode && state && country)
      ? { incompleteAddress: true }
      : null;
  };
}
