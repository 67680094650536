import { Validators, FormArray } from '@angular/forms';

import { QuickReplyElement } from 'ideta-library/lib/common/node';

import { QuickReplyItemForm } from './quick-reply-item-form.model';

export class QuickRepliesManualForm extends FormArray {
  constructor(quickReplies: QuickReplyElement[] = []) {
    const formArray =
      (quickReplies && quickReplies.map((quickReply: QuickReplyElement) => new QuickReplyItemForm(quickReply))) || [];

    super(formArray, [Validators.required]);
  }

  addQuickReply() {
    this.push(new QuickReplyItemForm());
  }

  removeQuickReply(index: number) {
    this.removeAt(index);
  }

  moveQuickReply(index: number, shift: 1 | -1) {
    const quickReply = this.at(index);
    this.removeAt(index);
    this.insert(index + shift, quickReply);
  }
}
