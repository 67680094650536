import { Component, OnInit, Input } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { FormGroup } from '@angular/forms';

import { BaseTemplateFormComponent } from '../../shared/base-template-form.component';
import { ButtonsListForm } from '../../shared/buttons-list-form/buttons-list-form.model';
import { GenericManualForm } from '../../shared/generic-manual-form.model';
import { RichInputFormControl } from '../../../models/rich-input-form-control.model';

@Component({
  selector: 'app-carrousel-manual-form',
  templateUrl: './carrousel-manual-form.component.html',
  styleUrls: ['./carrousel-manual-form.component.scss'],
  animations: [
    trigger('expandAnimation', [
      state(
        'collapsed',
        style({
          height: '0',
          overflow: 'hidden'
        })
      ),
      state(
        'expanded',
        style({
          height: '*'
        })
      ),
      transition('collapsed <=> expanded', [animate('0.4s cubic-bezier(.87,-.41,.19,1.44)')])
    ])
  ]
})
export class CarrouselManualFormComponent extends BaseTemplateFormComponent implements OnInit {
  @Input() parentForm: GenericManualForm;
  expandedElements: { [index: number]: boolean };
  maxElements: number;

  constructor() {
    super();
    this.maxElements = 10;
    this.expandedElements = {};
  }

  ngOnInit() {}

  getElement(index: number): FormGroup {
    return this.parentForm.at(index) as FormGroup;
  }

  addElement() {
    this.parentForm.addElement();
    this.expandedElements[this.parentForm.length - 1] = true;
  }

  removeElement(index: number) {
    this.parentForm.removeAt(index);
  }

  moveElement(index: number, shift: -1 | 1) {
    const element = this.parentForm.at(index);
    this.parentForm.removeAt(index);
    this.parentForm.insert(index + shift, element);
  }

  getElementTitle(index: number): RichInputFormControl {
    return this.getElement(index).get('title') as RichInputFormControl;
  }

  getElementSubtitle(index: number): RichInputFormControl {
    return this.getElement(index).get('subtitle') as RichInputFormControl;
  }

  getElementImageUrl(index: number): RichInputFormControl {
    return this.getElement(index).get('imageUrl') as RichInputFormControl;
  }

  getElementButtons(index: number): ButtonsListForm {
    return this.getElement(index).get('buttons') as ButtonsListForm;
  }

  trackByFn(index: number): number {
    return index;
  }
}
