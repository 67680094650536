import { FormControl, ValidatorFn, ValidationErrors } from '@angular/forms';
import { isEmpty } from 'lodash';

import { AllowedScope, AllowedType, DataStoreArrayElems, DataStoreElemKeys } from 'ideta-library/lib/common/data';

export interface AdditionalDataInfos {
  key?: string;
  // ###DK
  oldFormat?: boolean;
  scope?: AllowedScope;
  dataType?: AllowedType;
  elements?: DataStoreArrayElems;
  keys?: DataStoreElemKeys;
  allowedTypes?: AllowedType[];
  allowedScopes?: AllowedScope[];
}

export type DataKeyForm = { value?: string } & AdditionalDataInfos;

export class DataKeyFormControl extends FormControl {
  displayName?: string;
  scope?: AllowedScope;
  dataType?: AllowedType;
  elements?: DataStoreArrayElems;
  keys?: DataStoreElemKeys;
  allowedTypes?: AllowedType[];
  allowedScopes?: AllowedScope[];
  isElement?: boolean;
  private isMissing?: boolean;
  private isInit: boolean;

  get isNumber(): boolean {
    return !this.isInit || (this.value && this.dataType === 'number');
  }

  get isString(): boolean {
    return !this.isInit || (this.value && this.dataType === 'string');
  }

  get isBoolean(): boolean {
    return !this.isInit || (this.value && this.dataType === 'boolean');
  }

  get isAlphanumeric(): boolean {
    return !this.isInit || (this.value && (this.dataType === 'string' || this.dataType === 'number'));
  }

  get isObject(): boolean {
    return !this.isInit || (this.value && this.dataType === 'object');
  }

  get listValue(): any {
    return this.elements && this.elements.type;
  }

  /**
   * [constructor description]
   * @param input   [description]
   * @param keyType [description]
   */
  constructor(input?: string, validators: ValidatorFn[] = [], isElement?: boolean) {
    super(input, [
      ...validators,
      DataKeyFormControl.missingDataKey,
      DataKeyFormControl.invalidDataType,
      DataKeyFormControl.invalidScope
    ]);
    this.isElement = isElement;
  }

  /**
   * [missingDataKey description]
   * @param  control [description]
   * @return       [description]
   */
  static missingDataKey = (control: DataKeyFormControl): ValidationErrors | null => {
    return control.isMissing ? { missingDataKey: true } : null;
  };

  /**
   * [invalidDataType description]
   * @param  control [description]
   * @return                  [description]
   */
  static invalidDataType = (control: DataKeyFormControl): ValidationErrors | null => {
    const fullDataType = control.dataType === 'array' && control.elements ? 'array.' + control.elements.type : null;
    return control.allowedTypes &&
      !(
        control.dataType &&
        (control.allowedTypes.indexOf(control.dataType) > -1 ||
          (fullDataType && control.allowedTypes.indexOf(fullDataType as AllowedType) > -1))
      )
      ? { invalidDataType: true }
      : null;
  };

  /**
   * [invalidScope description]
   * @param  control [description]
   * @return               [description]
   */
  static invalidScope = (control: DataKeyFormControl): ValidationErrors | null => {
    return control.allowedScopes &&
      control.scope &&
      !(control.allowedScopes.indexOf(control.scope) > -1) &&
      !(
        (control.value === 'first_name' || control.value === 'last_name') &&
        control.allowedScopes.indexOf('identifier') > -1
      )
      ? { invalidDataScope: true }
      : null;
  };

  patchValue(dataKey: DataKeyForm = {}) {
    const { value, ...additionalDataInfos } = dataKey;

    if (value !== undefined && value !== this.value) {
      super.patchValue(value);
    }

    this.updateAdditionalDataInfos(additionalDataInfos);
  }

  setAsMissingKey() {
    this.isMissing = true;
    this.updateValueAndValidity();
  }

  clear() {
    this.scope = null;
    this.dataType = null;
    this.elements = null;
    this.keys = null;
    this.allowedTypes = null;
    this.allowedScopes = null;
    this.displayName = null;
    this.isMissing = false;
    this.isElement = false;
    this.reset();
  }

  isList(type?: AllowedType): boolean {
    return this.value && this.dataType === 'array' && (!type || (this.elements && this.elements.type === type));
  }

  private updateAdditionalDataInfos(infos: AdditionalDataInfos = {}) {
    this.isInit = true;
    if (!isEmpty(infos)) {
      const { key, scope, dataType, elements, keys, allowedTypes, allowedScopes } = infos;
      if (key !== undefined) this.displayName = key;
      if (scope !== undefined) this.scope = scope;
      if (dataType !== undefined) this.dataType = dataType;
      if (elements !== undefined) this.elements = elements;
      if (keys !== undefined) this.keys = keys;
      if (allowedTypes !== undefined) this.allowedTypes = allowedTypes;
      if (allowedScopes !== undefined) this.allowedScopes = allowedScopes;
      this.isMissing = false;
      this.updateValueAndValidity();
    }
  }
}
