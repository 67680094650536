import { Component, OnInit, Input } from '@angular/core';

import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent implements OnInit {
  @Input() url: string;

  get backgroundUrl(): string {
    const url = this.url || environment.defaults.user.avatarUrl;
    return `url(${url})`;
  }

  constructor() {}

  ngOnInit() {}
}
