import { Component } from '@angular/core';
import { get } from 'lodash';

import { MessageTemplateText } from 'ideta-library/lib/common/message';

import { UserSessionService } from './../../services/session/user-session.service';
import { BasePreviewComponent } from '../shared/base-preview.component';

@Component({
  selector: 'app-template-text-preview',
  templateUrl: './template-text-preview.component.html',
  styleUrls: ['./template-text-preview.component.scss']
})
export class TemplateTextPreviewComponent extends BasePreviewComponent {
  constructor(private _user: UserSessionService) {
    super();
  }

  get isTypingNode() {
    return get(this.message, 'node.id') === 'typing';
  }

  get template() {
    return this.message.template as MessageTemplateText;
  }

  get isAutoGen(): boolean {
    return this.displayOptions.context === 'preview' && this.template['feedType'] === 'auto';
  }

  get textStyle(): any {
    const style = {};
    if (this.template.text && this.template.text.length < 5) {
      style['justify-content'] = 'center';
    }
    style['color'] = this.displayOptions.getColors(this.message.sender).text;

    return style;
  }

  get lang(): string {
    return this.displayOptions.context === 'sandbox' ? this._user.lang : this.displayOptions.speechLang;
  }
}
