import { FormGroup, Validators } from '@angular/forms';

import { TemplateText } from 'ideta-library/lib/common/node';

import { RichInputFormControl } from '../../models/rich-input-form-control.model';

const defaultTemplate: TemplateText = {
  text: "Je suis la bulle de départ de votre chatbot. Vous pouvez m'éditer mais pas me supprimer !"
};

export class TemplateTextForm extends FormGroup {
  constructor(template: TemplateText = defaultTemplate) {
    const formObject = {
      text: new RichInputFormControl(template.text, { validators: [Validators.required] })
    };

    super(formObject);
  }
}
