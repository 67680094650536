import { FormGroup, FormControl, FormArray } from '@angular/forms';

import { urlValidator } from '../../models/custom-validators';

export type WhitelistedDomains = string[];

export class WhitelistedDomainsForm extends FormGroup {
  get domains(): FormArray {
    return this.get('domains') as FormArray;
  }

  constructor(domains: any = []) {
    const formGroup = {
      domains: new FormArray(domains.map((domain: string) => new FormControl(domain, [urlValidator])))
    };

    super(formGroup);

    if (this.domains.length === 0) this.addDomain();
  }

  getProfileValue(): WhitelistedDomains {
    return this.domains.value.filter((domain: string) => !!domain);
  }

  setProfileValue(value: any = []) {
    this.domains.clear();
    value.map((domain: string) => this.domains.push(new FormControl(domain, [urlValidator])));
  }

  addDomain(domain: string = '') {
    this.domains.push(new FormControl(domain, [urlValidator]));
  }

  whitelistUrl(url: string) {
    if (url && this.domains.value.indexOf(url) < 0) this.addDomain(url);
  }
}
