import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

import { ComparisonAction, ComparisonActionType } from 'ideta-library/lib/common/node';

import { NodeKeyFormControl } from '../../../../models/node-key-form-control.model';

@Component({
  selector: 'app-data-comparison-action-selector',
  templateUrl: './data-comparison-action-selector.component.html',
  styleUrls: ['./data-comparison-action-selector.component.scss']
})
export class DataComparisonActionSelectorComponent implements OnInit {
  @Input() action: FormGroup;
  @Input() index: number;
  @Input() statement: 'true' | 'false';
  @Output() createNode: EventEmitter<{
    name: string;
    callback: (key: string) => void;
  }>;
  actionTypes: ComparisonActionType[];

  get actionType(): FormControl {
    return this.action.get('type') as FormControl;
  }

  get typeValue(): string {
    return this.actionType.value;
  }

  get actionTargetNode(): NodeKeyFormControl {
    return this.action.get('targetNode') as NodeKeyFormControl;
  }

  constructor() {
    this.actionTypes = ['next', 'exit'];
    this.createNode = new EventEmitter();
  }

  ngOnInit() {}

  setActionProperty(property: keyof ComparisonAction, value: ComparisonAction[keyof ComparisonAction]) {
    this.action.get(property).patchValue(value);
  }

  createNodeDropDownValue(name: string) {
    this.createNode.emit({
      name,
      callback: (key: string) => this.setActionProperty('targetNode', key)
    });
  }
}
