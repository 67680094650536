import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { get } from 'lodash';

import { OperationType } from 'ideta-library/lib/common/node';
import { AllowedType } from 'ideta-library/lib/common/data';

import { DataKeyFormControl } from '../../../models/data-key-form-control.model';
import { RichInputFormControl } from '../../../models/rich-input-form-control.model';
import { KeyCreationEvent } from '../../../models/data-selector.component';
import { DataOperationForm } from '../../../models/data-operation-form.model';

interface OperationTypeItem {
  key: OperationType | SeparatorType;
  symbol?: string;
  type?: 'single-value' | 'bounded-options' | 'composed-options' | 'search-options' | 'separator';
  objectAllowedTypes?: AllowedType[];
  sourceAllowedTypes?: AllowedType[];
}

type SeparatorType = 'general' | 'string' | 'number' | 'list' | 'object' | 'misc';

@Component({
  selector: 'app-data-operation',
  templateUrl: './data-operation.component.html',
  styleUrls: ['./data-operation.component.scss']
})
export class DataOperationComponent implements OnInit {
  @Input() operation: DataOperationForm;
  @Input() length: number;
  @Input() selectedParentKey: FormControl; // Keep for array element selection
  @Output() remove: EventEmitter<void>;
  @Output() createDataKey: EventEmitter<KeyCreationEvent>;
  @Output() order: EventEmitter<'up' | 'down'>;
  uniqueId: string;
  operationTypes: OperationTypeItem[];
  dicoOperationTypes: { [key in OperationType]: OperationTypeItem };

  get type(): FormControl {
    return this.operation.get('type') as FormControl;
  }

  get key(): DataKeyFormControl {
    return this.operation.get('key') as DataKeyFormControl;
  }

  get operand(): RichInputFormControl {
    return this.operation.get('operand') as RichInputFormControl;
  }

  get operandValue(): FormControl {
    return this.operation.get('operand.value') as FormControl;
  }

  get options(): FormGroup {
    return this.operation.get('options') as FormGroup;
  }

  constructor() {
    this.remove = new EventEmitter();
    this.createDataKey = new EventEmitter();
    this.order = new EventEmitter();
    this.uniqueId = Math.random()
      .toString(36)
      .substring(2, 8);
    const baseAllowedTypes: AllowedType[] = ['string', 'number', 'boolean', 'element'];
    this.operationTypes = [
      // general operations
      {
        key: 'general',
        type: 'separator'
      },
      {
        key: 'set',
        symbol: '=',
        type: 'single-value'
      },
      {
        key: 'delete',
        symbol: '<i class="fas fa-eraser"></i>'
      },
      {
        key: 'erase',
        symbol: '<i class="fas fa-magic"></i>'
      },
      {
        key: 'dateNow',
        symbol: '<i class="fas fa-calendar-minus"></i>'
      },
      // string operations
      {
        key: 'string',
        type: 'separator'
      },
      {
        key: 'concatenate',
        symbol: '+=',
        type: 'composed-options',
        sourceAllowedTypes: baseAllowedTypes
      },
      {
        key: 'split',
        symbol: '<i class="fas fa-quote-right"></i>',
        type: 'composed-options',
        sourceAllowedTypes: baseAllowedTypes
      },
      {
        key: 'replace',
        symbol: '<i class="fas fa-random"></i>',
        type: 'composed-options',
        sourceAllowedTypes: baseAllowedTypes
      },
      {
        key: 'match',
        symbol: '<i class="fas fa-font"></i>',
        type: 'composed-options',
        sourceAllowedTypes: baseAllowedTypes
      },
      {
        key: 'toString',
        symbol: '<i class="fas fa-arrow-left"></i>',
        type: 'single-value'
      },
      {
        key: 'toUpperCase',
        symbol: 'A-Z',
        type: 'single-value'
      },
      {
        key: 'toLowerCase',
        symbol: 'a-z',
        type: 'single-value'
      },
      { key: 'stringify', symbol: '"..."', type: 'single-value' },
      // number operations
      {
        key: 'number',
        type: 'separator'
      },
      {
        key: 'add',
        symbol: '+=',
        type: 'single-value'
      },
      {
        key: 'remove',
        symbol: '-=',
        type: 'single-value'
      },
      {
        key: 'divide',
        symbol: '/=',
        type: 'single-value'
      },
      {
        key: 'multiply',
        symbol: '*=',
        type: 'single-value'
      },
      {
        key: 'random',
        symbol: '<i class="fas fa-dice mx-0"></i>',
        type: 'bounded-options'
      },
      {
        key: 'round',
        symbol: '0.',
        type: 'single-value'
      },
      {
        key: 'maximum',
        symbol: 'V',
        type: 'composed-options',
        sourceAllowedTypes: ['number', 'element']
      },
      {
        key: 'minimum',
        symbol: 'Λ',
        type: 'composed-options',
        sourceAllowedTypes: ['number', 'element']
      },
      {
        key: 'formula',
        symbol: '<i class="fas fa-calculator"></i>',
        type: 'composed-options',
        sourceAllowedTypes: ['number', 'element']
      },
      {
        key: 'toNumber',
        symbol: '<i class="fas fa-arrow-left"></i>',
        type: 'single-value'
      },
      // array operations
      {
        key: 'list',
        type: 'separator'
      },
      {
        key: 'push',
        symbol: '<i class="fas fa-stream"></i>',
        type: 'single-value'
      },
      {
        key: 'slice',
        symbol: '<i class="fas fa-cut"></i>',
        type: 'bounded-options'
      },
      {
        key: 'find',
        symbol: '<i class="fas fa-search"></i>',
        type: 'search-options'
      },
      {
        key: 'filter',
        symbol: '<i class="fas fa-filter"></i>',
        type: 'search-options'
      },
      {
        key: 'sort',
        symbol: '<i class="fas fa-sort-amount-up"></i>',
        type: 'single-value'
      },
      {
        key: 'invertedSort',
        symbol: '<i class="fas fa-sort-amount-down"></i>',
        type: 'single-value'
      },
      {
        key: 'join',
        symbol: '<i class="fas fa-file-signature"></i>',
        type: 'composed-options',
        objectAllowedTypes: ['array.string', 'array.number']
      },
      // object operations
      {
        key: 'object',
        type: 'separator'
      },
      {
        key: 'get',
        symbol: '<i class="fas fa-bullseye"></i>',
        type: 'composed-options',
        objectAllowedTypes: [...baseAllowedTypes, 'object', 'array'],
        sourceAllowedTypes: baseAllowedTypes
      },
      { key: 'merge', symbol: '<i class="fas fa-object-ungroup"></i>', type: 'single-value' },
      // misc operations
      {
        key: 'misc',
        type: 'separator'
      },
      { key: 'parse', symbol: '{ }', type: 'single-value' },
      {
        key: 'conditional',
        symbol: '?',
        type: 'search-options'
      }
    ];
    this.dicoOperationTypes = this.operationTypes.reduce((acc: any, curr: any) => (acc[curr.key] = curr) && acc, {});
  }

  ngOnInit() {}

  trackByKey(operation: OperationTypeItem): string {
    return operation.key;
  }

  setOperationType(operationType: any, event: any) {
    if (operationType.type === 'separator') {
      event.stopPropagation();
      return;
    }
    this.operation.updateOperationType(operationType.key);
  }

  changeConditionalIndex(value: any, condition: 'ifTrue' | 'ifFalse') {
    if (this.type.value === 'conditional' || value !== get(this.options.get(condition), 'value')) {
      DataOperationForm.updateConditionalIndex(value, this.operation, condition);
    }
  }
}
