import { BehaviorSubject, Subscription, combineLatest, Observable, pipe } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';
import { AngularFireDatabase, AngularFireAction, DatabaseSnapshot } from '@angular/fire/database';
import { Injectable, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';

import { BotNode } from 'ideta-library/lib/common/node';

import { CoreSessionService } from '../../services/session/core-session.service';
import { SessionModel } from './session.model';
import { ChannelSessionService } from './channel-session.service';
import { BotSessionService } from './bot-session.service';

import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NodeSessionService implements SessionModel, OnDestroy {
  public activeNode$: BehaviorSubject<Partial<BotNode>>;
  private _subject$: BehaviorSubject<BotNode[]>;
  private botId: string;
  private channel: string;
  private nodesSub: Subscription;
  private routerSub: Subscription;

  get subject$() {
    return this._subject$.pipe(filter(value => !!value));
  }

  get activeNode(): Partial<BotNode> {
    return this.activeNode$.value || {};
  }

  get activeKey() {
    return this.activeNode.key;
  }

  get value(): BotNode[] {
    return this._subject$.value || [];
  }

  get isActiveNode(): boolean {
    return !!this.activeNode$.value;
  }

  constructor(
    private db: AngularFireDatabase,
    private _session: CoreSessionService,
    private _bot: BotSessionService,
    private _channel: ChannelSessionService,
    private location: Location
  ) {
    this.activeNode$ = new BehaviorSubject(null);
    this._subject$ = new BehaviorSubject(null);
    this.routerSub = combineLatest([this._session.routerEvent$, this._channel.subject$]).subscribe(
      ([event, channel]) => {
        if (event.botId) {
          switch (event.location) {
            case 'edition':
            case 'deployment':
            case 'messaging':
            case 'testing':
            case 'settings':
            case 'lexicon':
              this.startSession(event.botId, channel.type);
              break;
            default:
              this.endSession();
              break;
          }
        } else {
          this.endSession();
        }
      }
    );
  }

  startSession(botId: string, channel: string) {
    if (!botId || channel === this.channel) return;
    // ###M
    // console.log('init _nodes', botId, channel);

    // session may vary inside the same page
    // that's why we need to properly end the previous one before
    this.endSession();
    this.botId = botId;
    this.channel = channel;

    // Waiting for a bot value to avoid permission denied
    this.nodesSub = this._bot.subject$
      .pipe(
        take(1),
        switchMap(() => this.getNodes())
      )
      .subscribe(nodes => {
        this._subject$.next(nodes || []);
      });
    return this.subject$;
  }

  endSession() {
    if (!this.botId || !this.channel) return;
    // ###M
    // console.log('reset _nodes');
    if (this.nodesSub) this.nodesSub.unsubscribe();
    this.activeNode$.next(null);
    this._subject$.next(null);
    this.botId = this.channel = null;
  }

  ngOnDestroy(): void {
    this.endSession();
    this.routerSub.unsubscribe();
  }

  public navigateToNode(nodeKey: string, keepLocation?: boolean, bypassCurrentNodeCheck?: boolean): void {
    const node = this.value.find(entry => entry.key === nodeKey);
    if (node && (bypassCurrentNodeCheck || node.key !== this.activeNode.key)) {
      this.activeNode$.next(node);
      if (!keepLocation) this.location.go(location.pathname, '?node=' + node.key);
    }
  }

  private getNodes(): Observable<BotNode[]> {
    return this.db
      .list(`/nodes/${this.botId}/${this.channel}`)
      .snapshotChanges()
      .pipe(this.nodesHandler());
  }

  private nodesHandler() {
    return pipe(
      map((changes: AngularFireAction<DatabaseSnapshot<any>>[]) => {
        return changes.map(c => {
          return { key: c.payload.key, ...c.payload.val() };
        });
      }),
      map((nodes: any[]) => {
        return nodes.map((node: any) => {
          if (node && !node.name) {
            node.name = environment.defaults.naming.noNameNode;
          }
          return node;
        });
      }),
      map(this.orderListByStringKey('name'))
    );
  }

  private orderListByStringKey(key: string) {
    return (list: any = []) => [...list].sort((a: any, b: any) => a[key].localeCompare(b[key]));
  }
}
