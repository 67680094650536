import { Component, OnInit, Input } from '@angular/core';

import { TextSpeechService } from '../../../services/text-speech/text-speech.service';

@Component({
  selector: 'app-text-speech-reader',
  templateUrl: './text-speech-reader.component.html',
  styleUrls: ['./text-speech-reader.component.scss']
})
export class TextSpeechReaderComponent implements OnInit {
  @Input() text: string;
  @Input() lang: string;

  isReading: boolean;
  private currentAudioNode: AudioBufferSourceNode;

  get isDisabled(): boolean {
    // checks if AudioContext API is available in the client
    return !this.text || !this.textSpeechService.audioContext;
  }

  constructor(private textSpeechService: TextSpeechService) {}

  ngOnInit(): void {}

  triggerReading() {
    if (this.isReading) {
      this.stopReading();
    } else {
      this.isReading = true;
      this.startReading();
    }
  }

  startReading() {
    this.textSpeechService
      .readText(this.text, { lang: this.lang || 'en_US' })
      .then(audioNode => {
        this.currentAudioNode = audioNode;
        this.currentAudioNode.onended = () => {
          this.isReading = false;
        };
      })
      .catch(() => {
        this.isReading = false;
      });
  }

  stopReading() {
    if (this.currentAudioNode) this.currentAudioNode.stop();
    this.currentAudioNode = null;
  }
}
