import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import { ProtocolDroidService } from '../../../protocol-droid/services/protocol-droid.service';
import { CoreSessionService } from '../../../services/session/core-session.service';
import { NodeSessionService } from '../../../services/session/node-session.service';
import { NodeKeyFormControl } from '../../../models/node-key-form-control.model';
import { KeyCreationEvent } from '../../../models/data-selector.component';
import { DataStoreSessionService } from '../../../services/session/data-store-session.service';

@Component({
  selector: 'app-next-node-dropdown',
  templateUrl: './next-node-dropdown.component.html',
  styleUrls: ['./next-node-dropdown.component.scss']
})
export class NextNodeDropdownComponent implements OnInit, OnDestroy {
  @Input() input: NodeKeyFormControl;
  @Input() allowSelfSelection?: boolean;
  @Input() hideActionBtn: boolean;
  @Input() enableNoneOption: boolean;
  @Input() action: 'clear' | 'reset';
  @Input() selectedParentKey?: FormControl;
  @Output() selectKey: EventEmitter<string>;
  @Output() create?: EventEmitter<string>;
  @Output() createDataKey: EventEmitter<KeyCreationEvent>;
  nodes: any;
  dicoNodes: { [key: string]: any };
  newNodeName: string;
  dataStoreExists: boolean;
  uniqueId: string;
  private subscriptions: Subscription[];

  get selectedValue() {
    return this.dicoNodes && this.dicoNodes[this.input.value];
  }

  get showNavigation(): boolean {
    return (
      !this.input.isAlias && this.input.value && this.input.value !== 'none' && this._session.location === 'edition'
    );
  }

  get disabledAction(): boolean {
    return this.action === 'reset' && this.input.value === this.input.defaultValue;
  }

  constructor(
    private protocolDroidService: ProtocolDroidService,
    private _dataStore: DataStoreSessionService,
    public _session: CoreSessionService,
    public _nodes: NodeSessionService
  ) {
    this.selectKey = new EventEmitter();
    this.create = new EventEmitter();
    this.createDataKey = new EventEmitter();
    this.dicoNodes = {};
    this.newNodeName = null;
    this.subscriptions = [];
    this.uniqueId = Math.random()
      .toString(36)
      .substring(2, 8);
    this.enableNoneOption = true;
    this.action = 'clear';
  }

  ngOnInit() {
    this.subscriptions.push(
      this._dataStore.exists$.subscribe(doesExist => (this.dataStoreExists = doesExist)),
      this._nodes.subject$
        .pipe(
          map(nodes =>
            this.enableNoneOption
              ? [
                  {
                    key: 'none',
                    icon: 'far fa-stop-circle',
                    name: this.protocolDroidService.translate('shared.ui-elements.next-node-dropdown.none')
                  },
                  ...nodes.map(node => ({ icon: 'fas fa-comment', ...node }))
                ]
              : [...nodes.map(node => ({ icon: 'fas fa-comment', ...node }))]
          )
        )
        .subscribe((nodes: any) => {
          this.nodes = nodes;
          this.dicoNodes = nodes.reduce((acc: any, curr: any) => (acc[curr.key] = curr) && acc, {});
        })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  selectItem(key: string, event: Event) {
    if (key === this._nodes.activeKey && !this.allowSelfSelection) {
      event.stopPropagation();
      return;
    }
    this.input.patchValue(key);
    this.selectKey.emit(key);
  }

  triggerAddItem(event: Event, input: HTMLInputElement) {
    this.newNodeName = this.protocolDroidService.translate(
      'shared.ui-elements.next-node-dropdown.new-bubble',
      'New bubble'
    );
    event.stopPropagation();
    // Execute in next tick
    setTimeout(() => input.select(), 10);
  }

  cancelAddItem(event?: Event) {
    this.newNodeName = null;
    if (event) {
      event.stopPropagation();
    }
  }

  addItem() {
    if (!this.newNodeName) {
      return;
    }
    this.create.emit(this.newNodeName);
  }

  clearInput() {
    if (this.action === 'reset') {
      this.input.reset();
    } else if (this.action === 'clear') {
      this.input.clear();
    }
  }

  trackByFn(_index: number, item: any): string {
    return item.key;
  }

  navigate() {
    this._nodes.navigateToNode(this.selectedValue.key);
  }
}
