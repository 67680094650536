import { Component, OnInit, SecurityContext, OnChanges, SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Lightbox } from 'ngx-lightbox';

import { MessageTemplateMedia } from 'ideta-library/lib/common/message';

import { BasePreviewComponent } from '../shared/base-preview.component';
import { MediaElementForm } from '../../node-template-form/template-media-form/media-element-form.model';
import { formats } from '../../models/regex-formats.model';

@Component({
  selector: 'app-template-media-preview',
  templateUrl: './template-media-preview.component.html',
  styleUrls: ['./template-media-preview.component.scss']
})
export class TemplateMediaPreviewComponent extends BasePreviewComponent implements OnInit, OnChanges {
  mediaUrl: string | SafeResourceUrl;
  mediaType: any;

  get template() {
    return this.message.template as MessageTemplateMedia;
  }

  constructor(private sanitizer: DomSanitizer, private lightbox: Lightbox) {
    super();
  }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.message) {
      this.setMediaParams();
    }
  }

  setMediaParams() {
    const template = this.message && (this.message.template as MessageTemplateMedia);
    if (template.elements && template.elements[0]) {
      const { mediaType, url: mediaUrl } = template.elements[0];

      if (mediaType === 'image') {
        if (MediaElementForm.isValidFileImageUrl(mediaUrl)) {
          this.mediaType = 'image';
          this.mediaUrl = this.sanitizer.sanitize(SecurityContext.URL, mediaUrl);
        } else {
          this.setPlaceholder('assets/img/mapping-picture.svg');
        }
      } else if (mediaType === 'video') {
        if (MediaElementForm.isValidFileVideoUrl(mediaUrl)) {
          this.mediaType = 'video';
          this.mediaUrl = this.sanitizer.sanitize(SecurityContext.URL, mediaUrl);
        } else if (MediaElementForm.isValidYoutubeVideoUrl(mediaUrl)) {
          const shortLink = mediaUrl.split(formats.youtubeShortLink);
          const regularLink = mediaUrl.split(formats.youtubeRegularLink);
          const embedLink = mediaUrl.split(formats.youtubeEmbeddedLink);
          this.mediaType = 'frame';
          this.mediaUrl = 'https://www.youtube.com/embed/' + (shortLink[1] || regularLink[1] || embedLink[1]);
        } else {
          this.setPlaceholder('assets/img/play-button.svg');
        }
      } else if (mediaType === 'audio') {
        if (MediaElementForm.isValidFileAudioUrl(mediaUrl)) {
          this.mediaType = 'audio';
          this.mediaUrl = this.sanitizer.sanitize(SecurityContext.URL, mediaUrl);
        } else {
          this.setPlaceholder('assets/img/play-button.svg');
        }
      } else if (mediaType === 'map') {
        if (MediaElementForm.isValidGoogleMapsUrl(mediaUrl)) {
          this.mediaType = 'frame';
          this.mediaUrl = mediaUrl;
        } else {
          this.setPlaceholder('assets/img/mapping-picture.svg');
        }
      } else if (mediaType === 'calendar') {
        if (MediaElementForm.isValidGoogleCalendarUrl(mediaUrl)) {
          this.mediaType = 'frame';
          this.mediaUrl = mediaUrl.replace(formats.regex('googleCalendarIntegration'), '&');
        } else {
          this.setPlaceholder('assets/img/mapping-picture.svg');
        }
      }
    }
  }

  openLightbox() {
    this.lightbox.open([{ src: this.mediaUrl as string, thumb: this.mediaUrl as string }], 0);
  }

  private setPlaceholder(url: string) {
    this.mediaType = 'placeholder';
    this.mediaUrl = url;
  }
}
