import { ContentChild, Directive, HostBinding } from '@angular/core';
import { FormControlName } from '@angular/forms';

/**
 * This directive is a workaround for an Angular 6+ bug which prevents reactive form input disabled
 * state from being updated if the FormControl is replaced with a new instance under the view.
 * From: https://gist.github.com/alantreadway/773c4880585bc9eb27703b3073c7fcd2
 *
 * @see https://github.com/angular/angular/issues/17806
 */
@Directive({
  /* tslint:disable-next-line:directive-selector */
  selector: 'input[formControlName], select[formControlName], textarea[formControlName]'
})
export class InputDisabledFixDirective {
  @ContentChild(FormControlName, { static: false }) public formControlName: FormControlName;

  @HostBinding('attr.disabled')
  public get disabled(): 'disabled' | undefined {
    return this.formControlName && this.formControlName.control.disabled ? 'disabled' : undefined;
  }
}
