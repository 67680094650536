import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';

import { AllowedType } from 'ideta-library/lib/common/data';
import { ComparisonType } from 'ideta-library/lib/common/node';

interface ComparisonTypeItem {
  key: ComparisonType;
  symbol: string;
  allowedTypes: AllowedType[];
}

@Component({
  selector: 'app-data-comparison-type-selector',
  templateUrl: './data-comparison-type-selector.component.html',
  styleUrls: ['./data-comparison-type-selector.component.scss']
})
export class DataComparisonTypeSelectorComponent {
  @Input() input: FormControl;
  @Output() update: EventEmitter<ComparisonType>;
  comparisonTypes: ComparisonTypeItem[];
  dicoComparisonTypes: { [key in ComparisonType]: ComparisonTypeItem };
  htmlId: string;

  constructor() {
    this.update = new EventEmitter();
    this.comparisonTypes = [
      {
        key: '!',
        symbol: '!',
        allowedTypes: ['string', 'number', 'boolean', 'array', 'object']
      },
      {
        key: '.!',
        symbol: '.!',
        allowedTypes: ['string', 'number', 'boolean', 'array', 'object']
      },
      {
        key: '==',
        symbol: '==',
        allowedTypes: ['string', 'number', 'boolean']
      },
      {
        key: '<>',
        symbol: '<>',
        allowedTypes: ['string', 'number', 'boolean']
      },
      {
        key: '<',
        symbol: '<',
        allowedTypes: ['number']
      },
      {
        key: '<=',
        symbol: '<=',
        allowedTypes: ['number']
      },
      {
        key: '>',
        symbol: '>',
        allowedTypes: ['number']
      },
      {
        key: '>=',
        symbol: '>=',
        allowedTypes: ['number']
      },
      {
        key: '^$',
        symbol: '^$',
        allowedTypes: ['string']
      }
    ];
    this.dicoComparisonTypes = this.comparisonTypes.reduce((acc: any, curr: any) => (acc[curr.key] = curr) && acc, {});
    this.htmlId = Math.random()
      .toString(36)
      .substring(2, 8);
  }
}
