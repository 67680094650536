import { Component, OnInit, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { Dictionary } from 'lodash';

import { TypeAdaptingFormControl } from './type-adapting-form-control.model';
import { ProtocolDroidService } from '../../../../shared/protocol-droid/services/protocol-droid.service';

@Component({
  selector: 'app-type-adapting-form-control',
  templateUrl: './type-adapting-form-control.component.html',
  styleUrls: ['./type-adapting-form-control.component.scss']
})
export class TypeAdaptingFormControlComponent implements OnInit {
  @Input() control: TypeAdaptingFormControl;
  @Input() labelAddress: string;
  dicoChoices: Dictionary<string>;
  private subscriptions: Subscription[];

  constructor(private protocolDroidService: ProtocolDroidService) {
    this.subscriptions = [];
    this.dicoChoices = {};
  }

  ngOnInit() {
    if (this.control.type === 'dropdown') {
      this.subscriptions.push(
        this.protocolDroidService.onTranslationChangeEvent.subscribe(() => {
          this.dicoChoices = this.control.choices.reduce(
            (agg: Dictionary<string>, choice: string) =>
              Object.assign(agg, {
                [choice]: this.protocolDroidService.translate(this.labelAddress + '-' + choice)
              }),
            {}
          );
        })
      );
    }
  }
}
