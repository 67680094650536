import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { FeedbackService } from '../feedback/feedback.service';
import { ProtocolDroidService } from '../../protocol-droid/services/protocol-droid.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorsService {
  constructor(private feedbackService: FeedbackService, private protocolDroidService: ProtocolDroidService) {}

  public handlePromise = <T>(defaultValue?: T) => (response: any): Promise<T> => {
    console.log('Promise Error Handling -> ', response);
    const message = this.buildErrorMessage(response);
    return this.feedbackService
      .showMessage(message, {
        title: this.protocolDroidService.translate('misc.modals.error-title', 'Oops...'),
        decoration: 'error'
      })
      .then(() => {
        /* Hiding spinner just in case */
        this.feedbackService.hideSpinner();
        return defaultValue;
      });
  };

  public handleObservable = <T>(response: any, fallbackResponse: T): Observable<T> => {
    console.log('Observable Error Handling -> ', response);
    const message = this.buildErrorMessage(response);
    /* Hiding spinner just in case */
    this.feedbackService
      .showMessage(message, {
        title: this.protocolDroidService.translate('misc.modals.error-title', 'Oops...'),
        decoration: 'error'
      })
      /* Hiding spinner just in case */
      .then(() => this.feedbackService.hideSpinner());
    return of(fallbackResponse);
  };

  private buildErrorMessage(response: any): string {
    if (response.status === 404) {
      return this.protocolDroidService.translate('misc.errors.not-found', 'The resource could not be found.');
    } else if (response.status === 0) {
      return this.protocolDroidService.translate('misc.errors.no-contact', 'Cannot contact server.');
    } else {
      return (
        this.protocolDroidService.translate('misc.errors.intro', 'An error occurred: ') +
        ' <b>' +
        ((response.error && response.error.message) ||
          (response.message !== 'INTERNAL' && response.message) ||
          this.protocolDroidService.translate('misc.errors.unknown-short', 'Unknown Error')) +
        '<b>'
      );
    }
  }
}
