import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { trigger, transition, animate, style } from '@angular/animations';
import { Subscription, Observable, BehaviorSubject, combineLatest, of } from 'rxjs';
import { tap, map, switchMap, filter, catchError, delayWhen, distinctUntilChanged, take } from 'rxjs/operators';
import { get } from 'lodash';

import { Actors, Conversation, ConversationMetadata } from 'ideta-library/lib/common/conversation';
import { WebMenu } from 'ideta-library/lib/common/bot';
import { ButtonElement } from 'ideta-library/lib/common/node';

import { AuthService } from '../shared/services/auth/auth.service';
import { Auth } from '../shared/services/auth/auth.types';
import { MessageService } from '../shared/services/message/message.service';
import { BotService } from '../shared/services/bot/bot.service';
import { BotWebService } from '../shared/services/bot/bot-web/bot-web.service';
import { BotBackService } from '../shared/services/bot-back/bot-back.service';
import { ColorService } from '../shared/services/color/color.service';
import { FeedbackService } from '../shared/services/feedback/feedback.service';
import { ProtocolDroidService } from '../shared/protocol-droid/services/protocol-droid.service';
import { ConversationService } from '../shared/services/conversation/conversation.service';
import { DisplayOptions } from '../shared/models/display-options.model';
import { BotSessionService } from '../shared/services/session/bot-session.service';
import { ConversationSessionService } from '../shared/services/session/conversation-session.service';
import { TextSpeechService } from '../shared/services/text-speech/text-speech.service';

import { environment } from './../../environments/environment';

@Component({
  selector: 'app-web-display',
  templateUrl: './web-display.component.html',
  styleUrls: ['./web-display.component.scss'],
  animations: [
    trigger('menuAnim', [
      transition(':enter', [style({ height: '0px' }), animate('700ms cubic-bezier(0.23, 1, 0.32, 1)')]),
      transition(':leave', [animate('700ms cubic-bezier(0.23, 1, 0.32, 1)', style({ height: '0px' }))])
    ])
  ]
})
export class WebDisplayComponent implements OnInit, OnDestroy {
  @Input() remoteStart: boolean;
  displayOptions: DisplayOptions;
  conversationId: string;
  routeParams$: Observable<any>;
  routeParams: any;
  messages$: BehaviorSubject<any>;
  listLoaded: boolean;
  alternativeUserId: string = null; // TODO: Remove once we get rid of alternativeUserId
  metadata$: BehaviorSubject<ConversationMetadata>;
  webMenu: WebMenu;
  landingUrl: string;
  actors: Actors;
  triggerWebMenu: boolean;
  private currentConversation: Conversation;
  private subscriptions: Subscription[];
  private loginWindow: Window;
  private isInit: boolean;

  get headerStyle(): any {
    const up = this.colorService.hexToRGB(this.displayOptions.bannerColors.header, 1);
    const down = this.colorService.hexToRGB(this.displayOptions.bannerColors.header, 0);
    return { 'background-image': `linear-gradient(${up} 65%, ${down})` };
  }

  get footerStyle(): any {
    const up = this.colorService.hexToRGB(this.displayOptions.bannerColors.footer, 0);
    const down = this.colorService.hexToRGB(this.displayOptions.bannerColors.footer, 1);
    return { 'background-image': `linear-gradient(${up}, ${down})` };
  }

  get pageStyle(): any {
    const styleObj = { 'background-position': this.displayOptions.backgroundPosition };
    if (this.displayOptions.isBackground) {
      if (this.displayOptions.backgroundType === 'url') {
        styleObj['background-image'] = this.displayOptions.backgroundUrl;
      } else if (this.displayOptions.backgroundType === 'color') {
        styleObj['background'] = this.displayOptions.backgroundColor;
      }
    }
    return styleObj;
  }

  get menuTemplate(): any {
    return { template: { buttons: this.webMenuOptions } };
  }

  get showFooter(): boolean {
    return this.displayOptions.isBranding || this.displayOptions.freetext || (this.webMenu && this.webMenu.active);
  }

  get isWebMenuActive(): boolean {
    return get(this.webMenu, 'active') && this.webMenuOptions.length > 0;
  }

  get isWebMenuAlwaysVisible(): boolean {
    return get(this.webMenu, 'options.alwaysVisible');
  }

  get webMenuOptions(): ButtonElement[] {
    /* ###L */
    const buttons = get(this.webMenu, 'options');
    if (!Array.isArray(buttons)) return get(this.webMenu, 'options.buttons', []);
    return buttons;
  }

  get showVolumeButton(): boolean {
    return (
      !!this.textSpeechService.audioContext &&
      (this.displayOptions.autoTextToSpeech || this.displayOptions.toggleAutoTextToSpeech) &&
      this._conversation.isAutoTextReadingActive
    );
  }

  private get reloadParams(): any {
    if (!this.displayOptions.deleteOnReload) return this.routeParams;
    const params: any = this.routeParams;
    params.ref = params.ref && params.ref.value ? params.ref : { value: 'noeud_0' };
    params.restart = params.restart && params.restart.value ? params.restart : { value: 'true' };
    return params;
  }

  private get isReloadable(): boolean {
    return (
      this.displayOptions.deleteOnReload && (!this.routeParams.restart || this.routeParams.restart.value !== 'false')
    );
  }

  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private messageService: MessageService,
    private botService: BotService,
    private botWebService: BotWebService,
    private botBackService: BotBackService,
    private colorService: ColorService,
    private feedbackService: FeedbackService,
    private protocolDroidService: ProtocolDroidService,
    private conversationService: ConversationService,
    private _bot: BotSessionService,
    public textSpeechService: TextSpeechService,
    public _conversation: ConversationSessionService
  ) {
    this.isInit = true;
    this.subscriptions = [];
    this.routeParams = {};
    this.messages$ = new BehaviorSubject([]);
    this.displayOptions = new DisplayOptions('web');
    this.metadata$ = new BehaviorSubject(undefined);
    this.landingUrl = environment.analytics.web_display_watermark;
    this.actors = { emitter: 'user', receiver: 'bot' };
  }

  ngOnInit() {
    this.subscriptions.push(
      combineLatest([this.fetchUserId(), this._bot.distinctSubject$])
        .pipe(
          switchMap(() =>
            combineLatest([
              this.fetchBackendUrl(),
              this.fetchBotToken(),
              this.fetchRouteParams(),
              this.fetchDisplayOptions(),
              this.fetchMenuSettings()
            ])
          ),
          filter(() => this.isInit),
          /* take only the first value without closing the stream bellow,
           * unlike first() or take(1) which will close all observables
           * in the combineLatest above
           */
          tap(() => (this.isInit = false)),
          delayWhen(() =>
            this.isReloadable ? this.conversationService.resetConversation(this.conversationId) : of(true)
          ),
          tap(() => {
            this.resumeConversation(this.reloadParams);
            this.conversationService.getConversationStatus(this.conversationId).subscribe((newStatus: any) => {
              if (newStatus === 'admin_to_user-node') {
                this.displayOptions.pause();
                this._conversation.focusResponseBar();
              } else {
                setTimeout(() => this.displayOptions.resume(), 100);
              }
            });
            this.conversationService
              .readMetadata(this.conversationId)
              .subscribe(metadata => this.metadata$.next(metadata));
            this.conversationService
              .getConversation(this.alternativeUserId || this.conversationId)
              .pipe(distinctUntilChanged((prev, curr) => (prev && prev.userId) === (curr && curr.userId)))
              .subscribe(conversation => {
                this.currentConversation = conversation;
              });
          }),
          switchMap(() =>
            this.fetchMessages().pipe(
              tap(() => {
                if (this.loginWindow) this.loginWindow.close();
              })
            )
          ),
          catchError(error => {
            this.showErrorMessage(error);
            return of(error);
          })
        )
        .subscribe((messages: any) => {
          this.messages$.next(messages || []);
          this.listLoaded = true;
        })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  toggleTextSpeech(): void {
    this._conversation.isSoundOn = !this._conversation.isSoundOn;
    if (!this._conversation.isSoundOn) {
      this.textSpeechService.suspend();
    } else {
      this.textSpeechService.resume();
    }
  }

  restartConversation(): void {
    this.botBackService.restartConversation(this.conversationId, this.displayOptions.deleteOnRestart, {
      ref: { value: this.displayOptions.restartTarget }
    });
    this._conversation.stopAudioEvents();
  }

  sendMessage(message: any, isFromWebMenu?: boolean): void {
    this._conversation.stopAudiolistening();
    if (message.type === 'login') {
      this.startLoginProcess(message);
    } else if (message.type === 'postback' && isFromWebMenu) {
      this.botBackService.sendMessageToConversation(this.conversationId, { type: 'node', id: message.targetNode });
    } else {
      this.botBackService
        .sendMessageToBot(this.conversationId, message, this.routeParams)
        .catch(error => this.showErrorMessage(error));
    }
    if (isFromWebMenu) this._conversation.stopAudioEvents();
  }

  handleTypingEvent(value: boolean) {
    if (!!this.currentConversation) {
      this.conversationService.updateMetadata(this.conversationId, this.actors.emitter, 'isTyping', value);
    }
  }

  updateLastRead() {
    if (!!this.currentConversation) {
      this.conversationService.updateMetadata(
        this.conversationId,
        this.actors.emitter,
        'last_read',
        new Date().toISOString()
      );
    }
  }

  private showErrorMessage(error: any) {
    console.log(error);
    this.feedbackService.showMessage(
      this.protocolDroidService.translate('shared.components.bot-display.error-message'),
      {
        title: this.protocolDroidService.translate('misc.modals.error-title', 'Oops...'),
        decoration: 'error'
      }
    );
  }

  private resumeConversation(routeParams: any): void {
    this.botBackService
      .resumeConversation(this.conversationId, routeParams)
      .catch(error => this.showErrorMessage(error));
  }

  private fetchUserId(): Observable<string> {
    return this.authService.getAuth().pipe(
      switchMap((user: Auth) => (user ? of(user) : this.authService.loginAnonymously())),
      map((user: Auth) => user.uid),
      filter((conversationId: string) => !!conversationId),
      tap((conversationId: string) => (this.conversationId = conversationId))
    );
  }

  private fetchBackendUrl(): Observable<string> {
    return this.botService.getInBot<string>('endPointBack').pipe(
      filter(backendUrl => !!backendUrl),
      tap(backendUrl => (this._bot.value.endPointBack = backendUrl))
    );
  }

  private fetchBotToken(): Observable<string> {
    return this.botService.getInBot<string>('token').pipe(
      take(1),
      filter(token => !!token),
      tap(token => (this._bot.value.token = token))
    );
  }

  private fetchMessages(): Observable<any> {
    return this.messageService.readAll(this.conversationId);
  }

  private fetchRouteParams(): Observable<any> {
    return this.botWebService.getWebParams().pipe(
      switchMap((params: any) => {
        return params
          ? this.route.queryParamMap.pipe(
              map((queryParams: ParamMap) => {
                const routeParams = params.reduce(
                  (obj: any, param: any) => ({
                    ...obj,
                    [param.key]: {
                      ...param,
                      value: queryParams.get(param.key)
                    }
                  }),
                  {}
                );
                routeParams.ref = { value: queryParams.get('ref') };
                routeParams.restart = { value: queryParams.get('restart') };
                return routeParams;
              })
            )
          : of({});
      }),
      tap((routeParams: any) => (this.routeParams = routeParams)),
      tap((routeParams: any) => this.checkForAlternativeId(routeParams))
    );
  }

  private fetchDisplayOptions(): Observable<any> {
    return this.botWebService.getDisplayOptions().pipe(
      tap((displayOptions: any) => {
        this.displayOptions.merge(displayOptions);
      })
    );
  }

  private fetchMenuSettings(): Observable<any> {
    return this.botWebService.getWebMenu().pipe(tap(menuSettings => (this.webMenu = menuSettings)));
  }

  // TODO: Remove once we get rid of alternativeUserId
  private checkForAlternativeId(routeParams: any) {
    Object.keys(routeParams)
      .filter((paramKey: string) => paramKey !== 'ref' && paramKey !== 'restart')
      .forEach((paramKey: string) => {
        this.conversationId =
          routeParams[paramKey].isAlternativeId && routeParams[paramKey].value
            ? routeParams[paramKey].value
            : this.conversationId;
      });
  }

  private startLoginProcess(options: any) {
    const { service, targetNodeLoginSuccess, targetNodeLoginFailure, dataProfileKey } = options;
    const url =
      this._bot.endPointBack +
      '/conversations/init-login' +
      '?convId=' +
      this.conversationId +
      '&botId=' +
      this._bot.id +
      '&service=' +
      service +
      '&targetNodeLoginSuccess=' +
      targetNodeLoginSuccess +
      '&targetNodeLoginFailure=' +
      targetNodeLoginFailure +
      '&dataProfileKey=' +
      dataProfileKey +
      '&channel=web' +
      '&origin=' +
      window.location.origin;

    this.loginWindow = window.open(url, 'Login window');
  }
}
