import { Component, OnInit, OnDestroy, ViewChild, TemplateRef } from '@angular/core';
import { Subscription, BehaviorSubject } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { get } from 'lodash';

import { UserService } from '../../../services/user/user.service';
import { UserSessionService } from './../../../services/session/user-session.service';
import { NavTab } from '../../../ui-elements/components/nav-tabs/nav-tabs.component';
import { NavPanel } from '../../../ui-elements/components/nav-panels/nav-panels.component';
import { ProtocolDroidService } from '../../../protocol-droid/services/protocol-droid.service';
import { FeedbackService } from '../../../services/feedback/feedback.service';
import { ErrorsService } from '../../../services/errors/errors.service';

type Menu = NavTab & NavPanel;

@Component({
  selector: 'app-modal-user-settings',
  templateUrl: './modal-user-settings.component.html',
  styleUrls: ['./modal-user-settings.component.scss']
})
export class ModalUserSettingsComponent implements OnInit, OnDestroy {
  @ViewChild('general', { static: true }) generalRef: TemplateRef<any>;
  @ViewChild('payment', { static: true }) paymentRef: TemplateRef<any>;
  startingTab: string;
  menus$: BehaviorSubject<Menu[]>;
  activeMenu$: BehaviorSubject<string>;
  private subscriptions: Subscription[];

  constructor(
    private modalRef: BsModalRef,
    private userService: UserService,
    private protocolDroidService: ProtocolDroidService,
    private feedbackService: FeedbackService,
    private errorsService: ErrorsService,
    private _user: UserSessionService
  ) {
    this.menus$ = new BehaviorSubject([]);
    this.activeMenu$ = new BehaviorSubject(null);
    this.subscriptions = [];
  }

  ngOnInit() {
    this.menus$.next(this.buildTabsMenu());
    this.activeMenu$.next(this.startingTab || this.menus$.value[0].id);
    this.subscriptions.push(
      this._user.subject$.subscribe((user: any) => {
        const paymentTab = this.menus$.value.find(tab => tab.id === 'payment');
        const status = get(user, 'status');
        if (paymentTab) paymentTab.disabled = !status || status === 'super_admin';
        if (paymentTab) paymentTab.disabled = false;
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  updateUser(value: any) {
    this.userService.updateUser(value);
  }

  updateEmail(email: string) {
    if (this._user.email !== email) {
      this.userService
        .updateEmail(email)
        .then(() =>
          this.feedbackService.showMessage(
            this.protocolDroidService.translate('shared.components.modals.user-settings.email-success'),
            { type: 'message', decoration: 'success' }
          )
        )
        .catch(error => {
          if (error.code === 'auth/requires-recent-login') {
            return this.feedbackService.showMessage(
              this.protocolDroidService.translate('shared.components.modals.user-settings.email-error-login'),
              { type: 'message', decoration: 'warning' }
            );
          }
          throw error;
        })
        .catch(this.errorsService.handlePromise());
    }
  }

  dismiss(): void {
    this.modalRef.hide();
  }

  private buildTabsMenu(): Menu[] {
    return [
      {
        id: 'general',
        name: this.protocolDroidService.translate('shared.components.modals.user-settings.tab-general'),
        ref: this.generalRef
      },
      {
        id: 'payment',
        name: this.protocolDroidService.translate('shared.components.modals.user-settings.tab-payment'),
        ref: this.paymentRef
      }
    ];
  }
}
