import { FormGroup, FormControl, Validators, ValidationErrors } from '@angular/forms';
import { get } from 'lodash';

import { ComparisonType, DataComparison } from 'ideta-library/lib/common/node';
import { DataRichSegment } from 'ideta-library/lib/common/data';

import { RichInputSegmentForm } from './rich-input-segment-form.model';
import { NodeKeyFormControl } from './node-key-form-control.model';

const defaultComparison: DataComparison = {
  type: '==',
  key: {
    type: 'literal',
    value: ''
  },
  ifTrue: {
    type: 'next'
  },
  ifFalse: {
    type: 'next'
  }
};

export class DataComparisonForm extends FormGroup {
  constructor(comparison: DataComparison = defaultComparison) {
    /* ###L */
    const comparisonKey: DataRichSegment =
      typeof get(comparison, 'key') === 'string'
        ? { type: 'key', value: comparison.key as string }
        : get(comparison, 'key');

    const formObject = {
      type: new FormControl(comparison.type || '==', [Validators.required]),
      key: new RichInputSegmentForm(comparisonKey),
      comparator: new RichInputSegmentForm(get(comparison, 'comparator')),
      ifTrue: new FormGroup({
        type: new FormControl(get(comparison, 'ifTrue.type', 'next'), [Validators.required]),
        targetNode: new NodeKeyFormControl(get(comparison, 'ifTrue.targetNode', 'noeud_0'), [Validators.required])
      }),
      ifFalse: new FormGroup({
        type: new FormControl(get(comparison, 'ifFalse.type', 'next'), [Validators.required]),
        targetNode: new NodeKeyFormControl(get(comparison, 'ifFalse.targetNode', 'noeud_0'), [Validators.required])
      })
    };

    super(formObject, [
      DataComparisonForm.requiredSettings,
      DataComparisonForm.invalidAlphanumericalComparison,
      DataComparisonForm.invalidNumericalComparison,
      DataComparisonForm.invalidEqualComparison
    ]);

    this.switchControlsDisabling();
  }

  static requiredSettings = (group: DataComparisonForm): ValidationErrors | null => {
    const type = group.get('type').value;
    const dataKey = group.get('key') as RichInputSegmentForm;
    const comparator = group.get('comparator') as RichInputSegmentForm;
    const error = { required: true };

    return !dataKey.get('value').value || (type !== '!' && type !== '.!' && !comparator.get('value').value)
      ? error
      : null;
  };

  static invalidAlphanumericalComparison = (group: DataComparisonForm): ValidationErrors | null => {
    const type = group.get('type').value;
    const dataKey = group.get('key') as RichInputSegmentForm;
    const comparator = group.get('comparator') as RichInputSegmentForm;
    const error = { invalidAlphanumericalComparison: true };

    return DataComparisonForm.isAlphanumericalComparison(type) &&
      dataKey.get('value').value &&
      comparator &&
      comparator.get('value').value &&
      (!dataKey.isAlphanumeric || !comparator.isAlphanumeric)
      ? error
      : null;
  };

  static invalidNumericalComparison = (group: DataComparisonForm): ValidationErrors | null => {
    const type = group.get('type').value;
    const dataKey = group.get('key') as RichInputSegmentForm;
    const comparator = group.get('comparator') as RichInputSegmentForm;
    const error = { invalidNumericalComparison: true };

    return DataComparisonForm.isNumericalComparison(type) &&
      dataKey.get('value').value &&
      comparator &&
      comparator.get('value').value &&
      (!dataKey.isNumber || !comparator.isNumber)
      ? error
      : null;
  };

  static invalidEqualComparison = (group: DataComparisonForm): ValidationErrors | null => {
    const type = group.get('type').value;
    const dataKey = group.get('key') as RichInputSegmentForm;
    const comparator = group.get('comparator') as RichInputSegmentForm;
    const error = { invalidEqualComparison: true };

    return (type === '==' || type === '<>') &&
      dataKey.get('value').value &&
      comparator &&
      comparator.get('value').value &&
      !(
        (!comparator.isKey &&
          (dataKey.isString ||
            (dataKey.isNumber && comparator.isNumber) ||
            (dataKey.isBoolean && comparator.isBoolean))) ||
        (comparator.isKey && dataKey.dataType === comparator.dataType)
      )
      ? error
      : null;
  };

  static isNumericalComparison(type: ComparisonType): boolean {
    return type === '<' || type === '<=' || type === '>' || type === '>=';
  }

  static isAlphanumericalComparison(type: ComparisonType): boolean {
    return type === '^$';
  }

  enable(options?: { onlySelf?: boolean; emitEvent?: boolean }): void {
    super.enable(options);
    this.switchControlsDisabling();
  }

  updateComparisonType(type: ComparisonType) {
    this.get('type').patchValue(type);
    this.switchControlsDisabling();
  }

  private switchControlsDisabling() {
    const comparisonType = this.get('type').value;
    if (comparisonType === '!' || comparisonType === '.!') {
      this.get('comparator').disable({ emitEvent: false });
    } else {
      this.get('comparator').enable({ emitEvent: false });
    }
    this.updateValueAndValidity();
  }
}
