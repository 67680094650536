import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  EventEmitter,
  HostListener,
  Output,
  OnDestroy
} from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';
import { Subject, Observable, Subscription } from 'rxjs';
import { map, filter, tap, debounceTime, skip, take } from 'rxjs/operators';
import { get } from 'lodash';

import { TemplateType } from 'ideta-library/lib/common/node';

import { MessageDisplay } from './bot-display.types';
import { BotSessionService } from '../../services/session/bot-session.service';
import { UserSessionService } from '../../services/session/user-session.service';
import { BaseDisplayComponent } from './base-display/base-display.component';
import { CoreSessionService } from '../../services/session/core-session.service';
import { ConversationService } from '../../services/conversation/conversation.service';
import { TextSpeechService } from '../../services/text-speech/text-speech.service';
import { ConversationSessionService } from '../../services/session/conversation-session.service';

interface Buffer<T> {
  trigger: Subject<number>;
  payload: T[];
  processing: boolean;
}

@Component({
  selector: 'app-bot-display',
  templateUrl: './bot-display.component.html',
  styleUrls: ['./bot-display.component.scss'],
  animations: [
    trigger('bubbleAnim', [
      transition(':enter', [style({ opacity: 0 }), animate(100, style({ opacity: 1 }))]),
      transition(':leave', [animate(100, style({ opacity: 0 }))])
    ])
  ]
})
export class BotDisplayComponent extends BaseDisplayComponent implements OnInit, OnDestroy {
  @ViewChild('scrollable', { static: true }) scrollableContainer: ElementRef;
  @Input() messages$: Observable<any>;
  @Output() onDisplayNodeList: EventEmitter<void>;
  @Output() restartSandboxConversation: EventEmitter<void>;
  @Output() deleteSandboxMessages: EventEmitter<void>;
  @Output() updateLastRead: EventEmitter<void>;

  messagesList: MessageDisplay[];
  typingStatus: boolean;
  triggerConversationStart: EventEmitter<void>;

  private hideStartButton: boolean;
  private notificationTimer: NodeJS.Timeout;
  private isUserActive: boolean;
  private pageTitle: string;
  private userAction$: EventEmitter<void>;
  private conversationBuffer: Buffer<any[]>;
  private speechBuffer: Buffer<string>;
  private currentAudioNode: AudioBufferSourceNode;
  private delay: number;

  get activeStartButton(): boolean {
    return (
      !this.hideStartButton &&
      get(this.displayOptions, 'autoTextToSpeech') &&
      !get(this.displayOptions, 'toggleAutoTextToSpeech') &&
      !(get(this.conversationBuffer, 'payload[0].length') > 0)
    );
  }

  constructor(
    private textSpeechService: TextSpeechService,
    public conversationService: ConversationService,
    public _session: CoreSessionService,
    public _bot: BotSessionService,
    public _user: UserSessionService,
    public _conversation: ConversationSessionService
  ) {
    super();
    this.onDisplayNodeList = new EventEmitter();
    this.restartSandboxConversation = new EventEmitter();
    this.deleteSandboxMessages = new EventEmitter();
    this.updateLastRead = new EventEmitter();
    this.userAction$ = new EventEmitter();
    this.triggerConversationStart = new EventEmitter();
    this.conversationBuffer = { trigger: new Subject(), payload: [], processing: false };
    this.speechBuffer = { trigger: new Subject(), payload: [], processing: false };
    this.messagesList = [];
    this.delay = 0;
  }

  // Keeping user alive while he/she is
  // doing something on the page
  @HostListener('window:keyup')
  @HostListener('window:mouseup')
  @HostListener('window:touchdown')
  @HostListener('window:focus')
  onUserEvent() {
    this.userAction$.next();
    if (this._conversation.isSoundOn) {
      this._conversation.isAutoTextReadingActive = true;
      this.textSpeechService.resume();
    }
  }

  @HostListener('window:message', ['$event'])
  @HostListener('window:blur')
  onInactiveUserEvent(event?: any) {
    if (!event || event.data === 'v2bnpga') {
      this.isUserActive = false;
      this.textSpeechService.suspend();
    }
  }

  ngOnInit() {
    this._conversation.botDisplayInit = true;
    this.pageTitle = document.title;

    this.subscriptions.push(
      this._conversation.scrollEvent.subscribe(() => this.scrollToBottom()),
      this._conversation.stopAudioReadingEvent.subscribe(() => this.stopAndClearSpeech()),
      this.conversationBuffer.trigger.subscribe(index => this.processConversationBuffer(index)),
      this.speechBuffer.trigger.subscribe(index => this.processSpeechBuffer(index)),
      this.triggerConversationStart.pipe(take(1)).subscribe(() => {
        this._conversation.isAutoTextReadingActive = true;
        this.startBuffer(this.conversationBuffer);
      }),
      this.userAction$
        .pipe(
          tap(() => {
            if (!this.isUserActive) {
              this.isUserActive = true;
              this.updateLastRead.emit();
              this.resetNotificationState();
            }
          }),
          debounceTime(this.displayOptions.inactiveAfter * 1000)
        )
        .subscribe(() => {
          this.isUserActive = false;
        }),
      this.messages$
        .pipe(
          filter((messages: MessageDisplay[]) => messages.length === 0 || messages.length >= this.messagesList.length),
          tap(messages => {
            const reversed = [...messages];
            reversed.reverse();
            this._conversation.lastBotMessage = reversed.find(message => message.sender === 'bot');
          }),
          map((messages: any) =>
            messages.map(
              (message: any, index: number): MessageDisplay => ({
                ...message,
                firstOfConv: index === 0,
                lastOfConv: index === messages.length - 1,
                firstOfGroup: index === 0 || message.sender !== messages[index - 1].sender,
                lastOfGroup: index === messages.length - 1 || message.sender !== messages[index + 1].sender
              })
            )
          )
        )
        .subscribe(messages => {
          // /* ###M */ console.log(messages);
          this.conversationBuffer.payload.push(messages);
          if (!this.activeStartButton) this.startBuffer(this.conversationBuffer);
        })
    );
    setTimeout(() => this._conversation.focusResponseBar(), 400);
    // waiting 500ms before subscribing because
    // first typing values may be false
    setTimeout(
      () =>
        this.subscriptions.push(
          this.metadata$
            .pipe(
              filter(metadata => !!metadata),
              skip(1)
            )
            .subscribe(metadata => {
              this.typingStatus = get(metadata, `${this.actors.receiver}.isTyping`, false);
            })
        ),
      500
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  scrollToBottom(): void {
    const container = this.scrollableContainer.nativeElement;
    const nodes = document.getElementsByClassName('node-preview-container') || [];
    const lastChild = nodes[nodes.length - 1];

    if (container) {
      if (container.scrollTo) {
        container.scrollTo(0, (lastChild && lastChild.offsetTop - 5) || container.scrollHeight);
      } else {
        container.scrollTop = container.scrollHeight;
      }
    }
  }

  trackById(_index: number, item: any): string {
    return item.id;
  }

  restartConversation() {
    this.restartSandboxConversation.emit();
    this._conversation.stopAudioEvents();
  }

  deleteMessages() {
    this.deleteSandboxMessages.emit();
    this._conversation.stopAudioEvents();
  }

  bubbleColors(fromEmitter: boolean) {
    const property =
      (this.displayOptions.context === 'cockpit' && fromEmitter) || !fromEmitter ? 'botColors' : 'userColors';
    return {
      'background-color': get(this.displayOptions, `${property}.bubble`),
      color: get(this.displayOptions, `${property}.text`)
    };
  }

  private sendNotification(): void {
    const audio = new Audio('assets/sound/notification.mp3');
    const favicon = document.querySelector('link[rel="icon"]');
    let switcher;
    if (favicon) {
      favicon.setAttribute('href', 'assets/img/notification.ico');
    }
    if (this.displayOptions.useNotificationSound && audio) {
      audio.volume = 0.5;
      (audio.play() || Promise.resolve()).catch(() => {});
    }

    if (!this.notificationTimer) {
      this.notificationTimer = setInterval(() => {
        document.title = switcher ? this.pageTitle : 'Nouveau message !';
        switcher = !switcher;
      }, 2000);
    }
    window.parent.postMessage('z64tefu', '*');
  }

  private resetNotificationState(): void {
    const favicon = document.querySelector('link[rel="icon"]');
    if (favicon) {
      favicon.setAttribute('href', 'favicon.ico');
    }
    clearInterval(this.notificationTimer);
    this.notificationTimer = null; // clearInterval() doesn't empty the variable
    document.title = this.pageTitle;
    window.parent.postMessage('dj9y4b8', '*');
  }

  private handleAutoTextReading(message: MessageDisplay) {
    const templateType = get(message, 'template.type') as TemplateType;
    if (
      (this.displayOptions.toggleAutoTextToSpeech || this.displayOptions.autoTextToSpeech) &&
      this._conversation.isAutoTextReadingActive &&
      (templateType === 'text' || templateType === 'quick-replies')
    ) {
      if (message.sender === 'user') {
        this._conversation.stopAudioReading();
      }
      this.speechBuffer.payload.push(get(message, 'template.text'));
      this.startBuffer(this.speechBuffer);
    }
  }

  private processConversationBuffer(index: number) {
    this.conversationBuffer.processing = true;

    if (this.conversationBuffer.payload.length > 0 && index <= this.conversationBuffer.payload.length - 1) {
      this.hideStartButton = true;
      this.messagesList = this.conversationBuffer.payload[index] || [];
      const lastMessage = this.messagesList[this.messagesList.length - 1];
      const interactionCondition =
        this._session.location !== 'edition' &&
        lastMessage &&
        !this._conversation.botDisplayInit &&
        get(lastMessage, 'node.id') !== 'typing';

      if (this.isUserActive) {
        this.updateLastRead.emit();
        if (interactionCondition) {
          this.handleAutoTextReading(lastMessage);
        }
      } else if (
        interactionCondition &&
        ((this.displayOptions.inverted && get(lastMessage, 'sender') === 'user') ||
          (!this.displayOptions.inverted && get(lastMessage, 'sender') === 'bot'))
      ) {
        this.sendNotification();
      }

      this.setBufferTimeout(this.conversationBuffer, index, this.delay);
      this.delay = this.displayOptions.delay;
      // necessary to hide the typing bubble if the conversation already exists
      setTimeout(() => (this._conversation.botDisplayInit = false), 1);
    } else {
      this.clearBuffer(this.conversationBuffer);
      setTimeout(() => this._conversation.scroll(), 300);
    }
  }

  private processSpeechBuffer(index: number) {
    this.speechBuffer.processing = true;

    if (this.speechBuffer.payload.length > 0 && index <= this.speechBuffer.payload.length - 1) {
      this.textSpeechService
        .readText(this.speechBuffer.payload[index], {
          lang: this.displayOptions.context === 'sandbox' ? this._user.lang : this.displayOptions.speechLang
        })
        .then(audioNode => {
          this.currentAudioNode = audioNode;
          this.currentAudioNode.onended = () => this.setBufferTimeout(this.speechBuffer, index, 300);
        });
    } else {
      this.clearBuffer(this.speechBuffer);
    }
  }

  private startBuffer(buffer: Buffer<any>) {
    if (!buffer.processing && buffer.payload.length > 0) {
      buffer.trigger.next(0);
    }
  }

  private clearBuffer(buffer: Buffer<any>): void {
    buffer.payload = [];
    buffer.processing = false;
  }

  private setBufferTimeout(buffer: Buffer<any>, index: number, timeout: number): void {
    setTimeout(() => buffer.trigger.next(index + 1), timeout);
  }

  private stopAndClearSpeech() {
    if (this.currentAudioNode) {
      this.currentAudioNode.onended = null;
      this.currentAudioNode.stop();
      this.currentAudioNode = null;
    }
    this.clearBuffer(this.speechBuffer);
  }
}
