import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.scss']
})
export class ToggleComponent implements OnInit {
  @Input() value: any;
  @Output() toggle: EventEmitter<any>;

  constructor() {
    this.toggle = new EventEmitter();
  }

  ngOnInit() {}
}
