import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { ColorService } from '../../../../shared/services/color/color.service';

@Component({
  selector: 'app-layered-text',
  templateUrl: './layered-text.component.html',
  styleUrls: ['./layered-text.component.scss']
})
export class LayeredTextComponent implements OnInit {
  @Input() text: string;
  @Input() layers: any;

  constructor(private sanitizer: DomSanitizer, private colorService: ColorService) {}

  ngOnInit() {}

  getLayerHtml(layer: any): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(
      this.text.replace(layer.text, this.getWrappedHtml(layer))
    );
  }

  private getWrappedHtml(layer: any): string {
    return `<span style="
    background-color:${this.colorService.hexToRGB(layer.color, 0.25)};
    padding: 0.15rem 0;
    box-shadow: 0px 0px 0px 0.1rem ${this.colorService.hexToRGB(layer.color, 0.25)};
    border-radius: 0.1rem;
    ">${layer.text}</span> `;
  }
}
