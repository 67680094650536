import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { Subscription } from 'rxjs';
import { get } from 'lodash';

import { AuthService } from '../shared/services/auth/auth.service';
import { ProtocolDroidService } from '../shared/protocol-droid/services/protocol-droid.service';

import { environment } from '../../environments/environment';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
  animations: [
    trigger('signInAnimation', [
      state(
        'form',
        style({
          transform: 'translateY(0)'
        })
      ),
      state(
        'spinner',
        style({
          transform: 'translateY(-180px)'
        })
      ),
      transition('* => *', animate('.2s ease-out'))
    ])
  ]
})
export class SignupComponent implements OnInit, OnDestroy {
  credentials: any;
  customMessage: string;
  emailInput: string;
  signupStatus: string;
  signupError: string;
  botSignup: HTMLScriptElement;
  private subscriptions: Subscription[];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private protocolDroidService: ProtocolDroidService
  ) {
    this.subscriptions = [];
    this.signupStatus = 'form';
  }

  ngOnInit() {
    this.protocolDroidService.setLang(get(navigator, 'language') || get(navigator, 'userLanguage'));
    this.subscriptions.push(
      this.authService.getAuth().subscribe(user => {
        if (user && !user.isAnonymous) {
          this.router.navigateByUrl('/');
        }
        if (environment.botSignup.use) {
          this.botSignup = document.createElement('script');
          this.botSignup.src = environment.botSignup.src;
          this.botSignup.type = environment.botSignup.type;
          document.body.appendChild(this.botSignup);
        }
      }),
      this.route.queryParamMap.subscribe((params: ParamMap) => {
        this.customMessage = params.get('message');
        this.emailInput = params.get('email');
      })
    );
  }

  ngOnDestroy() {
    if (environment.botSignup.use) {
      window['ideta_bot_action']('remove');
      this.botSignup.remove();
    }
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  createUser(email: string, password: string): void {
    this.signupError = null;
    this.signupStatus = 'spinner';
    setTimeout(() => {
      this.authService
        .createUserWithEmailAndPassword({ email, password })
        .then(() => {
          window['dataLayer'] = window['dataLayer'] || [];
          window['dataLayer'].push({ event: 'signUp' });
        })
        .catch((errorCreation: any) => {
          /* for now the error message is displayed without translation
            as the default lang is EN for unlogged users */
          this.signupError = errorCreation;
          setTimeout(() => (this.signupStatus = 'form'), 2000);
        });
    }, 1000);
  }

  facebookAuth(): void {
    this.authService.signinWithFacebook();
  }

  googleAuth(): void {
    this.authService.signinWithGoogle();
  }

  handleSignupError(): boolean {
    if (this.signupError) {
      this.signupStatus = 'form';
      return true;
    } else return false;
  }
}
