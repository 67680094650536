import { Component, OnInit, ViewChild, ElementRef, OnDestroy, Input } from '@angular/core';
import { trigger, style, state, transition, animate } from '@angular/animations';
import { FormControl, Validators } from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, tap, filter } from 'rxjs/operators';
import { get } from 'lodash';

import { AttachmentType } from 'ideta-library/lib/common/bot';

import { BaseDisplayComponent } from '../base-display/base-display.component';
import { ProtocolDroidService } from '../../../protocol-droid/services/protocol-droid.service';
import { UserStorageService } from '../../../services/user-storage/user-storage.service';
import { environment } from '../../../../../environments/environment';
import { formats } from '../../../models/regex-formats.model';
import { countries } from './countries';
import { nationalities } from './nationalities';
import { ConversationSessionService } from '../../../services/session/conversation-session.service';

type UploadStatus = 'idle' | 'success' | 'error';

@Component({
  selector: 'app-response-display',
  templateUrl: './response-display.component.html',
  styleUrls: ['./response-display.component.scss'],
  animations: [
    trigger('inputAnim', [
      state(
        'focus',
        style({
          'border-color': '{{ bdColorIn }}'
        }),
        { params: { bdColorIn: '' } }
      ),
      state(
        'unfocus',
        style({
          'border-color': '{{ bdColorOut }}'
        }),
        { params: { bdColorOut: '' } }
      ),
      transition('* => *', animate('0.2s'))
    ])
  ]
})
export class ResponseDisplayComponent extends BaseDisplayComponent implements OnInit, OnDestroy {
  @ViewChild('inputField', { static: false }) inputField: ElementRef;
  @ViewChild('fileInput', { static: false }) fileInput: ElementRef;
  @ViewChild('textareaField', { static: false }) textareaField: ElementRef;
  @Input() isDropup: boolean;
  message: FormControl;
  animState: string;
  dynamicType: string;
  placeholder: string;
  uploadStatus$: Subject<UploadStatus>;
  uploadStatus: UploadStatus;
  isUploading: boolean;
  dicoDropdownValues: { [key: string]: string };
  private lastInput: string;
  private typingState: boolean;
  private previousInputType: string;

  get isInput(): boolean {
    return this.dynamicType !== 'text' && this.dynamicType !== 'country' && this.dynamicType !== 'nationality';
  }

  get isTextArea(): boolean {
    return this.dynamicType === 'text';
  }

  get isDropdown(): boolean {
    return this.dynamicType === 'country' || this.dynamicType === 'nationality';
  }

  constructor(
    private userStorageService: UserStorageService,
    private protocolDroidService: ProtocolDroidService,
    public _conversation: ConversationSessionService
  ) {
    super();
    this.uploadStatus$ = new Subject();
    this.uploadStatus = 'idle';
    this.animState = 'unfocus';
    this.dynamicType = 'text';
    this.isUploading = false;
    this.message = new FormControl('', Validators.required);
    this.lastInput = '';
    this.dicoDropdownValues = {};
  }

  ngOnInit() {
    this.subscriptions.push(
      this.message.valueChanges
        .pipe(
          filter(value => {
            const isNew = value.length > this.lastInput.length;
            this.lastInput = value;
            return isNew;
          }),
          tap(() => {
            if (!this.typingState) {
              this.typingState = true;
              this.isTyping.emit(true);
            }
          }),
          // time before assuming the user has stopped to type
          debounceTime(environment.appSettings.typingVisualDelay)
        )
        .subscribe(() => {
          this.typingState = false;
          this.isTyping.emit(false);
        }),
      this._conversation.focusResponseBarEvent.pipe(debounceTime(1000)).subscribe(() => {
        const element = this.inputField || this.textareaField;
        if (element) {
          element.nativeElement.focus();
          this.animState = 'focus';
        }
      }),
      this._conversation.inputType$.subscribe((type: string) => {
        this.dynamicType = (this.displayOptions.context === 'cockpit' && 'text') || type || 'text';
        if (this.dynamicType === 'country') {
          this.dicoDropdownValues = countries
            .map(country =>
              this.protocolDroidService.currentLang$.value === 'fr' ? country.translations.fr : country.name
            )
            .reduce((p, c) => {
              p[c] = c;
              return p;
            }, {});
        } else if (this.dynamicType === 'nationality') {
          this.dicoDropdownValues = nationalities.reduce((p, c) => {
            p[c] = c;
            return p;
          }, {});
        }
      }),
      this.uploadStatus$.subscribe(status => {
        this.uploadStatus = status;
        setTimeout(() => (this.uploadStatus = 'idle'), 1000);
      }),
      this.protocolDroidService.onTranslationChangeEvent.subscribe(() => {
        this.placeholder = this.protocolDroidService.translate(
          'shared.components.bot-display.message-input',
          'Votre message...'
        );
      }),
      this.message.valueChanges.subscribe(value => {
        if (!value && !!this.previousInputType) {
          this._conversation.inputType$.next(this.previousInputType);
          this.previousInputType = null;
        }
      })
    );
  }

  ngOnDestroy() {
    this.isTyping.emit(false);
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  sendMessage(event?) {
    // mandatory
    if (!!event) {
      event.preventDefault();
      event.stopPropagation();
    }
    if (this.message.valid) {
      let isSent: boolean;
      this._conversation.stopAudiolistening();

      if (get(this._conversation.lastBotMessage, 'template.type') === 'quick-replies') {
        isSent = this.handleQuickReplySend();
      } else if (get(this._conversation.lastBotMessage, 'template.type') === 'button') {
        isSent = this.handleButtonSend();
      }

      if (!isSent) {
        this.send.emit({
          type: 'text',
          text: this.message.value
        });
      }
      this.previousInputType = null;
    }
    // setting empty after delay to deal with textarea
    // auto resize
    setTimeout(() => this.message.patchValue(''), 5);
  }

  handleAttachment(files: FileList) {
    if (files.length > 0) {
      const file = files[0];
      const path = `attachments/${file.name}`;

      this.isUploading = true;

      this.userStorageService
        .setFile(path, file)
        .then(() => this.userStorageService.getFileUrl(path))
        .then(url => this.sendAttachmentMessage(url))
        .then(() => {
          this.fileInput.nativeElement.value = '';
          this.uploadStatus$.next('success');
        })
        .catch(() => {
          this.uploadStatus$.next('error');
        })
        .then(() => (this.isUploading = false));
    }
  }

  onSpeechStart(): void {
    this.previousInputType = this._conversation.inputType$.value;
    this._conversation.inputType$.next('string');
  }

  onSpeechUpdate(text: string): void {
    this.message.patchValue(text);
  }

  onSpeechEnd(): void {
    if (this.displayOptions.autoSend) {
      this.previousInputType = null;
      this.sendMessage();
    } else if (!this.message.valid) {
      this._conversation.inputType$.next(this.previousInputType || 'string');
      this.previousInputType = null;
    }
  }

  private sendAttachmentMessage(url: string): void {
    let attachmentType: AttachmentType;
    const lowUrl = url.toLowerCase();
    if (formats.test('imageExtension', lowUrl)) attachmentType = 'image';
    else if (formats.test('videoExtension', lowUrl)) attachmentType = 'video';
    else if (formats.test('audioExtension', lowUrl)) attachmentType = 'audio';
    else attachmentType = 'file';

    this.send.emit({
      type: 'attachments',
      attachments: [
        {
          type: attachmentType,
          url
        }
      ]
    });
  }

  private handleQuickReplySend(): boolean {
    const parsedMessageText = this.message.value.replace(/[!?;\.,:\"'\(\)\s]/g, '');
    const result = get(this._conversation.lastBotMessage, 'template.quickReplies', []).find(reply => {
      const parsedReplyText = (reply.title + '').replace(/[!?;\.,:\"'\(\)\s]/g, '');
      return parsedReplyText.localeCompare(parsedMessageText, [], { sensitivity: 'base' }) === 0;
    });
    if (result) {
      this.send.emit({
        type: 'postback',
        text: this.message.value,
        targetNode: result.targetNode,
        operations: result.operations
      });
      return true;
    }
    return false;
  }

  private handleButtonSend(): boolean {
    const parsedMessageText = this.message.value.replace(/[!?;\.,:\"'\(\)\s]/g, '');
    const result = get(this._conversation.lastBotMessage, 'template.buttons', []).find(button => {
      const parsedButtonText = (button.title + '').replace(/[!?;\.,:\"'\(\)\s]/g, '');
      return parsedButtonText.localeCompare(parsedMessageText, [], { sensitivity: 'base' }) === 0;
    });
    if (result) {
      if (result.type === 'url') {
        window.open(
          result.url,
          this._conversation.isEmbedded || result.openNewTab === undefined || result.openNewTab === true
            ? '_blank'
            : '_self'
        );
        return true;
      }
      this.send.emit({
        type: 'postback',
        targetNode: result.targetNode,
        operations: result.operations
      });
      return true;
    }
    return false;
  }
}
