import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, FormArray } from '@angular/forms';

import { ApiActionForm } from './api-action-form.model';
import { KeyCreationEvent } from '../../../../models/data-selector.component';
import { RichInputFormControl } from '../../../../models/rich-input-form-control.model';

@Component({
  selector: 'app-api-action-form',
  templateUrl: './api-action-form.component.html',
  styleUrls: ['./api-action-form.component.scss']
})
export class ApiActionFormComponent implements OnInit {
  @Input() parentForm: ApiActionForm;
  @Output() createDataKey: EventEmitter<KeyCreationEvent>;
  methodTypes: { [key: string]: string };
  bodyTypes: { [key: string]: string };

  get url(): RichInputFormControl {
    return this.parentForm.get('url') as RichInputFormControl;
  }

  get method(): FormControl {
    return this.parentForm.get('method') as FormControl;
  }

  get headers(): FormArray {
    return this.parentForm.get('headers') as FormArray;
  }

  get body(): FormGroup {
    return this.parentForm.get('body') as FormGroup;
  }

  get bodyType(): FormControl {
    return this.parentForm.get('body.type') as FormControl;
  }

  get bodyValue(): RichInputFormControl {
    return this.parentForm.get('body.value') as RichInputFormControl;
  }

  constructor() {
    this.createDataKey = new EventEmitter();
  }

  ngOnInit() {
    this.methodTypes = {
      GET: 'GET',
      POST: 'POST',
      PUT: 'PUT',
      PATCH: 'PATCH',
      DELETE: 'DELETE'
    };
    this.bodyTypes = {
      TEXT: 'TEXT',
      JSON: 'JSON',
      XML: 'XML'
    };
  }

  submitHeader(index: number) {
    if (this.headers.at(index + 1)) {
      const nextInput = this.headers.at(index + 1) as RichInputFormControl;
      nextInput.focus$.next();
    } else {
      const nextInput = this.parentForm.addHeader();
      setTimeout(() => nextInput.focus$.next(), 0);
    }
  }

  trackByFn(index: number): number {
    return index;
  }
}
