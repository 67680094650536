import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { Subject } from 'rxjs';

import {
  DataSelectorComponent,
  SelectedDataStoreElem,
  DataStoreElemPreview
} from '../../../models/data-selector.component';
import { ViewEvent } from '../data-keys-rich-input/data-keys-rich-input.component';

@Component({
  selector: 'app-data-keys-floating-selector',
  templateUrl: './data-keys-floating-selector.component.html',
  styleUrls: ['./data-keys-floating-selector.component.scss'],
  animations: [
    trigger('ctaContainerAnimation', [
      state(
        'hidden',
        style({
          height: '0',
          opacity: 0,
          overflow: 'hidden'
        })
      ),
      state(
        'visible',
        style({
          height: '*',
          opacity: 1,
          overflow: 'visible'
        })
      ),
      transition('hidden => visible', [animate('0.3s ease-out')]),
      transition('visible => hidden', [animate('0.3s 100ms ease-out')])
    ])
  ]
})
export class DataKeysFloatingSelectorComponent extends DataSelectorComponent implements OnInit {
  @Input() isStatic: boolean;
  @Input() viewEvent$: Subject<ViewEvent>;
  @Output() selectKey: EventEmitter<SelectedDataStoreElem>;
  @Output() createKey: EventEmitter<DataStoreElemPreview>;
  showMenuContainer: boolean;
  showCtaContainer: boolean;
  showCtaContainerTimer: NodeJS.Timer;
  hideCtaContainerTimer: NodeJS.Timer;

  constructor() {
    super();
    this.selectKey = new EventEmitter();
    this.createKey = new EventEmitter();
  }

  ngOnInit() {
    this.subscriptions.push(
      this.viewEvent$.pipe().subscribe(event => {
        if (event.float !== undefined) {
          event.float ? this.showContainer() : this.hideContainer();
        }
      })
    );
    if (this.isStatic) {
      this.showCtaContainer = true;
    }
  }

  openMenu() {
    this.showMenuContainer = !this.showMenuContainer;
    if (this.showMenuContainer) {
      this.viewEvent$.next({ badge: false });
    }
  }

  private showContainer() {
    if (this.hideCtaContainerTimer) {
      clearTimeout(this.hideCtaContainerTimer);
      this.hideCtaContainerTimer = null;
    }
    this.showCtaContainerTimer = setTimeout(() => {
      if (!this.isStatic) this.showCtaContainer = true;
      this.showCtaContainerTimer = null;
    }, 200);
  }

  private hideContainer() {
    if (this.showCtaContainerTimer) {
      clearTimeout(this.showCtaContainerTimer);
      this.showCtaContainerTimer = null;
    }
    this.hideCtaContainerTimer = setTimeout(() => {
      if (!this.isStatic) this.showCtaContainer = false;
      this.showMenuContainer = false;
      this.hideCtaContainerTimer = null;
    }, 200);
  }
}
