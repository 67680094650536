import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FeedbackService } from '../../../services/feedback/feedback.service';
import { ConversationService } from '../../../services/conversation/conversation.service';
import { MessageService } from '../../../services/message/message.service';
import { ProtocolDroidService } from '../../../protocol-droid/services/protocol-droid.service';

@Component({
  selector: 'app-modal-conversation',
  templateUrl: './modal-conversation.component.html',
  styleUrls: ['./modal-conversation.component.scss']
})
export class ModalConversationComponent implements OnInit {
  conversationIdList: string[];
  onSuccess: Function;
  hasToConfirm: boolean;

  constructor(
    private modalRef: BsModalRef,
    private feedbackService: FeedbackService,
    private conversationService: ConversationService,
    private messageService: MessageService,
    private protocolDroidService: ProtocolDroidService
  ) {
    this.conversationIdList = [];
    this.onSuccess = () => {
      return;
    };
  }

  ngOnInit() {}

  hide(): void {
    this.modalRef.hide();
  }

  confirm(target: 'conversation' | 'messages'): void {
    const promise: Promise<boolean> = this.hasToConfirm
      ? this.feedbackService.showMessage(
          this.protocolDroidService.translate('shared.components.modals.conversation.delete-warning', '', {
            length: this.conversationIdList.length
          }),
          {
            title: this.protocolDroidService.translate(`shared.components.modals.conversation.delete-${target}`),
            type: 'confirm',
            decoration: 'warning'
          }
        )
      : Promise.resolve(true);

    promise
      .then((isConfirmed: boolean) => {
        if (isConfirmed) {
          const deletePromise =
            target === 'conversation'
              ? this.conversationService.deleteConversation(this.conversationIdList)
              : this.messageService.deleteAll(this.conversationIdList);

          return deletePromise
            .then(() => Promise.resolve(this.hide()))
            .then(() => {
              if (target === 'conversation') this.onSuccess();
            });
        }
      })
      .catch(() =>
        this.feedbackService.showMessage(
          this.protocolDroidService.translate('shared.components.modals.conversation.delete-error'),
          {
            title: this.protocolDroidService.translate('shared.components.modals.conversation.delete-error-title'),
            type: 'message',
            decoration: 'error'
          }
        )
      );
  }
}
