import { FormGroup, FormControl, FormArray } from '@angular/forms';
import { get } from 'lodash';

export type TargetAudienceType = 'all' | 'none' | 'custom';

export type TargetAudienceCustomType = 'blacklist' | 'whitelist';

export interface TargetAudience {
  audience_type: TargetAudienceType;
  countries?: {
    whitelist?: string[];
    blacklist?: string[];
  };
}

export class TargetAudienceForm extends FormGroup {
  get list(): FormArray {
    return this.get('list') as FormArray;
  }

  constructor(profile: any = {}) {
    const formGroup = {
      audienceType: new FormControl(profile.audience_type || 'all'),
      customType: new FormControl(get(profile, 'countries.blacklist') ? 'blacklist' : 'whitelist'),
      list: new FormArray(
        (get(profile, 'countries.whitelist') || get(profile, 'countries.blacklist', [])).map(
          (country: string) => new FormControl(country)
        )
      )
    };

    super(formGroup);

    this.switchControlsDisabling();
  }

  getProfileValue(): TargetAudience {
    const targetAudience: any = {
      audience_type: this.get('audienceType').value
    };

    if (targetAudience.audience_type === 'custom') {
      if (this.get('customType').value === 'whitelist') {
        targetAudience.countries = { whitelist: this.get('list').value };
      } else {
        targetAudience.countries = { blacklist: this.get('list').value };
      }
    }

    return targetAudience;
  }

  setProfileValue(value: any = {}) {
    this.get('audienceType').patchValue(value.audience_type || 'all');
    this.get('customType').patchValue(
      get(value, 'countries.blacklist') ? 'blacklist' : 'whitelist'
    );
    this.list.clear();
    get(value, 'countries.whitelist', get(value, 'countries.blacklist', [])).forEach(
      (country: string) => this.list.push(new FormControl(country))
    );

    this.switchControlsDisabling();
  }

  switchControlsDisabling() {
    const type = this.get('audienceType').value;

    if (type === 'custom') {
      this.get('customType').enable({ emitEvent: false });
      this.get('list').enable({ emitEvent: false });
    } else {
      this.get('customType').disable({ emitEvent: false });
      this.get('list').disable({ emitEvent: false });
    }

    this.updateValueAndValidity();
  }
}
