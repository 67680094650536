import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { trigger, style, state, transition, animate } from '@angular/animations';

import { QuickReplyElement } from 'ideta-library/lib/common/node';

import { DisplayOptions } from '../../../models/display-options.model';
import { ColorService } from '../../../services/color/color.service';
import { FeedbackService } from '../../../services/feedback/feedback.service';

@Component({
  selector: 'app-quick-reply-preview',
  templateUrl: './quick-reply-preview.component.html',
  styleUrls: ['./quick-reply-preview.component.scss'],
  animations: [
    trigger('qrAnim', [
      state(
        'in',
        style({
          transform: 'translateY(-0.3rem)',
          'background-color': '{{bgIn}}'
        }),
        { params: { bgIn: 'rgb(0,0,0)' } }
      ),
      state('out', style({ 'background-color': '{{bgOut}}' }), { params: { bgOut: 'rgb(0,0,0)' } }),
      transition('* => *', animate('0.15s'))
    ])
  ]
})
export class QuickReplyPreviewComponent implements OnInit {
  @Input() reply: QuickReplyElement;
  @Input() displayOptions: DisplayOptions;
  @Output() send: EventEmitter<any>;
  animState: string;

  constructor(private colorService: ColorService, private feedBackService: FeedbackService) {
    this.animState = 'init';
    this.displayOptions = new DisplayOptions();
    this.send = new EventEmitter();
  }

  ngOnInit() {}

  get animBgColorIn(): string {
    return this.colorService.hexToHSL(this.displayOptions.userColors.bubble, 20);
  }

  get animBgColorOut(): string {
    return this.displayOptions.userColors.bubble;
  }

  get itemStyle(): any {
    return {
      color: this.displayOptions.userColors.text,
      // 'border-color': this.displayOptions.userColors.bubble,
      'background-color': this.displayOptions.userColors.bubble
    };
  }

  changeState(newState: 'in' | 'out'): void {
    if (this.displayOptions.context !== 'cockpit') {
      this.animState = newState;
    }
  }

  routeReply() {
    if (this.displayOptions.context === 'cockpit') return;
    const text = this.reply.title;

    if (this.reply.contentType && this.reply.contentType === 'location') {
      if (window.navigator.geolocation) {
        window.navigator.geolocation.getCurrentPosition(
          (location: any) => {
            this.postback(JSON.stringify(location));
          },
          () => {
            this.feedBackService.showMessage('Ce navigateur ne prend pas en charge la géolocalisation', {
              title: 'Erreur de localisation',
              decoration: 'error'
            });
          }
        );
      }
    } else {
      this.postback(text);
    }
  }

  private postback(message: any): any {
    this.send.emit({
      text: message,
      targetNode: this.reply.targetNode,
      operations: this.reply.operations,
      type: 'postback'
    });
  }
}
