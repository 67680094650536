import { FormGroup, FormControl, Validators, ValidationErrors } from '@angular/forms';

import { MediaElement } from 'ideta-library/lib/common/node';

import { ButtonsListForm } from '../shared/buttons-list-form/buttons-list-form.model';
import { RichInputFormControl } from '../../models/rich-input-form-control.model';
import { formats } from '../../models/regex-formats.model';

const defaultElement: MediaElement = {
  mediaType: 'image'
};

export class MediaElementForm extends FormGroup {
  constructor(element: MediaElement = defaultElement) {
    const formObject = {
      mediaType: new FormControl(element.mediaType, [Validators.required]),
      url: new RichInputFormControl(element.url, { validators: [Validators.required] }),
      buttons: new ButtonsListForm(element.buttons)
    };

    super(formObject, {
      validators: [
        MediaElementForm.validatorImageUrl,
        MediaElementForm.validatorVideoUrl,
        MediaElementForm.validatorAudioUrl,
        MediaElementForm.validatorGoogleUrl
      ]
    });
  }

  static isValidUrl = (url: string): boolean => {
    return formats.test('url', url, 'i');
  };

  static getUrlExtension = (url: string): string => {
    return url
      .split(/\#|\?/)[0]
      .split('.')
      .pop()
      .trim()
      .toLowerCase();
  };

  static isUrlWithDataKey = (url: string): boolean => {
    return formats.test('taggedData', url, 'i');
  };

  static isValidFileImageUrl = (url: string): boolean => {
    const validImageFormats = ['jpg', 'jpeg', 'png', 'gif'];
    return MediaElementForm.isValidUrl(url) && validImageFormats.indexOf(MediaElementForm.getUrlExtension(url)) > -1;
  };

  static isValidFileVideoUrl = (url: string): boolean => {
    const validVideoFormats = ['mp4', 'webm', 'ogg', 'mov'];
    return MediaElementForm.isValidUrl(url) && validVideoFormats.indexOf(MediaElementForm.getUrlExtension(url)) > -1;
  };

  static isValidFileAudioUrl = (url: string): boolean => {
    const validAudioFormats = ['mp3', 'wav', 'ogg', 'flac'];
    return MediaElementForm.isValidUrl(url) && validAudioFormats.indexOf(MediaElementForm.getUrlExtension(url)) > -1;
  };

  static isValidFacebookImageUrl = (url: string): boolean => {
    return formats.test('facebookBusinessImageUrl', url) || formats.test('facebookImageUrl', url);
  };

  static isValidFacebookVideoUrl = (url: string): boolean => {
    return formats.test('facebookVideoUrl', url);
  };

  static isValidYoutubeVideoUrl = (url: string): boolean => {
    return formats.test('youtubeUrl', url);
  };

  static isValidGoogleMapsUrl = (url: string): boolean => {
    return formats.test('googleMapsUrl', url);
  };

  static isValidGoogleCalendarUrl = (url: string): boolean => {
    return formats.test('googleCalendarUrl', url);
  };

  static validatorImageUrl = (control: FormGroup): ValidationErrors | null => {
    const mediaType = control.get('mediaType') && control.get('mediaType').value;
    const url = control.get('url') && control.get('url').value;

    return mediaType === 'image' &&
      url &&
      !MediaElementForm.isValidFileImageUrl(url) &&
      !MediaElementForm.isValidFacebookImageUrl(url) &&
      !MediaElementForm.isUrlWithDataKey(url)
      ? { invalidImageUrl: true }
      : null;
  };

  static validatorVideoUrl = (control: FormGroup): ValidationErrors | null => {
    const mediaType = control.get('mediaType') && control.get('mediaType').value;
    const url = control.get('url') && control.get('url').value;

    return mediaType === 'video' &&
      url &&
      !MediaElementForm.isValidFileVideoUrl(url) &&
      !MediaElementForm.isValidFacebookVideoUrl(url) &&
      !MediaElementForm.isValidYoutubeVideoUrl(url) &&
      !MediaElementForm.isUrlWithDataKey(url)
      ? { invalidVideoUrl: true }
      : null;
  };

  static validatorAudioUrl = (control: FormGroup): ValidationErrors | null => {
    const mediaType = control.get('mediaType') && control.get('mediaType').value;
    const url = control.get('url') && control.get('url').value;

    return mediaType === 'audio' &&
      url &&
      !MediaElementForm.isValidFileAudioUrl(url) &&
      !MediaElementForm.isUrlWithDataKey(url)
      ? { invalidAudioUrl: true }
      : null;
  };

  static validatorGoogleUrl = (control: FormGroup): ValidationErrors | null => {
    const mediaType = control.get('mediaType') && control.get('mediaType').value;
    const url = control.get('url') && control.get('url').value;

    return url &&
      ((mediaType === 'map' && !MediaElementForm.isValidGoogleMapsUrl(url)) ||
        (mediaType === 'calendar' && !MediaElementForm.isValidGoogleCalendarUrl(url))) &&
      !MediaElementForm.isUrlWithDataKey(url)
      ? { invalidMapsUrl: true }
      : null;
  };
}
