import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import htmlStringify from 'html-stringify';

@Pipe({
  name: 'htmlStringify'
})
export class HtmlStringifyPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(value: any): any {
    if (!value) return value;
    return this.sanitizer.bypassSecurityTrustHtml(htmlStringify(value));
  }
}
