import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { get } from 'lodash';

import { DataStoreSessionService } from './../../../services/session/data-store-session.service';
import { DataKeyFormControl } from '../../../models/data-key-form-control.model';
import { formats } from '../../../models/regex-formats.model';

@Component({
  selector: 'app-data-keys-dropdown-form',
  templateUrl: './data-keys-dropdown-form.component.html',
  styleUrls: ['./data-keys-dropdown-form.component.scss']
})
export class DataKeysDropdownFormComponent implements OnInit {
  @Input() selectedParentKey: FormControl;
  @Output() add: EventEmitter<string>;
  newKey: DataKeyFormControl;
  inputType: 'key' | 'property';

  constructor(private _dataStore: DataStoreSessionService) {
    this.add = new EventEmitter();
    this.newKey = null;
  }

  ngOnInit() {
    this.inputType = this.selectedParentKey ? 'property' : 'key';
  }

  triggerAddKey(event: Event) {
    this.newKey = new DataKeyFormControl(formats.generateKeyName());
    event.stopPropagation();
  }

  cancelAddKey(event?: Event) {
    this.newKey = null;
    if (event) {
      event.stopPropagation();
    }
  }

  addKey() {
    if (this.isDuplicatedInput()) {
      event.stopPropagation();
      this.newKey.setErrors({ duplicated: true });
    }
    if (this.newKey.valid) {
      this.add.emit(this.newKey.value);
      this.newKey = null;
    }
  }

  private isDuplicatedInput(): boolean {
    if (this.newKey.invalid) return false;
    if (!this.selectedParentKey) return !this._dataStore.isKeyNameUnique(this.newKey.value);
    if (this.selectedParentKey) {
      const dataElem = this._dataStore.getDataStoreElem(this.selectedParentKey.value);
      const keys = get(dataElem, 'elements.keys', get(dataElem, 'keys', {}));
      return !!Object.keys(keys).find((id: string) => keys[id].key === this.newKey.value);
    }
  }
}
