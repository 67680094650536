import { Component, OnInit, OnDestroy, ViewChild, ElementRef, HostListener } from '@angular/core';
import { Observable, Subscription, BehaviorSubject, combineLatest, of } from 'rxjs';
import { tap, map, switchMap, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { UserRole } from 'ideta-library/lib/common/user';

import { BillingService } from './../../../services/billing/billing.service';
import { UserSessionService } from './../../../services/session/user-session.service';
import { BotService } from '../../../services/bot/bot.service';
import { UserService } from '../../../services/user/user.service';
import { FeedbackService } from '../../../services/feedback/feedback.service';
import { ProtocolDroidService } from '../../../protocol-droid/services/protocol-droid.service';
import { BotApiService } from '../../../services/bot-api/bot-api.service';

export interface ModalShareOptions {
  botId: string;
}

@Component({
  selector: 'app-modal-share',
  templateUrl: './modal-share.component.html',
  styleUrls: ['./modal-share.component.scss']
})
export class ModalShareComponent implements OnInit, OnDestroy {
  @ViewChild('searchbox', { static: false }) searchbox: ElementRef;
  botId: string;
  usersInBot: any;
  usersToAdd: any;
  search$: BehaviorSubject<any>;
  searchTypes: any;
  searchHasFocus: boolean;
  isLoadingUsersToAdd: boolean;
  userRoles: UserRole[];
  selectedUserRole: any;
  canEdit: boolean;
  private subscriptions: Subscription[];

  constructor(
    private modalRef: BsModalRef,
    private botService: BotService,
    private botApiService: BotApiService,
    private userService: UserService,
    private feedbackService: FeedbackService,
    private protocolDroidService: ProtocolDroidService,
    private billingService: BillingService,
    public _user: UserSessionService
  ) {
    this.search$ = new BehaviorSubject({ type: 'email', value: '' });
    this.subscriptions = [];
    this.usersToAdd = [];
    this.usersInBot = [];
    this.searchTypes = ['email', 'firstname', 'lastname'];
    this.userRoles = ['contributor', 'support'];
    this.selectedUserRole = 'contributor';
  }

  @HostListener('window:keyup', ['$event'])
  onEnter(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.done();
    }
  }

  ngOnInit() {
    this.canEdit = this._user.isSuperAdmin || this._user.getRoleIn(this.botId) === 'owner';
    this.subscriptions.push(
      combineLatest([this.getUsersInBot(), this.getUsersToAdd()])
        .pipe(map(([usersInBot, usersToAdd]: [any, any]) => this.disableUsers(usersInBot, usersToAdd)))
        .subscribe(([usersInBot, usersToAdd]: [any, any]) => {
          this.usersInBot = usersInBot;
          this.usersToAdd = usersToAdd;
          this.isLoadingUsersToAdd = false;
        })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  selectSearchType(type: string) {
    this.search$.next({
      type: type,
      value: this.searchbox.nativeElement.value
    });
  }

  searchUser(): void {
    this.search$.next({
      type: this.search$.value.type,
      value: this.searchbox.nativeElement.value
    });
  }

  addUser(user: any, addAction: boolean): void {
    if (addAction) {
      this.billingService.predictAddons(this.selectedUserRole, this.botId, {
        onConfirm: () => {
          this.botApiService.updateBotUsers(this.botId, user.id, this.selectedUserRole).then(isSuccess => {
            this.search$.next({ type: this.search$.value.type, value: '' });
            this.handleOperationSuccess(isSuccess);
          });
          this.searchHasFocus = false;
        }
      });
    }
  }

  removeUser(user: any): void {
    if (!user.deletable) return;
    this.botService.removeUser(this.botId, user.id).then(isSuccess => {
      this.handleOperationSuccess(isSuccess);
    });
  }

  updateUserRole(user: any, role: UserRole): void {
    if (!user.updatable) return;
    this.botApiService.updateBotUsers(this.botId, user.id, role).then(isSuccess => {
      this.handleOperationSuccess(isSuccess);
    });
  }

  done(): void {
    this.modalRef.hide();
  }

  private getUsersInBot(): Observable<any> {
    return this.botService.getBotUsersIds(this.botId).pipe(
      switchMap((usersList: any) => {
        if (usersList && usersList.length > 0) {
          return this.userService.getUsersById(usersList);
        } else {
          return of([]);
        }
      })
    );
  }

  private getUsersToAdd(): Observable<any> {
    return this.search$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => (this.isLoadingUsersToAdd = true)),
      switchMap((search: any) => (search && search.value ? this.userService.getUsers(search) : of([])))
    );
  }

  private disableUsers(usersInBot: any, usersToAdd: any) {
    const newUsersInBot = usersInBot.map((user: any) => {
      user.updatable =
        this._user.isSuperAdmin || (this._user.getRoleIn(this.botId) === 'owner' && user.role !== 'owner');
      user.deletable =
        this._user.isSuperAdmin || (this._user.getRoleIn(this.botId) === 'owner' && this._user.id !== user.id);
      return user;
    });
    const newUsersToAdd = usersToAdd.map((user: any) => {
      user.addable = !usersInBot.find((userInBot: any) => userInBot.id === user.id);
      return user;
    });
    return [newUsersInBot, newUsersToAdd];
  }

  private handleOperationSuccess(isSuccess: boolean) {
    if (!isSuccess) {
      this.feedbackService.showMessage(
        this.protocolDroidService.translate(
          'misc.errors.unknown',
          'An unknown error occurred. Please contact support.'
        ),
        {
          title: this.protocolDroidService.translate('misc.modals.error-title', 'Oops...'),
          decoration: 'error'
        }
      );
    }
  }
}
