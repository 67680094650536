import { UiElementsModule } from './../ui-elements/ui-elements.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AutosizeModule } from 'ngx-autosize';

// Modules
import { ProtocolDroidModule } from '../protocol-droid/protocol-droid.module';
import { PipesModule } from '../pipes/pipes.module';

// Components
import { NodeTemplatePreviewComponent } from './node-template-preview.component';
import { BasePreviewComponent } from './shared/base-preview.component';
import { TemplateTextPreviewComponent } from './template-text-preview/template-text-preview.component';
import { TemplateButtonsPreviewComponent } from './template-buttons-preview/template-buttons-preview.component';
import { TemplateQuickRepliesPreviewComponent } from './template-quick-replies-preview/template-quick-replies-preview.component';
import { QuickReplyPreviewComponent } from './template-quick-replies-preview/quick-reply-preview/quick-reply-preview.component';
import { TemplateMediaPreviewComponent } from './template-media-preview/template-media-preview.component';
import { TemplateListPreviewComponent } from './template-list-preview/template-list-preview.component';
import { TemplateCarrouselPreviewComponent } from './template-carrousel-preview/template-carrousel-preview.component';
import { TemplateReceiptPreviewComponent } from './template-receipt-preview/template-receipt-preview.component';
import { TemplateOpenGraphPreviewComponent } from './template-open-graph-preview/template-open-graph-preview.component';
import { ButtonElementPreviewComponent } from './shared/button-element-preview/button-element-preview.component';
import { ResponseDisplayComponent } from '../components/bot-display/response-display/response-display.component';

// Services
import { OpengraphioService } from './template-open-graph-preview/opengraphio.service';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    ProtocolDroidModule,
    AutosizeModule,
    PipesModule,
    UiElementsModule
  ],
  providers: [OpengraphioService],
  entryComponents: [NodeTemplatePreviewComponent],
  declarations: [
    NodeTemplatePreviewComponent,
    BasePreviewComponent,
    TemplateTextPreviewComponent,
    TemplateQuickRepliesPreviewComponent,
    QuickReplyPreviewComponent,
    TemplateButtonsPreviewComponent,
    ButtonElementPreviewComponent,
    TemplateMediaPreviewComponent,
    TemplateListPreviewComponent,
    TemplateCarrouselPreviewComponent,
    TemplateReceiptPreviewComponent,
    TemplateOpenGraphPreviewComponent,
    ResponseDisplayComponent
  ],
  exports: [
    NodeTemplatePreviewComponent,
    TemplateTextPreviewComponent,
    TemplateQuickRepliesPreviewComponent,
    TemplateButtonsPreviewComponent,
    TemplateMediaPreviewComponent,
    TemplateListPreviewComponent,
    TemplateCarrouselPreviewComponent,
    TemplateReceiptPreviewComponent,
    TemplateOpenGraphPreviewComponent,
    ResponseDisplayComponent
  ]
})
export class NodeTemplatePreviewModule {}
