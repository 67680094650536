import { FormGroup, FormControl, Validators } from '@angular/forms';

import { ButtonElementUrl } from 'ideta-library/lib/common/node';

import { ButtonFactoryOptions } from '../button-element-form.factory';
import { RichInputFormControl } from '../../../models/rich-input-form-control.model';

export type WebviewType = 'compact' | 'tall' | 'full';

const defaultElement: ButtonElementUrl = {
  title: '',
  type: 'url',
  url: ''
};

export class ButtonUrlForm extends FormGroup {
  constructor(element: ButtonElementUrl = defaultElement, options?: ButtonFactoryOptions) {
    const formObject: any = {
      type: new FormControl(element.type, [Validators.required]),
      url: new RichInputFormControl(element.url, {
        validators: [
          Validators.required
          // Too restrictive for url provided by data key
          // Validators.pattern(
          //   /((https?|ftp):\/\/(-\.)?([^\s\/?\.#-]+\.?)+(\/[^\s]*)?)|((#{3}\w+(\.\w+)?#{3}))/i
          // )
        ]
      }),
      openNewTab: new FormControl(element.openNewTab !== undefined ? element.openNewTab : true)
    };

    if (!(options && options.removeTitle)) {
      formObject.title = new RichInputFormControl(element.title, {
        validators: [Validators.required]
      });
    }

    if (!(options && options.removeMessengerUrlOptions)) {
      formObject.messengerExtensions = new FormControl(element.messengerExtensions);
      formObject.webviewHeightRatio = new FormControl(element.webviewHeightRatio || 'tall');
    }

    super(formObject);
  }
}
