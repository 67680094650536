import { Component, OnInit } from '@angular/core';
import { take } from 'rxjs/operators';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { LuisProfile } from '../../../luis/luis-profile-connector/luis-profile-connector.component';
import { LexiconApiService } from '../../../services/lexicon-api/lexicon-api.service';
import { ProtocolDroidService } from '../../../protocol-droid/services/protocol-droid.service';
import { FeedbackService } from '../../../services/feedback/feedback.service';
import { ErrorsService } from '../../../services/errors/errors.service';
import { LexiconService } from '../../../services/lexicon/lexicon.service';

type LexiconProfile = LuisProfile;

export interface ModalLexiconOptions {
  serviceCode: string;
}

@Component({
  selector: 'app-modal-lexicon',
  templateUrl: './modal-lexicon.component.html',
  styleUrls: ['./modal-lexicon.component.scss']
})
export class ModalLexiconComponent implements OnInit {
  serviceCode: string;
  profile: any;
  errorMessage: string;
  oauth = false;
  agents: any;

  constructor(
    private modalRef: BsModalRef,
    private modalService: BsModalService,
    private lexiconService: LexiconService,
    private lexiconApiService: LexiconApiService,
    private protocolDroidService: ProtocolDroidService,
    private feedbackService: FeedbackService,
    private errorsService: ErrorsService
  ) {}

  ngOnInit() {
    this.getProfile();
    this.modalService.onHidden.pipe(take(1)).subscribe(() => {
      this.hiddenCallback();
    });
    this.lexiconApiService.fetchAgents(this.serviceCode).then((agents: any) => {
      if (agents) {
        this.agents = agents.filter((agent: any) => agent !== null);
      }
    });
  }

  getProfile() {
    this.profile = null;
    this.errorMessage = '';
    this.lexiconService
      .getCredentials(this.serviceCode)
      .pipe(take(1))
      .toPromise()
      .then((credentials: any) => (this.profile = credentials || {}))
      .catch((error: any) => {
        console.log(error);
        if (error && error.code === 'permission-denied') {
          this.errorMessage = this.protocolDroidService.translate(
            'shared.components.modals.lexicon.not-authorized-get',
            "Il semble que vous n'ayez pas les <b>droits suffisants</b> pour visualiser les" +
              ' paramètres <b>' +
              this.protocolDroidService.translate(
                'idechat.lexicon.cloudservices-list.cloudservice-' + this.serviceCode
              ) +
              "</b>. Contactez un <b>administrateur</b> du bot pour qu'il vous les fournisse.",
            {
              service: this.protocolDroidService.translate(
                'idechat.lexicon.cloudservices-list.cloudservice-' + this.serviceCode
              )
            }
          );
        } else {
          this.errorMessage = this.protocolDroidService.translate(
            'misc.errors.unknown',
            "Une erreur inconnue s'est produite. Veuillez contacter le support."
          );
        }
      });
  }

  updateProfile(profile: LexiconProfile) {
    this.hiddenCallback = () => {
      this.feedbackService
        .showSpinner()
        .then(() => this.lexiconApiService.updateCredentials(this.serviceCode, profile))
        .then(() => this.feedbackService.hideSpinner())
        .catch(this.errorsService.handlePromise());
    };
    this.modalRef.hide();
  }

  updateProfileFromOAuth(code: any) {
    this.hiddenCallback = () => {
      this.feedbackService
        .showSpinner()
        .then(() => this.lexiconApiService.updateCredentials(this.serviceCode, code))
        .then(() => this.feedbackService.hideSpinner())
        .catch(this.errorsService.handlePromise());
    };
    this.modalRef.hide();
  }

  cancel(): void {
    this.modalRef.hide();
  }

  private hiddenCallback() {} // Will be overriden at runtime
}
