import { FormGroup, FormArray, Validators } from '@angular/forms';

import { MediaElement, TemplateMedia } from 'ideta-library/lib/common/node';

import { MediaElementForm } from './media-element-form.model';
import { RichInputFormControl } from '../../models/rich-input-form-control.model';

const defaultTemplate: TemplateMedia = {
  elements: [
    {
      mediaType: 'image',
      url:
        /* tslint:disable-next-line */
        'https://res.cloudinary.com/meet-ideta/image/upload/v1582540541/IMG-20190201-WA0005_zvmg7l.jpg'

      // Farewell 'How to choose your eggs' picture
      // 'http://res.cloudinary.com/jimmythebot/image/upload' +
      // '/v1478282330/14996360_172774589850120_888900788_n_ich3by.jpg'
    }
  ]
};

export class TemplateMediaForm extends FormGroup {
  get elements(): FormArray {
    return this.get('elements') as FormArray;
  }

  constructor(template: TemplateMedia = defaultTemplate) {
    const formObject = {
      intro: new RichInputFormControl(template.intro),
      elements: new FormArray(
        (template.elements && template.elements.map((element: MediaElement) => new MediaElementForm(element))) || [],
        [Validators.required]
      )
    };

    super(formObject);
  }

  addElement() {
    this.elements.push(new MediaElementForm());
  }

  moveElement(index: number, shift: -1 | 1) {
    const element = this.elements.at(index);
    this.elements.removeAt(index);
    this.elements.insert(index + shift, element);
  }
}
