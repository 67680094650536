import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

import { BaseMappingFormComponent } from '../shared/base-mapping-form.component';

@Component({
  selector: 'app-mapping-data-input-form',
  templateUrl: './mapping-data-input-form.component.html',
  styleUrls: ['./mapping-data-input-form.component.scss']
})
export class MappingDataInputFormComponent extends BaseMappingFormComponent implements OnInit {
  get saveData(): FormControl {
    return this.parentForm.get('saveData') as FormControl;
  }

  get triggers(): FormControl {
    return this.parentForm.get('triggers') as FormControl;
  }

  get nlp(): FormControl {
    return this.parentForm.get('nlp') as FormControl;
  }

  get fallback(): FormControl {
    return this.parentForm.get('fallback') as FormControl;
  }

  constructor() {
    super();
  }

  ngOnInit() {}
}
