import { Component, OnInit, AfterViewInit, OnDestroy, Input, ElementRef } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { Observable, Subscription } from 'rxjs';

export type FlashStatus = 'success' | 'error' | 'default';

@Component({
  selector: 'app-button-flashing',
  templateUrl: './button-flashing.component.html',
  styleUrls: ['./button-flashing.component.scss'],
  animations: [
    trigger('buttonAnimation', [
      state(
        'error',
        style({
          transform: 'translateY(0)'
        })
      ),
      state(
        'default',
        style({
          transform: 'translateY(' + -100 / 3 + '%)'
        })
      ),
      state(
        'success',
        style({
          transform: 'translateY(' + (-100 / 3) * 2 + '%)'
        })
      ),
      transition('* <=> *', animate('.2s ease-out'))
    ])
  ]
})
export class ButtonFlashingComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() status$: Observable<FlashStatus>;
  @Input() delay?: number; // How long should the flash last ?
  @Input() disabled?: boolean; // Button disabled ?
  @Input() isLoading?: boolean; // Should show a spinner ?
  @Input() isSmall?: boolean; // Should use btn-sm paddings ?
  height: number;
  state: 'default' | FlashStatus;
  private subscription: Subscription;

  constructor(private elRef: ElementRef) {
    this.height = 0;
    this.state = 'default';
    this.delay = 1000;
    this.isLoading = false;
    this.isSmall = false;
  }

  ngOnInit() {
    this.subscription = this.status$.subscribe((status: FlashStatus) => {
      this.state = status;
      setTimeout(() => (this.state = 'default'), this.delay);
    });
  }

  ngAfterViewInit() {
    setTimeout(() => (this.height = this.elRef.nativeElement.clientHeight));
  }

  ngOnDestroy() {
    if (this.subscription && !this.subscription.closed) this.subscription.unsubscribe();
  }
}
