import { Injectable, OnDestroy } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
// import { Router } from '@angular/router';
import { BehaviorSubject, Subscription, Observable, of } from 'rxjs';
import { filter, map, catchError, distinctUntilChanged } from 'rxjs/operators';
import { get } from 'lodash';

import { Bot } from 'ideta-library/lib/common/bot';

import { SessionModel } from './session.model';
import { CoreSessionService } from './core-session.service';

import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BotSessionService implements SessionModel, OnDestroy {
  private _subject$: BehaviorSubject<Partial<Bot>>;
  private botSub: Subscription;
  private routerSub: Subscription;

  get subject$() {
    return this._subject$.pipe(filter(value => value && !!value.id));
  }

  get distinctSubject$() {
    return this.subject$.pipe(distinctUntilChanged((prev, curr) => prev.id === curr.id));
  }

  get value() {
    return this._subject$.value || {};
  }

  get id() {
    return this.value.id;
  }

  get name() {
    return this.value.name;
  }

  get channels() {
    return this.value.channels;
  }

  get endPointBack() {
    return this.value.endPointBack;
  }

  get defaultMappingOptions() {
    return this.value.defaultMappingOptions;
  }

  get token() {
    return this.value.token;
  }

  get useChannelDataStore() {
    return this.value.useChannelDataStore;
  }

  get useMap() {
    return this.value.useMap;
  }

  get useAutoConnect() {
    return this.value.useAutoConnect;
  }

  get exists(): boolean {
    return !!this._subject$.value;
  }

  get isDeployed(): boolean {
    const channels = this.value.channels || {};
    return !!Object.keys(channels).find((channel: string) => channels[channel].status === 'deployed');
  }

  constructor(private db: AngularFireDatabase, private _session: CoreSessionService) {
    this._subject$ = new BehaviorSubject(null);
    this.routerSub = this._session.routerEvent$.subscribe(event => {
//      console.log('event : ', event);
      if (event.botId) {
        switch (event.location) {
          case 'embedded':
          case 'web-display':
            this._subject$.next({ id: event.botId });
            break;
          default:
            this.startSession(event.botId);
            break;
        }
      } else {
        this.endSession();
      }
    });
  }

  startSession(botId: string) {
    if (botId === this.id) return;
    // ###M
    // console.log('init _bot', botId);
    if (environment.useSupportBot) window['_ibid'] = botId;
    console.log('botId : ', botId);
    this.botSub = this.getBot(botId).subscribe(bot => {
      this._subject$.next(bot);
    });
  }

  endSession() {
    if (!this.exists) return;
    // ###M
    // console.log('reset _bot');
    if (this.botSub) this.botSub.unsubscribe();
    this._subject$.next(null);
  }

  ngOnDestroy() {
    this.endSession();
    this.routerSub.unsubscribe();
  }

  isDeployedOn(channel: string) {
    if (channel === 'sandbox') return true;
    return get(this.value, `channels.${channel}.status`) === 'deployed';
  }

  getBot(botId: string): Observable<Bot> {
    return this.db
      .object('/bots/' + botId)
      .valueChanges()
      .pipe(
        filter((bot: any) => !!bot),
        map((bot: any) => ({ ...bot, id: botId })),
        catchError((error: any) => {
          if (error && error.code === 'PERMISSION_DENIED') {
            console.log('Permission denied for bot', botId);
          } else {
            console.error(error);
          }
          return of(null);
        })
      );
  }
}
