import { FormGroup, FormControl, Validators } from '@angular/forms';
import { get } from 'lodash';

import { FallbackOptions } from 'ideta-library/lib/common/node';

import { RichInputFormControl } from '../../../models/rich-input-form-control.model';
import { NodeKeyFormControl } from '../../../models/node-key-form-control.model';
import { urlOrKeyValidator } from '../../../models/custom-validators';

const defaultOptions = { active: false };

export class FallbackForm extends FormGroup {
  constructor(fallback: FallbackOptions = defaultOptions) {
    const formGroup = {
      active: new FormControl(fallback.active, { updateOn: 'change' }),
      options: new FormGroup({
        type: new FormControl(get(fallback, 'options.type', 'go-to-node'), [Validators.required]),
        targetNode: new NodeKeyFormControl(get(fallback, 'options.targetNode'), [Validators.required]),
        message: new RichInputFormControl(get(fallback, 'options.message'), {
          validators: [Validators.required]
        }),
        url: new FormControl(get(fallback, 'options.url'), [Validators.required, urlOrKeyValidator])
      })
    };

    super(formGroup);
    this.switchControlsDisabling();
  }

  enable(options?: { onlySelf?: boolean; emitEvent?: boolean }): void {
    super.enable(options);
    this.switchControlsDisabling();
  }

  updateActive(active: boolean) {
    this.get('active').patchValue(active, { emitViewToModelChange: false, emitEvent: false });
    this.switchControlsDisabling();
  }

  switchControlsDisabling() {
    if (this.get('active').value) {
      this.get('options').enable({ emitEvent: false });
      this.get('options.message').disable({ emitEvent: false });
      this.get('options.url').disable({ emitEvent: false });
      if (this.get('options.type').value === 'message') {
        this.get('options.message').enable({ emitEvent: false });
      } else if (this.get('options.type').value === 'ai') {
        this.get('options.url').enable({ emitEvent: false });
      }
    } else {
      this.get('options').disable({ emitEvent: false });
    }
    this.updateValueAndValidity();
  }
}
