import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-auto-reply-parameters',
  templateUrl: './auto-reply-parameters.component.html',
  styleUrls: ['./auto-reply-parameters.component.scss']
})
export class AutoReplyParametersComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
