import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { trigger, state, style as animStyle, transition, animate } from '@angular/animations';
import { get } from 'lodash';

import { MessageTemplateCarrousel } from 'ideta-library/lib/common/message';

import { BasePreviewComponent } from '../shared/base-preview.component';
import { ColorService } from '../../services/color/color.service';

@Component({
  selector: 'app-template-carrousel-preview',
  templateUrl: './template-carrousel-preview.component.html',
  styleUrls: ['./template-carrousel-preview.component.scss'],
  animations: [
    trigger('buttonAnim', [
      state('out', animStyle({})),
      state('in', animStyle({ 'background-color': '{{bgColor}}' }), {
        params: { bgColor: 'black' }
      }),
      transition('in => out', animate('0.2s')),
      transition('out => in', animate('0.2s')),
      transition(':enter', [animStyle({ opacity: 0 }), animate('0.3s', animStyle({ opacity: 1 }))]),
      transition(':leave', [animate('0.3s', animStyle({ opacity: 0 }))])
    ])
  ]
})
export class TemplateCarrouselPreviewComponent extends BasePreviewComponent implements OnInit {
  @ViewChild('elementContainer', { static: false }) elementContainer: ElementRef;
  leftScrollDisabled: boolean;
  rightScrollDisabled: boolean;
  currentFocus: number;
  leftState: string;
  rightState: string;
  private disabledArrows: boolean;

  get template() {
    return this.message.template as MessageTemplateCarrousel;
  }

  get elementsLength(): number {
    return get(this.template, 'elements.length', 0);
  }

  get elementsSize() {
    return this.template.size || 'tall';
  }

  get elementStyle(): any {
    const style = {};
    style['background-color'] = this.displayOptions.botColors.bubble;
    style['border-color'] = this.displayOptions.botColors.bubble;
    style['width'] = '100%';
    if (this.elementsLength > 1) {
      style['width'] = '76%';
    }

    return style;
  }

  get containerScroll() {
    return { 'overflow-x': this.elementsLength > 1 ? 'auto' : 'hidden' };
  }

  get isAutoGen(): boolean {
    return this.displayOptions.context === 'preview' && this.template.feedType !== 'manual';
  }

  constructor(private colorService: ColorService) {
    super();
    this.leftScrollDisabled = true;
    this.rightScrollDisabled = true;
    this.currentFocus = 1;
    this.leftState = 'out';
    this.rightState = 'out';
  }

  ngOnInit() {
    if (this.elementsLength > 1) {
      this.rightScrollDisabled = false;
    }
  }

  buttonColors(direction: string): any {
    const style = { color: this.displayOptions.botColors.text };
    switch (direction) {
      case 'left':
        style['background-image'] =
          'linear-gradient(-90deg, ' +
          this.colorService.hexToRGB(this.displayOptions.botColors.bubble, 0) +
          ', ' +
          this.colorService.hexToRGB(this.displayOptions.botColors.bubble, 1) +
          ')';
        break;
      case 'right':
        style['background-image'] =
          'linear-gradient(90deg, ' +
          this.colorService.hexToRGB(this.displayOptions.botColors.bubble, 0) +
          ', ' +
          this.colorService.hexToRGB(this.displayOptions.botColors.bubble, 1) +
          ')';
        break;
    }
    return style;
  }

  over(enter: boolean, direction: string) {
    switch (direction) {
      case 'left':
        if (enter) {
          this.leftState = 'in';
        } else {
          this.leftState = 'out';
        }
        break;
      case 'right':
        if (enter) {
          this.rightState = 'in';
        } else {
          this.rightState = 'out';
        }
        break;
    }
  }

  onClick(direction: string): void {
    switch (direction) {
      case 'left':
        this.focusElement(this.currentFocus - 1);
        break;
      case 'right':
        this.focusElement(this.currentFocus + 1);
        break;
    }
  }

  trackByFn(index: number): number {
    return index;
  }

  onScroll() {
    if (this.disabledArrows && this.elementContainer.nativeElement.scrollLeft === 0) {
      this.disabledArrows = this.rightScrollDisabled = false;
      this.currentFocus = 1;
    } else if (!this.disabledArrows) {
      this.disabledArrows = this.leftScrollDisabled = this.rightScrollDisabled = true;
    }
  }

  private focusElement(index: number): void {
    const container = this.elementContainer.nativeElement;
    const unitWidth = container.scrollWidth / this.elementsLength;

    if (index >= 1 && index <= this.template.elements.length) {
      this.disabledArrows = true;
      container.scrollLeft = (index - 1) * unitWidth - 0.14 * unitWidth;
      this.currentFocus = index;
      setTimeout(() => (this.disabledArrows = false), 50);
    }

    this.leftScrollDisabled = this.currentFocus === 1;
    if (this.leftScrollDisabled) {
      this.leftState = 'out';
    }
    this.rightScrollDisabled = this.currentFocus === this.elementsLength;
    if (this.rightScrollDisabled) {
      this.rightState = 'out';
    }
  }
}
