import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AngularFireDatabase, AngularFireAction, DatabaseSnapshot } from '@angular/fire/database';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Message } from 'ideta-library/lib/common/message';

import { BotSessionService } from '../session/bot-session.service';
import { ChannelSessionService } from '../session/channel-session.service';

@Injectable({
  providedIn: 'root'
})
export class MessageService {
  constructor(
    private http: HttpClient,
    private db: AngularFireDatabase,
    private _bot: BotSessionService,
    private _channel: ChannelSessionService
  ) {}

  public readAll(conversationId: string, length: number = 50): Observable<Message[]> {
    return this.db
      .list(`/messages/${this._bot.id}/${this._channel.type}/${conversationId}`, ref => ref.limitToLast(length))
      .snapshotChanges()
      .pipe(
        map((changes: AngularFireAction<DatabaseSnapshot<any>>[]) => {
          return changes.map(c => {
            return { id: c.payload.key, ...c.payload.val() };
          });
        })
      );
  }

  public deleteAll(conversationIdList: string[] = []): Promise<any> {
    return this.http
      .post(`${this._bot.endPointBack}/messages/delete/${this._channel.type}`, {
        botId: this._bot.id,
        conversationIdList
      })
      .toPromise();
  }
}
