import { FormGroup, FormControl } from '@angular/forms';

import { DataAsyncAction } from 'ideta-library/lib/common/data';

import {
  AsyncActionForm,
  defaultAction
} from '../../../../../ui-elements/components/async-action-form/async-action-form.model';

const defaultDataAction: DataAsyncAction = {
  trigger: 'write',
  action: defaultAction()
};

export class DataAsyncActionForm extends FormGroup {
  constructor(dataAction: DataAsyncAction = defaultDataAction) {
    const formGroup: any = {
      trigger: new FormControl(dataAction.trigger || 'write'),
      action: new AsyncActionForm(dataAction.action)
    };

    super(formGroup);
  }
}
