import { Component, OnInit, Input } from '@angular/core';
import { FormControl, FormArray } from '@angular/forms';

import { PersistentMenuForm } from './persistent-menu-form.model';
import { PersistentMenuButtonForm } from './persistent-menu-button-form/persistent-menu-button-form.model';

@Component({
  selector: 'app-persistent-menu-form',
  templateUrl: './persistent-menu-form.component.html',
  styleUrls: ['./persistent-menu-form.component.scss']
})
export class PersistentMenuFormComponent implements OnInit {
  @Input() parentForm: PersistentMenuForm;
  maxButtons: number;

  get disableInput(): FormControl {
    return this.parentForm.get('disableInput') as FormControl;
  }

  get buttons(): FormArray {
    return this.parentForm.get('buttons') as FormArray;
  }

  constructor() {}

  ngOnInit() {
    this.maxButtons = 3;
  }

  getButton(index: number): PersistentMenuButtonForm {
    return this.buttons.at(index) as PersistentMenuButtonForm;
  }

  addButton() {
    if (this.buttons.length < this.maxButtons) {
      this.buttons.push(new PersistentMenuButtonForm());
    }
  }

  removeButton(index: number) {
    this.buttons.removeAt(index);
  }

  moveButton(index: number, shift: -1 | 1) {
    const button = this.buttons.at(index);
    this.buttons.removeAt(index);
    this.buttons.insert(index + shift, button);
  }

  trackByFn(index: number): number {
    return index;
  }
}
