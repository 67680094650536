import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BotSessionService } from './../../../services/session/bot-session.service';
import { FrontChannel } from '../../../../shared/services/session/channel-session.service';

import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-deployment-selection',
  templateUrl: './deployment-selection.component.html',
  styleUrls: ['./deployment-selection.component.scss']
})
export class DeploymentSelectionComponent implements OnInit {
  @Input() deploymentsList: FrontChannel[];
  @Input() activeDeployment: FrontChannel;
  @Input() compact?: boolean;
  @Output() select: EventEmitter<any>;

  constructor(private _bot: BotSessionService) {
    this.deploymentsList = environment.deploymentServices;
    this.select = new EventEmitter<any>();
    this.compact = false;
  }

  ngOnInit() {}

  selectDeployment(deployment: any): void {
    this.select.emit(deployment);
  }

  isActive(deployment: any): boolean {
    return (
      deployment.type === 'sandbox' ||
      (this._bot.channels &&
        this._bot.channels[deployment.type] &&
        this._bot.channels[deployment.type].status === 'deployed')
    );
  }
}
