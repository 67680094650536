import { Component, OnInit } from '@angular/core';

import { ConversationSessionService } from '../../shared/services/session/conversation-session.service';

@Component({
  selector: 'app-embedded-web-display',
  templateUrl: './embedded-web-display.component.html',
  styleUrls: ['./embedded-web-display.component.scss']
})
export class EmbeddedWebDisplayComponent implements OnInit {
  constructor(private _conversation: ConversationSessionService) {}

  ngOnInit() {
    this._conversation.isEmbedded = true;
  }
}
