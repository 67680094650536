import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { AllowedType } from 'ideta-library/lib/common/data';

import {
  MenuItem,
  SelectedDataStoreElem,
  DataStoreElemPreview,
  SelectableItems
} from '../../../models/data-selector.component';
import { formats } from '../../../models/regex-formats.model';

@Component({
  selector: 'app-data-keys-dropdown-menu-list',
  templateUrl: './data-keys-dropdown-menu-list.component.html',
  styleUrls: ['./data-keys-dropdown-menu-list.component.scss']
})
export class DataKeysDropdownMenuListComponent implements OnInit {
  @Input() dataKeysList: MenuItem[];
  @Input() hideAddButton: boolean;
  @Input() isElement: boolean;
  @Input() allowedTypes: AllowedType[];
  @Output() selectKey: EventEmitter<SelectedDataStoreElem>;
  @Output() createKey: EventEmitter<DataStoreElemPreview>;

  constructor() {
    this.createKey = new EventEmitter();
    this.selectKey = new EventEmitter();
    this.isElement = false;
  }

  ngOnInit() {}

  create(key: string): void {
    this.createKey.emit({ key });
  }

  select(event: any, dataKey: MenuItem, property?: MenuItem, isArrayProperty?: boolean): void {
    if ((property && property.isAllowed) || (!property && dataKey.isAllowed)) {
      this.selectKey.emit({
        id: this.buildSelectionAddress(dataKey, property, isArrayProperty),
        key: this.buildSelectionName(dataKey, property, isArrayProperty),
        type: property ? property.type : dataKey.type,
        scope: dataKey.scope,
        keys: dataKey.keys,
        elements: dataKey.elements,
        isElement: this.isElement
      });
    } else {
      event.stopPropagation();
    }
  }

  hasItems(dataKey: MenuItem): boolean {
    return dataKey.selectableItems && !!dataKey.selectableItems.items;
  }

  hasElementItems(dataKey: MenuItem): boolean {
    return (
      dataKey.selectableItems &&
      dataKey.selectableItems.elementItems &&
      (this.allowedTypes.indexOf('content') > -1 || this.allowedTypes.indexOf('element') > -1)
    );
  }

  trackById(_index: number, item: any): string {
    return item.id;
  }

  hasIndex(selectables: SelectableItems): boolean {
    const value = parseFloat(selectables.index);
    const isNumericalValue = value >= 0 && value % 1 === 0;
    const isDataInterpolation = formats.test('indexedData', selectables.index);
    return isNumericalValue || isDataInterpolation;
  }

  private buildSelectionAddress(dataKey: MenuItem, property?: MenuItem, isArrayProperty?: boolean) {
    return formats.buildAddress(
      dataKey.id,
      property && property.id !== '_element' ? property.id + '' : null,
      isArrayProperty ? dataKey.selectableItems.index : null
    );
  }

  private buildSelectionName(dataKey: MenuItem, property?: MenuItem, isArrayProperty?: boolean) {
    return formats.buildDisplayedName(
      dataKey.key,
      property && property.id !== '_element' ? property.key : null,
      isArrayProperty ? dataKey.selectableItems.index : null
    );
  }

  // Not used for now, to keep if needed
  // hidePropertyDropdown(id: string): void {
  //   const list = document.getElementById(id);
  //   switch (list.style.display) {
  //     case 'block':
  //       list.style.display = 'none';
  //       break;
  //     case 'none':
  //       list.style.display = 'block';
  //       break;
  //   }
  // }
}
