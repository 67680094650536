import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConversationService } from '../conversation/conversation.service';
import { BotSessionService } from '../session/bot-session.service';
import { ChannelSessionService } from '../session/channel-session.service';

/*
 * NOTE : Functions where 'backendUrl' is specified as an argument,
 *        are functions that may be called in web-display
 */

@Injectable({
  providedIn: 'root'
})
export class BotBackService {
  private undefinedConversationIdError: Error;

  constructor(
    private conversationService: ConversationService,
    private http: HttpClient,
    private _bot: BotSessionService,
    private _channel: ChannelSessionService
  ) {
    this.undefinedConversationIdError = new Error('Conversation ID is undefined');
  }

  sendMessageToConversation(
    conversationId: string,
    payload: {
      type: string;
      [key: string]: any;
    }
  ): Promise<any> {
    if (!conversationId) throw this.undefinedConversationIdError;
    return this.http
      .post(`${this._bot.endPointBack}/messages/send/${this._channel.type}`, {
        botId: this._bot.id,
        conversationId,
        payload,
        token: this._bot.token
      })
      .toPromise();
  }

  sendMessageToBot(conversationId: string, message: any, routeParams: any = {}): Promise<any> {
    if (!conversationId) throw this.undefinedConversationIdError;
    const params = Object.keys(routeParams).reduce((prev: any, curr: any) => {
      prev[curr] = routeParams[curr].value;
      return prev;
    }, {});
    return this.http
      .post(`${this._bot.endPointBack}/messages/receive/${this._channel.type}`, {
        botId: this._bot.id,
        conversationId,
        message,
        params
      })
      .toPromise();
  }

  restartConversation(conversationId: string, remove: boolean, routeParams: any = {}): Promise<any> {
    if (!conversationId) throw this.undefinedConversationIdError;
    const promise: Promise<void> = remove
      ? this.conversationService.resetConversation(conversationId).toPromise()
      : Promise.resolve();

    return promise.then(() =>
      this.resumeConversation(conversationId, {
        ref: { value: 'noeud_0' },
        restart: { value: 'true' },
        ...routeParams
      })
    );
  }

  resumeConversation(conversationId: string, routeParams: any = {}): Promise<any> {
    if (!conversationId) throw this.undefinedConversationIdError;
    const params = Object.keys(routeParams).reduce((prev: any, curr: any) => {
      prev[curr] = routeParams[curr].value;
      return prev;
    }, {});
    return this.http
      .post(`${this._bot.endPointBack}/messages/resume/${this._channel.type}`, {
        botId: this._bot.id,
        conversationId,
        params
      })
      .toPromise();
  }
}
