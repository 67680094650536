import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import { FeedbackSpinnerComponent } from '../../components/feedback/feedback-spinner/feedback-spinner.component';
import { FeedbackMessageComponent } from '../../components/feedback/feedback-message/feedback-message.component';

export type Decoration = 'none' | 'success' | 'info' | 'warning' | 'error';

@Injectable({
  providedIn: 'root'
})
export class FeedbackService {
  private spinnerRef: BsModalRef;
  private messageRef: BsModalRef;

  constructor(private modalService: BsModalService, private sanitizer: DomSanitizer) {}

  public showSpinner(): Promise<void> {
    return new Promise((resolve: any) => {
      const spinnerOptions = {
        class: 'modal-dialog-centered modal-spinner',
        keyboard: false,
        ignoreBackdropClick: true,
        initialState: {
          shownHandler: () => {
            setTimeout(() => {
              resolve();
            }, 1000);
          }
        }
      };

      this.spinnerRef = this.modalService.show(FeedbackSpinnerComponent, spinnerOptions);
    });
  }

  public hideSpinner(): Promise<void> {
    if (this.spinnerRef) {
      return new Promise((resolve: any) => {
        this.spinnerRef.content.hiddenHandler = () => {
          setTimeout(() => {
            resolve();
          });
        };
        this.spinnerRef.hide();
      });
    } else return Promise.resolve();
  }

  public showMessage(
    message: string,
    options: { type?: 'message' | 'confirm'; decoration?: Decoration; title?: string } = {}
  ): Promise<boolean> {
    const { type = 'message', decoration = 'none', title } = options;

    const modalOptions = {
      class: `modal-dialog-centered`,
      ignoreBackdropClick: true,
      initialState: {
        title,
        message: this.sanitizer.bypassSecurityTrustHtml(message),
        type,
        decoration
      }
    };

    this.messageRef = this.modalService.show(FeedbackMessageComponent, modalOptions);

    return new Promise((resolve: any) => {
      this.messageRef.content.callback = (isConfirmed: boolean) => {
        resolve(isConfirmed);
        this.messageRef.hide();
      };
    });
  }

  public hideMessage(): void {
    if (this.messageRef) {
      this.messageRef.hide();
    }
  }
}
