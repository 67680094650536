import { Pipe, PipeTransform } from '@angular/core';
import { formats } from '../models/regex-formats.model';
import { DataStoreSessionService } from '../services/session/data-store-session.service';

@Pipe({
  name: 'botText'
})
export class BotTextPipe implements PipeTransform {
  constructor(private _dataStore: DataStoreSessionService) {}

  transform(value: string): any {
    return this.handleDataReplacement((value || '').replace(/\n|\\n/g, '<br>'));
  }

  handleDataReplacement(input: string) {
    const data = input.match(formats.regex('chainTaggedData'));
    if (!data) return input;

    data.forEach((key: string) => {
      const { subject, property } = formats.parseAddress(key.slice(3, -3));
      const dataElem = this._dataStore.getDataStoreElem(subject);
      input = input.replace(
        key,
        `<span class="preview-key">${dataElem ? dataElem.key : 'key'}${
          property ? '...' : ''
        }</span>`
      );
    });
    return input;
  }
}
