import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { get } from 'lodash';

import { BotNode, NodeMapping, NodeTemplate } from 'ideta-library/lib/common/node';

import { ProtocolDroidService } from '../../protocol-droid/services/protocol-droid.service';
import { environment } from '../../../../environments/environment';
import { BotSessionService } from '../session/bot-session.service';
import { UserSessionService } from '../session/user-session.service';

@Injectable({
  providedIn: 'root'
})
export class NodeService {
  constructor(
    private db: AngularFireDatabase,
    private protocolDroidService: ProtocolDroidService,
    private _bot: BotSessionService,
    private _user: UserSessionService
  ) {}

  countNodes(): Observable<number> {
    return this.db
      .object(`/nodes/${this._bot.id}/sandbox`)
      .valueChanges()
      .pipe(map((sandbox: any) => Object.keys(sandbox || {}).length));
  }

  getNodeType(node: any): string {
    return node.template.type[0].toUpperCase() + node.template.type.slice(1);
  }

  getNode(nodeKey: string): Observable<BotNode> {
    return this.db
      .object<BotNode>(`/nodes/${this._bot.id}/sandbox/${nodeKey}`)
      .valueChanges()
      .pipe(map((node: any) => (node ? { key: nodeKey, ...node } : null)));
  }

  getNodeName(nodeKey: string): Observable<string> {
    return this.db.object<string>(`/nodes/${this._bot.id}/sandbox/${nodeKey}/name`).valueChanges();
  }

  updateNodeName(nodeKey: string, name: string): Promise<void> {
    return this.db.object(`/nodes/${this._bot.id}/sandbox/${nodeKey}`).update({ name });
  }

  getNodeTemplate(nodeKey: string): Observable<NodeTemplate> {
    return this.db.object<NodeTemplate>(`/nodes/${this._bot.id}/sandbox/${nodeKey}/template`).valueChanges();
  }

  updateNodeTemplate(nodeKey: string, template: Partial<NodeTemplate>): Promise<void> {
    return this.db.object(`/nodes/${this._bot.id}/sandbox/${nodeKey}/template`).update(template);
  }

  getNodeMapping(nodeKey: string): Observable<NodeMapping> {
    return this.db.object<NodeMapping>(`/nodes/${this._bot.id}/sandbox/${nodeKey}/mapping`).valueChanges();
  }

  updateNodeMapping(nodeKey: string, mapping: Partial<NodeMapping>): Promise<void> {
    return this.db.object(`/nodes/${this._bot.id}/sandbox/${nodeKey}`).update({ mapping });
  }

  createNode(defaultMappingOptions: any, node: any = {}): any {
    return new Promise((resolve: any, reject: any) => {
      this.db
        .list(`/nodes/${this._bot.id}/sandbox`)
        .push(this.getDefaultNode(defaultMappingOptions, node))
        .then((ref: any) => resolve(ref.key), reject);
    });
  }

  updateNode(node: Partial<BotNode>): Promise<boolean> {
    return this.db
      .object(`/nodes/${this._bot.id}/sandbox/${node.key}`)
      .update(node)
      .then(() => true)
      .catch((error: any) => {
        console.error(error);
        return false;
      });
  }

  deleteNode(nodeKey: string): Promise<boolean> {
    return this.db
      .object(`/nodes/${this._bot.id}/sandbox/${nodeKey}`)
      .remove()
      .then(() => true)
      .catch((error: any) => {
        console.error(error);
        return false;
      });
  }

  buildDicoNodes(nodes: any[]): any {
    return (
      (nodes &&
        nodes.reduce(
          (acc: any, curr: any) => (acc[curr.key] = curr.name || environment.defaults.naming.noNameNode) && acc,
          {}
        )) ||
      {}
    );
  }

  updateNodesCategory(value: string, nodeKeys: string[]): Promise<void> {
    const updateObject = nodeKeys.reduce((prev, curr) => ({ ...prev, [curr + '/category']: value }), {});
    return this.db.object(`/nodes/${this._bot.id}/sandbox`).update(updateObject);
  }

  hasNlp(mapping: any): boolean {
    return (
      mapping.type === 'data-input' &&
      get(mapping, 'dataInput.nlp.active', false) &&
      !!get(mapping, 'dataInput.nlp.options.intents.length', 0)
    );
  }

  hasApiStorages(mapping: any): boolean {
    return (
      mapping.type === 'switch' &&
      get(mapping, 'switch.sendToExternalApi.active', false) &&
      get(mapping, 'switch.sendToExternalApi.options.data.active', false) &&
      !!get(mapping, 'switch.sendToExternalApi.options.data.key', '') &&
      !!Object.keys(get(mapping, 'switch.sendToExternalApi.options.data.mapping', {})).length
    );
  }

  private getDefaultNode(defaultMappingOptions: any, node: any = {}): BotNode {
    const { behaviorGeoloc = {}, behaviorMedia = {} } = defaultMappingOptions;
    return {
      name:
        'Bulle-' +
        Math.random()
          .toString(36)
          .substring(2, 8),
      createdAt: new Date().toISOString(),
      createdBy: this._user.id,
      template: {
        type: 'text',
        templateText: {
          text: this.protocolDroidService.translate('shared.services.node.new-bubble', 'I am a new bubble')
        }
      },
      mapping: {
        type: 'data-input',
        options: {
          behaviorGeoloc: {
            active: false,
            options: behaviorGeoloc.options || null
          },
          behaviorMedia: {
            active: false,
            options: behaviorMedia.options || null
          }
        },
        dataInput: {}
      },
      ...node
    };
  }
}
