import { FormControl, ValidatorFn, AbstractControlOptions } from '@angular/forms';
import { get } from 'lodash';

interface Options {
  expanded: boolean;
}

export class CachedFormControl extends FormControl {
  initialValue: string;
  expanded: boolean;

  constructor(
    value?: any,
    validators?: ValidatorFn | ValidatorFn[] | AbstractControlOptions,
    options?: Options
  ) {
    super(value, validators);
    this.initialValue = typeof value === 'object' ? value.value : value;
    this.expanded = get(options, 'expanded', false);
  }

  resetInitialValue(): void {
    this.initialValue = this.value;
  }
}
