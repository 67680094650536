import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl } from '@angular/forms';

import { BaseTemplateFormComponent } from '../shared/base-template-form.component';

@Component({
  selector: 'app-template-buttons-form',
  templateUrl: './template-buttons-form.component.html',
  styleUrls: ['./template-buttons-form.component.scss']
})
export class TemplateButtonsFormComponent extends BaseTemplateFormComponent implements OnInit {
  maxButtons: number;

  get text(): FormControl {
    return this.parentForm.get('text') as FormControl;
  }

  get buttons(): FormArray {
    return this.parentForm.get('buttons') as FormArray;
  }

  constructor() {
    super();
    this.maxButtons = 4;
  }

  ngOnInit() {}
}
