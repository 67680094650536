import { Component, OnInit, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import { MappingOptionsForm } from './mapping-options-form.model';
import { BaseMappingFormComponent } from '../shared/base-mapping-form.component';

@Component({
  selector: 'app-mapping-options-form',
  templateUrl: './mapping-options-form.component.html',
  styleUrls: ['./mapping-options-form.component.scss']
})
export class MappingOptionsFormComponent extends BaseMappingFormComponent implements OnInit {
  @Input() parentForm: MappingOptionsForm;
  @Input() allowCurrentNodeSelection: boolean;

  get behaviorMedia(): FormGroup {
    return this.parentForm.get('behaviorMedia') as FormGroup;
  }

  get behaviorGeoloc(): FormGroup {
    return this.parentForm.get('behaviorGeoloc') as FormGroup;
  }

  get isSystemNode(): FormControl {
    return this.parentForm.get('isSystemNode') as FormControl;
  }

  constructor() {
    super();
    this.allowCurrentNodeSelection = true;
  }

  ngOnInit() {}
}
