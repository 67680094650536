import { FormGroup, FormControl, Validators } from '@angular/forms';
import { get } from 'lodash';

import { SaveDataOptions } from 'ideta-library/lib/common/node';

import { DataKeyFormControl } from '../../../models/data-key-form-control.model';
import { NodeKeyFormControl } from '../../../models/node-key-form-control.model';

const defaultOptions = { active: false };

export class SaveDataForm extends FormGroup {
  constructor(saveData: SaveDataOptions = defaultOptions) {
    const formGroup = {
      active: new FormControl(saveData.active, { updateOn: 'change' }),
      options: new FormGroup({
        key: new DataKeyFormControl(get(saveData, 'options.key'), [Validators.required]),
        targetNode: new NodeKeyFormControl(get(saveData, 'options.targetNode'), [Validators.required]),
        formatCheck: new FormControl(get(saveData, 'options.formatCheck')),
        customFormat: new FormControl(get(saveData, 'options.customFormat'), [Validators.required]),
        dateDisplay: new FormControl(get(saveData, 'options.dateDisplay', 'asInput')),
        dateInput: new FormControl(get(saveData, 'options.dateInput', 'dd-mm-yyyy')),
        dateOutput: new FormControl(get(saveData, 'options.dateOutput', 'iso8601')),
        targetNodeIfCheckFails: new NodeKeyFormControl(get(saveData, 'options.targetNodeIfCheckFails'), [
          Validators.required
        ])
      })
    };

    super(formGroup);
    this.switchControlsDisabling();
  }

  enable(options?: { onlySelf?: boolean; emitEvent?: boolean }): void {
    super.enable(options);
    this.switchControlsDisabling();
  }

  updateActive(active: boolean) {
    this.get('active').patchValue(active, { emitViewToModelChange: false, emitEvent: false });
    this.switchControlsDisabling();
  }

  updateFormatCheck(formatCheck: boolean) {
    this.get('options.formatCheck').patchValue(formatCheck, {
      emitViewToModelChange: false,
      emitEvent: false
    });
    this.switchControlsDisabling();
  }

  switchControlsDisabling() {
    if (this.get('active').value) {
      this.get('options').enable({ emitEvent: false });
      const formatCheck = this.get('options.formatCheck').value;
      if (formatCheck && formatCheck !== 'none') {
        this.get('options.dateDisplay').disable({ emitEvent: false });
        this.get('options.dateInput').disable({ emitEvent: false });
        this.get('options.dateOutput').disable({ emitEvent: false });
        this.get('options.customFormat').disable({ emitEvent: false });
        this.get('options.targetNodeIfCheckFails').enable({ emitEvent: false });
        if (formatCheck === 'custom') {
          this.get('options.customFormat').enable({ emitEvent: false });
        } else if (formatCheck === 'date') {
          this.get('options.dateDisplay').enable({ emitEvent: false });
          this.get('options.dateInput').enable({ emitEvent: false });
          this.get('options.dateOutput').enable({ emitEvent: false });
        }
      } else {
        this.get('options.dateDisplay').disable({ emitEvent: false });
        this.get('options.dateInput').disable({ emitEvent: false });
        this.get('options.dateOutput').disable({ emitEvent: false });
        this.get('options.customFormat').disable({ emitEvent: false });
        this.get('options.targetNodeIfCheckFails').disable({ emitEvent: false });
      }
    } else {
      this.get('options').disable({ emitEvent: false });
    }
    this.updateValueAndValidity();
  }
}
