import { Component, Input, EventEmitter, Output, OnDestroy } from '@angular/core';
import { FormGroup, FormArray } from '@angular/forms';
import { Subscription } from 'rxjs';

import { KeyCreationEvent } from '../../models/data-selector.component';

@Component({
  selector: 'app-base-template-form',
  template: ''
})
export class BaseTemplateFormComponent implements OnDestroy {
  @Input() parentForm: FormGroup | FormArray;
  @Output() createNode: EventEmitter<{
    name: string;
    callback: (key: string) => void;
  }>;
  @Output() createDataKey: EventEmitter<KeyCreationEvent>;
  protected subscriptions: Subscription[];

  constructor() {
    this.createNode = new EventEmitter();
    this.createDataKey = new EventEmitter();
    this.subscriptions = [];
  }

  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
    }
  }
}
