import { FormGroup } from '@angular/forms';

import { NodeKeyFormControl } from '../../models/node-key-form-control.model';

export interface GetStarted {
  payload: string; // Stringified JSON
}

export class GetStartedForm extends FormGroup {
  constructor(profile: any) {
    const targetNode = GetStartedForm.parsePayload(profile);

    const formGroup = {
      targetNode: new NodeKeyFormControl(targetNode, [], false)
    };

    super(formGroup);
  }

  private static parsePayload(profile: any): string {
    try {
      return JSON.parse(profile.payload).targetNode;
    } catch {
      return null;
    }
  }

  getProfileValue(): GetStarted {
    return {
      payload: JSON.stringify(this.value)
    };
  }

  setProfileValue(value: any) {
    const targetNode = GetStartedForm.parsePayload(value);

    this.get('targetNode').patchValue(targetNode);
  }
}
