import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  ViewChild,
  Output,
  EventEmitter,
  ElementRef
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject, Subscription } from 'rxjs';

import { UserStorageService } from '../../../services/user-storage/user-storage.service';

type UploadStatus = 'idle' | 'success' | 'error';

@Component({
  selector: 'app-inline-media-upload',
  templateUrl: './inline-media-upload.component.html',
  styleUrls: ['./inline-media-upload.component.scss']
})
export class InlineMediaUploadComponent implements OnInit, OnDestroy {
  @ViewChild('fileInput', { static: true }) fileInput: ElementRef;
  @Input() control: FormControl;
  @Input() type: 'image' | 'video' | 'audio';
  @Output() beforeUpdate: EventEmitter<string>;
  @Output() afterUpdate: EventEmitter<string>;
  status$: Subject<UploadStatus>;
  status: UploadStatus;
  isUploading: boolean;
  uniqueId: string;
  private subscription: Subscription;

  get whitelist(): string {
    switch (this.type) {
      case 'image':
        return ['image/jpg', 'image/jpeg', 'image/png', 'image/gif'].join(',');
      case 'video':
        return ['mp4', 'webm', 'ogg'].join(',');
      case 'audio':
        return ['mp3', 'wav', 'ogg', 'flac'].join(',');
      default:
        return '';
    }
  }

  constructor(private userStorageService: UserStorageService) {
    this.beforeUpdate = new EventEmitter();
    this.afterUpdate = new EventEmitter();
    this.status$ = new Subject();
    this.isUploading = false;
    this.status = 'idle';
  }

  ngOnInit() {
    this.uniqueId =
      'file-' +
      Math.random()
        .toString(36)
        .substring(2, 8);

    this.subscription = this.status$.subscribe(status => {
      this.status = status;
      setTimeout(() => (this.status = 'idle'), 1000);
    });
  }

  ngOnDestroy() {
    if (this.subscription && !this.subscription.closed) this.subscription.unsubscribe();
  }

  handleFileSelection(files: FileList): void {
    if (files.length > 0) {
      const bucket = this.type ? this.type + 's' : 'misc';
      const file = files[0];
      const path = `media/${bucket}/${file.name}`;

      this.isUploading = true;

      this.userStorageService
        .setFile(`media/${bucket}/${file.name}`, file)
        .then(() => this.userStorageService.getFileUrl(path))
        .then(url => {
          this.beforeUpdate.emit(url);
          this.control.patchValue(url);
          this.afterUpdate.emit(url);
          this.fileInput.nativeElement.value = '';
          this.status$.next('success');
        })
        .catch(() => this.status$.next('error'))
        .then(() => (this.isUploading = false));
    }
  }
}
