import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { filter, distinctUntilChanged } from 'rxjs/operators';
import { Location } from '@angular/common';

import { SessionModel } from './session.model';
import { CoreSessionService } from '../../services/session/core-session.service';

import { environment } from '../../../../environments/environment';

export interface FrontChannel {
  name: string;
  type: string;
}

@Injectable({
  providedIn: 'root'
})
export class ChannelSessionService implements SessionModel, OnDestroy {
  private _subject$: BehaviorSubject<FrontChannel>;
  private routerSub: Subscription;

  public get subject$() {
    return this._subject$.pipe(filter(channel => !!channel));
  }

  public get distinctSubject$() {
    return this.subject$.pipe(distinctUntilChanged((prev, curr) => prev.type === curr.type));
  }

  public get value(): Partial<FrontChannel> {
    return this._subject$.value || {};
  }

  public get type() {
    return this.value.type;
  }

  public get name() {
    return this.value.name;
  }

  public get extists() {
    return !!this._subject$.value;
  }

  constructor(private location: Location, private _session: CoreSessionService) {
    this._subject$ = new BehaviorSubject(null);
    this.routerSub = this._session.routerEvent$.subscribe(event => {
      if (event.botId) {
        switch (event.location) {
          case 'accounts':
            this.startSession('web');
            break;
          case 'embedded':
          case 'web-display':
            this.startSession('web');
            break;
          case 'broadcast':
            this.startSession('facebook');
            break;
          case 'deployment':
          case 'messaging':
            this.startSession(event.channel || 'web');
            break;
          default:
            this.startSession('sandbox');
            break;
        }
      } else {
        this.endSession();
      }
    });
  }

  public startSession(channel: string, navigate?: boolean) {
    const frontChannel: FrontChannel = environment.deploymentServices.find(entry => entry.type === channel);
    if (channel === this.type || !frontChannel) return;
    // ###M
    // console.log('init _channel', frontChannel.type);
    this._subject$.next(frontChannel);
    if (navigate) this.location.go(location.pathname, '?channel=' + channel);
  }

  public endSession() {
    if (!this.type) return;
    // ###M
    // console.log('reset _channel');
    this._subject$.next(null);
  }

  ngOnDestroy(): void {
    this.endSession();
    this.routerSub.unsubscribe();
  }
}
