import { FormGroup, Validators } from '@angular/forms';
import { get } from 'lodash';

import { OperationsAsyncAction } from 'ideta-library/lib/common/node';

import { DataOperationForm } from '../../../../models/data-operation-form.model';

export const defaultOperationsAction: OperationsAsyncAction = {
  operations: []
};

export class OperationsActionForm extends FormGroup {
  constructor(operationsAction: OperationsAsyncAction = defaultOperationsAction) {
    const formGroup = {
      operations: DataOperationForm.getDataOpertionFormArray(get(operationsAction, 'operations'), [Validators.required])
    };

    super(formGroup);
  }
}
