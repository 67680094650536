import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'maps'
})
export class MapsPipe implements PipeTransform {
  constructor(private dom: DomSanitizer) {}

  transform(value: any): any {
    const regexp = new RegExp('###location###', 'gi');
    const regexpEspace = new RegExp(' ', 'gi');
    const valueModified = value
      .replace(
        regexp,
        'https://s3.amazonaws.com/roxparis/images/Chat-profil.jpg'
      )
      .replace(regexpEspace, '+');

    return this.dom.bypassSecurityTrustResourceUrl(valueModified);
  }
}
