import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

import { TemplateType } from 'ideta-library/lib/common/node';

import { NodeTemplateForm } from './node-template-form.model';
import { BaseTemplateFormComponent } from './shared/base-template-form.component';
import { ProtocolDroidService } from '../protocol-droid/services/protocol-droid.service';
import { CoreSessionService } from '../services/session/core-session.service';

@Component({
  selector: 'app-node-template-form',
  templateUrl: './node-template-form.component.html',
  styleUrls: ['./node-template-form.component.scss']
})
export class NodeTemplateFormComponent extends BaseTemplateFormComponent implements OnInit, OnDestroy {
  @Input() parentForm: NodeTemplateForm;
  templateTypes: { [key in TemplateType]?: string };

  get type(): FormControl {
    return this.parentForm.get('type') as FormControl;
  }

  get templateText(): FormGroup {
    return this.parentForm.get('templateText') as FormGroup;
  }

  get templateQuickReplies(): FormGroup {
    return this.parentForm.get('templateQuickReplies') as FormGroup;
  }

  get templateButton(): FormGroup {
    return this.parentForm.get('templateButton') as FormGroup;
  }

  get templateMedia(): FormGroup {
    return this.parentForm.get('templateMedia') as FormGroup;
  }

  get templateList(): FormGroup {
    return this.parentForm.get('templateList') as FormGroup;
  }

  get templateGeneric(): FormGroup {
    return this.parentForm.get('templateGeneric') as FormGroup;
  }

  get templateReceipt(): FormGroup {
    return this.parentForm.get('templateReceipt') as FormGroup;
  }

  get templateOpenGraph(): FormGroup {
    return this.parentForm.get('templateOpenGraph') as FormGroup;
  }

  constructor(private protocolDroidService: ProtocolDroidService, private _session: CoreSessionService) {
    super();
  }

  ngOnInit() {
    this.subscriptions.push(
      this.protocolDroidService.onTranslationChangeEvent.subscribe(() => {
        this.templateTypes = {
          text: this.protocolDroidService.translate('shared.node-template-form.model-text', 'Texte'),
          'quick-replies': this.protocolDroidService.translate(
            'shared.node-template-form.model-quick-replies',
            'Réponses Rapides'
          ),
          button: this.protocolDroidService.translate('shared.node-template-form.model-button', 'Bouton'),
          media: this.protocolDroidService.translate('shared.node-template-form.model-media', 'Media'),
          generic: this.protocolDroidService.translate('shared.node-template-form.model-generic', 'Carrousel'),
          list: this.protocolDroidService.translate('shared.node-template-form.model-list', 'Liste')
        };
        // not allowed in broadcast
        if (this._session.location !== 'broadcast') {
          this.templateTypes = {
            ...this.templateTypes,
            receipt: this.protocolDroidService.translate('shared.node-template-form.model-receipt', 'Reçu/Note'),
            'open-graph': this.protocolDroidService.translate(
              'shared.node-template-form.model-open-graph',
              'Open Graph'
            )
          };
        }
      })
    );
  }
}
