import { Component, OnInit, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

import { HomeUrlForm } from './home-url-form.model';

@Component({
  selector: 'app-home-url-form',
  templateUrl: './home-url-form.component.html',
  styleUrls: ['./home-url-form.component.scss']
})
export class HomeUrlFormComponent implements OnInit {
  @Input() parentForm: HomeUrlForm;

  get url(): FormControl {
    return this.parentForm.get('url') as FormControl;
  }
  constructor() {}

  ngOnInit() {}
}
