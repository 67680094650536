import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';

import { RealScope } from 'ideta-library/lib/common/data';

type CopyStatus = 'success' | 'error';

export interface EditableDataElem {
  key: string;
  id: string;
  scope: RealScope;
  controlType: 'text' | 'number' | 'checkbox';
  control: FormControl;
}

@Component({
  selector: 'app-data-display-list',
  templateUrl: './data-display-list.component.html',
  styleUrls: ['./data-display-list.component.scss']
})
export class DataDisplayListComponent implements OnInit {
  @Input() dataList: EditableDataElem[];
  @Input() scope: RealScope;
  @Output() updateKey: EventEmitter<EditableDataElem>;
  copyStatus: CopyStatus[];

  constructor() {
    this.updateKey = new EventEmitter();
    this.copyStatus = [];
  }

  ngOnInit() {}

  updateDataKey(elem: EditableDataElem, event: any) {
    if (event) {
      elem.control.patchValue(event.target.innerText);
    }
    this.updateKey.emit(elem);
  }

  copyAsJson(elem: EditableDataElem, index: number): void {
    try {
      navigator.clipboard.writeText(JSON.stringify(elem.control.value));
      this.copyStatus[index] = 'success';
    } catch {
      this.copyStatus[index] = 'error';
    } finally {
      setTimeout(() => (this.copyStatus[index] = null), 1000);
    }
  }

  trackById(_i: number, data: EditableDataElem) {
    return data.id;
  }
}
