import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-tiny-bot-spinner',
  templateUrl: './tiny-bot-spinner.component.html',
  styleUrls: ['./tiny-bot-spinner.component.scss']
})
export class TinyBotSpinnerComponent implements OnInit {
  @Input() inv?: boolean; // Inverse outline color (white)
  @Input() hideText?: boolean; // Hide "Loading..."

  constructor() {}

  ngOnInit() {}
}
