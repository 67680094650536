import { Component, OnInit, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

import { AccountUrlForm } from './account-url-form.model';

@Component({
  selector: 'app-account-url-form',
  templateUrl: './account-url-form.component.html',
  styleUrls: ['./account-url-form.component.scss']
})
export class AccountUrlFormComponent implements OnInit {
  @Input() parentForm: AccountUrlForm;

  get url(): FormControl {
    return this.parentForm.get('url') as FormControl;
  }
  constructor() {}

  ngOnInit() {}
}
