import { FormGroup } from '@angular/forms';

import { MappingDataInput } from 'ideta-library/lib/common/node';

import { SaveDataForm } from './save-data-form/save-data-form.model';
import { TriggersForm } from './triggers-form/triggers-form.model';
import { NlpForm } from './nlp-form/nlp-form.model';
import { FallbackForm } from './fallback-form/fallback-form.model';

const defaultMapping: MappingDataInput = {
  saveData: { active: false },
  triggers: { active: false },
  nlp: { active: false },
  fallback: { active: false }
};

export class MappingDataInputForm extends FormGroup {
  constructor(mapping: MappingDataInput = defaultMapping) {
    const formGroup = {
      saveData: new SaveDataForm(mapping.saveData),
      triggers: new TriggersForm(mapping.triggers),
      nlp: new NlpForm(mapping.nlp),
      fallback: new FallbackForm(mapping.fallback)
    };

    super(formGroup);
  }
}
