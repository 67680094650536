import { AngularFireFunctions } from '@angular/fire/functions';
import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { tap } from 'rxjs/operators';
import { Element, StripeService } from 'ngx-stripe';
import { BotSessionService } from '../session/bot-session.service';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  constructor(
    private db: AngularFireDatabase,
    private stripe: StripeService,
    private functions: AngularFireFunctions,
    private _bot: BotSessionService
  ) {}

  public registerStripeCustomer(
    stripeElement: Element,
    userId: string,
    name: string,
    email: string
  ): Promise<any> {
    return this.stripe
      .createToken(stripeElement, {
        name
      })
      .toPromise()
      .then(res => {
        if (res.error) {
          return Promise.reject(res.error);
        }
        const callable = this.functions.httpsCallable('stripeRegisterCustomer');
        return callable({ source: res.token.id, userId, name, email }).toPromise();
      });
  }

  // Stripe account linking functions

  public readStripeStatus(): any {
    return this.db
      .object(`/bots/${this._bot.id}/stripe`)
      .valueChanges()
      .pipe(
        tap((stripe: any) => {
          return (
            stripe &&
            stripe.scope === 'read_write' &&
            stripe.publishable_key &&
            stripe.access_token &&
            stripe.refresh_token &&
            stripe.user_id
          );
        })
      );
  }

  public deleteStripeInfos(): any {
    return this.db.object(`/bots/${this._bot.id}`).update({ stripe: null });
  }
}
