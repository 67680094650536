import { FormGroup, FormArray, Validators } from '@angular/forms';

import { OpenGraphElement, TemplateOpenGraph } from 'ideta-library/lib/common/node';

import { OpenGraphElementForm } from './open-graph-element-form.model';

const defaultTemplate: TemplateOpenGraph = {
  elements: [
    {
      url: 'https://open.spotify.com/track/4WVEjrL4iyTqzheDaxsylt',
      buttons: [
        {
          title: 'See the lyrics',
          type: 'postback',
          targetNode: 'noeud_0'
        }
      ]
    }
  ]
};

export class TemplateOpenGraphForm extends FormGroup {
  get elements(): FormArray {
    return this.get('elements') as FormArray;
  }

  constructor(template: TemplateOpenGraph = defaultTemplate) {
    const formGroup = {
      elements: new FormArray(
        (template.elements &&
          template.elements.map((element: OpenGraphElement) => new OpenGraphElementForm(element))) ||
          [],
        [Validators.required]
      )
    };

    super(formGroup);
  }

  addElement() {
    this.elements.push(new OpenGraphElementForm());
  }
}
