import { FormGroup, FormControl, ValidationErrors } from '@angular/forms';
import { get } from 'lodash';

import { SaveNlpOptions } from 'ideta-library/lib/common/node';

import { DataKeyFormControl } from '../../../models/data-key-form-control.model';

const defaultOptions = { active: false };

export class SaveNlpForm extends FormGroup {
  public alwaysVisible: boolean;

  constructor(saveNlp: SaveNlpOptions = defaultOptions, alwaysVisible?: boolean) {
    const formGroup = {
      active: new FormControl(saveNlp.active, { updateOn: 'change' }),
      options: new FormGroup({
        input: new DataKeyFormControl(get(saveNlp, 'options.input')),
        intent: new DataKeyFormControl(get(saveNlp, 'options.intent')),
        entities: Object.keys(get(saveNlp, 'options.entities', {})).reduce((prev: FormGroup, curr: string) => {
          prev.registerControl(
            curr,
            new FormGroup({
              name: new DataKeyFormControl(get(saveNlp, 'options.entities.' + curr, {}).name),
              values: new FormGroup(
                {
                  key: new DataKeyFormControl(get(saveNlp, 'options.entities.' + curr + '.values', {}).key),
                  isMultipleValue: new FormControl(
                    get(saveNlp, 'options.entities.' + curr + '.values', {}).isMultipleValue
                  )
                },
                SaveNlpForm.invalidKeyType
              )
            })
          );
          return prev;
        }, new FormGroup({}))
      })
    };

    super(formGroup);
    this.alwaysVisible = alwaysVisible;
    this.switchControlsDisabling();
  }

  static invalidKeyType = (group: FormGroup): ValidationErrors | null => {
    const key = group.get('key') as DataKeyFormControl;
    const isMultipleValue = group.get('isMultipleValue');

    if (!key.value) {
      return null;
    } else if (isMultipleValue.value && !key.isList('string')) {
      return { invalidListKey: true };
    } else if (!isMultipleValue.value && !key.isString) {
      return { invalidStringKey: true };
    }
  };

  enable(options?: { onlySelf?: boolean; emitEvent?: boolean }): void {
    super.enable(options);
    this.switchControlsDisabling();
  }

  updateActive(active: boolean) {
    this.get('active').patchValue(active, { emitViewToModelChange: false, emitEvent: false });
    this.switchControlsDisabling();
  }

  switchControlsDisabling() {
    if (this.get('active').value || this.alwaysVisible) {
      this.get('options').enable({ emitEvent: false });
    } else {
      this.get('options').disable({ emitEvent: false });
    }
    this.updateValueAndValidity();
  }

  registerEntityMapping(name: string): void {
    const entities = this.get('options.entities') as FormGroup;
    const isMultipleValue = new FormControl();
    isMultipleValue.disable();
    entities.registerControl(
      name,
      new FormGroup({
        name: new DataKeyFormControl(),
        values: new FormGroup(
          {
            key: new DataKeyFormControl(),
            isMultipleValue
          },
          SaveNlpForm.invalidKeyType
        )
      })
    );
  }
}
