import { FormGroup, FormControl } from '@angular/forms';

import { urlValidator } from '../../models/custom-validators';

export interface HomeUrl {
  url: string;
  webview_height_ratio: 'tall';
  webview_share_button: 'show';
  in_test: false;
}

export class HomeUrlForm extends FormGroup {
  constructor(homeUrl: any = {}) {
    const formGroup = {
      url: new FormControl(homeUrl.url, [urlValidator])
    };

    super(formGroup);
  }

  getProfileValue(): HomeUrl {
    const url = this.get('url').value;
    return url
      ? {
          url,
          webview_height_ratio: 'tall',
          webview_share_button: 'show',
          in_test: false
        }
      : null;
  }

  setProfileValue(value: any = {}) {
    this.get('url').patchValue(value.url);
  }
}
