import { Component, OnInit } from '@angular/core';

import { ErrorsService } from '../shared/services/errors/errors.service';
import { UserService } from '../shared/services/user/user.service';

@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.scss']
})
export class VerifyEmailComponent implements OnInit {
  emailSent: boolean;
  constructor(private userService: UserService, private errorsService: ErrorsService) {
    this.emailSent = false;
  }

  ngOnInit() {}

  sendTestEmailVerification(): void {
    this.userService.sendEmailVerification().then(() => (this.emailSent = true), this.errorsService.handlePromise());
  }
}
