import { FormGroup, FormControl, Validators } from '@angular/forms';
import { get } from 'lodash';

import { PerformOperationsOptions } from 'ideta-library/lib/common/node';

import { DataOperationForm } from '../../../models/data-operation-form.model';
import { NodeKeyFormControl } from '../../../models/node-key-form-control.model';

const defaultOptions = { active: false };

export class PerformOperationsForm extends FormGroup {
  constructor(performOperations: PerformOperationsOptions = defaultOptions) {
    const formGroup = {
      active: new FormControl(performOperations.active, { updateOn: 'change' }),
      options: new FormGroup({
        targetNode: new NodeKeyFormControl(get(performOperations, 'options.targetNode'), [Validators.required]),
        operations: DataOperationForm.getDataOpertionFormArray(get(performOperations, 'options.operations'), [
          Validators.required
        ])
      })
    };

    super(formGroup);
    this.switchControlsDisabling();
  }

  enable(options?: { onlySelf?: boolean; emitEvent?: boolean }): void {
    super.enable(options);
    this.switchControlsDisabling();
  }

  updateActive(active: boolean) {
    this.get('active').patchValue(active, { emitViewToModelChange: false, emitEvent: false });
    this.switchControlsDisabling();
  }

  switchControlsDisabling() {
    if (this.get('active').value) {
      this.get('options').enable({ emitEvent: false });
    } else {
      this.get('options').disable({ emitEvent: false });
    }
    this.updateValueAndValidity();
  }
}
