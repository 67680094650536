import { FormGroup, FormControl, ValidationErrors } from '@angular/forms';
import { isEmpty } from 'lodash';

import { AllowedScope, AllowedType, DataRichSegment, DataStoreArrayElems } from 'ideta-library/lib/common/data';

import { formats } from './regex-formats.model';
import { AdditionalDataInfos } from './data-key-form-control.model';

export type RichInputSegment = DataRichSegment & AdditionalDataInfos;

export class RichInputSegmentForm extends FormGroup {
  displayName?: string;
  // ###DK
  oldFormat?: boolean;
  scope?: AllowedScope;
  dataType?: AllowedType;
  elements?: DataStoreArrayElems;
  allowedTypes?: AllowedType[];
  allowedScopes?: AllowedScope[];
  private isMissing?: boolean;

  get isKey(): boolean {
    return this.value.type !== 'literal';
  }

  get isElement(): boolean {
    return this.value.type === 'element';
  }

  get isString(): boolean {
    return this.value.type === 'literal' || this.dataType === 'string';
  }

  get isNumber(): boolean {
    const value = this.get('value').value;
    return value && ((!this.isKey && !isNaN(value)) || (this.isKey && this.dataType === 'number'));
  }

  get isBoolean(): boolean {
    const value = this.get('value').value;
    return (
      value && ((this.isKey && this.dataType === 'boolean') || (!this.isKey && (value === 'true' || value === 'false')))
    );
  }

  get isAlphanumeric(): boolean {
    return this.get('value').value && (!this.isKey || (this.dataType === 'string' || this.dataType === 'number'));
  }

  get isPrimitive(): boolean {
    return this.isString || this.isNumber || this.isBoolean;
  }

  get isObject(): boolean {
    return this.get('value').value && (!this.isKey || this.dataType === 'object');
  }

  /**
   * [constructor description]
   * @param input     [description]
   * @param dataInfos [description]
   */
  constructor(input: string | DataRichSegment = '', dataInfos: AdditionalDataInfos = {}) {
    const segment: DataRichSegment = !input
      ? { type: 'literal', value: '' }
      : typeof input === 'string'
      ? RichInputSegmentForm.parseInput(input)
      : input;

    super(
      {
        type: new FormControl(segment.type),
        value: new FormControl(segment.value)
      },
      [RichInputSegmentForm.missingDataKey, RichInputSegmentForm.invalidDataType, RichInputSegmentForm.invalidScope]
    );
    this.updateAdditionalDataInfos(dataInfos);
  }

  /**
   * [missingDataKey description]
   * @param  group [description]
   * @return        [description]
   */
  static missingDataKey = (group: RichInputSegmentForm): ValidationErrors | null => {
    return group.isKey && group.isMissing ? { missingDataKey: true } : null;
  };

  /**
   * [invalidDataType description]
   * @param  control [description]
   * @return                  [description]
   */
  static invalidDataType = (group: RichInputSegmentForm): ValidationErrors | null => {
    const fullDataType = group.dataType === 'array' && group.elements ? 'array.' + group.elements.type : null;
    return group.allowedTypes &&
      !(
        group.dataType &&
        (group.allowedTypes.indexOf(group.dataType) > -1 ||
          (fullDataType && group.allowedTypes.indexOf(fullDataType as AllowedType) > -1))
      )
      ? { invalidDataType: true }
      : null;
  };

  /**
   * [invalidScope description]
   * @param  group [description]
   * @return               [description]
   */
  static invalidScope = (group: RichInputSegmentForm): ValidationErrors | null => {
    return group.isKey &&
      group.allowedScopes &&
      group.scope &&
      !(group.allowedScopes.indexOf(group.scope) > -1) &&
      !(
        (group.get('value').value === 'first_name' || group.get('value').value === 'last_name') &&
        group.allowedScopes.indexOf('identifier') > -1
      )
      ? { invalidDataScope: true }
      : null;
  };

  /**
   * [parseInput description]
   * @param  input [description]
   * @return       [description]
   */
  static parseInput(input: string = ''): DataRichSegment {
    if (formats.test('strictTaggedData', input)) {
      return {
        type: 'key',
        value: input.slice(3, -3)
      };
    } else if (formats.test('strictElementData', input)) {
      return {
        type: 'element',
        value: input.slice(3, -3)
      };
    } else {
      return {
        type: 'literal',
        value: input
      };
    }
  }

  patchValue(dataKey: RichInputSegment = {}) {
    const { type, value, ...additionalDataInfos } = dataKey;

    if (!!type || value !== undefined) {
      super.patchValue(dataKey);
    }

    this.updateAdditionalDataInfos(additionalDataInfos);
  }

  setAsMissingKey() {
    this.isMissing = true;
    this.updateValueAndValidity();
  }

  toString(): string {
    const dTag = formats.regularKeyTag;
    const eTag = formats.elementKeyTag;
    switch (this.value.type) {
      case 'key':
        return dTag + this.get('value').value + dTag;
      case 'element':
        return eTag + this.get('value').value + eTag;
      default:
        return this.get('value').value;
    }
  }

  isList(type?: AllowedType): boolean {
    return (
      this.get('type').value !== 'key' ||
      (this.dataType === 'array' && (!type || (this.elements && this.elements.type === type)))
    );
  }

  // Concerning key infos if segment is a dataKey
  private updateAdditionalDataInfos(infos: AdditionalDataInfos = {}) {
    if (!isEmpty(infos)) {
      const { key, oldFormat, scope, dataType, elements, allowedTypes, allowedScopes } = infos;
      if (key !== undefined) this.displayName = key;
      if (scope !== undefined) this.scope = scope;
      if (dataType !== undefined) this.dataType = dataType;
      if (elements !== undefined) this.elements = elements;
      if (allowedTypes !== undefined) this.allowedTypes = allowedTypes;
      if (allowedScopes !== undefined) this.allowedScopes = allowedScopes;
      // ###DK
      this.oldFormat = oldFormat;
      this.isMissing = false;
      this.updateValueAndValidity();
    }
  }
}
