import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';

import { WorkflowActionForm } from './workflow-action-form.model';

@Component({
  selector: 'app-workflow-action-form',
  templateUrl: './workflow-action-form.component.html',
  styleUrls: ['./workflow-action-form.component.scss']
})
export class WorkflowActionFormComponent implements OnInit {
  @Input() parentForm: WorkflowActionForm;
  @Output() createNode: EventEmitter<{
    name: string;
    callback: (key: string) => void;
  }>;

  get node(): FormControl {
    return this.parentForm.get('node') as FormControl;
  }

  constructor() {
    this.createNode = new EventEmitter();
  }

  ngOnInit() {}

  createAndSelect(control: FormControl, name: string) {
    this.createNode.emit({
      name,
      callback: (key: string) => control.patchValue(key)
    });
  }
}
