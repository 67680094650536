import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

export interface FilterBadge<T> {
  key: T;
  name: string;
  active: boolean;
  enabled: boolean;
  isDeployed?: boolean;
}

@Component({
  selector: 'app-filter-badge',
  templateUrl: './filter-badge.component.html',
  styleUrls: ['./filter-badge.component.scss']
})
export class FilterBadgeComponent<T> implements OnInit {
  @Input() filter: FilterBadge<T>;
  @Output() switch: EventEmitter<void>;

  constructor() {
    this.switch = new EventEmitter();
  }

  ngOnInit() {}
}
