import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Subscription } from 'rxjs';

import { AuthService } from '../shared/services/auth/auth.service';
import { FeedbackService } from '../shared/services/feedback/feedback.service';
import { ErrorsService } from '../shared/services/errors/errors.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {
  emailInput: string;
  forgotPasswordError: string;
  private subscriptions: Subscription[];

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private feedbackService: FeedbackService,
    private errorsService: ErrorsService
  ) {
    this.subscriptions = [];
  }

  ngOnInit() {
    this.subscriptions.push(
      this.route.queryParamMap.subscribe((params: ParamMap) => {
        this.emailInput = params.get('email');
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  resetPassword() {
    if (!this.emailInput) {
      this.feedbackService.showMessage('Type in your email first');
    }
    this.authService
      .resetPasswordInit(this.emailInput)
      .then(
        () => this.feedbackService.showMessage('A password reset link has been sent to your email address'),
        rejectionReason => (this.forgotPasswordError = rejectionReason)
      )
      .catch(this.errorsService.handlePromise);
  }
}
