import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { Subscription } from 'rxjs';
import { get } from 'lodash';

import { AuthService } from '../shared/services/auth/auth.service';
import { ProtocolDroidService } from '../shared/protocol-droid/services/protocol-droid.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  animations: [
    trigger('signInAnimation', [
      state(
        'form',
        style({
          transform: 'translateY(0)'
        })
      ),
      state(
        'spinner',
        style({
          transform: 'translateY(-180px)'
        })
      ),
      transition('* => *', animate('.2s ease-out'))
    ])
  ]
})
export class LoginComponent implements OnInit, OnDestroy {
  credentials: any;
  customMessage: string;
  emailInput: string;
  signInStatus: string;
  signInError: string;
  private subscriptions: Subscription[];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private protocolDroidService: ProtocolDroidService
  ) {
    this.subscriptions = [];
    this.signInStatus = 'form';
  }

  ngOnInit() {
    this.protocolDroidService.setLang(get(navigator, 'language') || get(navigator, 'userLanguage'));
    this.subscriptions.push(
      this.authService.getAuth().subscribe(user => {
        if (user && !user.isAnonymous) {
          this.router.navigateByUrl('/');
        }
      }),
      this.route.queryParamMap.subscribe((params: ParamMap) => {
        this.customMessage = params.get('message');
        this.emailInput = params.get('email');
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  loginWithCredentials(email: string, password: string): void {
    this.signInError = null;
    this.signInStatus = 'spinner';
    setTimeout(
      () =>
        this.authService.loginWithCredentials({ email, password }).catch((error: any) => {
          /* for now the error message is displayed without translate
          as the default lang is ENG for unlogged users */
          this.signInError = error;
          setTimeout(() => (this.signInStatus = 'form'), 2000);
        }),
      1000
    );
  }

  googleAuth(): void {
    this.authService.signinWithGoogle();
  }

  facebookAuth(): void {
    this.authService.signinWithFacebook();
  }

  handleSignInError(): boolean {
    if (this.signInError) {
      this.signInStatus = 'form';
      return true;
    } else return false;
  }
}
