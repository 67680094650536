import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl, FormArray } from '@angular/forms';

import { TemplateFeedType } from 'ideta-library/lib/common/node';

import { TemplateQuickRepliesForm } from './template-quick-replies-form.model';
import { BaseTemplateFormComponent } from '../shared/base-template-form.component';
import { RichInputFormControl } from '../../models/rich-input-form-control.model';
import { ProtocolDroidService } from '../../protocol-droid/services/protocol-droid.service';
import { CoreSessionService } from '../../services/session/core-session.service';

@Component({
  selector: 'app-template-quick-replies-form',
  templateUrl: './template-quick-replies-form.component.html',
  styleUrls: ['./template-quick-replies-form.component.scss']
})
export class TemplateQuickRepliesFormComponent extends BaseTemplateFormComponent implements OnInit {
  @Input() parentForm: TemplateQuickRepliesForm;
  feedTypes: { [key in TemplateFeedType]?: string };

  get text(): RichInputFormControl {
    return this.parentForm.get('text') as RichInputFormControl;
  }

  get feedType(): FormControl {
    return this.parentForm.get('feedType') as FormControl;
  }

  get autoOptions(): FormGroup {
    return this.parentForm.get('autoOptions') as FormGroup;
  }

  get quickReplies(): FormArray {
    return this.parentForm.get('quickReplies') as FormArray;
  }

  constructor(private protocolDroidService: ProtocolDroidService, private _session: CoreSessionService) {
    super();
  }

  ngOnInit() {
    this.subscriptions.push(
      this.protocolDroidService.onTranslationChangeEvent.subscribe(() => {
        this.feedTypes = {
          manual: this.protocolDroidService.translate(
            'shared.node-template-form.quick-replies.feed-type-manual',
            'Manuel'
          )
        };
        // not allowed in broadcast
        if (this._session.location !== 'broadcast') {
          this.feedTypes = {
            ...this.feedTypes,
            auto: this.protocolDroidService.translate(
              'shared.node-template-form.quick-replies.feed-type-auto',
              'Automatique'
            )
          };
        }
      })
    );
  }
}
