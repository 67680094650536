import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';

import { BotSessionService } from '../../session/bot-session.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class BotDataService {
  constructor(private db: AngularFireDatabase, public http: HttpClient, private _bot: BotSessionService) {}

  createKey(dataKey: any = {}): Promise<firebase.database.Reference> {
    const salt: string = Math.random()
      .toString(36)
      .substring(2, 8);
    return new Promise((resolve, reject) =>
      this.db
        .list(`/data-stores/${this._bot.id}/sandbox`)
        .push({ key: 'myNewKey_' + salt, type: 'string', scope: 'conversation', ...dataKey })
        .then(ref => resolve(ref))
        .catch(() => reject())
    );
  }

  updateKey(keyId: string, keyValue: any): Promise<void> {
    return this.db.object(`/data-stores/${this._bot.id}/sandbox/${keyId}`).update(keyValue);
  }

  // infos must be specified manually in this function
  updateKeyValue(
    botId: string,
    channel: string,
    endPointBack: string,
    token: string,
    objectToStore: any
  ): Promise<any> {
    return this.http
      .post(`${endPointBack}/data/store/${channel}`, {
        botId,
        token,
        objectToStore
      })
      .toPromise();
  }

  deleteKey(keyId: string): Promise<void> {
    return this.db.object(`/data-stores/${this._bot.id}/sandbox/${keyId}`).remove();
  }
}
