import { FormGroup, FormControl, Validators } from '@angular/forms';

import { TemplateCarrousel } from 'ideta-library/lib/common/node';

import { GenericManualForm } from '../shared/generic-manual-form.model';
import { GenericAutoForm } from '../shared/generic-auto-form.model';
import { RichInputFormControl } from '../../models/rich-input-form-control.model';

const defaultTemplate: TemplateCarrousel = {
  intro: 'This is an example of carrousel !',
  feedType: 'manual',
  size: 'tall',
  elements: [
    {
      title: 'Welcome !',
      imageUrl: 'https://creapills.com/wp-content/uploads/2017/10/gifs-animes-effet-3d-5.gif',
      subtitle: "We've got the right gif for everyone.",
      buttons: [
        {
          title: 'View Website',
          type: 'url',
          url: 'https://petersapparel.parseapp.com/view_item?item_id=100'
        },
        {
          title: 'Start Chatting',
          type: 'postback',
          targetNode: 'noeud_0'
        }
      ],
      defaultAction: {
        type: 'url',
        url: 'https://www.google.fr/?q=gifs'
      }
    },
    {
      buttons: [
        {
          title: 'Color code',
          type: 'url',
          url: 'https://www.google.fr/?q=code%20couleur'
        },
        {
          title: 'Web fonts',
          type: 'url',
          url: 'https://www.google.fr/?q=web%20fonts'
        },
        {
          title: 'Icones',
          type: 'url',
          url: 'https://www.google.fr/?q=icones'
        }
      ],
      defaultAction: {
        type: 'url',
        url: 'https://www.google.fr/?q=icones'
      },
      title: 'Scrat',
      subtitle: 'From the Ice Age',
      imageUrl: 'https://creapills.com/wp-content/uploads/2017/10/gifs-animes-effet-3d-3.gif'
    }
  ]
};

export class TemplateCarrouselForm extends FormGroup {
  constructor(template: TemplateCarrousel = defaultTemplate) {
    const formGroup = {
      intro: new RichInputFormControl(template.intro),
      feedType: new FormControl(template.feedType || 'manual', [Validators.required]),
      size: new FormControl(template.size || 'tall', [Validators.required]),
      elements: new GenericManualForm(template.feedType === 'manual' && template.elements),
      autoOptions: new GenericAutoForm(template.feedType === 'auto' && template.autoOptions)
    };

    super(formGroup);

    this.switchControlsDisabling();
  }

  enable(options?: { onlySelf?: boolean; emitEvent?: boolean }): void {
    super.enable(options);
    this.switchControlsDisabling();
  }

  switchControlsDisabling() {
    const feedType = this.get('feedType').value;

    if (feedType === 'manual') {
      this.get('elements').enable({ emitEvent: false });
      this.get('autoOptions').disable({ emitEvent: false });
    } else if (feedType === 'auto') {
      this.get('elements').disable({ emitEvent: false });
      this.get('autoOptions').enable({ emitEvent: false });
    }
    this.updateValueAndValidity();
  }
}
