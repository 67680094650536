import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import { DataComparisonForm } from '../../../models/data-comparison-form.model';
import { RichInputSegmentForm } from '../../../models/rich-input-segment-form.model';
import { KeyCreationEvent } from '../../../models/data-selector.component';

@Component({
  selector: 'app-data-comparison',
  templateUrl: './data-comparison.component.html',
  styleUrls: ['./data-comparison.component.scss']
})
export class DataComparisonComponent {
  @Input() comparison: DataComparisonForm;
  @Input() index: number;
  @Input() length: number;
  @Output() update: EventEmitter<any>;
  @Output() remove: EventEmitter<void>;
  @Output() createDataKey: EventEmitter<KeyCreationEvent>;
  @Output() createNode: EventEmitter<{
    name: string;
    callback: (key: string) => void;
  }>;
  @Output() order: EventEmitter<'up' | 'down'>;

  get type(): FormControl {
    return this.comparison.get('type') as FormControl;
  }

  get key(): RichInputSegmentForm {
    return this.comparison.get('key') as RichInputSegmentForm;
  }

  get comparator(): RichInputSegmentForm {
    return this.comparison.get('comparator') as RichInputSegmentForm;
  }

  get ifTrue(): FormGroup {
    return this.comparison.get('ifTrue') as FormGroup;
  }

  get ifFalse(): FormGroup {
    return this.comparison.get('ifFalse') as FormGroup;
  }

  constructor() {
    this.remove = new EventEmitter();
    this.update = new EventEmitter();
    this.createDataKey = new EventEmitter();
    this.createNode = new EventEmitter();
    this.order = new EventEmitter();
  }
}
