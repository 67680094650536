import { NgModule, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
/* Uncomment next line to activate HttpClient debugging (logs) */
import { HttpClientModule /* HTTP_INTERCEPTORS */ } from '@angular/common/http';
import { AngularFireModule } from '@angular/fire';
import { AngularFireFunctionsModule } from '@angular/fire/functions';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { ModalModule } from 'ngx-bootstrap/modal';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { FacebookModule } from 'ngx-facebook';
import { PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

import { SharedModule } from './shared/shared.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { WebDisplayComponent } from './web-display/web-display.component';
import { EmbeddedWebDisplayComponent } from './web-display/embedded-web-display/embedded-web-display.component';
import { OauthCallbackComponent } from './oauth-callback/oauth-callback.component';
import { RollbarErrorHandler, RollbarService, rollbarFactory } from './shared/services/rollbar/rollbar.service';
import { environment } from '../environments/environment';
import { SignupComponent } from './signup/signup.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { VerifyEmailComponent } from './verify-email/verify-email.component';
/* Uncomment next line to activate HttpClient debugging (logs) */
// import { HttpLoggerService } from './shared/services/http-logger/http-logger.service';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireFunctionsModule,
    AngularFireStorageModule,
    ModalModule.forRoot(),
    BsDropdownModule.forRoot(),
    PopoverModule.forRoot(),
    FacebookModule.forRoot(),
    SharedModule,
    AppRoutingModule
  ],
  declarations: [
    AppComponent,
    LoginComponent,
    PageNotFoundComponent,
    WebDisplayComponent,
    EmbeddedWebDisplayComponent,
    OauthCallbackComponent,
    SignupComponent,
    ForgotPasswordComponent,
    ChangePasswordComponent,
    VerifyEmailComponent
  ],
  providers: [
    { provide: ErrorHandler, useClass: RollbarErrorHandler },
    { provide: RollbarService, useFactory: rollbarFactory },
    { provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG }
    /* Uncomment next line to activate HttpClient debugging (logs) */
    // { provide: HTTP_INTERCEPTORS, useClass: HttpLoggerService, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
