import { environment } from '../../../environments/environment';

export interface ParsedAddress {
  subject?: string;
  property?: string;
  index?: string;
}

class Formats {
  // Data Form
  readonly strictSubjectName: string;
  readonly propertyName: string;
  readonly strictPropertyName: string;

  // Data Keys
  readonly regularKeyTag: string;
  readonly aliasKeyTag: string;
  readonly indexKeyTag: string;
  readonly elementKeyTag: string;
  readonly subjectId: string;
  readonly aliasedData: string;
  readonly elementData: string;
  readonly strictElementData: string;
  readonly chainElementData: string;
  readonly indexedData: string;
  readonly taggedData: string;
  readonly strictTaggedData: string;
  readonly chainTaggedData: string;
  readonly dataIndex: string;
  readonly propertyKey: string;
  readonly keyOrElement: string;

  // Media
  readonly imageExtension: string;
  readonly videoExtension: string;
  readonly audioExtension: string;

  // Url
  readonly urlProtocol: string;
  readonly urlContent: string;
  readonly specifiedProtocolUrl: string;
  readonly urlOrKey: string;
  readonly url: string;

  // API
  readonly httpHeaders: string;
  readonly oldApiLocationPath: string;
  readonly simpleLocationPath: string;
  readonly apiLocationPath: string;

  // Facebook
  readonly facebookBusinessImageUrl: string;
  readonly facebookImageUrl: string;
  readonly facebookVideoUrl: string;

  // Google
  readonly youtubeUrl: string;
  readonly youtubeShortLink: string;
  readonly youtubeRegularLink: string;
  readonly youtubeEmbeddedLink: string;
  readonly googleMapsUrl: string;
  readonly googleCalendarUrl: string;
  readonly googleCalendarIntegration: string;

  // Misc
  readonly hexaDecimalColor: string;
  readonly email: string;
  readonly formulaSanitizer: string;
  readonly webParamName: string;

  // Routes
  readonly appRouteSplit: string;
  readonly appBotIdSplit: string;
  readonly appChannelSplit: string;

  constructor() {
    /* tslint:disable */
    // Data Form
    this.strictSubjectName = `^\\w{1,${environment.appSettings.maxKeyNameLength}}$`;
    this.propertyName = `\\w{1,${environment.appSettings.maxPropertyNameLength}}`;
    this.strictPropertyName = `^${this.propertyName}$`;

    // Data Keys
    this.regularKeyTag = '###';
    this.aliasKeyTag = '%%%';
    this.indexKeyTag = '@@@';
    this.elementKeyTag = '&&&';

    this.subjectId = '[\\w-]+';

    this.elementData = this.elementKeyTag + this.subjectId + this.elementKeyTag;
    this.strictElementData = `^${this.elementData}$`;
    this.chainElementData = `(${this.elementData})`;
    this.indexedData = this.indexKeyTag + this.subjectId + this.indexKeyTag;

    // ###DK
    this.dataIndex = `\\[(${this.indexedData}|!##${this.subjectId}##!|\\d+)\\]`;

    this.propertyKey = `\\.${this.propertyName}`;

    const fullDataAccess = `${this.subjectId}(${this.dataIndex})?(${this.propertyKey})?`;

    this.aliasedData = this.aliasKeyTag + fullDataAccess + this.aliasKeyTag;

    this.taggedData = this.regularKeyTag + fullDataAccess + this.regularKeyTag;
    this.strictTaggedData = `^${this.taggedData}$`;
    this.chainTaggedData = `(${this.taggedData.replace(/\(/g, '(?:')})`;

    this.keyOrElement = '(###.*###)|(&&&.*&&&)|(%%%.*%%%)|(@@@.*@@@)';

    // Media
    this.imageExtension = '\\.(jpe?g)|(png)|(gif)';
    this.videoExtension = '\\.(mp4)|(webm)|(ogg)|(mov)';
    this.audioExtension = '\\.(mp3)|(wav)|(ogg)|(flac)';

    // Url
    this.urlProtocol = '(https?|ftp):\\/\\/';
    this.urlContent = `[\\w\\/\\[\\]\\-.*+(){}?$&"'@!~#:,;=%]`;
    this.urlOrKey = `^(${this.urlProtocol}${this.urlContent}+)|(${this.taggedData}${this.urlContent}*)$`;
    this.specifiedProtocolUrl = `^(${this.urlProtocol}|mailto:|tel:).*$`;
    this.url = `^${this.urlProtocol}[\\w\\.-]+(\\.[\\w-]+)*${this.urlContent}+$`;

    // API
    this.httpHeaders = `(([\\w-]+)(\\s*:\\s*)([^;\\s]+\\s?[^;\\s]+)|(${this.taggedData}))(;)`;
    this.oldApiLocationPath = `^((([\\w-]+|${this.taggedData})\\.?)*(\\[(\\d+|'[\\w-@%\\d]+'|'?${this.taggedData}'?)\\])?)((\\.| \\|\\| )((([\\w-]+|${this.taggedData})\\.?)+(\\[(\\d+|'[\\w-@%\\d]+'|'?${this.taggedData}'?)\\])?))*$`;
    this.simpleLocationPath = `^((([\\w-]+|${this.taggedData})|(\\[(\\d+|'[\\w\\-@%\\d]+'|'?${this.taggedData}'?)\\]))\\.?)+$`;
    this.apiLocationPath = `^((([\\w-]+|${this.taggedData})|(\\[(\\d+|'[\\w\\-@%\\d]+'|'?${this.taggedData}'?)\\]))(\\.| \\|\\| )?)+$`;

    // Facebook
    this.facebookBusinessImageUrl = '^https:\\/\\/business\\.facebook\\.com\\/\\w+\\/photos\\/\\d+\\/?$';
    this.facebookImageUrl = '^https:\\/\\/www\\.facebook\\.com\\/photo\\.php\\?fbid=\\d+\\/?$';
    this.facebookVideoUrl = '^https:\\/\\/(www|business)\\.facebook\\.com\\/\\w+\\/videos\\/\\d+\\/?$';

    // Google
    this.youtubeUrl =
      '^((?:https?:)?\\/\\/)?((?:www|m)\\.)?((?:youtube\\.com|youtu.be))(\\/(?:[\\w\\-]+\\?v=|embed\\/|v\\/)?)([\\w\\-]+)(\\S+)?$';
    this.youtubeShortLink = '.be/';
    this.youtubeRegularLink = '/watch?v=';
    this.youtubeEmbeddedLink = '/embed/';
    this.googleMapsUrl = '^https:\\/\\/(www\\.)?google\\.com\\/maps\\/';
    this.googleCalendarUrl = '^https:\\/\\/(www\\.)?calendar.google.com\\/calendar\\/embed';
    this.googleCalendarIntegration = '&amp;';

    // Misc
    this.hexaDecimalColor = '^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$';
    this.email = `^(\\S+@\\S+\\.\\S+)|(${this.taggedData})$`;
    this.formulaSanitizer = '[^-()\\d\\/*+.]';
    this.webParamName = '^[^?&:/,;=]*$';

    // Routes
    this.appRouteSplit = '\\/([\\w-]+)\\/?';
    this.appBotIdSplit = '([\\w-]+)\\??';
    this.appChannelSplit = '\\??channel=([\\w]+)&?';
    /* tslint:enable */

    /* ###M */
    // console.log('formats constructor', this);
  }

  test(format: keyof Formats, value: string, flag?: string): boolean {
    return this.regex(format, flag).test(value);
  }

  regex(format: keyof Formats, flag?: string): RegExp {
    return RegExp(this[format] as string, flag || 'g');
  }

  buildAddress(subject: string, property?: string, index?: string): string {
    return this.concatenateKeys(subject, property, index);
  }

  buildDisplayedName(subject: string, property?: string, index?: string): string {
    if (!subject) return 'unknown';
    index = this.test('indexedData', index) ? index.slice(3, -3) : index;
    return this.concatenateKeys(subject, property, index);
  }

  parseAddress(address: string): ParsedAddress {
    const [rawSubject, property] = (address || '').split('.');
    const [subject, index] = rawSubject.split(this.regex('dataIndex'));
    return { subject, property, index };
  }

  generateKeyName(): string {
    return (
      'myNewKey_' +
      Math.random()
        .toString(36)
        .substring(2, 8)
    );
  }

  generatePropertyInfos(): { id: string; name: string } {
    const id =
      'property_' +
      Math.random()
        .toString(36)
        .substring(2, 12);
    return { id, name: id.slice(0, -6) };
  }

  private concatenateKeys(subject: string, property?: string, index?: string): string {
    return subject + (index ? '[' + index + ']' : '') + (property ? '.' + property : '');
  }
}

export const formats = new Formats();
