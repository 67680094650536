import { FormGroup, FormControl, Validators } from '@angular/forms';
import { get } from 'lodash';

import { MappingOptionBehavior, OptionBehaviorType } from 'ideta-library/lib/common/node';

import { NodeKeyFormControl } from '../../../models/node-key-form-control.model';
import { urlValidator } from '../../../models/custom-validators';

const defaultBehavior: MappingOptionBehavior = {
  active: false
};

export class OptionBehaviorForm extends FormGroup {
  constructor(behavior: MappingOptionBehavior = defaultBehavior) {
    const formGroup = {
      active: new FormControl(behavior.active, { updateOn: 'change' }),
      options: new FormGroup({
        type: new FormControl(get(behavior, 'options.type', 'message'), [Validators.required]),
        message: new FormControl(get(behavior, 'options.message'), [Validators.required]),
        targetNode: new NodeKeyFormControl(get(behavior, 'options.targetNode'), [Validators.required]),
        url: new FormControl(get(behavior, 'options.url'), [Validators.required, urlValidator])
      })
    };

    super(formGroup);

    this.switchControlsDisabling();
  }

  enable(options?: { onlySelf?: boolean; emitEvent?: boolean }): void {
    super.enable(options);
    this.switchControlsDisabling();
  }

  updateActive(active: boolean) {
    this.get('active').patchValue(active, { emitViewToModelChange: false, emitEvent: false });
    this.switchControlsDisabling();
  }

  updateType(type: OptionBehaviorType) {
    this.get('options.type').patchValue(type, { emitViewToModelChange: false, emitEvent: false });
    this.switchControlsDisabling();
  }

  switchControlsDisabling() {
    if (this.get('active').value) {
      this.get('options').enable({ emitEvent: false });
      if (this.get('options.type').value === 'message') {
        this.get('options.message').enable({ emitEvent: false });
        this.get('options.targetNode').enable({ emitEvent: false });
        this.get('options.url').disable({ emitEvent: false });
      } else if (this.get('options.type').value === 'api') {
        this.get('options.message').disable({ emitEvent: false });
        this.get('options.targetNode').disable({ emitEvent: false });
        this.get('options.url').enable({ emitEvent: false });
      }
    } else {
      this.get('options').disable({ emitEvent: false });
    }
    this.updateValueAndValidity();
  }
}
