import { FormGroup, Validators } from '@angular/forms';

import { OpenGraphElement } from 'ideta-library/lib/common/node';

import { ButtonsListForm } from '../shared/buttons-list-form/buttons-list-form.model';
import { RichInputFormControl } from '../../models/rich-input-form-control.model';
import { urlOrKeyValidator } from '../../models/custom-validators';

const defaultElement: OpenGraphElement = {
  url: '',
  buttons: []
};

export class OpenGraphElementForm extends FormGroup {
  constructor(element: OpenGraphElement = defaultElement) {
    const formGroup = {
      url: new RichInputFormControl(element.url, {
        validators: [Validators.required, urlOrKeyValidator]
      }),
      buttons: new ButtonsListForm(element.buttons)
    };

    super(formGroup);
  }
}
