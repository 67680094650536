import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { combineLatest, Observable, of, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { DataStoreElemType, RealScope } from 'ideta-library/lib/common/data';

import { ConversationService } from '../../../services/conversation/conversation.service';
import { DataStoreSessionService } from '../../../services/session/data-store-session.service';
import { CoreSessionService } from '../../../services/session/core-session.service';
import { EditableDataElem } from './data-display-list/data-display-list.component';
import { BotSessionService } from '../../../services/session/bot-session.service';

@Component({
  selector: 'app-data-display',
  templateUrl: './data-display.component.html',
  styleUrls: ['./data-display.component.scss']
})
export class DataDisplayComponent implements OnInit, OnDestroy {
  @Input() userId$: Observable<string>;
  @Input() displayableData: RealScope[];
  @Input() editableData: RealScope[];
  @Input() dataTypeFilters: { [key in RealScope]?: DataStoreElemType[] };
  @Output() updateKey: EventEmitter<EditableDataElem>;
  data: { [key in RealScope]?: EditableDataElem[] };
  showConversationData: boolean;
  private defaultTypeFilters: { [key in RealScope]?: DataStoreElemType[] };
  private subscriptions: Subscription[];

  constructor(
    private conversationService: ConversationService,
    private _session: CoreSessionService,
    private _dataStore: DataStoreSessionService,
    private _bot: BotSessionService
  ) {
    this.updateKey = new EventEmitter();
    this.displayableData = this.editableData = [];
    this.defaultTypeFilters = this.dataTypeFilters = {
      conversation: ['string', 'number', 'boolean', 'object', 'array'],
      bot: ['string', 'number', 'boolean']
    };
    this.data = {};
    this.userId$ = new Observable();
    this.subscriptions = [];
  }

  ngOnInit() {
    if (this.displayableData.indexOf('conversation') !== -1) {
      this.subscriptions.push(
        combineLatest([this.userId$, this._bot.distinctSubject$])
          .pipe(
            switchMap(([userId]) => {
              this.showConversationData = !!userId;
              return userId ? this.conversationService.getConversationData(userId) : of({});
            })
          )
          .subscribe((conversationData: any) => {
            this.data['conversation'] = Object.keys(conversationData || {}).map(key => {
              const value = conversationData[key];
              const elem: EditableDataElem = {
                key,
                id: key,
                scope: 'conversation',
                controlType: 'text',
                control: new FormControl(
                  value,
                  // typeof value === 'object' || Array.isArray(value) ? JSON.stringify(value) : value + '',
                  { updateOn: 'blur' }
                )
              };
              if (this.editableData.indexOf('conversation') === -1) elem.control.disable();
              return elem;
            });
          })
      );
    }
    if (this.displayableData.indexOf('bot') !== -1) {
      this.subscriptions.push(
        this._dataStore.subject$.subscribe(() => {
          this.data['bot'] = this._dataStore
            .getScopedDataList('bot')
            .filter(
              dataElem =>
                (this._session.location !== 'messaging' || dataElem.editable) &&
                (this.dataTypeFilters.bot || this.defaultTypeFilters.bot).indexOf(dataElem.type) !== -1
            )
            .map(dataElem => {
              const elem: EditableDataElem = {
                key: dataElem.key,
                id: dataElem.id,
                scope: 'bot',
                controlType: dataElem.type === 'boolean' ? 'checkbox' : dataElem.type === 'number' ? 'number' : 'text',
                control: new FormControl(
                  dataElem.type === 'object' || dataElem.type === 'array'
                    ? JSON.stringify(dataElem.value)
                    : dataElem.value,
                  { updateOn: 'blur' }
                )
              };
              if (this.editableData.indexOf('bot') === -1) elem.control.disable();
              return elem;
            });
        })
      );
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub: Subscription) => sub.unsubscribe());
  }

  updateDataKey(elem: EditableDataElem) {
    if (this.editableData.indexOf(elem.scope) !== -1) {
      this.updateKey.emit(elem);
    }
  }
}
