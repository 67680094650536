import { Component, OnInit, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';

import { RichInputSegmentForm } from '../../../models/rich-input-segment-form.model';
import {
  DataSelectorComponent,
  SelectedDataStoreElem,
  DataStoreElemPreview
} from '../../../models/data-selector.component';
import { ViewEvent } from '../data-keys-rich-input/data-keys-rich-input.component';
import { formats } from '../../../models/regex-formats.model';

@Component({
  selector: 'app-data-single-value-selector',
  templateUrl: './data-single-value-selector.component.html',
  styleUrls: ['./data-single-value-selector.component.scss']
})
export class DataSingleValueSelectorComponent extends DataSelectorComponent implements OnInit {
  @Input() selector: RichInputSegmentForm;
  viewEvent$: Subject<ViewEvent>;
  container: string;

  get selectorType(): FormControl {
    return this.selector.get('type') as FormControl;
  }

  get selectorValue(): FormControl {
    return this.selector.get('value') as FormControl;
  }

  constructor() {
    super();
    this.viewEvent$ = new Subject();
  }

  ngOnInit() {}

  updateSelector(dataKey: SelectedDataStoreElem) {
    const { isElement, id, key, type: dataType, scope } = dataKey;
    const eTag = formats.elementKeyTag;
    this.selector.patchValue({
      type: isElement ? 'element' : 'key',
      value: isElement ? eTag + id + eTag : id,
      dataType,
      scope,
      key
    });
    this.selectDataKey.emit(dataKey);
  }

  clearSelector() {
    this.selector.patchValue({ type: 'literal', value: '' });
  }

  createDataSegment(dataKey: DataStoreElemPreview) {
    this.createDataKey.emit({
      dataKey,
      callback: createdDatakey => this.updateSelector(createdDatakey)
    });
  }
}
