import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { Observable } from 'rxjs';
import { BotSessionService } from '../../session/bot-session.service';

@Injectable({
  providedIn: 'root'
})
export class BotFacebookService {
  constructor(private db: AngularFireDatabase, private _bot: BotSessionService) {}

  public getPageToken(): Observable<string> {
    return this.db
      .object<string>('/bots/' + this._bot.id + '/channels/facebook/pageInfos/access_token')
      .valueChanges();
  }

  public updatePageToken(access_token: string): any {
    return this.db
      .object('/bots/' + this._bot.id + '/channels/facebook/pageInfos')
      .update({ access_token })
      .then(() => true)
      .catch(error => {
        console.error(error);
        return false;
      });
  }
}
