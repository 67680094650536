import { Component, OnInit, OnDestroy, Output, EventEmitter, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { Subscription } from 'rxjs';

import { UserSessionService } from './../../../../services/session/user-session.service';
import { AuthService } from '../../../../services/auth/auth.service';

export type AvailableLangs = 'fr' | 'en';

@Component({
  selector: 'app-user-general-settings',
  templateUrl: './user-general-settings.component.html',
  styleUrls: ['./user-general-settings.component.scss'],
  animations: [
    trigger('expandAnimation', [
      state(
        'collapsed',
        style({
          height: '0',
          overflow: 'hidden'
        })
      ),
      state(
        'expanded',
        style({
          height: '*'
        })
      ),
      transition('collapsed <=> expanded', [animate('0.6s cubic-bezier(.87,-.41,.19,1.44)')])
    ])
  ]
})
export class UserGeneralSettingsComponent implements OnInit, OnDestroy {
  @ViewChild('fileInput', { static: true }) fileInput: any;
  @Output() update: EventEmitter<any>;
  @Output() updateEmail: EventEmitter<any>;
  generalForm: FormGroup;
  options: any;
  languages: { [key in AvailableLangs]: string };
  editEmail?: boolean;
  showVerificationLink: boolean;
  enableEmailPasswordEdit: boolean;
  private subscriptions: Subscription[];

  get firstname(): FormControl {
    return this.generalForm.get('firstname') as FormControl;
  }

  get lastname(): FormControl {
    return this.generalForm.get('lastname') as FormControl;
  }

  get email(): FormControl {
    return this.generalForm.get('email') as FormControl;
  }

  get avatarUrl(): FormControl {
    return this.generalForm.get('avatarUrl') as FormControl;
  }

  get lang(): FormControl {
    return this.generalForm.get('lang') as FormControl;
  }

  constructor(private _user: UserSessionService, private authService: AuthService) {
    this.options = {
      facebook: { collapsed: false }
    };
    this.languages = {
      fr: 'Français',
      en: 'English'
    };
    this.update = new EventEmitter();
    this.updateEmail = new EventEmitter();
    this.subscriptions = [];
    this.editEmail = false;
    this.enableEmailPasswordEdit = false;
    this.showVerificationLink = false;
  }

  ngOnInit() {
    this.subscriptions.push(
      this.authService.getAuth().subscribe(user => {
        this.enableEmailPasswordEdit = !!user.providerData.find(data => data.providerId === 'password');
        this.showVerificationLink = this.enableEmailPasswordEdit && !user.emailVerified;
      }),
      this._user.subject$.subscribe((user: any) => {
        this.generalForm = this.getUserForm(user);
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  deleteAvatar() {
    this.avatarUrl.patchValue(null);
    this.updateUser();
  }

  updateUser() {
    if (this.generalForm.valid) {
      this.update.emit(this.generalForm.value);
    }
  }

  startEditEmail() {
    if (!this.enableEmailPasswordEdit) return;
    if (!this.editEmail) {
      this.editEmail = true;
      this.email.enable();
    } else {
      this.editEmail = false;
      this.email.disable();
    }
  }

  updateUserEmail() {
    if (this.enableEmailPasswordEdit && this.generalForm.valid) {
      this.updateEmail.emit(this.email.value);
      this.editEmail = false;
      this.email.disable();
    }
  }

  goToVerifyEmailPage() {
    if (!this.showVerificationLink) return;
    window.open('/verify-email', '_blank');
  }

  goToPasswordPage() {
    if (!this.enableEmailPasswordEdit) return;
    window.open('/change-password', '_blank');
  }

  switchOptionCollapse(optionKey: string) {
    this.options[optionKey].collapsed = !this.options[optionKey].collapsed;
  }

  private getUserForm(user: any): FormGroup {
    if (user) {
      const formGroup = new FormGroup({
        firstname: new FormControl(user.firstname),
        lastname: new FormControl(user.lastname),
        email: new FormControl(user.email, Validators.email),
        avatarUrl: new FormControl(user.avatarUrl),
        lang: new FormControl(user.lang || 'fr')
      });

      formGroup.get('email').disable();
      return formGroup;
    }
  }
}
