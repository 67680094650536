import { mapValues, get, set } from 'lodash';

import {
  BackgroundOption,
  BackgroundType,
  ChatOption,
  ColorsOption,
  DisplayContext,
  DisplayOption,
  DisplayOptionName,
  IconOption,
  LayoutOptions,
  LogoOption,
  VocalOption
} from 'ideta-library/lib/common/bot';
import { LayoutSize } from 'ideta-library/lib/common/node';

import { environment } from '../../../environments/environment';

export class DisplayOptions {
  private logo: LogoOption;
  private background: BackgroundOption;
  private icon: IconOption;
  private colors: ColorsOption;
  private chat: ChatOption;
  private vocal: VocalOption;
  private layout: LayoutOptions;
  private save: { freetext?: boolean; delay?: number };

  public get context(): DisplayContext {
    return this.layout.context;
  }

  public get framed(): boolean {
    return this.layout.framed;
  }

  public get inverted(): boolean {
    return this.layout.inverted;
  }

  public get isLogo(): boolean {
    return this.logo.active;
  }

  public get logoUrl(): string {
    return this.logo.values.url;
  }

  public get isBackground(): boolean {
    return this.background.active;
  }

  public get backgroundType(): BackgroundType {
    return this.background.values.type;
  }

  public get backgroundColor(): string {
    const hex = this.background.values.color.replace('#', '');
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    return 'rgba(' + r + ',' + g + ',' + b + ',' + this.background.values.opacity / 100 + ')';
  }

  public get backgroundOpacity(): number {
    return this.background.values.opacity;
  }

  public get backgroundPosition(): string {
    return this.background.values.position;
  }

  public get backgroundUrl(): string {
    return `url("${this.background.values.url}")`;
  }

  public get isIcon(): boolean {
    return this.icon.active;
  }

  public get iconUrl(): string {
    return this.icon.values.url;
  }

  public get isColorOptions(): boolean {
    return this.colors.active;
  }

  public get bannerColors(): { header: string; footer: string } {
    return {
      header: this.colors.values.webHeader,
      footer: this.colors.values.webFooter
    };
  }

  public get userColors(): { bubble: string; text: string } {
    return {
      bubble: this.colors.values.userMessageBg,
      text: this.colors.values.userMessageTxt
    };
  }

  public get botColors(): { bubble: string; text: string } {
    return {
      bubble: this.colors.values.botMessageBg,
      text: this.colors.values.botMessageTxt
    };
  }

  public get controlButtonsColor(): string {
    return this.colors.values.controlButtons;
  }

  public get isChatOptions(): boolean {
    return this.chat.active;
  }

  public get freetext(): boolean {
    return this.chat.values.freetext;
  }

  public get attachments(): boolean {
    return this.chat.values.attachments;
  }

  public set attachments(value: boolean) {
    this.chat.values.attachments = value;
  }

  public set freetext(value: boolean) {
    this.chat.values.freetext = value;
  }

  public get restart(): boolean {
    return this.chat.values.restart;
  }

  public get restartTarget(): string {
    return this.chat.values.restartTarget;
  }

  public get usedQR(): boolean {
    return this.chat.values.usedQR;
  }

  public get usedButtons(): boolean {
    return this.chat.values.usedButtons;
  }

  public get delay(): number {
    return this.chat.values.delay;
  }

  public get deleteOnReload(): boolean {
    return this.chat.values.deleteOnReload;
  }

  public get deleteOnRestart(): boolean {
    return this.chat.values.deleteOnRestart;
  }

  public get isBranding(): boolean {
    return !this.chat.values.no_branding;
  }

  public get chatLayout(): LayoutSize {
    return this.chat.values.layout;
  }

  public get nodesLayout(): LayoutSize {
    return this.chat.values.nodesLayout;
  }

  public get useNotificationSound(): boolean {
    return this.chat.values.useNotificationSound;
  }

  public get showTypingVisual(): boolean {
    return this.chat.values.showTypingVisual;
  }

  public get showDates(): boolean {
    return this.chat.values.showMessageDate;
  }

  public get showRead(): boolean {
    return this.chat.values.showMessageRead;
  }

  public get inactiveAfter(): number {
    return this.chat.values.setInactiveAfter;
  }

  public get speechToText(): boolean {
    return this.vocal.values.speechToText;
  }

  public get autoSend(): boolean {
    return this.vocal.values.autoSend;
  }

  public get autoSendDelay(): number {
    return this.vocal.values.autoSendDelay;
  }

  public get textToSpeech(): boolean {
    return this.vocal.values.textToSpeech;
  }

  public get autoTextToSpeech(): boolean {
    return this.vocal.values.autoTextToSpeech;
  }

  public get toggleAutoTextToSpeech(): boolean {
    return this.vocal.values.toggleAutoTextToSpeech;
  }

  public get speechLang(): string {
    return this.vocal.values.speechLang;
  }

  constructor(context: DisplayContext = 'preview') {
    // ! Careful here not to keep a reference to the environment object.
    // ! We want to create a BRAND NEW object.
    this.logo = this.formatDisplayOptions(environment.defaults.displayOptions.logo);
    this.background = this.formatDisplayOptions(environment.defaults.displayOptions.background);
    this.icon = this.formatDisplayOptions(environment.defaults.displayOptions.icon);
    this.colors = this.formatDisplayOptions(environment.defaults.displayOptions.colors);
    this.chat = this.formatDisplayOptions(environment.defaults.displayOptions.chat);
    this.vocal = this.formatDisplayOptions(environment.defaults.displayOptions.vocal);
    this.layout = { ...environment.defaults.layoutOptions } as LayoutOptions;
    this.save = {};
    this.setContext(context);
  }

  public merge(options: any) {
    this.logo = this.mergeDisplayOptions('logo', options);
    this.background = this.mergeDisplayOptions('background', options);
    this.icon = this.mergeDisplayOptions('icon', options);
    this.colors = this.mergeDisplayOptions('colors', options);
    this.chat = this.mergeDisplayOptions('chat', options);
    this.vocal = this.mergeDisplayOptions('vocal', options);
  }

  public setContext(newContext: DisplayContext): void {
    this.layout.context = newContext;
    switch (newContext) {
      case 'preview':
        this.chat.values.delay = 0;
        this.icon.active = false;
        break;
      case 'sandbox':
        this.chat.values.freetext = true;
        this.chat.values.attachments = true;
        this.icon.active = false;
        this.vocal.values.speechToText = true;
        this.vocal.values.autoSend = true;
        this.vocal.values.textToSpeech = true;
        // this.vocal.values.autoTextToSpeech = true; /* test only */
        break;
      case 'web':
        this.chat.values.no_branding = true;
        this.layout.framed = false;
        break;
      case 'cockpit':
        this.colors.values.userMessageBg = '#01a6c7';
        this.chat.values.freetext = true;
        this.chat.values.attachments = true;
        this.chat.values.delay = 0;
        this.chat.values.usedButtons = false;
        this.chat.values.showTypingVisual = true;
        this.chat.values.showMessageDate = true;
        this.chat.values.showMessageRead = true;
        this.vocal.values.speechToText = true;
        this.vocal.values.autoSend = true;
        this.icon.active = false;
        this.layout.framed = false;
        this.layout.inverted = true;
        break;
      case 'embedded':
        this.icon.active = false;
        this.chat.values.freetext = true;
        this.chat.values.attachments = true;
        this.chat.values.usedQR = false;
        this.layout.framed = false;
        break;
    }
  }

  public getColors(sender: 'bot' | 'user'): { bubble: string; text: string } {
    return sender === 'bot' ? this.botColors : this.userColors;
  }

  public pause(): void {
    this.save.freetext = this.freetext;
    this.save.delay = this.delay;
    this.chat.values.freetext = true;
    this.chat.values.delay = 0;
  }

  public resume(): void {
    this.chat.values.freetext = this.save.freetext !== undefined ? this.save.freetext : this.freetext;
    this.chat.values.delay = this.save.delay || this.delay;
    this.save = {};
  }

  private formatDisplayOptions<T extends DisplayOption>(options: any): T {
    const formattedOptions: any = {
      key: options.key,
      active: options.active,
      values: { ...mapValues(options.values, (option: any) => option.value) }
    };

    return formattedOptions;
  }

  private mergeDisplayOptions<T extends DisplayOption>(key: DisplayOptionName, options: T): T {
    if (key === 'colors') {
      /* ###L */
      const returnButtonColor = get(options, 'colors.values.returnButton');
      if (returnButtonColor) set(options, 'colors.values.controlButtons', returnButtonColor);
    }
    if (key === 'vocal') {
      /* ###L */
      const speechToText = get(options, 'vocal.values.seechToText');
      if (typeof speechToText === 'boolean') set(options, 'vocal.values.speechToText', speechToText);
    }

    const isActive = get(options, `${key}.active`, this[key].active);
    const merge: any = {
      key,
      active: isActive,
      values: {}
    };
    Object.keys(this[key].values).forEach((option: string) => {
      const defaultValue = environment.defaults.displayOptions[key].values[option].value;
      merge.values[option] = isActive ? get(options, `${key}.values.${option}`, defaultValue) : defaultValue;
    });
    return merge;
  }
}
