import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, FormArray } from '@angular/forms';
import { Subscription } from 'rxjs';

import { AsyncActionForm } from './async-action-form.model';
import { KeyCreationEvent } from '../../../models/data-selector.component';
import { ProtocolDroidService } from '../../../protocol-droid/services/protocol-droid.service';

@Component({
  selector: 'app-async-action-form',
  templateUrl: './async-action-form.component.html',
  styleUrls: ['./async-action-form.component.scss']
})
export class AsyncActionFormComponent implements OnInit, OnDestroy {
  @Input() parentForm: AsyncActionForm;
  @Output() createDataKey: EventEmitter<KeyCreationEvent>;
  @Output() createNode: EventEmitter<{
    name: string;
    callback: (key: string) => void;
  }>;
  uniqueId: string;
  actionTypes: { [key: string]: string };
  private subscriptions: Subscription[];

  get name(): FormControl {
    return this.parentForm.get('name') as FormControl;
  }

  get type(): FormControl {
    return this.parentForm.get('type') as FormControl;
  }

  get optionsApi(): FormGroup {
    return this.parentForm.get('optionsApi') as FormGroup;
  }

  get optionsEmail(): FormGroup {
    return this.parentForm.get('optionsEmail') as FormGroup;
  }

  get optionsWorkflow(): FormGroup {
    return this.parentForm.get('optionsWorkflow') as FormGroup;
  }

  get optionsOperations(): FormArray {
    return this.parentForm.get('optionsOperations') as FormArray;
  }

  constructor(private protocolDroidService: ProtocolDroidService) {
    this.createDataKey = new EventEmitter();
    this.createNode = new EventEmitter();
    this.uniqueId = Math.random()
      .toString(36)
      .substring(2, 8);
    this.subscriptions = [];
  }

  ngOnInit() {
    this.subscriptions.push(
      this.protocolDroidService.onTranslationChangeEvent.subscribe(() => {
        this.actionTypes = {
          email: this.protocolDroidService.translate(
            'shared.ui-elements.async-action-form.label-email',
            'Envoyer un email'
          ),
          api: this.protocolDroidService.translate(
            'shared.ui-elements.async-action-form.label-api',
            'Requêter une API'
          ),
          workflow: this.protocolDroidService.translate(
            'shared.ui-elements.async-action-form.label-workflow',
            'Déclencher un workflow'
          ),
          operations: this.protocolDroidService.translate(
            'shared.ui-elements.async-action-form.label-operations',
            'Modifier des données'
          )
        };
      })
    );
  }

  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
    }
  }
}
