import { Pipe, PipeTransform } from '@angular/core';
import { formats } from '../models/regex-formats.model';

@Pipe({
  name: 'simpleText'
})
export class SimpleTextPipe implements PipeTransform {
  transform(value: string): any {
    return (value || '').replace(/\s|\\n/g, ' ').replace(formats.regex('taggedData'), '@');
  }
}
