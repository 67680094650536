import { Component, OnInit, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { uniqueId } from 'lodash';

import { SaveNlpForm } from './save-nlp-form.model';
import { BaseMappingFormComponent } from '../../shared/base-mapping-form.component';
import { DataKeyFormControl } from '../../../models/data-key-form-control.model';
import { CoreSessionService } from '../../../services/session/core-session.service';
import { LexiconDictionnary } from '../nlp-form/nlp-form.component';

@Component({
  selector: 'app-save-nlp-form',
  templateUrl: './save-nlp-form.component.html',
  styleUrls: ['./save-nlp-form.component.scss']
})
export class SaveNlpFormComponent extends BaseMappingFormComponent implements OnInit {
  @Input() parentForm: SaveNlpForm;
  @Input() dicoLexicon: LexiconDictionnary;
  componentId: string;
  newEntityMapping: FormControl;
  showEntityDropdown: boolean;
  expandedEntities: { [key: string]: boolean };

  get saveNlpActive(): FormControl {
    return this.parentForm.get('active') as FormControl;
  }

  get saveNlpOptions(): FormGroup {
    return this.parentForm.get('options') as FormGroup;
  }

  get saveNlpInput(): DataKeyFormControl {
    return this.saveNlpOptions.get('input') as DataKeyFormControl;
  }

  get saveNlpIntent(): DataKeyFormControl {
    return this.saveNlpOptions.get('intent') as DataKeyFormControl;
  }

  get saveNlpEntities(): FormGroup {
    return this.saveNlpOptions.get('entities') as FormGroup;
  }

  get entitiesList(): string[] {
    return Object.keys(this.saveNlpEntities.controls) || [];
  }

  constructor(public _session: CoreSessionService) {
    super();
    this.componentId = uniqueId();
    this.dicoLexicon = { intents: {}, entities: {} };
    this.newEntityMapping = new FormControl();
    this.expandedEntities = {};
  }

  ngOnInit() {}

  activate() {
    this.parentForm.updateActive(this.saveNlpActive.value);
  }

  getEntity(entity: string): FormGroup {
    return this.saveNlpEntities.get(entity) as FormGroup;
  }

  getEntityNameKey(entity: string): DataKeyFormControl {
    return this.getEntity(entity).get('name') as DataKeyFormControl;
  }

  getEntityValues(entity: string): FormGroup {
    return this.getEntity(entity).get('values') as FormGroup;
  }

  getEntityValuesKey(entity: string): DataKeyFormControl {
    return this.getEntityValues(entity).get('key') as DataKeyFormControl;
  }

  getEntityValuesIsMultiple(entity: string): FormControl {
    return this.getEntityValues(entity).get('isMultipleValue') as FormControl;
  }

  addEntityMapping() {
    const value = this.newEntityMapping.value;
    if (value && value !== '[WHATEVER]' && value !== '[MULTIPLE]' && !this.saveNlpEntities.get(value)) {
      this.parentForm.registerEntityMapping(value);
      this.expandedEntities[value] = true;
      this.newEntityMapping.patchValue('');
      this.showEntityDropdown = false;
    }
  }

  removeEntityMapping(entity: string) {
    this.saveNlpEntities.removeControl(entity);
  }

  resetControl(control: DataKeyFormControl, isMultipleValue?: FormControl) {
    control.clear();
    if (isMultipleValue) {
      isMultipleValue.patchValue(undefined);
      isMultipleValue.disable();
    }
  }

  enableNewEntity() {
    this.newEntityMapping.patchValue('');
    this.showEntityDropdown = true;
  }
}
