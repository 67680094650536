import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  OnDestroy
} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Observable, Subscription } from 'rxjs';

import { ColorService } from '../../../../shared/services/color/color.service';

@Component({
  selector: 'app-layered-input',
  templateUrl: './layered-input.component.html',
  styleUrls: ['./layered-input.component.scss']
})
export class LayeredInputComponent implements OnInit, OnDestroy {
  @ViewChild('layeredinput', { static: true }) input: ElementRef;
  @Input() text: string;
  @Input() layers: any;
  @Input() placeholder?: string;
  @Input() focus$?: Observable<void>;
  @Input() spinning?: boolean;
  @Output() selection: EventEmitter<string>;
  @Output() update: EventEmitter<string>;
  @Output() blur: EventEmitter<void>;
  @Output() reset: EventEmitter<void>;
  @Output() deleteLayer: EventEmitter<any>;
  private subscriptions: Subscription[];

  constructor(private sanitizer: DomSanitizer, private colorService: ColorService) {
    this.selection = new EventEmitter();
    this.update = new EventEmitter();
    this.blur = new EventEmitter();
    this.reset = new EventEmitter();
    this.deleteLayer = new EventEmitter();
    this.subscriptions = [];
  }

  ngOnInit() {
    if (this.focus$) {
      this.subscriptions.push(
        this.focus$.subscribe(() => {
          const input = this.input.nativeElement;
          input.focus();
        })
      );
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  updateSelection(): void {
    this.selection.emit(window.getSelection().toString());
  }

  resetInput(): void {
    this.input.nativeElement.value = this.text;
    this.reset.emit();
  }

  getLayerHtml(layer: any, index: number): SafeHtml {
    if (!this.text.includes(layer.text)) {
      this.deleteLayer.emit(index);
      return;
    } else {
      return this.sanitizer.bypassSecurityTrustHtml(
        this.text.replace(layer.text, this.getWrappedHtml(layer))
      );
    }
  }

  private getWrappedHtml(layer: any): string {
    return `<span style="
    background-color:${this.colorService.hexToRGB(layer.color, 0.25)};
    padding: 0.15rem 0;
    box-shadow: 0px 0px 0px 0.1rem ${this.colorService.hexToRGB(layer.color, 0.25)};
    border-radius: 0.1rem;
    ">${layer.text}</span> `;
  }
}
