import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup, FormControl, FormArray } from '@angular/forms';

import { MappingType } from 'ideta-library/lib/common/node';

import { NodeMappingForm } from './node-mapping-form.model';
import { MappingAsyncActionForm } from './mapping-async-action-form/mapping-async-action-form.model';
import { BaseMappingFormComponent } from './shared/base-mapping-form.component';

type OtherOptionType = 'options' | 'actions';

export interface MappingIconType {
  key: MappingType | OtherOptionType;
  ctrlName: string;
  icon: string;
  type: 'mapping' | 'option';
}

@Component({
  selector: 'app-node-mapping-form',
  templateUrl: './node-mapping-form.component.html',
  styleUrls: ['./node-mapping-form.component.scss']
})
export class NodeMappingFormComponent extends BaseMappingFormComponent implements OnInit, OnChanges {
  @Input() parentForm: NodeMappingForm;
  mappingTypes: MappingIconType[];
  otherOptions: MappingIconType[];
  selectedOtherOption: OtherOptionType;
  expandedActions: { [index: number]: boolean };
  dicoMappingTypes: any;

  get type(): FormControl {
    return this.parentForm.get('type') as FormControl;
  }

  get dataInput(): FormGroup {
    return this.parentForm.get('dataInput') as FormGroup;
  }

  get goToNode(): FormGroup {
    return this.parentForm.get('goToNode') as FormGroup;
  }

  get switch(): FormGroup {
    return this.parentForm.get('switch') as FormGroup;
  }

  get options(): FormGroup {
    return this.parentForm.get('options') as FormGroup;
  }

  get actions(): FormArray {
    return this.parentForm.get('actions') as FormArray;
  }

  constructor() {
    super();
    this.mappingTypes = [
      {
        key: 'go-to-node',
        ctrlName: 'goToNode',
        icon: '/assets/img/mapping-forward.svg',
        type: 'mapping'
      },
      {
        key: 'data-input',
        ctrlName: 'dataInput',
        icon: '/assets/img/mapping-text-input.svg',
        type: 'mapping'
      },

      {
        key: 'switch',
        ctrlName: 'switch',
        icon: '/assets/img/mapping-invisible.svg',
        type: 'mapping'
      }
    ];
    this.otherOptions = [
      {
        key: 'actions',
        ctrlName: 'actions',
        icon: '/assets/img/mapping-actions.svg',
        type: 'option'
      },
      {
        key: 'options',
        ctrlName: 'options',
        icon: '/assets/img/mapping-options.svg',
        type: 'option'
      }
    ];
    this.dicoMappingTypes = [...this.mappingTypes, ...this.otherOptions].reduce(
      (acc: any, curr: any) => (acc[curr.key] = curr) && acc,
      {}
    );
    this.expandedActions = {};
  }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.parentForm) this.selectedOtherOption = undefined;
  }

  setType(type: MappingType | OtherOptionType) {
    if (this.isMappingType(type)) {
      this.parentForm.updateType(type);
      this.selectedOtherOption = null;
    } else this.selectedOtherOption = type;
  }

  addAction() {
    this.actions.push(new MappingAsyncActionForm());
    this.expandedActions[this.actions.length - 1] = true;
  }

  removeAction(index: number) {
    this.expandedActions[index] = false;
    this.actions.removeAt(index);
  }

  trackByFn(index: number): number {
    return index;
  }

  private isMappingType(type: MappingType | OtherOptionType): type is MappingType {
    return this.dicoMappingTypes[type].type === 'mapping';
  }
}
