import { FormGroup, FormArray, Validators, ValidationErrors } from '@angular/forms';

import { GenericElement, TemplateDefaultActionType } from 'ideta-library/lib/common/node';

import { ButtonElementFormFactory } from './button-element-form.factory';
import { ButtonsListForm } from '../shared/buttons-list-form/buttons-list-form.model';
import { RichInputFormControl } from '../../models/rich-input-form-control.model';
import { urlOrKeyValidator } from '../../models/custom-validators';

const defaultElement: GenericElement = {
  title: 'Title',
  subtitle: 'Subtitle',
  buttons: [
    {
      title: 'My Button',
      type: 'postback',
      targetNode: 'noeud_0'
    }
  ]
};

export class GenericElementForm extends FormGroup {
  constructor(template: GenericElement = defaultElement) {
    const formGroup = {
      title: new RichInputFormControl(template.title, { validators: [Validators.required] }),
      subtitle: new RichInputFormControl(template.subtitle),
      imageUrl: new RichInputFormControl(template.imageUrl, {
        validators: [urlOrKeyValidator]
      }),
      buttons: new ButtonsListForm(template.buttons)
    };

    if (template.defaultAction) {
      Object.assign(formGroup, {
        defaultAction: ButtonElementFormFactory.factory(template.defaultAction, {
          removeTitle: true
        })
      });
    }

    super(formGroup, { validators: GenericElementForm.atListOneMoreValidator });
  }

  static atListOneMoreValidator = (control: FormGroup): ValidationErrors | null => {
    const title = control.get('title') && control.get('title').value;
    const subtitle = control.get('subtitle') && control.get('subtitle').value;
    const imageUrl = control.get('imageUrl') && control.get('imageUrl').value;
    const buttons = control.get('buttons') as FormArray;

    return title && !subtitle && !imageUrl && !(buttons && buttons.controls.length > 0) ? { needOneMore: true } : null;
  };

  updateDefaultActionType(type: TemplateDefaultActionType) {
    if (this.get('defaultAction').get('type').value !== type) {
      this.setControl('defaultAction', { ...this.get('defaultAction').value, type });
    }
  }
}
