import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription, Subject } from 'rxjs';

import { MessengerProfileForm, ProfileKey } from './messenger-profile-form.model';
import { FacebookService } from '../services/facebook/facebook.service';
import { ProtocolDroidService } from '../protocol-droid/services/protocol-droid.service';
import { FlashStatus } from '../ui-elements/components/button-flashing/button-flashing.component';
import { WhitelistedDomainsForm } from './whitelisted-domains-form/whitelisted-domains-form.model';
import { AccountUrlForm } from './account-url-form/account-url-form.model';
import { GetStartedForm } from './get-started-form/get-started-form.model';
import { GreetingForm } from './greeting-form/greeting-form.model';
import { HomeUrlForm } from './home-url-form/home-url-form.model';
import { PersistentMenuForm } from './persistent-menu-form/persistent-menu-form.model';
import { TargetAudienceForm } from './target-audience-form/target-audience-form.model';

@Component({
  selector: 'app-messenger-profile-form',
  templateUrl: './messenger-profile-form.component.html',
  styleUrls: ['./messenger-profile-form.component.scss']
})
export class MessengerProfileFormComponent implements OnInit, OnDestroy {
  @Input() pageToken: string;
  @Input() profileForm: MessengerProfileForm;
  @Output() reload: EventEmitter<void>;
  profileKeys: { [key in ProfileKey]: string };
  deleteBtnStatus$: Subject<FlashStatus>;
  deleteBtnLoading: boolean;
  deleteError: string;
  private subscriptions: Subscription[];

  get profileKey(): FormControl {
    return this.profileForm.get('profileKey') as FormControl;
  }

  get accountLinkingUrl(): AccountUrlForm {
    return this.profileForm.get('account_linking_url') as AccountUrlForm;
  }

  get getStarted(): GetStartedForm {
    return this.profileForm.get('get_started') as GetStartedForm;
  }

  get greeting(): GreetingForm {
    return this.profileForm.get('greeting') as GreetingForm;
  }

  get homeUrl(): HomeUrlForm {
    return this.profileForm.get('home_url') as HomeUrlForm;
  }

  get persistentMenu(): PersistentMenuForm {
    return this.profileForm.get('persistent_menu') as PersistentMenuForm;
  }

  get targetAudience(): TargetAudienceForm {
    return this.profileForm.get('target_audience') as TargetAudienceForm;
  }

  get whitelistedDomains(): WhitelistedDomainsForm {
    return this.profileForm.get('whitelisted_domains') as WhitelistedDomainsForm;
  }

  constructor(private facebookService: FacebookService, private protocolDroidService: ProtocolDroidService) {
    this.reload = new EventEmitter();
    this.deleteBtnStatus$ = new Subject();
    this.subscriptions = [];
  }

  ngOnInit() {
    this.subscriptions.push(
      this.protocolDroidService.onTranslationChangeEvent.subscribe(
        () =>
          (this.profileKeys = {
            account_linking_url: this.protocolDroidService.translate(
              'shared.components.modals.messenger-profile.form.profile-key-value-account_linking_url',
              "URL d'association de compte"
            ),
            get_started: this.protocolDroidService.translate(
              'shared.components.modals.messenger-profile.form.profile-key-value-get_started',
              'Bouton Démarrer'
            ),
            greeting: this.protocolDroidService.translate(
              'shared.components.modals.messenger-profile.form.profile-key-value-greeting',
              'Message de bienvenue'
            ),
            // This fiel has been deprecated by Facebook since v7.0
            // home_url: this.protocolDroidService.translate(
            //   'shared.components.modals.messenger-profile.form.profile-key-value-home_url',
            //   "URL de page d'accueil"
            // ),
            // persistent_menu: this.protocolDroidService.translate(
            //   'shared.components.modals.messenger-profile.form.profile-key-value-persistent_menu',
            //   'Menu persistent'
            // ),
            target_audience: this.protocolDroidService.translate(
              'shared.components.modals.messenger-profile.form.profile-key-value-target_audience',
              "Cible d'audience"
            ),
            whitelisted_domains: this.protocolDroidService.translate(
              'shared.components.modals.messenger-profile.form.profile-key-value-whitelisted_domains',
              'Domaines autorisés'
            )
          })
      ),
      this.profileKey.valueChanges.subscribe(() => (this.deleteError = null))
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  clearProfile() {
    this.deleteBtnLoading = true;
    this.deleteError = null;
    this.facebookService
      .clearMessengerProfileKey(this.profileKey.value, this.pageToken)
      .then(() => {
        this.deleteBtnStatus$.next('success');
        this.reload.emit();
      })
      .catch((error: any) => {
        this.deleteError = error.message;
        this.deleteBtnStatus$.next('error');
      })
      .then(() => (this.deleteBtnLoading = false));
  }
}
