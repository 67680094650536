import { Component, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';

import { MessageDisplay } from '../../components/bot-display/bot-display.types';
import { DisplayOptions } from '../../models/display-options.model';

@Component({
  selector: 'app-base-preview',
  template: ''
})
export class BasePreviewComponent implements OnDestroy {
  @Input() message: MessageDisplay;
  @Input() displayOptions: DisplayOptions;
  @Output() send: EventEmitter<any>;
  protected subscriptions: Subscription[];

  constructor() {
    this.displayOptions = new DisplayOptions();
    this.send = new EventEmitter();
    this.subscriptions = [];
  }

  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
    }
  }
}
