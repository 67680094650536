import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AngularFireDatabase, AngularFireAction, DatabaseSnapshot } from '@angular/fire/database';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Actor, Conversation, ConversationMetadata } from 'ideta-library/lib/common/conversation';

import { BotSessionService } from '../session/bot-session.service';
import { UserSessionService } from '../session/user-session.service';
import { ChannelSessionService } from '../session/channel-session.service';

import { environment } from '../../../../environments/environment';

/*
 * NOTE : Functions where 'backendUrl' is specified as an argument,
 *        are functions that may be called in web-display
 */

@Injectable({
  providedIn: 'root'
})
export class ConversationService {
  private undefinedConversationIdError: Error;

  constructor(
    private http: HttpClient,
    private db: AngularFireDatabase,
    private _bot: BotSessionService,
    private _channel: ChannelSessionService,
    private _user: UserSessionService
  ) {
    this.undefinedConversationIdError = new Error('Conversation ID is undefined');
  }

  getConversation(conversationId: string): Observable<any> {
    if (!conversationId) throw this.undefinedConversationIdError;
    return this.db
      .object<Conversation>(this.conversationsRef(conversationId))
      .snapshotChanges()
      .pipe(
        map((conv: AngularFireAction<DatabaseSnapshot<any>>) =>
          conv.key && conv.payload.val() ? { ...conv.payload.val(), userId: conv.key } : null
        )
      );
  }

  getConversations(options: {
    direction: 'first' | 'previous' | 'next' | 'last' | 'unique';
    length?: number;
    position?: string;
  }): Observable<Conversation[]> {
    const length = options.length || environment.appSettings.maxPaginatedUserListLength;
    const direction = options.direction || 'last';

    return (
      (direction === 'first' && this.getFirstConversations(length)) ||
      (direction === 'previous' && this.getPreviousConversations(options.position, length)) ||
      (direction === 'next' && this.getNextConversations(options.position, length)) ||
      (direction === 'last' && this.getLastConversations(length)) ||
      (direction === 'unique' &&
        this.getConversation(options.position).pipe(map(conversation => (conversation ? [conversation] : []))))
    );
  }

  getConversationsByDate(length?: number): Observable<Conversation[]> {
    length = length || environment.appSettings.maxLastUserListLength;
    return this.db
      .list(this.conversationsRef(), ref => ref.orderByChild('last_sent').limitToLast(length))
      .snapshotChanges()
      .pipe(
        map((convs: AngularFireAction<DatabaseSnapshot<any>>[]) =>
          convs.map(conv => ({ ...conv.payload.val(), userId: conv.key })).reverse()
        )
      );
  }

  searchConversation(keyword: string): Observable<Conversation[]> {
    return this.db
      .list(this.conversationsRef(), ref =>
        ref
          .orderByKey()
          .startAt(keyword)
          .endAt(keyword + '\uf8ff')
          .limitToFirst(50)
      )
      .snapshotChanges()
      .pipe(
        map((data: AngularFireAction<DatabaseSnapshot<any>>[]) =>
          data.map((elem: AngularFireAction<DatabaseSnapshot<any>>) => elem.payload.val())
        )
      );
  }

  deleteConversation(conversationIdList: string[] = []): Promise<any> {
    return this.http
      .post(`${this._bot.endPointBack}/conversations/delete`, {
        botId: this._bot.id,
        channel: this._channel.type,
        conversationIdList
      })
      .toPromise();
  }

  resetConversation(conversationId: string): Observable<any> {
    if (!conversationId) throw this.undefinedConversationIdError;
    return this.http.post(`${this._bot.endPointBack}/conversations/reset`, {
      botId: this._bot.id,
      channel: this._channel.type,
      conversationId
    });
  }

  getConversationStatus(conversationId: string): Observable<string> {
    if (!conversationId) throw this.undefinedConversationIdError;
    return this.db.object<string>(this.conversationsRef(conversationId) + '/status').valueChanges();
  }

  resetNotifications(conversationId: string): void {
    if (!conversationId) throw this.undefinedConversationIdError;
    this.db.object(this.conversationsRef(conversationId)).update({ isRead: true, 'data/ideta_notify': null });
  }

  updateConversationLabel(conversationId: string, label: string): void {
    if (!conversationId) throw this.undefinedConversationIdError;
    this.db.object(this.conversationsRef(conversationId)).update({ label });
  }

  assignConversation(conversationId: string): void {
    if (!conversationId) throw this.undefinedConversationIdError;
    this.db.object(this.conversationsRef(conversationId)).update({ assigneeId: this._user.id });
  }

  readMetadata(conversationId: string): Observable<ConversationMetadata> {
    if (!conversationId) throw this.undefinedConversationIdError;
    return this.db.object<ConversationMetadata>(this.conversationsRef(conversationId) + '/metadata').valueChanges();
  }

  updateMetadata(conversationId: string, actor: Actor, data: string, value: any) {
    if (!conversationId) throw this.undefinedConversationIdError;
    return this.db.object(this.conversationsRef(conversationId) + `/metadata/${actor}`).update({ [data]: value });
  }

  getConversationData(conversationId: string) {
    if (!conversationId) throw this.undefinedConversationIdError;
    return this.db.object(this.conversationsRef(conversationId) + '/data').valueChanges();
  }

  private getNextConversations(position: string, length: number): Observable<Conversation[]> {
    return this.db
      .list(this.conversationsRef(), ref =>
        ref
          .orderByKey()
          .startAt(position)
          .limitToFirst(length + 1)
      )
      .snapshotChanges()
      .pipe(
        map((convs: AngularFireAction<DatabaseSnapshot<any>>[]) => {
          const list = convs.map(conv => ({ ...conv.payload.val(), userId: conv.key }));
          list.splice(0, 1);
          return list;
        })
      );
  }

  private getPreviousConversations(position: string, length: number): Observable<Conversation[]> {
    return this.db
      .list(this.conversationsRef(), ref =>
        ref
          .orderByKey()
          .endAt(position)
          .limitToLast(length + 1)
      )
      .snapshotChanges()
      .pipe(
        map((convs: AngularFireAction<DatabaseSnapshot<any>>[]) => {
          const list = convs.map(conv => ({ ...conv.payload.val(), userId: conv.key }));
          list.splice(-1, 1);
          return list;
        })
      );
  }

  private getFirstConversations(length: number): Observable<Conversation[]> {
    return this.db
      .list(this.conversationsRef(), ref => ref.orderByKey().limitToFirst(length))
      .snapshotChanges()
      .pipe(
        map((convs: AngularFireAction<DatabaseSnapshot<any>>[]) =>
          convs.map(conv => ({ ...conv.payload.val(), userId: conv.key }))
        )
      );
  }

  private getLastConversations(length: number): Observable<Conversation[]> {
    return this.db
      .list(this.conversationsRef(), ref => ref.orderByKey().limitToLast(length))
      .snapshotChanges()
      .pipe(
        map((convs: AngularFireAction<DatabaseSnapshot<any>>[]) =>
          convs.map(conv => ({ ...conv.payload.val(), userId: conv.key }))
        )
      );
  }

  private conversationsRef(conversationId?: string): string {
    return `conversations/${this._bot.id}/${this._channel.type}/${conversationId || ''}`;
  }
}
