import { Component, OnInit, SimpleChanges, OnChanges } from '@angular/core';
import { FormControl, FormArray } from '@angular/forms';

import { DataStoreSessionService } from './../../../services/session/data-store-session.service';
import { BaseTemplateFormComponent } from '../../shared/base-template-form.component';
import { DataKeyFormControl } from '../../../models/data-key-form-control.model';
import { RichInputFormControl } from '../../../models/rich-input-form-control.model';
import { NodeKeyFormControl } from '../../../models/node-key-form-control.model';

@Component({
  selector: 'app-quick-replies-auto-form',
  templateUrl: './quick-replies-auto-form.component.html',
  styleUrls: ['./quick-replies-auto-form.component.scss']
})
export class QuickRepliesAutoFormComponent extends BaseTemplateFormComponent implements OnInit, OnChanges {
  private isPrimitiveList: boolean;
  private isValidObjectList: boolean;

  get listKey(): DataKeyFormControl {
    return this.parentForm.get('listKey') as DataKeyFormControl;
  }

  get title(): RichInputFormControl {
    return this.parentForm.get('title') as RichInputFormControl;
  }

  get imageUrl(): RichInputFormControl {
    return this.parentForm.get('imageUrl') as RichInputFormControl;
  }

  get targetNode(): NodeKeyFormControl {
    return this.parentForm.get('targetNode') as NodeKeyFormControl;
  }

  get fallbackNode(): NodeKeyFormControl {
    return this.parentForm.get('fallbackNode') as NodeKeyFormControl;
  }

  get operations(): FormArray {
    return this.parentForm.get('operations') as FormArray;
  }

  get isValidList(): boolean {
    return this.isPrimitiveList || this.isValidObjectList;
  }

  get hasError(): boolean {
    return this.listKey.value && !this.listKey.errors && !this.isPrimitiveList && !this.isValidObjectList;
  }

  constructor(private _dataStore: DataStoreSessionService) {
    super();
  }

  ngOnInit() {
    this.subscriptions.push(this._dataStore.subject$.subscribe(() => this.updateState()));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.parentForm) {
      this.updateState();
    }
  }

  createNodeDropDownValue(control: FormControl, name: string) {
    this.createNode.emit({
      name,
      callback: (key: string) => this.updateControlValue(control, key)
    });
  }

  private updateControlValue(control: FormControl, value: any) {
    control.patchValue(value);
    this.updateState();
  }

  private updateState() {
    this.isPrimitiveList = this._dataStore.isPrimitiveList(this.listKey.value);
    this.isValidObjectList = this._dataStore.isObjectWithPropertyList(this.listKey.value);
  }
}
