import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl, FormArray } from '@angular/forms';

import { LayoutSize, TemplateFeedType } from 'ideta-library/lib/common/node';

import { TemplateCarrouselForm } from './template-carrousel-form.model';
import { BaseTemplateFormComponent } from '../shared/base-template-form.component';
import { RichInputFormControl } from '../../models/rich-input-form-control.model';
import { ProtocolDroidService } from '../../protocol-droid/services/protocol-droid.service';
import { CoreSessionService } from '../../services/session/core-session.service';

@Component({
  selector: 'app-template-carrousel-form',
  templateUrl: './template-carrousel-form.component.html',
  styleUrls: ['./template-carrousel-form.component.scss']
})
export class TemplateCarrouselFormComponent extends BaseTemplateFormComponent implements OnInit {
  @Input() parentForm: TemplateCarrouselForm;
  feedTypes: { [key in TemplateFeedType]?: string };
  sizes: { [key in LayoutSize]?: string };

  get intro(): RichInputFormControl {
    return this.parentForm.get('intro') as RichInputFormControl;
  }

  get feedType(): FormControl {
    return this.parentForm.get('feedType') as FormControl;
  }

  get size(): FormControl {
    return this.parentForm.get('size') as FormControl;
  }

  get autoOptions(): FormGroup {
    return this.parentForm.get('autoOptions') as FormGroup;
  }

  get elements(): FormArray {
    return this.parentForm.get('elements') as FormArray;
  }

  constructor(private protocolDroidService: ProtocolDroidService, private _session: CoreSessionService) {
    super();
  }

  ngOnInit() {
    this.subscriptions.push(
      this.protocolDroidService.onTranslationChangeEvent.subscribe(() => {
        this.feedTypes = {
          manual: this.protocolDroidService.translate('shared.node-template-form.carrousel.feed-type-manual', 'Manuel')
        };
        // not allowed in broadcast
        if (this._session.location !== 'broadcast') {
          this.feedTypes = {
            ...this.feedTypes,
            auto: this.protocolDroidService.translate(
              'shared.node-template-form.carrousel.feed-type-auto',
              'Automatique'
            )
          };
        }
      })
    );
    this.sizes = {
      small: this.protocolDroidService.translate('shared.node-template-form.carrousel.size-small', 'Petits'),
      medium: this.protocolDroidService.translate('shared.node-template-form.carrousel.size-medium', 'Moyens'),
      tall: this.protocolDroidService.translate('shared.node-template-form.carrousel.size-tall', 'Grands')
    };
  }
}
