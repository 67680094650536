import { BotSessionService } from './../../../services/session/bot-session.service';
import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { trigger, transition, style, animate, state } from '@angular/animations';

@Component({
  selector: 'app-navigation-menu',
  templateUrl: './navigation-menu.component.html',
  styleUrls: ['./navigation-menu.component.scss'],
  animations: [
    trigger('compactAnimation', [
      state('true', style({ opacity: 0, display: 'none' })),
      state('false', style({ opacity: 1, display: 'block' })),
      transition('false <=> true', animate('500ms 500ms'))
    ])
  ]
})
export class NavigationMenuComponent implements OnInit {
  @Input() location: 'edit' | 'deploy' | 'lexicon' | 'cockpit';
  @Input() compact?: boolean;

  constructor(private router: Router, private _bot: BotSessionService) {
    this.compact = false;
  }

  ngOnInit() {}

  navigate(target: string, action: boolean, includeId = true): void {
    if (action) {
      const navigationPath: string[] = [target];
      if (includeId) navigationPath.push(this._bot.id);
      this.router.navigate(navigationPath);
    }
  }
}
