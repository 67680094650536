import { FormControl, ValidatorFn } from '@angular/forms';

import { DataKeyFormControl } from './data-key-form-control.model';
import { SelectedDataStoreElem } from './data-selector.component';
import { formats } from './regex-formats.model';

export class NodeKeyFormControl extends FormControl {
  useAlias: boolean;
  isAlias: boolean;
  aliasForm: DataKeyFormControl;
  defaultValue: string;

  constructor(input: string, validators: ValidatorFn[] = [], useAlias = true, defaultValue?: string) {
    const isAlias = formats.test('aliasedData', input);
    const isElement = formats.test('elementKeyTag', input);
    input = !useAlias && (isAlias || isElement) ? null : input;
    super(input, [...validators]);
    this.useAlias = useAlias;
    this.defaultValue = defaultValue;
    if (useAlias && (isAlias || isElement)) {
      this.isAlias = true;
      this.aliasForm = new DataKeyFormControl(input.slice(3, -3), [], isElement);
    }
  }

  switchMode() {
    if (!this.useAlias) return;
    else if (!this.isAlias && !this.aliasForm) this.aliasForm = new DataKeyFormControl();
    else if (!this.isAlias && this.aliasForm.value) this.patchAliasValue();
    else if (this.isAlias && formats.test('aliasedData', this.value)) this.patchValue(null);
    this.isAlias = !this.isAlias;
  }

  clear() {
    this.patchValue(null);
    if (this.aliasForm) this.aliasForm.clear();
  }

  reset() {
    this.patchValue(this.defaultValue);
    if (this.aliasForm) this.aliasForm.clear();
  }

  patchAliasValue(dataKey: SelectedDataStoreElem = {}) {
    const tag = dataKey.isElement ? formats.elementKeyTag : formats.aliasKeyTag;
    this.patchValue(tag + this.aliasForm.value + tag);
  }
}
