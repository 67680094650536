import { Component, OnInit, OnDestroy /*HostListener*/ } from '@angular/core';
import { Observable, BehaviorSubject, of, Subscription, combineLatest } from 'rxjs';
import { map, takeWhile, filter, take } from 'rxjs/operators';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import { TemplateService } from '../../../services/template/template.service';
import { FeedbackService } from '../../../services/feedback/feedback.service';
import { ProtocolDroidService } from '../../../protocol-droid/services/protocol-droid.service';
import { ErrorsService } from '../../../services/errors/errors.service';

@Component({
  selector: 'app-modal-templates',
  templateUrl: './modal-templates.component.html',
  styleUrls: ['./modal-templates.component.scss']
})
export class ModalTemplatesComponent implements OnInit, OnDestroy {
  checkedNodes$: BehaviorSubject<{ [id: string]: boolean }>;
  selectedTemplate$: BehaviorSubject<any>;
  templatePrefix$: BehaviorSubject<string>;
  newTemplateName$: BehaviorSubject<string>;
  templatesList$: Observable<any>;
  templateNodes$: Observable<any>;
  isCreatable: boolean;
  nbCheckedNodes: number;
  activeMenu: string;
  showDeleteConfirmation: boolean;
  isNodesChecked: boolean;
  private subscriptions: Subscription[];
  private hiddenCallback: Function; // Will be overwritten at run time

  // ---> disabled because non user friendly
  // @HostListener('window:keyup', ['$event'])
  // onEnter(event: KeyboardEvent) {
  //   if (event.key === 'Enter') {
  //     this.cancel();
  //   }
  // }

  constructor(
    private modalRef: BsModalRef,
    private modalService: BsModalService,
    private templateService: TemplateService,
    private feedbackService: FeedbackService,
    private protocolDroidService: ProtocolDroidService,
    private errorsService: ErrorsService
  ) {
    this.templatesList$ = of([]);
    this.selectedTemplate$ = new BehaviorSubject(null);
    this.templatePrefix$ = new BehaviorSubject('');
    this.newTemplateName$ = new BehaviorSubject('');
    this.checkedNodes$ = new BehaviorSubject({});
    this.activeMenu = 'import';
    this.hiddenCallback = Function.prototype; // stops error when closing modal by click
    this.subscriptions = [];
  }

  ngOnInit() {
    this.templatesList$ = this.templateService.getTemplatesList();
    this.templateNodes$ = this.selectedTemplate$.pipe(
      filter((template: any) => !!template),
      map((template: any) =>
        Object.keys(template.nodes).map((key: any) => ({
          key,
          name: template.nodes[key].name,
          category: template.nodes[key].category
        }))
      )
    );
    this.subscriptions.push(
      combineLatest([this.checkedNodes$, this.newTemplateName$, this.templatesList$]).subscribe(
        ([checkedNodes, templateName, templates]) => {
          this.nbCheckedNodes = Object.keys(checkedNodes).filter((key: string) => checkedNodes[key]).length;
          this.isCreatable = this.nbCheckedNodes > 0 && this.isUniqueTemplateName(templates, templateName);
        }
      )
    );
    this.modalService.onHidden.pipe(take(1)).subscribe(() => {
      this.hiddenCallback();
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  checkNodes(selection: any, all?: boolean): void {
    if (all) {
      const keyList = Object.keys(this.checkedNodes$.value);
      keyList.forEach((key: string) => (this.checkedNodes$.value[key] = selection.value));
      this.isNodesChecked = selection.value;
      this.nbCheckedNodes = selection.value ? keyList.length : 0;
    } else {
      this.checkedNodes$.next(
        selection.scope.reduce((prev: any, curr: any) => {
          prev[curr] = selection.value;
          return prev;
        }, this.checkedNodes$.value)
      );
      this.isNodesChecked = !!Object.keys(this.checkedNodes$.value).find(
        (key: string) => this.checkedNodes$.value[key]
      );
    }
  }

  validate(): void {
    if (this.activeMenu === 'create' && this.isCreatable) {
      this.hiddenCallback = () => {
        this.feedbackService.showSpinner().then(() => {
          this.createTemplate();
        });
      };
    } else if (this.activeMenu === 'import') {
      this.hiddenCallback = () => {
        this.feedbackService.showSpinner().then(() => {
          this.loadTemplate();
        });
      };
    } else {
      return;
    }
    this.modalRef.hide();
  }

  cancel(): void {
    this.hiddenCallback = Function.prototype; // Noop
    this.modalRef.hide();
  }

  deleteTemplate(templateId) {
    this.templateService.deleteTemplate(templateId).then(() => {
      this.selectedTemplate$.next(null);
      this.showDeleteConfirmation = false;
    });
  }

  private isUniqueTemplateName(templatesList: any, newTemplateName: string): boolean {
    return newTemplateName && !templatesList.some((template: any) => template.name === newTemplateName);
  }

  private loadTemplate(): void {
    this.templateService
      .startDeployment(this.selectedTemplate$.value.key, this.templatePrefix$.value)
      .then((poller: any) => {
        poller
          .pipe(
            takeWhile((status: string) => status === 'pending', true),
            filter((status: string) => status !== 'pending')
          )
          .subscribe((status: string) => {
            this.handleDeploymentStatus(status).then(() => this.feedbackService.hideSpinner());
          });
      });
  }

  private createTemplate(): void {
    this.templateService
      .createTemplate(this.checkedNodes$.value, this.newTemplateName$.value)
      .then((poller: Observable<string>) => {
        poller
          .pipe(
            takeWhile((status: string) => status === 'creating', true),
            filter((status: string) => status !== 'creating')
          )
          .subscribe((status: string) => {
            this.handleDeploymentStatus(status).then(() => this.feedbackService.hideSpinner());
          });
      })
      .catch(this.errorsService.handlePromise());
  }

  private handleDeploymentStatus(status: string): Promise<boolean> {
    if (status === 'success') {
      return this.feedbackService.showMessage(
        this.protocolDroidService.translate(
          'shared.components.modals.templates.load-success',
          'The template was successfully loaded!'
        ),
        {
          title: this.protocolDroidService.translate('misc.modals.success-title', 'Congratulations'),
          decoration: 'success'
        }
      );
    } else {
      return this.feedbackService.showMessage(
        this.protocolDroidService.translate(
          'misc.errors.unknown',
          'An unknown error occurred. Please contact support.'
        ),
        {
          title: this.protocolDroidService.translate('misc.modals.error-title', 'Oops...'),
          decoration: 'error'
        }
      );
    }
  }
}
