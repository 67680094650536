import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormArray } from '@angular/forms';

import { DataComparisonForm } from '../../../models/data-comparison-form.model';
import { KeyCreationEvent } from '../../../models/data-selector.component';

@Component({
  selector: 'app-data-comparison-list',
  templateUrl: './data-comparison-list.component.html',
  styleUrls: ['./data-comparison-list.component.scss']
})
export class DataComparisonListComponent implements OnInit {
  @Input() comparisons: FormArray;
  @Output() createDataKey: EventEmitter<KeyCreationEvent>;
  @Output() createNode: EventEmitter<{
    name: string;
    callback: (key: string) => void;
  }>;

  constructor() {
    this.createDataKey = new EventEmitter();
    this.createNode = new EventEmitter();
  }

  ngOnInit() {}

  addComparison() {
    this.comparisons.push(new DataComparisonForm());
  }

  removeComparison(index: number) {
    this.comparisons.removeAt(index);
  }

  trackByFn(index: number): number {
    return index;
  }

  handleOrderChange(event: 'up' | 'down', index: number) {
    const array = [...this.comparisons.controls];
    if (
      (event === 'up' && index > 0) ||
      (event === 'down' && index < this.comparisons.controls.length)
    ) {
      const temp = array[index];
      array[index] = array[event === 'up' ? index - 1 : index + 1];
      array[event === 'up' ? index - 1 : index + 1] = temp;
      this.comparisons.controls = array;
      this.comparisons.updateValueAndValidity();
    }
  }
}
