import { Component, OnInit } from '@angular/core';

import { MessageTemplateReceipt } from 'ideta-library/lib/common/message';

import { BasePreviewComponent } from '../shared/base-preview.component';
import { ColorService } from '../../services/color/color.service';

@Component({
  selector: 'app-template-receipt-preview',
  templateUrl: './template-receipt-preview.component.html',
  styleUrls: ['./template-receipt-preview.component.scss']
})
export class TemplateReceiptPreviewComponent extends BasePreviewComponent implements OnInit {
  get template() {
    return this.message.template as MessageTemplateReceipt;
  }

  constructor(private colorService: ColorService) {
    super();
  }

  ngOnInit() {}

  get subTitleColor(): string {
    return this.colorService.hexToHSL(this.displayOptions.botColors.text, -30);
  }
}
