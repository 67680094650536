import { FormControl, ValidationErrors } from '@angular/forms';
import { formats } from './regex-formats.model';
import { RichInputFormControl } from './rich-input-form-control.model';
import { CachedFormControl } from './cached-form-control.model';

export const urlValidator = (control: FormControl): ValidationErrors | null => {
  const url: string = control.value;

  return url && !formats.test('url', url, 'i') ? { invalidUrl: true } : null;
};

export const urlOrKeyValidator = (control: FormControl): ValidationErrors | null => {
  const url: string = control.value;

  return url && !formats.test('urlOrKey', url, 'i') ? { invalidUrl: true } : null;
};

export const multiplePathValidator = (control: RichInputFormControl): ValidationErrors | null => {
  const value = control.renderedValue;
  return value && !formats.test('apiLocationPath', value) ? { invalidPath: true } : null;
};

export const simplePathValidator = (control: RichInputFormControl): ValidationErrors | null => {
  const value = control.renderedValue;
  return value && !formats.test('simpleLocationPath', value) ? { invalidPath: true } : null;
};

export const keyNameValidator = (control: CachedFormControl): ValidationErrors | null => {
  return control.value && !formats.test('strictSubjectName', control.value)
    ? { invalidKey: true }
    : null;
};

export const propertyNameValidator = (control: CachedFormControl): ValidationErrors | null => {
  return control.value && !formats.test('strictPropertyName', control.value)
    ? { invalidProperty: true }
    : null;
};
