import { Component, OnInit, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Subscription, BehaviorSubject } from 'rxjs';
import { switchMap, catchError, tap } from 'rxjs/operators';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { MessengerProfileForm } from '../../../messenger-profile-form/messenger-profile-form.model';
import { BotFacebookService } from '../../../services/bot/bot-facebook/bot-facebook.service';
import { FacebookService } from '../../../services/facebook/facebook.service';
import { NavTab } from '../../../ui-elements/components/nav-tabs/nav-tabs.component';
import { NavPanel } from '../../../ui-elements/components/nav-panels/nav-panels.component';
import { ProtocolDroidService } from '../../../protocol-droid/services/protocol-droid.service';

export interface ModalMessengerProfileOptions {
  pageId: string;
}

type Menu = NavTab & NavPanel;

@Component({
  selector: 'app-modal-messenger-profile',
  templateUrl: './modal-messenger-profile.component.html',
  styleUrls: ['./modal-messenger-profile.component.scss']
})
export class ModalMessengerProfileComponent implements OnInit, OnDestroy {
  @ViewChild('profile', { static: true }) profileRef: TemplateRef<any>;
  @ViewChild('token', { static: true }) tokenRef: TemplateRef<any>;
  pageId: string;
  menus$: BehaviorSubject<Menu[]>;
  activeMenu$: BehaviorSubject<string>;
  reloadFormTrigger$: BehaviorSubject<void>;
  profileForm: MessengerProfileForm;
  tokenForm: FormControl;
  footerError: string;
  isUpdating: boolean;
  private subscriptions: Subscription[];

  constructor(
    private botFacebookService: BotFacebookService,
    private facebookService: FacebookService,
    private protocolDroidService: ProtocolDroidService,
    private modalRef: BsModalRef
  ) {
    this.menus$ = new BehaviorSubject([]);
    this.activeMenu$ = new BehaviorSubject(null);
    this.profileForm = new MessengerProfileForm();
    this.tokenForm = new FormControl('', Validators.required);
    this.isUpdating = false;
    this.subscriptions = [];
  }

  ngOnInit() {
    this.subscriptions.push(
      this.botFacebookService
        .getPageToken()
        .pipe(
          tap((token: string) => {
            this.tokenForm.patchValue(token);
          }),
          switchMap(() => this.reloadFormTrigger$),
          switchMap(() => this.facebookService.getMessengerProfile(this.tokenForm.value)),
          catchError((error: any) => (this.footerError = error.message))
        )
        .subscribe((profile: any) => {
          this.profileForm.setProfileValue(profile);
        })
    );
    this.menus$.next(this.buildTabsMenu());
    this.activeMenu$.next(this.menus$.value[0].id);
    this.reloadFormTrigger$ = new BehaviorSubject(null);
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  update(): void {
    this.isUpdating = true;
    this.footerError = null;
    Promise.all([
      this.facebookService.updateMessengerProfile(
        this.profileForm.getProfileValue(),
        this.tokenForm.value
      ),
      this.botFacebookService.updatePageToken(this.tokenForm.value)
    ])
      .then(() => this.modalRef.hide())
      .catch((error: any) => (this.footerError = error.message))
      .then(() => (this.isUpdating = false));
  }

  reloadForm() {
    this.reloadFormTrigger$.next();
  }

  done(): void {
    this.modalRef.hide();
  }

  private buildTabsMenu(): Menu[] {
    return [
      {
        id: 'profile',
        name: this.protocolDroidService.translate(
          'shared.components.modals.messenger-profile.tab-profile'
        ),
        ref: this.profileRef
      },
      {
        id: 'token',
        name: this.protocolDroidService.translate(
          'shared.components.modals.messenger-profile.tab-token'
        ),
        ref: this.tokenRef
      }
    ];
  }
}
