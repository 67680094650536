import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { get } from 'lodash';

import {
  AllowedScope,
  AllowedType,
  DataScope,
  DataStoreElem,
  DataStoreElemKey,
  DataStoreElemKeyType
} from 'ideta-library/lib/common/data';

import {
  DataSelectorComponent,
  SelectedDataStoreElem,
  DataStoreElemPreview,
  MenuItem,
  SelectableItems
} from '../../../models/data-selector.component';
import { CoreSessionService } from '../../../services/session/core-session.service';
import { DataStoreSessionService } from './../../../services/session/data-store-session.service';

@Component({
  selector: 'app-data-keys-dropdown-menu',
  templateUrl: './data-keys-dropdown-menu.component.html',
  styleUrls: ['./data-keys-dropdown-menu.component.scss']
})
export class DataKeysDropdownMenuComponent extends DataSelectorComponent implements OnInit {
  @Input() isParentStatic: boolean;
  @Output() selectKey: EventEmitter<SelectedDataStoreElem>;
  @Output() createKey: EventEmitter<DataStoreElemPreview>;
  dataKeysConversation: MenuItem[];
  dataKeysBot: MenuItem[];
  dataKeysSystem: MenuItem[];
  dataKeysElement: MenuItem[];
  hideAddButton: boolean;

  constructor(private _session: CoreSessionService, private _dataStore: DataStoreSessionService) {
    super();
    this.createKey = new EventEmitter();
    this.selectKey = new EventEmitter();
    this.dataKeysConversation = [];
    this.dataKeysBot = [];
    this.dataKeysSystem = [];
    this.dataKeysElement = [];
  }

  ngOnInit() {
    this.hideAddButton = this._session.location !== 'edition';
    this.subscriptions.push(
      this._dataStore.subject$.subscribe(() => {
        this.buildMenuList(this._dataStore.dataStoreList);
      })
    );

    if (this.selectedParentKey) {
      this.subscriptions.push(
        this.selectedParentKey.valueChanges.subscribe(() => (this.dataKeysElement = this.buildElementsList()))
      );
    }
  }

  buildMenuList(dataStore: DataStoreElem[]) {
    const dataKeysConversation = [];
    const dataKeysBot = [];
    const dataKeysSystem = [];

    // Building menu items for Conversation, Bit and System
    dataStore.forEach((dataKey: DataStoreElem) => {
      const { id, key, type, scope, elements, keys } = dataKey;
      const menuItem: MenuItem = {
        id,
        key,
        type,
        scope,
        elements,
        keys,
        selectableItems: this.buildSelectableProperties(dataKey),
        isAllowed: this.isAllowed(dataKey)
      };
      if (menuItem.scope === 'conversation') dataKeysConversation.push(menuItem);
      else if (menuItem.scope === 'bot') dataKeysBot.push(menuItem);
      else if (menuItem.scope === 'system') dataKeysSystem.push(menuItem);
    });

    this.dataKeysConversation = dataKeysConversation;
    this.dataKeysBot = dataKeysBot;
    this.dataKeysSystem = dataKeysSystem;

    // If a parent key is selected, building menu items for Elements
    if (this.selectedParentKey) {
      this.dataKeysElement = this.buildElementsList();
    }
  }

  create(event: DataStoreElemPreview, scope: AllowedScope): void {
    const rawType = this.allowedTypes[0] || 'string';
    const [type, subtype = 'string'] = rawType.split('.');
    const result: any = { ...event, scope, type };
    if (type === 'array') {
      result.elements = { type: subtype as any, length: { key: 'length', type: 'number' } };
    }
    this.createKey.emit(result);
  }

  private buildElementsList(): MenuItem[] {
    const result: MenuItem[] = [];

    const parentArray: DataStoreElem = this._dataStore.getDataStoreElem(this.selectedParentKey.value);
    if (!this._dataStore.isList(parentArray)) return result;
    result.push(
      this.getPropertyMenuItem('___element___', get(parentArray, 'elements.type', 'string'), parentArray.scope),
      this.getPropertyMenuItem('___index___', 'number', parentArray.scope),
      this.getPropertyMenuItem('___length___', 'number', parentArray.scope)
    );
    if (this._dataStore.isObjectWithPropertyList(parentArray)) {
      const keys = parentArray.elements.keys;
      Object.keys(keys).forEach((id: string) => {
        const property = keys[id];
        result.push(this.getPropertyMenuItem(id, property.type, parentArray.scope, property.key));
      });
    }
    return result;
  }

  private buildSelectableProperties(parentKey: DataStoreElem): SelectableItems {
    const result: SelectableItems = {};
    if (this._dataStore.isObjectWithProperty(parentKey)) {
      result.items = Object.keys(parentKey.keys).map((id: string) => {
        const property = parentKey.keys[id];
        return this.getPropertyMenuItem(id, property.type, parentKey.scope, property.key);
      });
    } else if (this._dataStore.isList(parentKey)) {
      const { keys, type, length = { key: 'length', type: 'number' as DataStoreElemKeyType } } = parentKey.elements;
      const defKeys = { ...keys, _element: { key: '_element', type } };

      result.elementItems = Object.keys(defKeys).map((id: string) => {
        const property = defKeys[id];
        return this.getPropertyMenuItem(id, property.type, parentKey.scope, property.key);
      });

      result.items = [
        {
          ...this.getPropertyMenuItem('length', length.type, parentKey.scope, length.key),
          isAllowed: !this.allowedTypes || this.allowedTypes.indexOf('element') > -1
        }
      ];
    }
    return result;
  }

  private getPropertyMenuItem(id: string, type: DataStoreElemKeyType, scope: DataScope, key?: string): MenuItem {
    const element: MenuItem = {
      id,
      key: key || id,
      type,
      scope,
      isAllowed: true
    };
    element.isAllowed = this.isAllowed(element);
    return element;
  }

  private isAllowed(dataKey: DataStoreElem | DataStoreElemKey, parentDataKey?: DataStoreElem): boolean {
    return this.isAllowedScope(dataKey, parentDataKey) && this.isAllowedType(dataKey);
  }

  private isPrimitive(dataKey: DataStoreElem | DataStoreElemKey): dataKey is DataStoreElem {
    return (dataKey as DataStoreElem).scope !== undefined;
  }

  private isAllowedType(dataKey: DataStoreElem | DataStoreElemKey): boolean {
    return (
      !this.allowedTypes ||
      (this.isPrimitive(dataKey) &&
        dataKey.elements &&
        this.allowedTypes.indexOf((dataKey.type + '.' + dataKey.elements.type) as AllowedType) > -1) ||
      this.allowedTypes.indexOf(dataKey.type) > -1
    );
  }

  private isAllowedScope(dataKey: DataStoreElem | DataStoreElemKey, parentDataKey?: DataStoreElem): boolean {
    const scope = this.isPrimitive(dataKey) ? dataKey.scope : parentDataKey.scope;
    return (
      !this.allowedScopes ||
      this.allowedScopes.indexOf(scope) > -1 ||
      (this.isPrimitive(dataKey) &&
        dataKey.scope === 'system' &&
        (dataKey.key === 'first_name' || dataKey.key === 'last_name') &&
        this.allowedScopes.indexOf('identifier') > -1)
    );
  }
}
