import {
  Component,
  AfterViewInit,
  OnDestroy,
  Input,
  ViewChildren,
  QueryList,
  ElementRef,
  Output,
  EventEmitter
} from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { delay } from 'rxjs/operators';

export interface NavTab {
  id: string;
  name: string; // Will be overwritten by protocol droid
  iconClass?: string; // Font awesome icon class
  disabled?: boolean;
  alert?: 'warning' | 'error' | 'none';
}

interface SlidePosition {
  left: number;
  width: number;
}

@Component({
  selector: 'app-nav-tabs',
  templateUrl: './nav-tabs.component.html',
  styleUrls: ['./nav-tabs.component.scss']
})
export class NavTabsComponent implements AfterViewInit, OnDestroy {
  @ViewChildren('tabElement') tabElements: QueryList<ElementRef>;
  @Input() tabs$: BehaviorSubject<NavTab[]>;
  @Input() activeTab$: BehaviorSubject<string>;
  @Input() highlightTabs?: Array<string>;
  @Output() select: EventEmitter<string>;
  sliderPosition: SlidePosition;
  warningIcon: string;
  errorIcon: string;
  private subscriptions: Subscription[];

  constructor() {
    this.select = new EventEmitter();
    this.sliderPosition = { left: 0, width: 0 };
    this.subscriptions = [];
    this.highlightTabs = [];
    this.warningIcon = '<i class="fas fa-exclamation-circle"></i>';
    this.errorIcon = '<i class="fas fa-exclamation-circle text-danger"></i>';
  }

  ngAfterViewInit() {
    this.subscriptions.push(
      this.activeTab$.pipe(delay(0)).subscribe(() => this.moveSlider()),
      this.tabs$.pipe(delay(0)).subscribe(() => this.moveSlider())
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  getSliderPosition(): any {
    const { left, width } = this.sliderPosition;
    return {
      'left.px': left,
      'width.px': width
    };
  }

  selectTab(tab: NavTab) {
    if (!tab.disabled) {
      this.activeTab$.next(tab.id);
      this.select.emit(tab.id);
    }
  }

  trackById(_index: number, tab: NavTab): string {
    return tab.id;
  }

  private moveSlider(): void {
    const activeTabIndex = this.activeTab$.value
      ? this.tabs$.value.findIndex((tab: NavTab) => tab.id === this.activeTab$.value)
      : 0;
    const elements = this.tabElements.toArray();
    const tabElement: HTMLElement = elements[activeTabIndex].nativeElement;
    const ratio = 0.8; // Slide ratio vs. element width
    const left = tabElement.offsetLeft + ((1 - ratio) * tabElement.clientWidth) / 2;
    const width = ratio * tabElement.clientWidth;
    this.sliderPosition = { left, width };
  }
}
