import { FormGroup, Validators, ValidationErrors } from '@angular/forms';

import { GenericAutoOptions, TemplateDefaultActionType } from 'ideta-library/lib/common/node';

import { ButtonsListForm } from '../shared/buttons-list-form/buttons-list-form.model';
import { ButtonElementFormFactory } from './button-element-form.factory';
import { DataKeyFormControl } from '../../models/data-key-form-control.model';
import { RichInputFormControl } from '../../models/rich-input-form-control.model';
import { NodeKeyFormControl } from '../../models/node-key-form-control.model';

const defaultTemplate: GenericAutoOptions = {
  listKey: ''
};

export class GenericAutoForm extends FormGroup {
  constructor(template: GenericAutoOptions = defaultTemplate) {
    const formGroup = {
      listKey: new DataKeyFormControl(template.listKey, [Validators.required]),
      title: new RichInputFormControl(template.title, { validators: [Validators.required] }),
      subtitle: new RichInputFormControl(template.subtitle),
      picture: new RichInputFormControl(template.picture),
      fallbackNode: new NodeKeyFormControl(template.fallbackNode, [Validators.required]),
      buttons: new ButtonsListForm(template.buttons)
    };

    if (template.defaultAction) {
      Object.assign(formGroup, {
        defaultAction: ButtonElementFormFactory.factory(template.defaultAction, {
          removeTitle: true
        })
      });
    }

    super(formGroup, [GenericAutoForm.emptyListKey]);
  }

  /**
   * [invalidListKey description]
   * @param  group [description]
   * @return        [description]
   */
  static emptyListKey = (group: GenericAutoForm): ValidationErrors | null => {
    const listKey = group.get('listKey') as DataKeyFormControl;

    return listKey.value &&
      listKey.dataType === 'array' &&
      listKey.elements &&
      listKey.elements.type === 'object' &&
      (!listKey.elements.keys || !Object.keys(listKey.elements.keys).length)
      ? { emptyListKey: true }
      : null;
  };

  updateDefaultActionType(type: TemplateDefaultActionType) {
    if (this.get('defaultAction').get('type').value !== type) {
      this.setControl('defaultAction', { ...this.get('defaultAction').value, type });
    }
  }
}
