import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnChanges, OnDestroy } from '@angular/core';
import { Subject, combineLatest, of, Subscription } from 'rxjs';
import { startWith, delay, debounceTime } from 'rxjs/operators';

import {
  DataSelectorComponent,
  SelectedDataStoreElem,
  DataStoreElemPreview
} from '../../../models/data-selector.component';
import { DataStoreSessionService } from './../../../services/session/data-store-session.service';
import { RichInputSegmentForm } from '../../../models/rich-input-segment-form.model';
import { ViewEvent } from '../data-keys-rich-input/data-keys-rich-input.component';
import { formats, ParsedAddress } from '../../../models/regex-formats.model';

@Component({
  selector: 'app-data-keys-badge',
  templateUrl: './data-keys-badge.component.html',
  styleUrls: ['./data-keys-badge.component.scss']
})
export class DataKeysBadgeComponent extends DataSelectorComponent implements OnInit, OnChanges, OnDestroy {
  @Input() viewEvent$: Subject<ViewEvent>;
  @Input() segment: RichInputSegmentForm;
  @Output() remove: EventEmitter<void>;
  @Output() selectKey: EventEmitter<SelectedDataStoreElem>;
  showMenuContainer: boolean;
  hideMenuContainerTimer: NodeJS.Timer;
  validityClass: string;
  // now that we stock dataKeys by id we want to display their name as a placeholder
  dataKeyName: string;
  // ###DK This property is used to warn the admin in the case
  // his key is still stored in the previous format
  // and require to be changed
  oldFormat: boolean;
  private segmentSubs: Subscription[];

  constructor(private _dataStore: DataStoreSessionService) {
    super();
    this.selectKey = new EventEmitter();
    this.remove = new EventEmitter();
    this.validityClass = 'scope-none';
    this.segmentSubs = [];
  }

  ngOnInit() {
    this.subscriptions.push(
      this.viewEvent$.subscribe(event => {
        if (event.badge !== undefined) {
          this.showMenuContainer = event.badge;
        }
      }),
      combineLatest([
        this._dataStore.subject$,
        (this.selectedParentKey ? this.selectedParentKey.valueChanges : of(null)).pipe(
          startWith(this.selectedParentKey && this.selectedParentKey.value),
          debounceTime(10)
        )
      ])
        .pipe(
          delay(0) // Avoid ExpressionChangedAfterItHasBeenCheckedError
        )
        .subscribe(() => this.updateSegmentInfos())
    );
    this.subscribeToSegment();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.segment && !changes.segment.firstChange) {
      this.subscribeToSegment();
      // Avoid ExpressionChangedAfterItHasBeenCheckedError
      setTimeout(() => this.updateSegmentInfos(), 0);
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub: Subscription) => sub.unsubscribe());
    this.segmentSubs.forEach((sub: Subscription) => sub.unsubscribe());
  }

  openMenu() {
    this.showMenuContainer = !this.showMenuContainer;
    if (this.showMenuContainer) {
      this.viewEvent$.next({ float: false });
    }
  }

  showContainer() {
    if (this.hideMenuContainerTimer) {
      clearTimeout(this.hideMenuContainerTimer);
      this.hideMenuContainerTimer = null;
    }
  }

  hideContainer() {
    this.hideMenuContainerTimer = setTimeout(() => (this.showMenuContainer = false), 200);
  }

  updateSegment(dataKey: SelectedDataStoreElem) {
    this.selectKey.emit(dataKey);
    this.showMenuContainer = false;
  }

  createKey(dataKey: DataStoreElemPreview) {
    this.createDataKey.emit({
      dataKey,
      callback: (elem: any) => this.updateSegment(elem)
    });
  }

  private updateSegmentInfos() {
    const { name, oldFormat } = this._dataStore.updateSelectedDataInfos(
      this.parseSegmentValue(),
      this.segment,
      this.allowedTypes,
      this.allowedScopes,
      this.selectedParentKey && this.segment.isElement
    );
    this.dataKeyName = name;
    this.oldFormat = oldFormat;
  }

  private parseSegmentValue(): ParsedAddress {
    // get key from segment
    const segment = this.segment && this.segment.value;
    const input: string =
      segment.type === 'element' && formats.test('elementData', segment.value)
        ? segment.value.slice(3, -3)
        : segment.value;

    return segment.type === 'element' && this.selectedParentKey
      ? { subject: this.selectedParentKey.value, property: input }
      : formats.parseAddress(input);
  }

  private getClassFromScope(segment: RichInputSegmentForm): string {
    if (segment.get('type').value === 'element') return 'scope-element';
    else if (!!segment.scope) return 'scope-' + segment.scope;
    else return 'scope-none';
  }

  private subscribeToSegment() {
    this.segmentSubs.forEach((sub: Subscription) => sub.unsubscribe());
    this.segmentSubs = [
      this.segment.valueChanges.pipe(debounceTime(50)).subscribe(() => {
        if (this.segment.displayName) this.dataKeyName = this.segment.displayName;
        this.oldFormat = this.segment.oldFormat;
        if (this.segment.valid) {
          this.validityClass = this.getClassFromScope(this.segment);
        }
      }),
      this.segment.statusChanges.pipe(startWith(this.segment.status)).subscribe((status: string) => {
        if (status === 'INVALID') this.validityClass = 'invalid';
        else this.validityClass = this.getClassFromScope(this.segment);
      })
    ];
  }
}
