import { Component, OnInit, Input } from '@angular/core';
import { FormArray, FormControl } from '@angular/forms';

import { ButtonPostbackForm } from './button-postback-form.model';
import { BaseTemplateFormComponent } from '../base-template-form.component';
import { NodeKeyFormControl } from '../../../models/node-key-form-control.model';
import { CoreSessionService } from '../../../services/session/core-session.service';

@Component({
  selector: 'app-button-postback-form',
  templateUrl: './button-postback-form.component.html',
  styleUrls: ['./button-postback-form.component.scss']
})
export class ButtonPostbackFormComponent extends BaseTemplateFormComponent implements OnInit {
  @Input() parentForm: ButtonPostbackForm;
  @Input() selectedParentKey: FormControl;
  @Input() showTitle: boolean;

  get title(): FormControl {
    return this.parentForm.get('title') as FormControl;
  }

  get targetNode(): NodeKeyFormControl {
    return this.parentForm.get('targetNode') as NodeKeyFormControl;
  }

  get operations(): FormArray {
    return this.parentForm.get('operations') as FormArray;
  }

  constructor(public _session: CoreSessionService) {
    super();
    this.showTitle = true;
  }

  ngOnInit() {}

  createNodeDropDownValue(control: FormControl, name: string) {
    this.createNode.emit({
      name,
      callback: (key: string) => this.updateControlValue(control, key)
    });
  }

  private updateControlValue(control: FormControl, value: any) {
    control.patchValue(value);
  }
}
