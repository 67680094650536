import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormArray, FormControl, Validators } from '@angular/forms';

import { ReceiptElementForm } from './receipt-element-form.model';
import { TemplateReceiptForm } from './template-receipt-form.model';
import { BaseTemplateFormComponent } from '../shared/base-template-form.component';
import { RichInputFormControl } from '../../models/rich-input-form-control.model';

@Component({
  selector: 'app-template-receipt-form',
  templateUrl: './template-receipt-form.component.html',
  styleUrls: ['./template-receipt-form.component.scss']
})
export class TemplateReceiptFormComponent extends BaseTemplateFormComponent implements OnInit {
  @Input() parentForm: TemplateReceiptForm;
  maxElements: number;
  expandedElements: { [key: string]: boolean };

  get recipientName(): RichInputFormControl {
    return this.parentForm.get('recipientName') as RichInputFormControl;
  }

  get orderNumber(): RichInputFormControl {
    return this.parentForm.get('orderNumber') as RichInputFormControl;
  }

  get currency(): RichInputFormControl {
    return this.parentForm.get('currency') as RichInputFormControl;
  }

  get paymentMethod(): RichInputFormControl {
    return this.parentForm.get('paymentMethod') as RichInputFormControl;
  }

  get orderUrl(): RichInputFormControl {
    return this.parentForm.get('orderUrl') as RichInputFormControl;
  }

  get timestamp(): RichInputFormControl {
    return this.parentForm.get('timestamp') as RichInputFormControl;
  }

  get address(): FormGroup {
    return this.parentForm.get('address') as FormGroup;
  }

  get street1(): RichInputFormControl {
    return this.parentForm.get('address.street1') as RichInputFormControl;
  }

  get street2(): RichInputFormControl {
    return this.parentForm.get('address.street2') as RichInputFormControl;
  }

  get city(): RichInputFormControl {
    return this.parentForm.get('address.city') as RichInputFormControl;
  }

  get postalCode(): RichInputFormControl {
    return this.parentForm.get('address.postalCode') as RichInputFormControl;
  }

  get state(): RichInputFormControl {
    return this.parentForm.get('address.state') as RichInputFormControl;
  }

  get country(): RichInputFormControl {
    return this.parentForm.get('address.country') as RichInputFormControl;
  }

  get summary(): FormGroup {
    return this.parentForm.get('summary') as FormGroup;
  }

  get subtotal(): FormControl {
    return this.parentForm.get('summary.subtotal') as FormControl;
  }

  get shippingCost(): FormControl {
    return this.parentForm.get('summary.shippingCost') as FormControl;
  }

  get totalTax(): FormControl {
    return this.parentForm.get('summary.totalTax') as FormControl;
  }

  get totalCost(): FormControl {
    return this.parentForm.get('summary.totalCost') as FormControl;
  }

  get elements(): FormArray {
    return this.parentForm.get('elements') as FormArray;
  }

  get adjustments(): FormArray {
    return this.parentForm.get('adjustments') as FormArray;
  }

  constructor() {
    super();
    this.maxElements = 100;
    this.expandedElements = {};
  }

  ngOnInit() {}

  getElement(index: number): FormGroup {
    return this.elements.at(index) as FormGroup;
  }

  addElement() {
    this.elements.push(new ReceiptElementForm());
    this.expandedElements[this.elements.length - 1] = true;
  }

  removeElement(index: number) {
    this.elements.removeAt(index);
  }

  moveElement(index: number, shift: -1 | 1) {
    const element = this.elements.at(index);
    this.elements.removeAt(index);
    this.elements.insert(index + shift, element);
  }

  getElementTitle(index: number): FormControl {
    return this.getElement(index).get('title') as FormControl;
  }

  getElementImageUrl(index: number): FormControl {
    return this.getElement(index).get('imageUrl') as FormControl;
  }

  getElementPrice(index: number): FormControl {
    return this.getElement(index).get('price') as FormControl;
  }

  getAdjustment(index: number): FormGroup {
    return this.adjustments.at(index) as FormGroup;
  }

  addAdjustment() {
    this.adjustments.push(
      new FormGroup({
        name: new FormControl('', [Validators.required]),
        amount: new FormControl('', [Validators.required])
      })
    );
  }

  removeAdjustment(index: number) {
    this.adjustments.removeAt(index);
  }

  moveAdjustment(index: number, shift: -1 | 1) {
    const adjustment = this.adjustments.at(index);
    this.adjustments.removeAt(index);
    this.adjustments.insert(index + shift, adjustment);
  }

  getAdjustmentName(index: number): FormControl {
    return this.getAdjustment(index).get('name') as FormControl;
  }

  getAdjustmentAmount(index: number): FormControl {
    return this.getAdjustment(index).get('amount') as FormControl;
  }

  isPersonalInfoInvalid(): boolean {
    return (
      this.recipientName.invalid ||
      this.orderNumber.invalid ||
      this.currency.invalid ||
      this.paymentMethod.invalid ||
      this.orderUrl.invalid ||
      this.timestamp.invalid
    );
  }

  trackByFn(index: number): number {
    return index;
  }

  /*
   * Useless for now
   * To keep for future form validation of summary
   */
  // getSelector(value: string): any {
  //   if (RichInputArray.isMatchingKey(value)) {
  //     return new RichInputSegment({ type: 'key', value: value.slice(3, -3) });
  //   }
  //   return new RichInputSegment({ type: 'literal', value });
  // }

  // updateSelector(selector: RichInputSegment, control: FormControl) {
  //   if (selector.type === 'key') control.patchValue('###' + selector._value + '###');
  //   else control.patchValue(selector._value);
  // }
}
