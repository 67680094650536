import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, combineLatest } from 'rxjs';
import { AngularFireDatabase } from '@angular/fire/database';

import { FacebookUserInfos, User } from 'ideta-library/lib/common/user';

import { AuthService } from '../auth/auth.service';
import { UserStorageService } from '../user-storage/user-storage.service';
import { UserSessionService } from '../session/user-session.service';
import { map, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(
    private db: AngularFireDatabase,
    private httpClient: HttpClient,
    private userStorageService: UserStorageService,
    private _user: UserSessionService,
    private auth: AuthService
  ) {}

  public getUser(userId: string): Observable<User> {
    return this.db
      .object<User>(`/users/${userId}`)
      .valueChanges()
      .pipe(map((user: any) => ({ ...user, id: userId })));
  }

  public getUsers(search?: any): Observable<User[]> {
    if (!search) {
      return this.db.list<User>('/users').valueChanges();
    } else {
      const { type, value } = search;
      return this.db
        .list<User>('/users', ref =>
          ref
            .orderByChild(type)
            .startAt(value)
            .endAt(value + '\uf8ff')
            .limitToLast(5)
        )
        .valueChanges();
    }
  }

  public getUsersById(usersList: any[]): Observable<User[]> {
    return combineLatest(
      usersList.map((userIdObject: any) =>
        this.getUser(userIdObject.id).pipe(map((user: any) => ({ ...user, ...userIdObject })))
      )
    );
  }

  public updateUser(user: Partial<User>): Promise<boolean> {
//    console.log('user id : ', this._user.id);
    if (this._user.id) {
      return this.db
        .object(`/users/${this._user.id}`)
        .update(user)
        .catch(error => {
          console.error(error);
          return false;
        })
        .then(() => true);
    } else {
      console.error('Missing user id for update');
      return Promise.resolve(false);
    }
  }

  public sendEmailVerification(): Promise<void> {
    return this.auth
      .getAuth()
      .pipe(take(1))
      .toPromise()
      .then(currentUser => currentUser.sendEmailVerification());
  }

  public updateEmail(email: string): Promise<boolean> {
    return this.auth
      .getAuth()
      .pipe(take(1))
      .toPromise()
      .then(currentUser => currentUser.updateEmail(email))
      .then(() => this.updateUser({ email }));
  }

  public updatePassword(newPassword: string): Promise<void> {
    return this.auth
      .getAuth()
      .pipe(take(1))
      .toPromise()
      .then(currentUser => currentUser.updatePassword(newPassword));
  }

  public updateUserFbInfos(facebookInfos: Partial<FacebookUserInfos>): Promise<boolean> {
    if (facebookInfos) {
      return this.createUserAvatar(facebookInfos, 'facebook').then((avatarUrl: string) => {
        return this.updateUser({
          avatarUrl,
          facebook: facebookInfos as FacebookUserInfos
        });
      });
    } else return this.updateUser({ facebook: null });
  }

  private createUserAvatar(userInfos: any, avatarType: string): Promise<string> {
    const avatarUrl = this.getAvatarUrl(userInfos, avatarType);

    if (avatarUrl) {
      return this.httpClient
        .get(avatarUrl, {
          responseType: 'blob'
        })
        .toPromise()
        .then((avatar: Blob) => this.userStorageService.setFile(`avatar/${avatarType}`, avatar))
        .catch((error: any) => {
          console.error(error);
          return null;
        });
    }
  }

  private getAvatarUrl(userInfos: FacebookUserInfos, avatarType: string): string {
    if (avatarType === 'facebook') {
      return userInfos.picture && userInfos.picture.data && userInfos.picture.data.url;
    }
  }
}
