import { FormGroup, Validators } from '@angular/forms';

import { TemplateButtons } from 'ideta-library/lib/common/node';

import { ButtonsListForm } from '../shared/buttons-list-form/buttons-list-form.model';
import { RichInputFormControl } from '../../models/rich-input-form-control.model';

const defaultTemplate: TemplateButtons = {
  text: 'A button example !',
  buttons: [
    {
      title: 'Button 1',
      type: 'postback',
      targetNode: 'noeud_0',
      operations: []
    },
    {
      title: 'Button 2',
      type: 'postback',
      targetNode: 'noeud_0',
      operations: []
    }
  ]
};

export class TemplateButtonsForm extends FormGroup {
  constructor(template: TemplateButtons = defaultTemplate) {
    const formObject = {
      text: new RichInputFormControl(template.text, { validators: [Validators.required] }),
      buttons: new ButtonsListForm(template.buttons, [Validators.required])
    };

    super(formObject);
  }
}
