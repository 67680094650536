import { Injectable } from '@angular/core';

import { formats } from '../../models/regex-formats.model';

const COLORS = [
  '#f19066',
  '#f5cd79',
  '#546de5',
  '#e15f41',
  '#c44569',
  '#574b90',
  '#f78fb3',
  '#3dc1d3',
  '#e66767',
  '#596275'
];

@Injectable({
  providedIn: 'root'
})
export class ColorService {
  private namespaces: any;

  constructor() {
    this.namespaces = {};
  }

  initNamespace(namespace: string = 'root-namespace'): (id: string) => string {
    if (!this.namespaces[namespace]) {
      this.namespaces[namespace] = {
        colors: this.shuffleColors([...COLORS]),
        values: {},
        increment: 0
      };
    }
    return this.getColorAccessor(namespace);
  }

  hexToRGB(hex: string, alpha: number): string {
    if (!formats.test('hexaDecimalColor', hex)) {
      return 'rgba(255, 255, 255, 1)';
    }
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);

    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  }

  hexToHSL(hex: string, lightnessVariation = 0) {
    let r = parseInt(hex.slice(1, 3), 16);
    let g = parseInt(hex.slice(3, 5), 16);
    let b = parseInt(hex.slice(5, 7), 16);

    r /= 255;
    g /= 255;
    b /= 255;
    const max = Math.max(r, g, b);
    const min = Math.min(r, g, b);
    const c = max - min;
    let lum = (max + min) / 2;
    let hue: number, sat: number, segment: number, shift: number;

    if (c === 0) {
      hue = 0;
      sat = 0;
    } else {
      sat = c / (1 - Math.abs(2 * lum - 1));
      switch (max) {
        case r:
          segment = (g - b) / c;
          shift = 0 / 60; // R° / (360° / hex sides)
          if (segment < 0) {
            // hue > 180, full rotation
            shift = 360 / 60; // R° / (360° / hex sides)
          }
          hue = segment + shift;
          break;
        case g:
          segment = (b - r) / c;
          shift = 120 / 60; // G° / (360° / hex sides)
          hue = segment + shift;
          break;
        case b:
          segment = (r - g) / c;
          shift = 240 / 60; // B° / (360° / hex sides)
          hue = segment + shift;
          break;
      }
    }

    hue = Math.round(hue * 60); // °
    sat = Math.round(sat * 100); // %
    lum = Math.round(lum * 100); // %
    if (lum + lightnessVariation < 0) {
      lum = 0;
    } else if (lum + lightnessVariation > 100) {
      lum = 100;
    } else {
      lum += lightnessVariation;
    }

    return 'hsl(' + hue + ', ' + sat + '%, ' + lum + '%)';
  }

  private getColorAccessor(namespace: string = 'root-namespace'): (id: string) => string {
    return (id: string) => {
      return (
        this.namespaces[namespace].values[id] || (this.namespaces[namespace].values[id] = this.getNewColor(namespace))
      );
    };
  }

  private getNewColor(namespace: string): string {
    const notUsedColors = this.namespaces[namespace].colors.filter(
      (color: string) =>
        !Object.keys(this.namespaces[namespace].values).find(
          (key: string) => color === this.namespaces[namespace].values[key]
        )
    );
    this.namespaces[namespace].increment += 1;
    return notUsedColors.length
      ? notUsedColors[0]
      : this.namespaces[namespace].colors[
          this.namespaces[namespace].increment % this.namespaces[namespace].colors.length
        ];
  }

  private shuffleColors(colors: string[]): string[] {
    for (let i = colors.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [colors[i], colors[j]] = [colors[j], colors[i]];
    }
    return colors;
  }
}
