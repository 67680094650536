import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormArray, FormControl } from '@angular/forms';

import { AssertEqualForm } from './assert-equal-form.model';
import { ActivateSectionEvent } from '../mapping-switch-form.component';
import { BaseMappingFormComponent } from '../../shared/base-mapping-form.component';

@Component({
  selector: 'app-assert-equal-form',
  templateUrl: './assert-equal-form.component.html',
  styleUrls: ['./assert-equal-form.component.scss']
})
export class AssertEqualFormComponent extends BaseMappingFormComponent implements OnInit {
  @Input() parentForm: AssertEqualForm;
  @Output() activateSection: EventEmitter<ActivateSectionEvent>;

  get assertEqualActive(): FormControl {
    return this.parentForm.get('active') as FormControl;
  }

  get assertEqualComparisons(): FormArray {
    return this.parentForm.get('comparisons') as FormArray;
  }

  constructor() {
    super();
    this.activateSection = new EventEmitter();
  }

  ngOnInit() {}

  activate() {
    this.parentForm.updateActive(this.assertEqualActive.value);
    this.activateSection.emit({ section: 'assertEqual', value: this.assertEqualActive.value });
  }
}
