import { Component, OnInit, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

import { ButtonCallForm } from './button-call-form.model';
import { BaseTemplateFormComponent } from '../base-template-form.component';

@Component({
  selector: 'app-button-call-form',
  templateUrl: './button-call-form.component.html',
  styleUrls: ['./button-call-form.component.scss']
})
export class ButtonCallFormComponent extends BaseTemplateFormComponent implements OnInit {
  @Input() parentForm: ButtonCallForm;
  @Input() selectedParentKey: FormControl;

  get title(): FormControl {
    return this.parentForm.get('title') as FormControl;
  }

  get phoneNumber(): FormControl {
    return this.parentForm.get('phoneNumber') as FormControl;
  }

  constructor() {
    super();
  }

  ngOnInit() {}
}
