import { Component, OnInit, Input } from '@angular/core';

import { MessageTemplateButtons } from 'ideta-library/lib/common/message';

import { BasePreviewComponent } from '../shared/base-preview.component';
import { UserSessionService } from '../../services/session/user-session.service';

@Component({
  selector: 'app-template-buttons-preview',
  templateUrl: './template-buttons-preview.component.html',
  styleUrls: ['./template-buttons-preview.component.scss']
})
export class TemplateButtonsPreviewComponent extends BasePreviewComponent implements OnInit {
  @Input() hideTitle: boolean;

  constructor(private _user: UserSessionService) {
    super();
  }

  get template() {
    return this.message.template as MessageTemplateButtons;
  }

  ngOnInit() {}

  get lang(): string {
    return this.displayOptions.context === 'sandbox' ? this._user.lang : this.displayOptions.speechLang;
  }
}
