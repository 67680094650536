import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/functions';

import { UserRole } from 'ideta-library/lib/common/user';

import { BotSessionService } from '../session/bot-session.service';
import { ChannelSessionService } from '../session/channel-session.service';

@Injectable({
  providedIn: 'root'
})
export class BotApiService {
  constructor(
    private functions: AngularFireFunctions,
    private _bot: BotSessionService,
    private _channel: ChannelSessionService
  ) {}

  public createBot(options: {
    bot?: any;
    templateId?: string;
    isDuplication?: boolean;
  }): Promise<{ botId: string; token: string }> {
    const callable = this.functions.httpsCallable('createBot');
    return callable(options).toPromise();
  }

  public deleteBot(botId: string): Promise<boolean> {
    const callable = this.functions.httpsCallable('deleteBot');
    return callable({
      botId
    }).toPromise();
  }

  public deploy(): Promise<any> {
    const callable = this.functions.httpsCallable('deployBot');
    return callable({
      botId: this._bot.id,
      channel: this._channel.type
    }).toPromise();
  }

  public deleteDeployment(): Promise<void> {
    const callable = this.functions.httpsCallable('undeployBot');
    return callable({
      botId: this._bot.id,
      channel: this._channel.type
    }).toPromise();
  }

  public connectFacebookPage(page: any, userId: string): Promise<void> {
    const callable = this.functions.httpsCallable('connectAutoReply');
//     console.log("connectFacebookPage : connecting", page)
    return callable({
      botId: "auto-reply-" + page.id,
      page,
      userId
    }).toPromise();
  }

  public disconnectFacebookPage(userId: string, pageId: string): Promise<void> {
    const callable = this.functions.httpsCallable('disconnectAutoReply');
    return callable({
      botId: "useless",
      userId,
      pageId
    }).toPromise();
  }

  public updateNbNodes(botId: string): Promise<void> {
    const callable = this.functions.httpsCallable('updateNbNodes');
    return callable({
      botId
    }).toPromise();
  }

  public updateBotUsers(botId: string, userId: string, role: UserRole): Promise<boolean> {
    if (botId && userId && role) {
      const callable = this.functions.httpsCallable('updateBotUsers');
      return callable({
        botId,
        userId,
        role
      })
        .toPromise()
        .then(() => true);
    } else {
      console.error('Missing informations while updating bot user');
      return Promise.resolve(false);
    }
  }
}
