import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { WebDisplayComponent } from './web-display/web-display.component';
import { LoginComponent } from './login/login.component';
import { OauthCallbackComponent } from './oauth-callback/oauth-callback.component';
import { EmbeddedWebDisplayComponent } from './web-display/embedded-web-display/embedded-web-display.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { SignupComponent } from './signup/signup.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { VerifyEmailComponent } from './verify-email/verify-email.component';

const appRoutes: Routes = [
  {
    path: '',
    loadChildren: () => import('./idechat/idechat.module').then(m => m.IdechatModule)
  },
  { path: 'login', component: LoginComponent, pathMatch: 'full' },
  { path: 'signup', component: SignupComponent, pathMatch: 'full' },
  { path: 'forgot-password', component: ForgotPasswordComponent, pathMatch: 'full' },
  { path: 'change-password', component: ChangePasswordComponent, pathMatch: 'full' },
  { path: 'verify-email', component: VerifyEmailComponent, pathMatch: 'full' },
  {
    path: 'embedded/:botid',
    component: EmbeddedWebDisplayComponent,
    pathMatch: 'full'
  },
  {
    path: 'web-display/:botid',
    component: WebDisplayComponent,
    pathMatch: 'full'
  },
  {
    path: 'oauth-callback/:status',
    component: OauthCallbackComponent,
    pathMatch: 'full'
  },
  { path: '**', component: PageNotFoundComponent, pathMatch: 'full' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {
      paramsInheritanceStrategy: 'always'
      /*      enableTracing: true */
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
