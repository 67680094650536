import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormArray, FormControl } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { pick } from 'lodash';

import { DataStore, DataStoreElem, DataStoreElemType } from 'ideta-library/lib/common/data';

import { BotDataForm } from './bot-data-form.model';
import { DataStoreSessionService } from '../../../../services/session/data-store-session.service';
import { ProtocolDroidService } from '../../../../protocol-droid/services/protocol-droid.service';
import { UniqueFormControl } from '../../../../models/unique-form-control.model';
import { DataAsyncActionForm } from './data-async-action-form/data-async-action-form.model';
import { KeyCreationEvent } from '../../../../models/data-selector.component';
import { UserSessionService } from '../../../../services/session/user-session.service';

import { environment } from '../../../../../../environments/environment';

@Component({
  selector: 'app-bot-data-form',
  templateUrl: './bot-data-form.component.html',
  styleUrls: ['./bot-data-form.component.scss']
})
export class BotDataFormComponent implements OnInit, OnDestroy {
  @Input() data$: Observable<any>;
  @Input() scope: string;
  @Input() isExpanded: boolean;
  @Input() isRemovable: boolean;
  @Output() update: EventEmitter<DataStoreElem>;
  @Output() remove: EventEmitter<void>;
  @Output() expand: EventEmitter<void>;
  @Output() createDataKey: EventEmitter<KeyCreationEvent>;
  @Output() createNode: EventEmitter<{
    name: string;
    callback: (key: string) => void;
  }>;
  dataForm: FormGroup;
  types: { [key in DataStoreElemType]: string };
  expandedActions: { [key: string]: boolean };
  htmlID: string;
  inputTypes: { [key: string]: string };
  private currentDataId: string;
  private dataFormSub: Subscription;
  private subscriptions: Subscription[];

  get key(): UniqueFormControl {
    return this.dataForm.get('key') as UniqueFormControl;
  }

  get desc(): FormControl {
    return this.dataForm.get('desc') as FormControl;
  }

  get type(): FormControl {
    return this.dataForm.get('type') as FormControl;
  }

  get elements(): FormGroup {
    return this.dataForm.get('elements') as FormGroup;
  }

  get keys(): FormGroup {
    return this.dataForm.get('keys') as FormGroup;
  }

  get init(): FormControl {
    return this.dataForm.get('init') as FormControl;
  }

  get isTemplateKey(): FormControl {
    return this.dataForm.get('isTemplateKey') as FormControl;
  }

  get value(): FormControl {
    return this.dataForm.get('value') as FormControl;
  }

  get elementsType(): FormControl {
    return this.elements.get('type') as FormControl;
  }

  get elementsKeys(): FormGroup {
    return this.elements.get('keys') as FormGroup;
  }

  get actions(): FormArray {
    return this.dataForm.get('actions') as FormArray;
  }

  get editable(): FormControl {
    return this.dataForm.get('editable') as FormControl;
  }

  get keepValue(): FormControl {
    return this.dataForm.get('keepValue') as FormControl;
  }

  get minimum(): FormControl {
    return this.dataForm.get('minimum') as FormControl;
  }

  get allowedKeyTypes() {
    return this.scope === 'bot' ? this.literalTypes : this.types;
  }

  get nonIterableTypes() {
    return pick(this.types, ['string', 'number', 'boolean', 'object']);
  }

  get literalTypes() {
    return pick(this.types, ['string', 'number', 'boolean']);
  }

  get color(): string {
    switch (this.scope) {
      case 'conversation':
        return environment.colors.primary;
      case 'bot':
        return environment.colors.secondary;
      default:
        return environment.colors.tertiary;
    }
  }

  constructor(
    private protocolDroidService: ProtocolDroidService,
    private _dataStore: DataStoreSessionService,
    private _user: UserSessionService
  ) {
    this.update = new EventEmitter();
    this.remove = new EventEmitter();
    this.expand = new EventEmitter();
    this.createDataKey = new EventEmitter();
    this.createNode = new EventEmitter();
    this.isRemovable = true;
    this.expandedActions = {};
    this.inputTypes = { string: 'text', number: 'number', boolean: 'checkbox' };
    this.subscriptions = [];
  }

  ngOnInit() {
    this.subscriptions.push(
      this._dataStore.subject$.subscribe((dataStore: DataStore) => {
        if (this.dataForm) {
          this.key.setBlackList(
            Object.keys(dataStore)
              .filter((id: string) => id !== this.currentDataId)
              .map((id: string) => dataStore[id].key)
          );
        }
      }),
      this.data$
        .pipe(filter((data: DataStoreElem) => data && data.id !== this.currentDataId))
        .subscribe((data: DataStoreElem) => {
          this.htmlID = data.scope + data['index'];
          this.currentDataId = data.id;
          this.dataForm = new BotDataForm(data, this._user.isSuperAdmin);
          this.key.setBlackList(
            Object.keys(this._dataStore.value)
              .filter((id: string) => id !== this.currentDataId)
              .map((id: string) => this._dataStore.value[id].key)
          );
          this.dataFormSub = this.dataForm.valueChanges.subscribe(() => this.updateData());
        }),
      this.protocolDroidService.onTranslationChangeEvent.subscribe(() => {
        this.types = {
          string: this.protocolDroidService.translate(
            'idechat.edition.bot-data.bot-data-form.data-type-string',
            'Texte'
          ),
          number: this.protocolDroidService.translate(
            'idechat.edition.bot-data.bot-data-form.data-type-number',
            'Nombre'
          ),
          boolean: this.protocolDroidService.translate(
            'idechat.edition.bot-data.bot-data-form.data-type-boolean',
            'Booléen'
          ),
          array: this.protocolDroidService.translate('idechat.edition.bot-data.bot-data-form.data-type-array', 'Liste'),
          object: this.protocolDroidService.translate(
            'idechat.edition.bot-data.bot-data-form.data-type-object',
            'Objet'
          )
        };
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
    if (this.dataFormSub && !this.dataFormSub.closed) this.dataFormSub.unsubscribe();
  }

  addAction() {
    this.actions.push(new DataAsyncActionForm());
    this.expandedActions[this.actions.length - 1] = true;
  }

  removeAction(index: number) {
    this.expandedActions[index] = false;
    this.actions.removeAt(index);
  }

  trackByFn(index: number): number {
    return index;
  }

  private updateData() {
    if (this.dataForm.valid) this.update.emit(this.dataForm.value);
  }
}
