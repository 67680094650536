import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { Observable, of, combineLatest } from 'rxjs';
import { distinctUntilChanged, map, switchMap, take, filter } from 'rxjs/operators';

import { Bot, BotTemplateSettings } from 'ideta-library/lib/common/bot';
import { NlpOptions, SaveNlpOptions } from 'ideta-library/lib/common/node';

import { BotSessionService } from '../session/bot-session.service';
import { UserSessionService } from '../session/user-session.service';
import { ChannelSessionService } from '../session/channel-session.service';

@Injectable({
  providedIn: 'root'
})
export class BotService {
  constructor(
    private db: AngularFireDatabase,
    private _bot: BotSessionService,
    private _user: UserSessionService,
    private _channel: ChannelSessionService
  ) {}

  getBots(): Observable<Bot[]> {
    return this._user.subject$.pipe(
      take(1),
      switchMap(() => (this._user.isSauron ? this.getAllBots() : this.getBotsFromUserList()))
    );
  }

  getBotTemplates(): Observable<BotTemplateSettings[]> {
    return this.db
      .object<{ [key: string]: boolean }>(`/bot-templates`)
      .valueChanges()
      .pipe(
        filter(botTemplateIndex => !!botTemplateIndex),
        switchMap(botTemplateIndex =>
          combineLatest(Object.keys(botTemplateIndex).map(index => this.getBotTemplateSettings(index)))
        )
      );
  }

  getBotTemplateSettings(id: string): Observable<BotTemplateSettings> {
    return this.db
      .object<BotTemplateSettings>(`/bots/${id}/templateSettings`)
      .snapshotChanges()
      .pipe(
        map(botSnapshot => ({
          id,
          ...botSnapshot.payload.val()
        }))
      );
  }

  updateBot(botId: string, botData: any): Promise<any> {
    if (botId) {
      return this.db
        .object(`bots/${botId}`)
        .update(this.formatBotBeforeSave(botData))
        .then(() => ({ success: true }));
    } else {
      return Promise.reject({ error: new Error('Missing bot id for update') });
    }
  }

  exportBot(botId: string): PromiseLike<boolean> {
    return this.db
      .list(`/bot-exports/${botId}`)
      .push({ status: 'starting' })
      .then(() => true);
  }

  runCustomAnalytics(botId: string): PromiseLike<boolean> {
    return this.db
      .list('/bot-analytics-request/' + botId)
      .push({ status: 'starting' })
      .then(() => true);
  }

  getBotUsersIds(botId: string): Observable<any> {
    return this.db.list(`/bots/${botId}/users`).valueChanges();
  }

  getInBot<T>(child: string): Observable<T> {
    return this.db.object<T>(`/bots/${this._bot.id}/${child}`).valueChanges();
  }

  updateGlobalIntents(intents: NlpOptions): Promise<any> {
    return this.db.object<NlpOptions>(`/bots/${this._bot.id}/globalIntents`).update(intents);
  }

  updateGlobalNlpStorage(storage: SaveNlpOptions): Promise<any> {
    return this.db.object<SaveNlpOptions>(`/bots/${this._bot.id}/globalNlpStorage`).update(storage);
  }

  updateDeploymentStatus(status: 'deploying' | 'undeploying'): Promise<any> {
    return this.db.object(`/bots/${this._bot.id}/channels/${this._channel.type}`).update({ status, statusError: null });
  }

  removeUser(botId: string, userId: string): Promise<boolean> {
    if (botId && userId) {
      return this.db
        .object(`/bots/${botId}/users/${userId}`)
        .remove()
        .then(() => true)
        .catch(error => {
          console.error(error);
          return false;
        });
    } else {
      console.error('Missing user id while removing user from bot');
      return Promise.resolve(false);
    }
  }

  isAuthServiceAvailable(service: string): Observable<boolean> {
    return this.db
      .object(`/bots/${this._bot.id}/oauthServices/${service}`)
      .valueChanges()
      .pipe(map((options: any) => !!(options && options.clientId && options.clientSecret)));
  }

  private formatBotBeforeSave(bot: any): any {
    // Removing last '/' in bot endpoint
    if (bot.endPointBack && bot.endPointBack.length > 0 && bot.endPointBack[bot.endPointBack.length - 1] === '/') {
      bot.endPointBack = bot.endPointBack.slice(0, -1);
    }
    return bot;
  }

  private getBotsFromUserList(): Observable<Bot[]> {
    return this.db
      .list(`/users/${this._user.id}/bots`)
      .valueChanges()
      .pipe(
        switchMap((bots: any[]) => {
          if (bots && bots.length > 0) {
            return combineLatest(bots.map((botInfos: any) => this._bot.getBot(botInfos.id)));
          } else {
            return of([]);
          }
        })
      );
  }

  private getAllBots(): Observable<Bot[]> {
    return this.db
      .object(`/bots`)
      .valueChanges()
      .pipe(
        distinctUntilChanged((prev, curr) => Object.keys(prev).length === Object.keys(curr).length),
        map(bots => Object.keys(bots).map(id => ({ id, ...bots[id] })))
      );
  }
}
