import { AngularFireDatabase } from '@angular/fire/database';
import { Router } from '@angular/router';
import { filter, map, distinctUntilChanged, tap, switchMap } from 'rxjs/operators';
import { BehaviorSubject, Subscription, Observable } from 'rxjs';
import { Injectable, OnDestroy } from '@angular/core';
import { get } from 'lodash';

import { User, UserRole } from 'ideta-library/lib/common/user';

import { ProtocolDroidService } from './../../protocol-droid/services/protocol-droid.service';
import { AuthService } from '../../services/auth/auth.service';
import { SessionModel } from './session.model';
import { BotAppPage, CoreSessionService } from './core-session.service';
import { FeedbackService } from '../feedback/feedback.service';

import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserSessionService implements SessionModel, OnDestroy {
  public pagesAccess: { [key in BotAppPage]: boolean };
  private _subject$: BehaviorSubject<User>;
  private userSub: Subscription;
  private authSub: Subscription;

  get subject$() {
    return this._subject$.pipe(filter(value => value && !!value.id));
  }

  get distinctSubject$() {
    return this.subject$.pipe(distinctUntilChanged((prev, curr) => prev.id === curr.id));
  }

  private get user(): Partial<User> {
    return this._subject$.value || {};
  }

  get id() {
    return this.user.id;
  }

  get email() {
    return this.user.email;
  }

  get lang() {
    return this.user.lang;
  }

  get settings() {
    return this.user.settings;
  }

  get isSauron() {
    return this.user.id === environment.superAdmin.id;
  }

  get isSuperAdmin() {
    return this.user.status === 'super_admin';
  }

  get isAdmin() {
    return this.user.status === 'admin';
  }

  get customerId() {
    return this.user.customer && this.user.customer.id;
  }

  get facebook() {
    return this.user.facebook;
  }

  get exists(): boolean {
    return !!this._subject$.value;
  }

  constructor(
    private auth: AuthService,
    private db: AngularFireDatabase,
    private router: Router,
    private protocolDroidService: ProtocolDroidService,
    private feedbackService: FeedbackService,
    private _session: CoreSessionService
  ) {
    this._subject$ = new BehaviorSubject(null);
    this.pagesAccess = {
      'bots-list': true,
      'data-manager': true,
      'accounts': true,
      edition: true,
      deployment: true,
      analytics: true,
      broadcast: true,
      lexicon: true,
      messaging: true,
      settings: true,
      testing: true
    };
    this.authSub = this.auth
      .getAuth()
      .pipe(
        map(user => user && user.uid),
        distinctUntilChanged()
      )
      .subscribe(userId => {
        if (userId) this.startSession(userId);
      });
  }

  startSession(userId: string) {
    // ###M
    // console.log('init _user', userId);
    this.userSub = this.getUser(userId)
      .pipe(filter(user => !!user))
      .pipe(
        tap(user => {
          this._subject$.next(user);
          this.protocolDroidService.setLang(
            this.user.lang || get(navigator, 'language') || get(navigator, 'userLanguage')
          );
        }),
        switchMap(() => this._session.routerEvent$)
      )
      .subscribe(event => {
        const isSupport = this.getRoleIn(event.botId) === 'support';
        Object.keys(this.pagesAccess).forEach(page => {
          this.pagesAccess[page] =
            !isSupport || environment.appSettings.supportPagesAccess.indexOf(page) !== -1 ? true : false;
        });
        if (isSupport && !this.pagesAccess[event.location]) {
          this.feedbackService.showMessage('You do not have the right to access this page', {
            type: 'message',
            decoration: 'warning'
          });
          this.router.navigate(['../messaging', event.botId]);
        }
      });
  }

  endSession() {
    // ###M
    // console.log('reset _user');
    if (this.userSub) this.userSub.unsubscribe();
    this._subject$.next(null);
  }

  ngOnDestroy() {
    this.endSession();
    if (this.authSub) this.authSub.unsubscribe();
  }

  getRoleIn(botId): UserRole | '' {
    return this.user.bots && this.user.bots[botId] && this.user.bots[botId].role;
  }

  // This service has its own getUser() function
  // to avoid circular dependencies with userService
  private getUser(userId: string): Observable<User> {
    return this.db
      .object<User>('/users/' + userId)
      .valueChanges()
      .pipe(map((user: any) => ({ ...user, id: userId })));
  }
}
