import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { ProtocolDroidService } from '../../protocol-droid/services/protocol-droid.service';
import { environment } from '../../../../environments/environment';

@Injectable()
export class OpengraphioService {
  endPoint: string;
  apiKey: string;

  constructor(private http: HttpClient, private protocolDroidService: ProtocolDroidService) {
    this.endPoint = environment.opengraphio.endPoint;
    this.apiKey = environment.opengraphio.apiKey;
  }

  public getOpenGraphMetaData(url: string): Observable<any> {
    return this.http
      .get(this.endPoint + encodeURIComponent(url), { params: { app_id: this.apiKey } })
      .pipe(
        map((res: any) => {
          if (res.error && res.error.code === 101) {
            return {
              title: 'API Limitation',
              description: 'Cannot display metadata'
            };
          } else if (res.error) {
            throw new Error(res.error.message);
          } else {
            return res.openGraph;
          }
        }),
        catchError((error: any) => {
          console.error(error.message);
          return of({
            title: this.protocolDroidService.translate('misc.modals.error-title', 'Oops...'),
            description: this.protocolDroidService.translate(
              'misc.errors.unknown-short',
              'Unknown Error'
            )
          });
        })
      );
  }
}
