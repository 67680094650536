import { Component, Input, EventEmitter, Output } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

import { KeyCreationEvent } from '../../models/data-selector.component';

@Component({
  selector: 'app-base-mapping-form',
  template: ''
})
export class BaseMappingFormComponent {
  @Input() parentForm: FormGroup;
  @Output() createNode: EventEmitter<{
    name: string;
    callback: (key: string) => void;
  }>;
  @Output() createDataKey: EventEmitter<KeyCreationEvent>;

  constructor() {
    this.createNode = new EventEmitter();
    this.createDataKey = new EventEmitter();
  }

  updateDropDownValue(control: FormControl, value: any) {
    control.patchValue(value);
  }

  createNodeDropDownValue(control: FormControl, name: string) {
    this.createNode.emit({
      name,
      callback: (key: string) => control.patchValue(key)
    });
  }

  trackByFn(index: number, _item: any): number {
    return index;
  }
}
