import { FormGroup, FormArray, FormControl } from '@angular/forms';
import { get } from 'lodash';

import { MappingType, NodeMapping } from 'ideta-library/lib/common/node';

import { MappingDataInputForm } from './mapping-data-input-form/mapping-data-input-form.model';
import { MappingGoToNodeForm } from './mapping-go-to-node-form/mapping-go-to-node-form.model';
import { MappingSwitchForm } from './mapping-switch-form/mapping-switch-form.model';
import { MappingAsyncActionForm } from './mapping-async-action-form/mapping-async-action-form.model';
import { MappingOptionsForm } from './mapping-options-form/mapping-options-form.model';

export class NodeMappingForm extends FormGroup {
  constructor(nodeMapping: Partial<NodeMapping> = {}) {
    const formGroup = {
      type: new FormControl(nodeMapping.type || 'data-input'),
      dataInput: new MappingDataInputForm(nodeMapping.dataInput),
      goToNode: new MappingGoToNodeForm(nodeMapping.goToNode),
      switch: new MappingSwitchForm(nodeMapping.switch),
      actions: new FormArray(
        get(nodeMapping, 'actions', [] as any).map((action: any) => new MappingAsyncActionForm(action))
      ),
      options: new MappingOptionsForm(nodeMapping.options)
    };

    super(formGroup);
    this.switchControlsDisabling(this.get('type').value);
  }

  updateType(type: MappingType) {
    this.get('type').patchValue(type, { emitViewToModelChange: false, emitEvent: false });
    this.switchControlsDisabling(type);
  }

  private switchControlsDisabling(type: MappingType) {
    /* Disabeling all template forms */
    this.get('dataInput').disable({ emitEvent: false });
    this.get('goToNode').disable({ emitEvent: false });
    this.get('switch').disable({ emitEvent: false });

    /* Re-enabeling only selected form */
    if (type === 'data-input') {
      this.get('dataInput').enable({ emitEvent: false });
    } else if (type === 'go-to-node') {
      this.get('goToNode').enable({ emitEvent: false });
    } else if (type === 'switch') {
      this.get('switch').enable({ emitEvent: false });
    }
    this.updateValueAndValidity();
  }
}
