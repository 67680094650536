import { Component, OnInit, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject, combineLatest } from 'rxjs';
import { switchMap, startWith } from 'rxjs/operators';

import { ButtonLoginService } from 'ideta-library/lib/common/node';
import { Bot } from 'ideta-library/lib/common/bot';
import { DataStoreElemKeys } from 'ideta-library/lib/common/data';

import { BotSessionService } from './../../../services/session/bot-session.service';
import { ButtonLoginForm } from './button-login-form.model';
import { BaseTemplateFormComponent } from '../base-template-form.component';
import { DataKeyFormControl } from '../../../models/data-key-form-control.model';
import { KeyCreationEvent } from '../../../models/data-selector.component';
import { BotService } from '../../../services/bot/bot.service';
import { NodeKeyFormControl } from '../../../models/node-key-form-control.model';
import { CoreSessionService } from '../../../services/session/core-session.service';

@Component({
  selector: 'app-button-login-form',
  templateUrl: './button-login-form.component.html',
  styleUrls: ['./button-login-form.component.scss']
})
export class ButtonLoginFormComponent extends BaseTemplateFormComponent implements OnInit {
  @Input() parentForm: ButtonLoginForm;
  serviceTypes: { [key in ButtonLoginService]: string };
  isServiceAvailable: boolean;
  private service$: Subject<string>;

  get title(): FormControl {
    return this.parentForm.get('title') as FormControl;
  }

  get service(): FormControl {
    return this.parentForm.get('service') as FormControl;
  }

  get targetNodeLoginSuccess(): NodeKeyFormControl {
    return this.parentForm.get('targetNodeLoginSuccess') as NodeKeyFormControl;
  }

  get targetNodeLoginFailure(): NodeKeyFormControl {
    return this.parentForm.get('targetNodeLoginFailure') as NodeKeyFormControl;
  }

  get dataProfileKey(): DataKeyFormControl {
    return this.parentForm.get('dataProfileKey') as DataKeyFormControl;
  }

  constructor(
    private botService: BotService,
    /*     private modalService: BsModalService,
     */ private _bot: BotSessionService,
    public _session: CoreSessionService
  ) {
    super();
    // Weird format but we need to match simple-dropdown format
    this.serviceTypes = { google: 'Google', facebook: 'Facebook' };
    this.service$ = new Subject();
  }

  ngOnInit() {
    this.subscriptions.push(
      combineLatest([this._bot.subject$, this.service$.pipe(startWith(this.service.value))])
        .pipe(switchMap(([_bot, service]: [Bot, string]) => this.botService.isAuthServiceAvailable(service)))
        .subscribe((isAvailable: boolean) => (this.isServiceAvailable = isAvailable))
    );
  }

  createNodeDropDownValue(control: FormControl, name: string) {
    this.createNode.emit({
      name,
      callback: (key: string) => this.updateControlValue(control, key)
    });
  }

  createProfileKey(event: KeyCreationEvent) {
    const { dataKey, callback } = event;
    const keys: DataStoreElemKeys = this.parentForm
      .getServiceProfileKeys(this.service.value)
      .reduce((prev: DataStoreElemKeys, key: string) => {
        prev[key] = {
          key,
          type: 'string'
        };
        return prev;
      }, {});
    this.createDataKey.emit({
      dataKey: { ...dataKey, type: 'object', keys },
      callback
    });
  }

  /*   openBotSettingsModal(): void {
    const modalOptions = {
      class: `modal-dialog-centered modal-lg`
    };

    this.modalService.show(ModalBotSettingsComponent, modalOptions);
  } */

  private updateControlValue(control: FormControl, value: any) {
    control.patchValue(value);
  }
}
