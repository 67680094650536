import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss']
})
export class BadgeComponent implements OnInit {
  @Input() value: string;
  @Input() title?: string;
  @Input() decoration?:
    | 'scope-none'
    | 'scope-conversation'
    | 'scope-bot'
    | 'scope-system'
    | 'scope-element'
    | 'invalid';
  @Input() warning?: boolean;
  @Output() clickEvent: EventEmitter<void>;
  @Output() remove: EventEmitter<void>;

  constructor() {
    this.clickEvent = new EventEmitter();
    this.remove = new EventEmitter();
  }

  ngOnInit() {
    this.title = this.title || this.value;
    this.decoration = this.decoration || 'scope-none';
  }
}
