import { FormGroup } from '@angular/forms';

import { MappingSwitch } from 'ideta-library/lib/common/node';

import { AssertEqualForm } from './assert-equal-form/assert-equal-form.model';
import { PerformOperationsForm } from './perform-operations-form/perform-operations-form.model';
import { SendToExternalApiForm } from './send-to-external-api-form/send-to-external-api-form.model';

const defaultMapping: MappingSwitch = {
  assertEqual: { active: false },
  performOperations: { active: false },
  sendToExternalApi: { active: false }
};

export class MappingSwitchForm extends FormGroup {
  constructor(mapping: MappingSwitch = defaultMapping) {
    const formGroup = {
      assertEqual: new AssertEqualForm(mapping.assertEqual),
      performOperations: new PerformOperationsForm(mapping.performOperations),
      sendToExternalApi: new SendToExternalApiForm(mapping.sendToExternalApi)
    };

    super(formGroup);
  }
}
