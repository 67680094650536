import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormArray, FormControl } from '@angular/forms';

import { EmailActionForm } from './email-action-form.model';
import { RichInputFormControl } from '../../../../models/rich-input-form-control.model';
import { KeyCreationEvent } from '../../../../models/data-selector.component';

@Component({
  selector: 'app-email-action-form',
  templateUrl: './email-action-form.component.html',
  styleUrls: ['./email-action-form.component.scss']
})
export class EmailActionFormComponent implements OnInit {
  @Input() parentForm: EmailActionForm;
  @Output() createDataKey: EventEmitter<KeyCreationEvent>;

  get from(): FormControl {
    return this.parentForm.get('from') as FormControl;
  }

  get addresses(): FormArray {
    return this.parentForm.get('addresses') as FormArray;
  }

  get title(): FormControl {
    return this.parentForm.get('title') as FormControl;
  }

  get content(): FormControl {
    return this.parentForm.get('content') as FormControl;
  }

  constructor() {
    this.createDataKey = new EventEmitter();
  }

  ngOnInit() {}

  submitAddress(index: number) {
    if (this.addresses.at(index + 1)) {
      const nextInput = this.addresses.at(index + 1) as RichInputFormControl;
      nextInput.focus$.next();
    } else {
      const nextInput = this.parentForm.addAddress();
      setTimeout(() => nextInput.focus$.next(), 0);
    }
  }

  trackByFn(index: number): number {
    return index;
  }
}
